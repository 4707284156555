import { myInfoType } from "../../types/User";

export const setUserInfoFromLocalStorage = (userInfo: any) => {
  return window.localStorage.setItem(
    "minsatUserInfo",
    JSON.stringify(userInfo)
  );
};
export const getUserInfoFromLocalStorage = (): myInfoType | null => {
  let userInfo = window.localStorage.getItem("minsatUserInfo");
  return userInfo ? JSON.parse(userInfo) : null;
};
export const removeUserInfoFromLocalStorage = () => {
  window.localStorage.removeItem("minsatUserInfo");
};

export const setAccessToken = (token: string) => {
  return window.localStorage.setItem("mindsatAccessToken", token);
};

export const getAccessToken = (): string | null => {
  return window.localStorage.getItem("mindsatAccessToken");
};
export const removeAccessToken = () => {
  return window.localStorage.removeItem("mindsatAccessToken");
};

export const setLocalCustomerCode = (code: string) => {
  return window.localStorage.setItem("mindsatCustomerCode", code);
};

export const getLocalCustomerCode = (): string | null => {
  return window.localStorage.getItem("mindsatCustomerCode");
};
export const removeLocalCustomerCode = () => {
  return window.localStorage.removeItem("mindsatCustomerCode");
};

export const setSessionCustomerCode = (code: string) => {
  return window.sessionStorage.setItem("mindsatCustomerCode", code);
};

export const getSessionCustomerCode = (): string | null => {
  return window.sessionStorage.getItem("mindsatCustomerCode");
};
export const removeSessionCustomerCode = () => {
  return window.sessionStorage.removeItem("mindsatCustomerCode");
};

export const setMenuList = (token: string) => {
  return window.localStorage.setItem("mindsatMenu", token);
};

export const getMenuList = (): string | null => {
  return window.localStorage.getItem("mindsatMenu");
};
export const removeMenuList = () => {
  return window.localStorage.removeItem("mindsatMenu");
};
