import "./index.scss";
import { Box } from "fasoo-ui-component-next";

interface ImageThumbProps {
  src?: string;
  width?: string;
  height?: string;
}
const ImageThumb: React.FC<ImageThumbProps> = ({
  src,
  width = "77.5px",
  height = "77.5px",
}) => {
  return (
    <Box width={width} height={height}>
      <img className="img__thumb" src={src} />
    </Box>
  );
};

export default ImageThumb;
