import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import { BaseResponseBean, BaseResponseDTO } from "../../types/Common";
import { DateBean } from "../../types/Report";
import {
  ConfigCodeListType,
  DateCodeListType,
  RankDetailType,
  ReportRankType,
  ReportStateType,
} from "../../types/ReportRank";
import { ConfigListType } from "../../types/Send";

const GATEWAY_URL = config.servers.gateway.url;

interface ReportRankApi {
  getRank(config_ids: number[]): Promise<ReportRankType>;
  getRankByPeriod(
    dateBean: DateBean
  ): Promise<BaseResponseDTO<ReportStateType>>;
  getRankDetail(
    configCodeListType: ConfigCodeListType
  ): Promise<RankDetailType[]>;
  getUserReportRankDetailByConfig(req: {
    type: number;
    configCodeListType: ConfigCodeListType;
  }): Promise<RankDetailType[]>;
  getRankDetailByPeriod(
    dateCodeListType: DateCodeListType
  ): Promise<RankDetailType[]>;
  getRankDeptDetail(
    configCodeListType: ConfigCodeListType
  ): Promise<RankDetailType[]>;
  getRankDeptDetailByPeriod(
    dateCodeListType: DateCodeListType
  ): Promise<RankDetailType[]>;
  getConfigInfo(configId: number): Promise<ConfigListType>;
}

class ReportRankServerApi implements ReportRankApi {
  async getRank(config_ids: number[]): Promise<ReportRankType> {
    const res = await axios.post("/report/rank", config_ids);

    return Promise.resolve(res.data);
  }

  async getRankByPeriod(
    dateBean: DateBean
  ): Promise<BaseResponseDTO<ReportStateType>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/stat/report`, dateBean);

    return Promise.resolve(res.data);
  }

  async getRankDetail(
    configCodeListType: ConfigCodeListType
  ): Promise<RankDetailType[]> {
    const res = await axios.post("/report/rank/detail", configCodeListType);

    return Promise.resolve(res.data);
  }

  async getUserReportRankDetailByConfig(req: {
    type: number;
    configCodeListType: ConfigCodeListType;
  }): Promise<RankDetailType[]> {
    const res = await axios.post(
      `/report/rank/detail/config/${req.type}`,
      req.configCodeListType
    );

    return Promise.resolve(res.data);
  }

  async getRankDetailByPeriod(
    dateCodeListType: DateCodeListType
  ): Promise<RankDetailType[]> {
    const res = await axios.post(
      "/report/rank/detail/period",
      dateCodeListType
    );

    return Promise.resolve(res.data);
  }

  async getRankDeptDetail(
    configCodeListType: ConfigCodeListType
  ): Promise<RankDetailType[]> {
    const res = await axios.post(
      "/report/rank/dept/detail",
      configCodeListType
    );

    return Promise.resolve(res.data);
  }

  async getRankDeptDetailByPeriod(
    dateCodeListType: DateCodeListType
  ): Promise<RankDetailType[]> {
    const res = await axios.post(
      "/report/rank/dept/detail/period",
      dateCodeListType
    );

    return Promise.resolve(res.data);
  }

  async getConfigInfo(configId: number): Promise<ConfigListType> {
    const res = await axios.get(`/programs/${configId}`);

    return Promise.resolve(res.data);
  }
}

const reportRankApi: ReportRankApi = new ReportRankServerApi();

export default reportRankApi;
