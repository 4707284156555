const NoSearchResultImage = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      width={width || "96"}
      height="76"
      className={classes}
      viewBox="0 0 93 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.8977 5.79897C84.1331 5.79897 83.5215 5.17557 83.5215 4.42276V1.37621C83.5215 0.611637 84.1449 0 84.8977 0C85.6623 0 86.2739 0.6234 86.2739 1.37621V4.42276C86.2739 5.17557 85.6505 5.79897 84.8977 5.79897Z"
        fill="#6DA8FC"
      />
      <path
        d="M84.8977 15.1756C84.1331 15.1756 83.5215 14.5521 83.5215 13.7993V10.7528C83.5215 9.98822 84.1449 9.37659 84.8977 9.37659C85.6623 9.37659 86.2739 9.99999 86.2739 10.7528V13.7993C86.2739 14.5638 85.6505 15.1756 84.8977 15.1756Z"
        fill="#6DA8FC"
      />
      <path
        d="M86.6772 7.5739C86.6772 6.80933 87.3007 6.19769 88.0535 6.19769H91.1C91.8646 6.19769 92.4762 6.82109 92.4762 7.5739C92.4762 8.33847 91.8528 8.95011 91.1 8.95011H88.0535C87.3007 8.96187 86.6772 8.33847 86.6772 7.5739Z"
        fill="#6DA8FC"
      />
      <path
        d="M77.3008 7.5739C77.3008 6.80933 77.9242 6.19769 78.677 6.19769H81.7235C82.4881 6.19769 83.0998 6.82109 83.0998 7.5739C83.0998 8.33847 82.4763 8.95011 81.7235 8.95011H78.677C77.9124 8.96187 77.3008 8.33847 77.3008 7.5739Z"
        fill="#6DA8FC"
      />
      <path
        d="M10.7628 76C16.7069 76 21.5256 71.1813 21.5256 65.2372C21.5256 59.2931 16.7069 54.4744 10.7628 54.4744C4.81867 54.4744 0 59.2931 0 65.2372C0 71.1813 4.81867 76 10.7628 76Z"
        fill="#FFCC75"
      />
      <path
        d="M7.37615 61.4972C7.57611 61.0855 7.84667 60.7561 8.19954 60.4973C8.55242 60.2385 8.95235 60.0504 9.41109 59.9327C9.85808 59.8151 10.3286 59.7446 10.8109 59.7446C11.4578 59.7446 12.0577 59.8504 12.6105 60.0621C13.1634 60.2738 13.5986 60.6032 13.9279 61.0384C14.2573 61.4736 14.422 62.0147 14.422 62.6735C14.422 63.1322 14.3396 63.5203 14.1867 63.8262C14.0338 64.132 13.8339 64.3907 13.5868 64.6025C13.3398 64.8142 13.0928 65.0025 12.8458 65.1789C12.54 65.3789 12.3047 65.5552 12.1047 65.7082C11.9048 65.8611 11.7754 66.014 11.693 66.1905C11.6107 66.3669 11.5637 66.5904 11.5637 66.8727V67.2491H9.37581L9.36403 66.8962C9.32875 66.4963 9.35228 66.1434 9.44638 65.8258C9.54048 65.5082 9.6934 65.2259 9.90512 64.9789C10.1169 64.7319 10.3756 64.4966 10.6932 64.3084C11.0579 64.0732 11.3519 63.8497 11.5754 63.638C11.7989 63.438 11.9048 63.1557 11.9048 62.8146C11.9048 62.5441 11.846 62.3323 11.7283 62.1677C11.6107 62.003 11.4578 61.8854 11.2696 61.803C11.0814 61.7207 10.8814 61.6854 10.6697 61.6854C10.458 61.6854 10.2815 61.7207 10.1286 61.7795C9.9757 61.8501 9.84631 61.9324 9.74045 62.0618C9.63458 62.1794 9.55224 62.3205 9.49342 62.4852C9.43461 62.6381 9.41109 62.8146 9.41109 63.0028H7.1409C7.09385 62.4029 7.18794 61.9089 7.37615 61.4972ZM9.41109 68.5194C9.68164 68.2842 10.058 68.1666 10.5285 68.1666C11.0108 68.1666 11.3872 68.2842 11.6578 68.5194C11.9401 68.7547 12.0694 69.0723 12.0694 69.4604C12.0694 69.8369 11.9283 70.1427 11.6578 70.3897C11.3755 70.625 10.999 70.7426 10.5285 70.7426C10.058 70.7426 9.6934 70.625 9.41109 70.3897C9.14055 70.1545 8.9994 69.8486 8.9994 69.4604C8.9994 69.0723 9.14055 68.7547 9.41109 68.5194Z"
        fill="white"
      />
      <path
        d="M85.6181 72.8695C84.1478 74.3398 81.7482 74.3398 80.2779 72.8695L64.4807 57.0722C63.0103 55.6019 63.0103 53.2023 64.4807 51.732C65.951 50.2616 68.3506 50.2616 69.8209 51.732L85.6181 67.5292C87.1002 68.9996 87.1002 71.3991 85.6181 72.8695Z"
        fill="#6F7985"
      />
      <path
        d="M47.8981 64.6203C64.4053 64.6203 77.787 51.2386 77.787 34.7314C77.787 18.2243 64.4053 4.84253 47.8981 4.84253C31.391 4.84253 18.0093 18.2243 18.0093 34.7314C18.0093 51.2386 31.391 64.6203 47.8981 64.6203Z"
        fill="#CFD5DB"
      />
      <path
        d="M47.8934 56.4144C59.8661 56.4144 69.5719 46.7086 69.5719 34.7359C69.5719 22.7632 59.8661 13.0574 47.8934 13.0574C35.9207 13.0574 26.2148 22.7632 26.2148 34.7359C26.2148 46.7086 35.9207 56.4144 47.8934 56.4144Z"
        fill="white"
      />
    </svg>
  );
};

export default NoSearchResultImage;
