import "./index.scss";
import classNames from "classnames";
import { useEffect, useRef } from "react";
interface MailBodyProps {
  body: string;
  id?: number;
  classes?: string;
  height?: string | number;
  mailWidth?: string | number;
  width?: string | number;
  allowScroll?: boolean;
}
const MailBody: React.FC<MailBodyProps> = ({
  body,
  id,
  classes,
  height,
  width,
  mailWidth,
  allowScroll = true,
}) => {
  const shadowRef = useRef<any>(null);

  useEffect(() => {
    const updateShadowDom = () => {
      if (shadowRef.current && body) {
        let shadowRoot = shadowRef.current.shadowRoot;
        if (!shadowRoot) {
          shadowRoot = shadowRef.current.attachShadow({ mode: "open" });
        }

        shadowRoot.innerHTML = "";
        const container = document.createElement("div");
        container.style.color = "black";
        container.innerHTML = width
          ? `<style>
        img {
          width: ${width};
        }
      </style>` + body
          : body;

        container.id = "mailId";
        container.style.display = "flex";
        container.style.flexDirection = "column";
        shadowRoot.appendChild(container);
        document.getElementById("mail-body")?.classList.add("exist");
      }
    };

    updateShadowDom();
  }, [body]);
  return (
    <div
      id={"mail-body--" + id}
      className={classNames("mail-body", classes, { overflow: allowScroll })}
      ref={shadowRef}
      style={{
        height: height,
        width: mailWidth,
      }}
    />
  );
};

export default MailBody;
