// import $ from "jquery";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "summernote/dist/lang/summernote-ko-KR";
import "summernote/dist/summernote.css";
const useEditor = () => {
  //링크,이미지,사이트 대체
  const { t } = useTranslation();
  const [nameTooltip, setNameTooltip] = useState<string>();
  useEffect(() => {
    setNameTooltip(t("template.msg.templateEditorNameInfoMsg"));
  }, [t]);

  let linkBtn = function (context: any) {
    let ui = ($ as any).summernote.ui;

    let button = ui.button({
      className: "note-btn-phishing-link",
      contents: "Link",
      tooltip: t("template.msg.templateEditorLinkInfoMsg"),
      click: function () {
        let range = context.invoke("createRange");
        let text = range.toString();
        //let code = context.invoke("code");

        let filtered_text = removeUnwantedTags(text);
        let textstyle = getFontStyle(text);

        if (text !== textstyle) {
          let fontarr = textstyle.split("*");
          if (fontarr[0] !== "" && fontarr[1] !== "") {
            context.invoke(
              "editor.pasteHTML",
              '<a href="##LINK##" target="_BLANK" style="color: #5d9cec; font-family:"' +
                fontarr[0] +
                '"; font-size:"' +
                fontarr[1] +
                '";>' +
                filtered_text +
                "</a>"
            );
          } else if (fontarr[0] !== "") {
            context.invoke(
              "editor.pasteHTML",
              '<a href="##LINK##" target="_BLANK" style="color: #5d9cec; font-family:"' +
                fontarr[0] +
                '";>' +
                filtered_text +
                "</a>"
            );
          } else if (fontarr[1] !== "") {
            context.invoke(
              "editor.pasteHTML",
              '<a href="##LINK##" target="_BLANK" style="color: #5d9cec"; font-size:"' +
                fontarr[1] +
                '";>' +
                filtered_text +
                "</a>"
            );
          }
        } else {
          context.invoke(
            "editor.pasteHTML",
            '<a href="##LINK##" target="_BLANK" style="color: #5d9cec">' +
              filtered_text +
              "</a>"
          );
        }
      },
    });

    return button.render();
  };

  let imageBtn = function (context: any) {
    let ui = ($ as any).summernote.ui;

    let button = ui.button({
      className: "note-btn-phishing-image",
      contents: "Image",
      tooltip: t("template.msg.templateEditorImageInfoMsg"),
      click: function () {
        context.invoke("editor.pasteHTML", '<img src="##IMAGE##"/>');
      },
    });
    console.log(button);
    return button.render();
  };

  let nameBtn = function (context: any) {
    let ui = ($ as any).summernote.ui;

    let button = ui.button({
      className: "note-btn-name",
      contents: "Name",
      tooltip: t("template.msg.templateEditorNameInfoMsg"),
      // tooltip: nameTooltip,
      click: function () {
        context.invoke("editor.insertText", "##NAME##");
      },
    });

    return button.render();
  };

  let emailBtn = function (context: any) {
    let ui = ($ as any).summernote.ui;

    let button = ui.button({
      className: "note-btn-email",
      contents: "Email",
      tooltip: t("template.msg.templateEditorEmailInfoMsg"),
      click: function () {
        context.invoke("editor.insertText", "##EMAIL##");
      },
    });

    return button.render();
  };

  let dateBtn = function (context: any) {
    let ui = ($ as any).summernote.ui;
    let button = ui.button({
      className: "note-btn-date",
      contents: "Date",
      tooltip: t("template.msg.templateEditorInfoMsg"),
      click: function () {
        context.invoke("editor.insertText", "##DATE##");
      },
    });

    return button.render();
  };

  let yearBtn = function (context: any) {
    let ui = ($ as any).summernote.ui;
    let button = ui.button({
      className: "note-btn-year",
      contents: "Year",
      tooltip: t("template.msg.templateYearInfoMsg"),
      click: function () {
        context.invoke("editor.insertText", "##YEAR##");
      },
    });

    return button.render();
  };

  function removeUnwantedTags(text: string) {
    // Remove general span tags
    text = text.replace(/<span[^>]*>/g, "");
    text = text.replace(/<\/span>/g, "");
    text = text.replace(/##[^#]+##/g, "");

    // Remove specific span tags with 'color:transparent' or 'font-size:0px' in style
    text = text.replace(
      /<span[^>]*style\s*=\s*"(?:[^"]*(?:color\s*:\s*transparent|font-size\s*:\s*0px)[^"]*)"[^>]*>[^<]*<\/span>/g,
      ""
    );
    return text;
  }

  function getFontStyle(text: string) {
    text = text.replace(
      /<span[^>]*style\s*=\s*"([^"]*)"[^>]*>([^<]*)<\/span>/g,
      function (match, style, content) {
        if (
          style.includes("color:transparent") ||
          style.includes("font-size:0px")
        ) {
          return "";
        } else {
          var fontFamilyMatch = style.match(
            /font-family\s*:\s*&quot;([^;&"]*)&quot;/
          );
          var fontSizeMatch = style.match(/font-size\s*:\s*([^;"]*)/);

          var fontFamily = fontFamilyMatch ? fontFamilyMatch[1] : "";
          if (fontFamily === "&quot") {
            fontFamily = fontFamilyMatch[2];
          }
          var fontSize = fontSizeMatch ? fontSizeMatch[1] : "";
          return fontFamily + "*" + fontSize + "*";
        }
      }
    );
    return text;
  }

  return {
    linkBtn,
    imageBtn,
    nameBtn,
    emailBtn,
    dateBtn,
    yearBtn,
  };
};
export default useEditor;
