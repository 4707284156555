import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import signupApi from "../api/signupApi";
import { SignupType } from "../../types/Signup";

interface SignupState {}

const initialState: SignupState = {};

export const trunkIsExistsEmail = createAsyncThunk(
  "signup/isExistsEmail",
  async (email: string) => {
    const resp = await signupApi.isExistsEmail(email);

    return resp;
  }
);

export const trunkSendAuthMail = createAsyncThunk(
  "signup/sendAuthMail",
  async (email: string) => {
    const resp = await signupApi.sendAuthMail(email);

    return resp;
  }
);

export const trunkIsMatchAuthCode = createAsyncThunk(
  "signup/isMatchAuthCode",
  async (req: { email: string; code: string }) => {
    const resp = await signupApi.isMatchAuthCode(req);

    return resp;
  }
);
export const trunkIsExistsReportAddress = createAsyncThunk(
  "signup/isExistsReportAddress",
  async (address: string) => {
    const resp = await signupApi.isExistsReportAddress(address);

    return resp;
  }
);
export const trunkDoSignup = createAsyncThunk(
  "signup/doSignup",
  async (signupType: SignupType) => {
    const resp = await signupApi.doSignup(signupType);

    return resp;
  }
);

const signupSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkIsExistsEmail.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );

    builder.addCase(trunkSendAuthMail.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });

    builder.addCase(
      trunkIsMatchAuthCode.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );

    builder.addCase(
      trunkIsExistsReportAddress.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );

    builder.addCase(trunkDoSignup.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
  },
});

export const signupActions = signupSlice.actions;

export default signupSlice.reducer;
