import { Avatar, Box, Icons, Typography } from "fasoo-ui-component-next";
import "./index.scss";

interface SmallUserChipProps {
  userId: string;
  userName?: string;
  classes?: string;
}
const SmallUserChip: React.FC<SmallUserChipProps> = ({
  userId,
  userName,
  classes,
}) => {
  return (
    <Box alignItems="center" classes={`smallChip ${classes}`} maxWidth={"100%"}>
      <Avatar userId={userId} name={userName} size={"sm"} />
      <Typography
        size={12}
        classes="mg ml-4"
        ellipsis
        whiteSpace="pre"
        maxWidth={"400px"}
      >
        {userName + "(" + userId + ")"}
      </Typography>
    </Box>
  );
};

export default SmallUserChip;
