import { Box, Button, Typography } from "fasoo-ui-component-next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useAccountStore from "../../redux/dispatcher/useAccountStore";
import "./index.scss";
import { getAccessToken } from "../../shared/utils/auth";

const Denied: React.FC = () => {
  const { sessionCheck } = useAccountStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mindsatLogo = require("../../shared/image/logo/img_mindsat_bi.png");

  useEffect(() => {
    sessionCheck();
  }, []);

  return (
    <Box classes="denied" alignItems={"center"} direction={"column"}>
      <Box
        classes="denied-header"
        onClick={() => navigate(getAccessToken() ? "/" : "/login")}
      >
        <img
          src={mindsatLogo}
          alt={"Mindsat"}
          key={"mindsatLogoBox-img"}
          height={24}
        />
      </Box>
      <Box
        alignItems="center"
        justifyContent="center"
        direction="column"
        width={"100%"}
        height={"100%"}
      >
        <img
          src={require("../../shared/image/logo/NoPage.png")}
          alt={"notFound"}
        />
        <Typography size={18} isBold>
          {t("common.msg.notFound")}
        </Typography>
        <Button
          text={t("common.button.notFoundToHome")}
          classes="mg mt-20"
          onClick={() => navigate(getAccessToken() ? "/" : "/login")}
        />
      </Box>
    </Box>
  );
};

export default Denied;
