import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAccessToken, getMenuList } from "../shared/utils/auth";
import useCommonStore from "../redux/dispatcher/useCommonStore";
import { MenuBean } from "../types/Login";

interface Props {
  children: any;
}
const allMenu = [
  {
    menuIdx: 1,
    path: "/dashboard",
  },
  {
    menuIdx: 2,
    path: "/manageTrain",
  },
  {
    menuIdx: 3,
    path: "/manageScenario",
  },
  {
    menuIdx: 4,
    path: "/report",
  },
  {
    menuIdx: 5,
    path: "/organ",
  },
  {
    menuIdx: 6,
    path: "/manager",
  },
  {
    menuIdx: 7,
    path: "/system",
  },
  {
    menuIdx: 8,
    path: "/manageTrain/list",
  },
  {
    menuIdx: 9,
    path: "/manageTrain/result",
  },
  {
    menuIdx: 10,
    path: "/manageTrain/stat",
  },
  {
    menuIdx: 11,
    path: "/manageScenario/category",
  },
  {
    menuIdx: 12,
    path: "/manageScenario/mgnt",
  },
  {
    menuIdx: 13,
    path: "/report/setting",
  },
  {
    menuIdx: 14,
    path: "/report/view",
  },
  {
    menuIdx: 15,
    path: "/report/statistics",
  },
  {
    menuIdx: 16,
    path: "/manager/access",
  },
  {
    menuIdx: 17,
    path: "/manager/customer",
  },
  {
    menuIdx: 18,
    path: "/manager/log",
  },
];
const AuthRoute = ({ children }: Props) => {
  const accessToken = getAccessToken();
  const navigate = useNavigate();
  const { commonState } = useCommonStore();
  const [availableMenu, changeAvailableMenu] = useState<MenuBean[]>([]);

  let path = window.location.pathname.split("/");
  let path1 = path[path.length - 2];
  let path2 = path[path.length - 1];
  const currentMenuIdx = allMenu.filter(
    (item) =>
      item.path === (path1 ? "/" + path1 : "") + (path2 ? "/" + path2 : "")
  )[0]?.menuIdx;

  useEffect(() => {
    let menuStr = getMenuList();
    changeAvailableMenu(menuStr ? JSON.parse(menuStr) : commonState.accessMenu);
  }, [commonState.accessMenu]);
  useEffect(() => {
    console.log(availableMenu);
  }, [availableMenu]);
  if (!accessToken) navigate(`${process.env.PUBLIC_URL}/login`);
  if (
    availableMenu.filter((menu) => menu.menuIdx === currentMenuIdx).length === 0
  ) {
    navigate(`${process.env.PUBLIC_URL}/denied`);
  }
  return children;
};

export default AuthRoute;
