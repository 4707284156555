import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import reportApi from "../api/reportApi";
import {
  AttachmentType,
  ReportCommentType,
  ReportMail,
  ReportMailSearchType,
  SendReplyType,
} from "../../types/Report";

interface ReportState {
  reportListWithCnt: { total: number; list: ReportMail[] };
  reportDetail?: ReportMail;
  reportCnt: number;
}

const initialState: ReportState = {
  reportListWithCnt: { total: 0, list: [] },
  reportDetail: undefined,
  reportCnt: 0,
};

export const trunkGetReportMails = createAsyncThunk(
  "report/getReportMails",
  async (params: ReportMailSearchType) => {
    const resp = await reportApi.getReportMails(params);

    return resp;
  }
);

export const trunkGetReportMailCnt = createAsyncThunk(
  "report/getReportMailCnt",
  async (req: {
    searchTerm: string;
    rsd: string;
    red: string;
    fsd: string;
    fed: string;
    trainingFlag?: boolean | null;
  }) => {
    const resp = await reportApi.getReportMailCnt(req);

    return resp;
  }
);

export const trunkGetReportMailDetail = createAsyncThunk(
  "report/getReportMailDetail",
  async (id: number) => {
    const resp = await reportApi.getReportMailDetail(id);

    return resp;
  }
);

export const updateSpamFlag = createAsyncThunk(
  "report/updateSpamFlag",
  async (req: { flag: boolean; mailReportId: number }) => {
    const resp = await reportApi.updateSpamFlag(req);

    return resp;
  }
);

export const updateComment = createAsyncThunk(
  "report/updateComment",
  async (reportCommentType: ReportCommentType) => {
    const resp = await reportApi.updateComment(reportCommentType);

    return resp;
  }
);

export const updateComplete = createAsyncThunk(
  "report/updateComplete",
  async (req: { flag: boolean; mailReportId: number }) => {
    const resp = await reportApi.updateComplete(req);

    return resp;
  }
);

export const updateManualReplyState = createAsyncThunk(
  "report/updateManualReplyState",
  async (req: { mailReportId: number; nowFlag: boolean }) => {
    const resp = await reportApi.updateManualReplyState(req);

    return resp;
  }
);

export const sendManualReply = createAsyncThunk(
  "report/sendManualReply",
  async (sendReplyType: SendReplyType) => {
    const resp = await reportApi.sendManualReply(sendReplyType);

    return resp;
  }
);

export const getReportAttachments = createAsyncThunk(
  "report/getAttachments",
  async (id: number) => {
    const resp = await reportApi.getReportAttachments(id);

    return resp;
  }
);

export const downloadAttachment = createAsyncThunk(
  "report/downloadAttachment",
  async (attachmentType: AttachmentType) => {
    const resp = await reportApi.downloadAttachment(attachmentType);

    return resp;
  }
);
export const trunkUpdateReportMail = createAsyncThunk(
  "report/update",
  async (req: ReportCommentType) => {
    const resp = await reportApi.updateReportMail(req);

    return resp;
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkGetReportMails.fulfilled,
      (state, { payload: data }) => {
        state.reportListWithCnt = data.data;
      }
    );
    builder.addCase(
      trunkGetReportMailCnt.fulfilled,
      (state, { payload: data }) => {
        state.reportCnt = data;
      }
    );
    builder.addCase(
      trunkGetReportMailDetail.fulfilled,
      (state, { payload: data }) => {
        state.reportDetail = data.data;
      }
    );
    builder.addCase(updateSpamFlag.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
    builder.addCase(updateComment.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
    builder.addCase(updateComplete.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
    builder.addCase(
      updateManualReplyState.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(sendManualReply.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
    builder.addCase(
      getReportAttachments.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      downloadAttachment.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
  },
});

export const reportActions = reportSlice.actions;

export default reportSlice.reducer;
