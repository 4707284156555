import "./index.scss";
import { Box, Button, Modal, Typography } from "fasoo-ui-component-next";
import React from "react";
import { useTranslation } from "react-i18next";

interface CommonModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  header?: JSX.Element;
  body?: string | JSX.Element;
  footer?: JSX.Element;
  subMessage?: string;
  afterSubmitButton: () => void;
  width?: number;
  isDivider?: boolean;
  isExit?: boolean;
  disabledConfirm?: boolean;
  type?: "delete";
}

const CommonModal: React.FC<CommonModalProps> = ({
  open,
  onClose,
  title,
  header,
  body,
  footer,
  subMessage,
  disabledConfirm = false,
  afterSubmitButton,
  width = 480,
  isDivider = false,
  isExit = true,
  type,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      width={width}
      isDivider={isDivider}
      isExit={isExit}
      header={header}
      footer={
        footer ? (
          footer
        ) : (
          <Box justifyContent="flex-end">
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => onClose()}
            />
            {type === "delete" ? (
              <Button
                text={t("common.button.delete")}
                classes={"mg ml-8"}
                color="warning"
                disabled={disabledConfirm}
                onClick={() => (afterSubmitButton(), onClose())}
              />
            ) : (
              <Button
                text={t("common.button.check")}
                classes={"mg ml-8"}
                color="primary"
                disabled={disabledConfirm}
                onClick={() => (afterSubmitButton(), onClose())}
              />
            )}
          </Box>
        )
      }
      classes="commonModal"
    >
      <Box
        alignItems="center"
        justifyContent="center"
        width={"100%"}
        classes="common__box"
      >
        {subMessage ? (
          <Box
            alignItems="flex-start"
            justifyContent="center"
            width={"100%"}
            direction={"column"}
          >
            <Typography color="secondary" size={14} lineHeight="normal">
              {subMessage ?? ""}
            </Typography>
          </Box>
        ) : (
          body
        )}
      </Box>
    </Modal>
  );
};

export default CommonModal;
