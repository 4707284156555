import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ipApi from "../api/ipApi";
import { IpFilterType } from "../../types/Ip";

interface IpState {
  ipList: IpFilterType[];
}

const initialState: IpState = { ipList: [] };

export const trunkGetIpList = createAsyncThunk("ip/getIpList", async () => {
  const resp = await ipApi.getIpList();

  return resp;
});

export const trunkInsertIp = createAsyncThunk(
  "ip/insertIp",
  async (ipFilterType: IpFilterType) => {
    const resp = await ipApi.insertIp(ipFilterType);

    return resp;
  }
);

export const trunkDeleteIp = createAsyncThunk(
  "ip/deleteIp",
  async (ipIds: number[]) => {
    const resp = await ipApi.deleteIp(ipIds);

    return resp;
  }
);

export const trunkUpdateIp = createAsyncThunk(
  "ip/updateIp",
  async (ipFilterType: IpFilterType) => {
    const resp = await ipApi.updateIp(ipFilterType);

    return resp;
  }
);

export const trunkDownloadFilteredLog = createAsyncThunk(
  "ip/downloadFilteredLog",
  async (req: { configId: number; type: string }) => {
    const resp = await ipApi.downloadFilteredLog(req);

    return resp;
  }
);

export const trunkDownloadAccessLog = createAsyncThunk(
  "ip/downloadAccessLog",
  async (req: { startDate: string; endDate: string }) => {
    const resp = await ipApi.downloadAccessLog(req);

    return resp;
  }
);
export const trunkIsExistsIp = createAsyncThunk(
  "ip/isExistsIp",
  async (ip: string) => {
    const resp = await ipApi.isExistsIp(ip);

    return resp;
  }
);

const ipSlice = createSlice({
  name: "ip",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(trunkGetIpList.fulfilled, (state, { payload: data }) => {
      state.ipList = data.data;
    });
    builder.addCase(trunkInsertIp.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
    builder.addCase(trunkDeleteIp.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
    builder.addCase(trunkUpdateIp.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
    builder.addCase(
      trunkDownloadFilteredLog.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkDownloadAccessLog.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(trunkIsExistsIp.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
  },
});

export const ipActions = ipSlice.actions;

export default ipSlice.reducer;
