import { useMemo } from "react";
import { useAppDispatch, useRootState } from ".";
import { bindActionCreators } from "redux";
import {
  trunkDoSignup,
  trunkIsExistsEmail,
  trunkIsExistsReportAddress,
  trunkIsMatchAuthCode,
  trunkSendAuthMail,
} from "../store/signup";

const useSignupStore = () => {
  const signupState = useRootState((state) => state.signupStore);

  const dispatch = useAppDispatch();

  const boundIsExistsEmail = useMemo(
    () => bindActionCreators(trunkIsExistsEmail, dispatch),
    [dispatch]
  );

  const boundSendAuthMail = useMemo(
    () => bindActionCreators(trunkSendAuthMail, dispatch),
    [dispatch]
  );
  const boundIsMatchAuthCode = useMemo(
    () => bindActionCreators(trunkIsMatchAuthCode, dispatch),
    [dispatch]
  );
  const boundIsExistsReportAddress = useMemo(
    () => bindActionCreators(trunkIsExistsReportAddress, dispatch),
    [dispatch]
  );
  const boundDoSignup = useMemo(
    () => bindActionCreators(trunkDoSignup, dispatch),
    [dispatch]
  );
  return {
    signupState,
    isExistsEmail: boundIsExistsEmail,
    sendAuthMail: boundSendAuthMail,
    isMatchAuthCode: boundIsMatchAuthCode,
    isExistsReportAddress: boundIsExistsReportAddress,
    doSignup: boundDoSignup,
  };
};

export default useSignupStore;
