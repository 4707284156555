import { useCallback, useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  trunkCheckCustomerTraining,
  trunkGetAccessCompanyList,
  trunkGetCustomerCategory,
  trunkGetCustomerName,
  trunkGetCustomerSetting,
  trunkGetCustomersList,
  trunkSelectCustomer,
  trunkSyncMasterToCustomer,
  trunkUpdateCustomerInfo,
} from "../store/customer";

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useCustomerStore = () => {
  const customerState = useRootState((state) => state.customerStore);

  const dispatch = useAppDispatch();

  const boundSyncMasterToCustomer = useMemo(
    () => bindActionCreators(trunkSyncMasterToCustomer, dispatch),
    [dispatch]
  );

  const boundSelectCustomer = useMemo(
    () => bindActionCreators(trunkSelectCustomer, dispatch),
    [dispatch]
  );
  const boundGetCustomerName = useMemo(
    () => bindActionCreators(trunkGetCustomerName, dispatch),
    [dispatch]
  );
  const boundGetAccessCompanyList = useMemo(
    () => bindActionCreators(trunkGetAccessCompanyList, dispatch),
    [dispatch]
  );
  const boundGetCustomersList = useMemo(
    () => bindActionCreators(trunkGetCustomersList, dispatch),
    [dispatch]
  );
  const boundUpdateCustomerInfo = useMemo(
    () => bindActionCreators(trunkUpdateCustomerInfo, dispatch),
    [dispatch]
  );
  const boundCheckCustomerTraining = useMemo(
    () => bindActionCreators(trunkCheckCustomerTraining, dispatch),
    [dispatch]
  );
  const boundGetCustomerCategory = useMemo(
    () => bindActionCreators(trunkGetCustomerCategory, dispatch),
    [dispatch]
  );
  const boundGetCustomerSetting = useMemo(
    () => bindActionCreators(trunkGetCustomerSetting, dispatch),
    [dispatch]
  );
  return {
    customerState,
    syncMasterToCustomer: boundSyncMasterToCustomer,
    selectCustomer: boundSelectCustomer,
    getCustomerName: boundGetCustomerName,
    getAccessCompanyList: boundGetAccessCompanyList,
    getCustomersList: boundGetCustomersList,
    updateCustomerInfo: boundUpdateCustomerInfo,
    checkCustomerTraining: boundCheckCustomerTraining,
    getCustomerCategory: boundGetCustomerCategory,
    getCustomerSetting: boundGetCustomerSetting,
  };
};

export default useCustomerStore;
