import { createSlice } from "@reduxjs/toolkit";

interface ProgressState {
  count: number;
}

// # initial state
const initialState: ProgressState = {
  count: 0,
};

const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    startProgress(state) {
      state.count++;
    },
    stopProgress(state) {
      state.count = Math.max(state.count - 1, 0);
    },
  },
});
// # actions
export const { startProgress, stopProgress } = progressSlice.actions;

export default progressSlice.reducer;
