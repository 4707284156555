import {
  Divider,
  DropdownItem,
  Icons,
  Typography,
} from "fasoo-ui-component-next";
import { useTranslation } from "react-i18next";

interface SettingChildrenProps {
  editEvent?: () => void;
  deleteEvent?: () => void;
  detailEvent?: () => void;
}

const SettingChildren: React.FC<SettingChildrenProps> = ({
  editEvent,
  deleteEvent,
  detailEvent,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {detailEvent && (
        <DropdownItem
          onClick={(e) => {
            e.stopPropagation();
            detailEvent();
          }}
        >
          <Icons
            variant="sideMenu"
            label="info"
            classes="mg mr-4"
            stroke="#000000"
          />
          {t("common.button.detail")}
        </DropdownItem>
      )}
      {editEvent && (
        <DropdownItem
          onClick={(e) => {
            e.stopPropagation();
            editEvent();
          }}
        >
          <Icons variant="file" label="rename" classes="mg mr-4" />
          {t("common.button.update")}
        </DropdownItem>
      )}
      {editEvent && deleteEvent && (
        <Divider direction="row" classes="mg mt-2 mb-2" />
      )}
      {deleteEvent && (
        <DropdownItem
          onClick={(e) => {
            e.stopPropagation();
            deleteEvent();
          }}
          isRed
        >
          <Icons
            variant="sideMenu"
            label="trash"
            stroke="#EF5E4B"
            classes="mg mr-4"
          />
          <Typography color={"red"}>{t("common.button.delete")}</Typography>
        </DropdownItem>
      )}
    </>
  );
};

export default SettingChildren;
