import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import { BaseResponseBean } from "../../types/Common";
import { ManagerBean, ManagerRequestBean } from "../../types/Manage";

const GATEWAY_URL = config.servers.gateway.url;

interface ManagerApi {
  getManagerList(
    managerName?: string
  ): Promise<BaseResponseBean<ManagerBean[]>>;
  getCommonMangerList(customerCode: string): Promise<ManagerBean[]>;
  addNewManager(
    req: ManagerRequestBean
  ): Promise<BaseResponseBean<ManagerBean>>;
  updateManagerInfo(
    req: ManagerRequestBean
  ): Promise<BaseResponseBean<ManagerBean>>;
  deleteManager(ids: string[]): Promise<BaseResponseBean<null>>;
}
class ManagerServerApi implements ManagerApi {
  async getManagerList(
    managerName?: string
  ): Promise<BaseResponseBean<ManagerBean[]>> {
    const res = await axios.post<BaseResponseBean<ManagerBean[]>>(
      `${GATEWAY_URL}/api/v2/manager/user/list`,
      {
        managerName,
      }
    );
    return Promise.resolve(res.data);
  }
  async getCommonMangerList(customerCode: string): Promise<ManagerBean[]> {
    const res = await axios.get<ManagerBean[]>(
      `/managers/common?customerCode=${customerCode}`
    );
    return Promise.resolve(res.data);
  }
  async addNewManager(
    req: ManagerRequestBean
  ): Promise<BaseResponseBean<ManagerBean>> {
    const res = await axios.post<BaseResponseBean<ManagerBean>>(
      `${GATEWAY_URL}/api/v2/manager/user/insert`,
      req
    );
    return Promise.resolve(res.data);
  }
  async updateManagerInfo(
    req: ManagerRequestBean
  ): Promise<BaseResponseBean<ManagerBean>> {
    const res = await axios.post<BaseResponseBean<ManagerBean>>(
      `${GATEWAY_URL}/api/v2/manager/user/update`,
      req
    );
    return Promise.resolve(res.data);
  }
  async deleteManager(ids: string[]): Promise<BaseResponseBean<null>> {
    const res = await axios.post<BaseResponseBean<null>>(
      `${GATEWAY_URL}/api/v2/manager/user/delete`,
      { managerCode: ids[0] }
    );
    return Promise.resolve(res.data);
  }
}

const managerApi: ManagerApi = new ManagerServerApi();

export default managerApi;
