import {
  Box,
  Button,
  Checkbox,
  Icons,
  SettingButton,
  Table,
  Tag,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import { useEffect, useLayoutEffect, useState } from "react";
import NoTemplateImage from "../../../shared/image/NoTemplateImage";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import { SiteInfo } from "../../../types/Info";
import SettingChildren from "../../../component/SettingChildren";
import RegitOrEditSiteModal from "./RegitOrEditSiteModal";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import ResourceListModal from "./ResourceListModal";
import { useTranslation } from "react-i18next";
import { getLocalCustomerCode } from "../../../shared/utils/auth";

const TrainingSite: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [sites, setSites] = useState<SiteInfo[]>([]);
  const [sitesTotal, setSitesTotal] = useState<number>(0);

  const [isRegitModalOpen, setIsRegitModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isResourceModalOpen, setIsResourceModalOpen] = useState<
    "LIST" | "DELETE" | "INSERT" | "NONE"
  >("NONE");
  const [selectedSites, setSelectedSites] = useState<number[]>([]);

  const { contentState, getSites, deleteSite } = useContentStore();
  const localCustomerCode = getLocalCustomerCode();
  const toast = useToast();
  const checkboxGroup = useCheckboxGroup();

  useEffect(() => {
    getSites({});
  }, []);

  useEffect(() => {
    setSites(contentState.sites);
    setSitesTotal(contentState.sitesTotal);
  }, [contentState.sites]);

  const onDelete = async () => {
    const resp: any = await deleteSite({
      request: {
        siteIds:
          selectedSites.length > 0
            ? selectedSites
            : (checkboxGroup.selected as number[]),
      },
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("link.msg.linkDeleteSuccessMsg"), "success");
    } else if (resp.payload && resp.payload.code === "DATA_USED") {
      toast.toastMsg(
        nanoid(),
        t("link.msg.linkDeleteUsedTemplateFailMsg"),
        "error"
      );
    } else {
      toast.toastMsg(nanoid(), t("link.msg.linkDeleteFailMsg"), "error");
    }

    checkboxGroup.handleAllClick([]);
    setSelectedSites([]);
    setIsDeleteModalOpen(false);
  };

  const onCheck = (id: number) => {
    checkboxGroup.handleClick(id);
  };

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [sites]);

  return (
    <Box classes="main manageScenarioMgnt">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath
            path={[
              t("menu.templateManagement"),
              t("menu.templateContentsSetting"),
              t("link.link"),
            ]}
          />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("link.link")}
          </Typography>
        </Box>
        <Box>
          <Button
            color="text"
            classes="mg mr-8"
            textColor="green"
            onClick={() => setIsResourceModalOpen("LIST")}
          >
            {t("resource.phishingInfoImageManage")}
          </Button>
          <Button
            startIcon={<Icons variant="common" label="plus" stroke="#FFFFFF" />}
            onClick={() => setIsRegitModalOpen(true)}
          >
            {t("common.button.add")}
          </Button>
        </Box>
      </Box>
      <Box classes="mg mt-8" height="calc(100% - 104px)">
        {sites && sites?.length === 0 ? (
          <Box
            width={"100%"}
            direction="column"
            justifyContent="center"
            alignItems="center"
            classes="mg mt-24"
          >
            <NoTemplateImage />
            <Typography color="secondary" classes="mg mt-24 mb-24" size={16}>
              {t("link.msg.noDataMsg")}
            </Typography>
            <Button
              startIcon={
                <Icons variant="common" label="plus" stroke="#FFFFFF" />
              }
              color="primary"
              onClick={() => setIsRegitModalOpen(true)}
            >
              {t("link.linkAdd")}
            </Button>
          </Box>
        ) : (
          <Box direction="column">
            <Box classes="mg" justifyContent="space-between" width={"100%"}>
              <Box alignItems="center" height={40}>
                <Typography fontWeight={700} size={16}>
                  {t("link.linkList")}
                </Typography>
                <Typography
                  classes="mg ml-8"
                  size={16}
                  color="green"
                  fontWeight={700}
                >
                  {sitesTotal}
                </Typography>
              </Box>

              {checkboxGroup.selected.length > 0 && (
                <Box>
                  {checkboxGroup.selected.length === 1 && (
                    <Button
                      color={"text-noline"}
                      height={37}
                      startIcon={
                        <Icons
                          variant="mindsat"
                          label="icon_edit3"
                          classes="mg mr-4"
                        />
                      }
                      onClick={() => {
                        const item: SiteInfo = sites.filter(
                          (t) => t.siteId === checkboxGroup.selected[0]
                        )[0];

                        if (item.commonId) {
                          toast.toastMsg(
                            nanoid(),
                            t("common.msg.cannotDeletePublicContentsMsg"),
                            "error"
                          );
                          return;
                        }
                        setSelectedSites(checkboxGroup.selected as number[]);
                        setIsRegitModalOpen(true);
                      }}
                    >
                      {t("common.button.update")}
                    </Button>
                  )}
                  <Button
                    color={"text-noline"}
                    textColor="red"
                    height={37}
                    startIcon={
                      <Icons
                        variant="mindsat"
                        label="icon_trash"
                        classes="mg mr-4"
                      />
                    }
                    onClick={() => {
                      let commonFlag = false;
                      checkboxGroup.selected.map((c) => {
                        const item: SiteInfo[] = sites.filter(
                          (t) => t.siteId === c
                        );

                        item.map((i) => {
                          if (i.commonId) {
                            commonFlag = true;
                          }
                        });
                      });
                      if (commonFlag) {
                        toast.toastMsg(
                          nanoid(),
                          t("common.msg.cannotDeletePublicContentsMsg"),
                          "error"
                        );
                        return;
                      }
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    {t("common.button.delete")}
                  </Button>
                </Box>
              )}
            </Box>

            <Box direction="column" height="calc(100% - 40px)">
              <Table classes="default-table mg mt-12">
                <colgroup>
                  <col style={{ width: "52px" }}></col>
                  <col style={{ width: "72px" }}></col>
                  <col style={{ width: "200px" }}></col>
                  <col style={{ width: "*" }}></col>
                  <col
                    style={{ width: `calc(46px + ${scrollbarWidth}px)` }}
                  ></col>
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <Checkbox
                        checked={
                          checkboxGroup.selected.length === sites?.length
                        }
                        onChange={() =>
                          checkboxGroup.handleAllClick(
                            checkboxGroup.selected.length === sites?.length
                              ? []
                              : sites?.map((item) => item.siteId)
                          )
                        }
                      />
                    </th>
                    <th>{t("template.type")}</th>
                    <th>{t("link.linkNameSimple")}</th>
                    <th>{t("link.linkUrl")}</th>
                    <th></th>
                  </tr>
                </thead>
              </Table>
              <Table classes="table-scroll">
                <colgroup>
                  <col style={{ width: "52px" }}></col>
                  <col style={{ width: "72px" }}></col>
                  <col style={{ width: "200px" }}></col>
                  <col style={{ width: "*" }}></col>
                  <col style={{ width: "46px" }}></col>
                </colgroup>
                <tbody className="roundTable">
                  {sites?.map((site) => (
                    <tr key={site.siteId}>
                      <td>
                        <Checkbox
                          onChange={() => onCheck(site.siteId)}
                          checked={checkboxGroup.isSelected(site.siteId)}
                        />
                      </td>
                      <td>
                        {localCustomerCode ? (
                          site.commonId ? (
                            <Tag
                              name={t("template.public")}
                              size={i18n.language === "ko" ? "ms" : "sm"}
                              color="blue"
                              radius={6}
                            />
                          ) : (
                            <Tag
                              name={t("template.private")}
                              size={i18n.language === "ko" ? "ms" : "sm"}
                              color="pink"
                              radius={6}
                            />
                          )
                        ) : (
                          <Tag
                            name={t("template.public")}
                            size={i18n.language === "ko" ? "ms" : "sm"}
                            color="blue"
                            radius={6}
                          />
                        )}
                      </td>
                      <td>{site.siteName}</td>
                      <td>{site.siteUrl}</td>
                      <td>
                        {site.commonId ? null : (
                          <SettingButton
                            hovered={true}
                            children={
                              <SettingChildren
                                editEvent={() => {
                                  setSelectedSites([site.siteId]);
                                  setIsRegitModalOpen(true);
                                }}
                                deleteEvent={() => {
                                  setSelectedSites([site.siteId]);
                                  setIsDeleteModalOpen(true);
                                }}
                              />
                            }
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </Box>
        )}
      </Box>
      {isRegitModalOpen && (
        <RegitOrEditSiteModal
          isOpen={isRegitModalOpen}
          onClose={() => {
            setSelectedSites([]);
            setIsRegitModalOpen(false);
          }}
          siteId={selectedSites.length > 0 ? selectedSites[0] : undefined}
        />
      )}
      {isDeleteModalOpen && (
        <CommonModal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          title={t("link.linkDelete")}
          afterSubmitButton={onDelete}
          subMessage={t("common.msg.deleteConfirmMsg")}
          type="delete"
        />
      )}

      <ResourceListModal
        isOpen={isResourceModalOpen !== "NONE"}
        onClose={() => setIsResourceModalOpen("NONE")}
        modalType={isResourceModalOpen}
        setModalType={setIsResourceModalOpen}
      />
    </Box>
  );
};

export default TrainingSite;
