import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.scss";

import Login from "./Pages/Login";
import BaseLayout from "./Pages/BaseLayout";
import Customer from "./Pages/Customer";
import AuthRoute from "./Pages/AuthRouter";
import { CookiesProvider } from "react-cookie";
import useProgressStore from "./redux/dispatcher/useProgressStore";
import ProgressParents from "./Pages/ProgressParents";
import { ToastProvider } from "fasoo-ui-component-next";
import Denied from "./Pages/Denied";

const App = () => {
  const { progressState } = useProgressStore();

  return (
    <CookiesProvider>
      <ToastProvider>
        {progressState.count > 0 && <ProgressParents />}
        <BrowserRouter>
          <Routes>
            <Route
              path={`${process.env.PUBLIC_URL}/login`}
              element={<Login />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/customer`}
              element={
                <AuthRoute>
                  <Customer />
                </AuthRoute>
              }
            />
            <Route
              path={`${process.env.PUBLIC_URL}/error`}
              element={<Denied />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/*`}
              element={<BaseLayout />}
            />
          </Routes>
        </BrowserRouter>
      </ToastProvider>
    </CookiesProvider>
  );
};

export default App;
