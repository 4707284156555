import { useMemo } from "react";
import { useAppDispatch, useRootState } from ".";
import { bindActionCreators } from "redux";
import {
  trunkGetConfigsByDate,
  trunkGetReportStatByPeriod,
  trunkGetReportStatDetailByPeriod,
  trunkGetReportStatDetailByPeriodType,
  trunkGetReportStatDetailPaging,
  trunkGetReportStatistics,
} from "../store/reportStat";

const useReportStatStore = () => {
  const reportStatState = useRootState((state) => state.reportStatStore);

  const dispatch = useAppDispatch();

  const boundGetReportStat = useMemo(
    () => bindActionCreators(trunkGetReportStatistics, dispatch),
    [dispatch]
  );

  const boundGetReportStatByPeriod = useMemo(
    () => bindActionCreators(trunkGetReportStatByPeriod, dispatch),
    [dispatch]
  );

  const boundGetReportStatDetailPaging = useMemo(
    () => bindActionCreators(trunkGetReportStatDetailPaging, dispatch),
    [dispatch]
  );

  const boundGetReportStatDetailByPeriod = useMemo(
    () => bindActionCreators(trunkGetReportStatDetailByPeriod, dispatch),
    [dispatch]
  );

  const boundGetReportStatDetailByPeriodType = useMemo(
    () => bindActionCreators(trunkGetReportStatDetailByPeriodType, dispatch),
    [dispatch]
  );

  const boundGetConfigsByDate = useMemo(
    () => bindActionCreators(trunkGetConfigsByDate, dispatch),
    [dispatch]
  );

  return {
    reportStatState,
    getReportStat: boundGetReportStat,
    getReportStatByPeriod: boundGetReportStatByPeriod,
    getReportStatDetailPaging: boundGetReportStatDetailPaging,
    getReportStatDetailByPeriod: boundGetReportStatDetailByPeriod,
    getReportStatDetailByPeriodType: boundGetReportStatDetailByPeriodType,
    getConfigsByDate: boundGetConfigsByDate,
  };
};

export default useReportStatStore;
