import Axios from "axios";
import { getAccessToken } from "./auth";

const axiosForm = Axios.create({
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    Authorization:
      localStorage.getItem("accessToken") !== null &&
      `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

export const setAxiosHeaders = () => {
  axiosForm.interceptors.request.use(function (config) {
    const accessToken = getAccessToken();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  });
};

axiosForm.interceptors.response.use(
  (res) => {
    console.log("success==>", res.data);
    return res;
  },
  (err) => {
    alert("Fail");
    if (
      err.response &&
      (err.response.status === 401 || err.response.status === 400)
    ) {
      // logout
      localStorage.removeItem("accessToken");
      delete axiosForm.defaults.headers.common["Authorization"];
      delete axiosForm.defaults.headers["Authorization"];
    }
    return Promise.reject(err);
  }
);

export default axiosForm;
