import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import {
  BaseResponseBean,
  BaseResponseDTO,
  mindSatConfig,
} from "../../types/Common";
import { Progress } from "../../types/Mail";
import { ReportMail, ReportResponseType } from "../../types/Report";
import { PhishTypesInfo, SendHistoryType } from "../../types/Send";

const GATEWAY_URL = config.servers.gateway.url;

interface DashboardApi {
  recentClickHistoryList(req: mindSatConfig): Promise<BaseResponseBean<null>>;
  getProgressList(configId: number): Promise<BaseResponseBean<Progress[]>>;
  getDashboardReport(
    configId: number
  ): Promise<BaseResponseBean<ReportResponseType[]>>;
  getSendHistory(
    configId: number
  ): Promise<BaseResponseBean<SendHistoryType[]>>;
  getPhishTypes(configId: number): Promise<BaseResponseBean<PhishTypesInfo[]>>;
  getReportHistory(): Promise<BaseResponseBean<ReportMail[]>>;
  getStatTraining(req?: {
    realTrainingFlag: boolean | null;
    configIds?: number[];
    intervalTime?: number;
    startDate?: number;
    endDate?: number;
  }): Promise<BaseResponseDTO<StatTraining[]>>;
  getStatTopTraining(req: {
    configId?: number;
    intervalTime?: number;
    startDate?: number;
    endDate?: number;
    realTrainingFlag?: boolean | null;
  }): Promise<BaseResponseDTO<StatTopTraining>>;
}

class DashboardServerApi implements DashboardApi {
  async getStatTraining(req?: {
    realTrainingFlag: boolean;
    configIds?: number[];
    intervalTime?: number;
    startDate?: number;
    endDate?: number;
  }): Promise<BaseResponseDTO<StatTraining[]>> {
    const res = await axios.post<BaseResponseDTO<StatTraining[]>>(
      `${GATEWAY_URL}/api/v2/stat/training`,
      req
    );

    return Promise.resolve(res.data);
  }

  async getStatTopTraining(req: {
    configId?: number;
    intervalTime?: number;
    startDate?: number;
    endDate?: number;
  }): Promise<BaseResponseDTO<StatTopTraining>> {
    const res = await axios.post<BaseResponseDTO<StatTopTraining>>(
      `${GATEWAY_URL}/api/v2/stat/training/top`,
      req
    );

    return Promise.resolve(res.data);
  }

  async recentClickHistoryList(
    req: mindSatConfig
  ): Promise<BaseResponseBean<null>> {
    const res = await axios.post<BaseResponseBean<null>>(
      `/dashboard/recentClickHistory/list?configId=${req.configId}&cnt=${
        req?.cnt ?? 0
      }`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async getProgressList(
    configId: number
  ): Promise<BaseResponseBean<Progress[]>> {
    const res = await axios.post<BaseResponseBean<Progress[]>>(
      `/dashboard/progress/list?configId=${configId}`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async getDashboardReport(
    configId: number
  ): Promise<BaseResponseBean<ReportResponseType[]>> {
    const res = await axios.post<BaseResponseBean<ReportResponseType[]>>(
      `/dashboard/report?configId=${configId}`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async getSendHistory(
    configId: number
  ): Promise<BaseResponseBean<SendHistoryType[]>> {
    const res = await axios.post<BaseResponseBean<SendHistoryType[]>>(
      `/dashboard/sendHistory?configId=${configId}`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async getPhishTypes(
    configId: number
  ): Promise<BaseResponseBean<PhishTypesInfo[]>> {
    const res = await axios.post<BaseResponseBean<PhishTypesInfo[]>>(
      `/dashboard/phishTypes?configId=${configId}`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async getReportHistory(): Promise<BaseResponseBean<ReportMail[]>> {
    const res = await axios.post<BaseResponseBean<ReportMail[]>>(
      `/dashboard/reportHistory`,
      {}
    );
    return Promise.resolve(res.data);
  }
}

const dashboardApi: DashboardApi = new DashboardServerApi();

export default dashboardApi;
