import axios from "../../shared/utils/axios";
import { ConfigListType } from "../../types/Send";

interface ConfigApi {
  getConfigInfo(configId: number): Promise<ConfigListType>;
  getConfigList(): Promise<ConfigListType[]>;
  getTrainConfigInfo(configId: number): Promise<ConfigListType>;
}

class ConfigServerApi implements ConfigApi {
  async getConfigInfo(configId: number): Promise<ConfigListType> {
    const res = await axios.get<ConfigListType>(
      `/config/info?configId=${configId}`
    );
    return Promise.resolve(res.data);
  }
  async getTrainConfigInfo(configId: number): Promise<ConfigListType> {
    const res = await axios.get<ConfigListType>(
      `/getConfigInfo?sendHistoryId=${configId}`
    );
    return Promise.resolve(res.data);
  }
  async getConfigList(): Promise<ConfigListType[]> {
    const res = await axios.post<ConfigListType[]>(`/config/list`);
    return Promise.resolve(res.data);
  }
}

const configApi: ConfigApi = new ConfigServerApi();

export default configApi;
