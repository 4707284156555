import { useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  trunkDeleteImage,
  trunkDeleteSite,
  trunkDeleteSiteResource,
  trunkGetAttaches,
  trunkGetImages,
  trunkGetSites,
  trunkGetSitesFile,
  trunkGetSitesResource,
  trunkInsertSiteResource,
  trunkUpdateAttach,
  trunkUpdateAttachFile,
  trunkDownloadAttachFile,
  trunkUpdateFileImage,
  trunkUpdateFileSiteResource,
  trunkUpdateImage,
  trunkUpdateSiteResource,
  trunkUpdateSites,
  trunkUploadImage,
  trunkUploadSites,
  trunkUploadAttach,
  trunkDeleteAttach,
} from "../store/content";

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useContentStore = () => {
  const contentState = useRootState((state) => state.contentStore);

  const dispatch = useAppDispatch();

  const boundGetAttaches = useMemo(
    () => bindActionCreators(trunkGetAttaches, dispatch),
    [dispatch]
  );
  const boundGetSites = useMemo(
    () => bindActionCreators(trunkGetSites, dispatch),
    [dispatch]
  );
  const boundUploadSites = useMemo(
    () => bindActionCreators(trunkUploadSites, dispatch),
    [dispatch]
  );
  const boundUpdateSites = useMemo(
    () => bindActionCreators(trunkUpdateSites, dispatch),
    [dispatch]
  );
  const boundGetSitesFile = useMemo(
    () => bindActionCreators(trunkGetSitesFile, dispatch),
    [dispatch]
  );
  const boundDeleteSite = useMemo(
    () => bindActionCreators(trunkDeleteSite, dispatch),
    [dispatch]
  );
  const boundGetSitesResource = useMemo(
    () => bindActionCreators(trunkGetSitesResource, dispatch),
    [dispatch]
  );
  const boundInsertSiteResource = useMemo(
    () => bindActionCreators(trunkInsertSiteResource, dispatch),
    [dispatch]
  );
  const boundUpdateSiteResource = useMemo(
    () => bindActionCreators(trunkUpdateSiteResource, dispatch),
    [dispatch]
  );
  const boundUpdateFileSiteResource = useMemo(
    () => bindActionCreators(trunkUpdateFileSiteResource, dispatch),
    [dispatch]
  );
  const boundDeleteSiteResource = useMemo(
    () => bindActionCreators(trunkDeleteSiteResource, dispatch),
    [dispatch]
  );
  const boundGetImages = useMemo(
    () => bindActionCreators(trunkGetImages, dispatch),
    [dispatch]
  );
  const boundUploadImage = useMemo(
    () => bindActionCreators(trunkUploadImage, dispatch),
    [dispatch]
  );
  const boundUpdateImage = useMemo(
    () => bindActionCreators(trunkUpdateImage, dispatch),
    [dispatch]
  );
  const boundUpdateFileImage = useMemo(
    () => bindActionCreators(trunkUpdateFileImage, dispatch),
    [dispatch]
  );
  const boundDeleteImage = useMemo(
    () => bindActionCreators(trunkDeleteImage, dispatch),
    [dispatch]
  );
  const boundUploadAttach = useMemo(
    () => bindActionCreators(trunkUploadAttach, dispatch),
    [dispatch]
  );
  const boundDeleteAttach = useMemo(
    () => bindActionCreators(trunkDeleteAttach, dispatch),
    [dispatch]
  );
  const boundUpdateAttach = useMemo(
    () => bindActionCreators(trunkUpdateAttach, dispatch),
    [dispatch]
  );
  const boundUpdateAttachFile = useMemo(
    () => bindActionCreators(trunkUpdateAttachFile, dispatch),
    [dispatch]
  );
  const boundDownloadAttachFile = useMemo(
    () => bindActionCreators(trunkDownloadAttachFile, dispatch),
    [dispatch]
  );
  return {
    contentState,
    getAttaches: boundGetAttaches,
    getSites: boundGetSites,
    uploadSites: boundUploadSites,
    updateSites: boundUpdateSites,
    getSitesFile: boundGetSitesFile,
    deleteSite: boundDeleteSite,
    getSiteResource: boundGetSitesResource,
    insertSiteResource: boundInsertSiteResource,
    updateSiteResource: boundUpdateSiteResource,
    updateFileSiteResource: boundUpdateFileSiteResource,
    deleteSiteResource: boundDeleteSiteResource,
    getImages: boundGetImages,
    uploadImage: boundUploadImage,
    updateImage: boundUpdateImage,
    updateFileImage: boundUpdateFileImage,
    deleteImage: boundDeleteImage,
    uploadAttach: boundUploadAttach,
    deleteAttach: boundDeleteAttach,
    updateAttach: boundUpdateAttach,
    updateAttachFile: boundUpdateAttachFile,
    downloadAttachFile: boundDownloadAttachFile,
  };
};

export default useContentStore;
