const envPublicUrl =
  process.env.PUBLIC_URL && process.env.PUBLIC_URL !== "/"
    ? process.env.PUBLIC_URL
    : "";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  contextPath: envPublicUrl ? envPublicUrl : "/",
  url: (subUrl: string) => `${envPublicUrl}${subUrl}`,
  servers: {
    gateway: {
      enabled: true,
      url: "/mindsat",
    },
  },
};
