import React from "react";

import Progress from "./Progress";

// Progress를 App.tsx에서 바로 사용하면, useProgressStore에 의해 axios 호출할 때마다 계속 rerendring밣생
const ProgressParents: React.FC = () => {
  return <Progress />;
};

export default ProgressParents;
