import {
  Box,
  Button,
  Calendar,
  Checkbox,
  DropdownItem,
  Icons,
  Modal,
  SettingButton,
  Tab,
  TabBox,
  TabPanel,
  Table,
  Tabs,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import { useEffect, useLayoutEffect, useState } from "react";
import useIpStore from "../../../redux/dispatcher/useIpStore";
import { IpFilterType } from "../../../types/Ip";
import utilsCommon from "../../../shared/utils/common";
import NoInfo from "../../../component/NoInfo";
import InsertUpdateIPModal from "./InsertUpdateIPModal";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import { getUserInfoFromLocalStorage } from "../../../shared/utils/auth";

const ManageLog: React.FC = () => {
  const { t, i18n } = useTranslation();
  const {
    ipState,
    getIpList,
    deleteIp,
    downloadFilteredLog,
    donwloadAccessLog,
  } = useIpStore();
  const { programState, getProgramList } = useProgramStore();
  const checkboxGroup = useCheckboxGroup<number>();
  const toast = useToast();

  const [tab, setTab] = useState(0);
  const [whiteIPlist, changeWhiteIPList] = useState<IpFilterType[]>([]);
  const [blackIPlist, changeBlackIPList] = useState<IpFilterType[]>([]);
  const [buttonClick, setButtonClick] = useState(0);
  const [insertUpdateIPModalInfo, changeInsertUpdateIPModalInfo] = useState({
    isOpen: false,
    modalType: "CLOSE",
    ipCode: -1,
  });
  const [deleteModalInfo, changeDeleteModalInfo] = useState({
    isOpen: false,
    targetIpId: -1,
  });

  useEffect(() => {
    getIpList();
    getProgramList({ size: 3 });
  }, []);
  useEffect(() => {
    checkboxGroup.handleAllClick([]);
  }, [tab]);
  useEffect(() => {
    changeWhiteIPList(
      ipState.ipList.filter((item: IpFilterType) => item.ipType === "W")
    );
    changeBlackIPList(
      ipState.ipList.filter((item: IpFilterType) => item.ipType === "B")
    );
  }, [ipState.ipList]);

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [whiteIPlist, blackIPlist]);

  const renderListTable = (list: IpFilterType[]) => {
    return (
      <Box classes="mg mt-20" direction="column" height="calc(100% - 40px)">
        <Box justifyContent="space-between" height={36}>
          <Box alignItems="center" height={36}>
            <Typography size={16} isBold>
              {/* {t("common.list.list")} */}
              IP
            </Typography>
            <Typography size={16} isBold color="green" classes="mg ml-4">
              {list?.length ?? 0}
            </Typography>
          </Box>
          <Box alignItems="center">
            {checkboxGroup.selected.length === 1 && (
              <Button
                color={"text-noline"}
                startIcon={<Icons variant="file" label="rename" />}
                onClick={() =>
                  changeInsertUpdateIPModalInfo({
                    isOpen: true,
                    ipCode: checkboxGroup.selected[0] ?? -1,
                    modalType: "UPDATE",
                  })
                }
                text={t("common.button.update")}
              />
            )}
            {checkboxGroup.selected.length >= 1 && (
              <Button
                color={"text-noline"}
                text={t("common.button.delete")}
                onClick={() =>
                  changeDeleteModalInfo({
                    isOpen: true,
                    targetIpId: -1,
                  })
                }
                startIcon={
                  <Icons variant="common" label="trash" stroke={"red"} />
                }
                textColor="red"
              />
            )}
          </Box>
        </Box>

        {list && list.length > 0 ? (
          <Box direction="column" height="calc(100% - 40px)">
            <Table classes="default-table mg mt-24">
              <colgroup>
                {/* 체크 박스 */}
                <col style={{ width: "55px" }}></col>
                {/* IP */}
                <col style={{ minWidth: "100px", width: "30%" }}></col>
                {/* 등록 일시 */}
                <col style={{ minWidth: "100px", width: "30%" }}></col>
                {/* 설명 */}
                <col style={{ minWidth: "100px", width: "40%" }}></col>
                {/*  */}
                <col
                  style={{ width: `calc(50px + ${scrollbarWidth}px)` }}
                ></col>
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      checked={checkboxGroup.selected.length === list.length}
                      onChange={() =>
                        checkboxGroup.handleAllClick(
                          checkboxGroup.selected.length === list.length
                            ? ([] as number[])
                            : list?.map((item) => item.ipId ?? -1)
                        )
                      }
                    />
                  </th>
                  <th>{"IP"}</th>
                  <th>{t("log.createDate")}</th>
                  <th>{t("log.description")}</th>
                  <th />
                </tr>
              </thead>
            </Table>
            <Box classes="table-scroll">
              <Table>
                <colgroup>
                  {/* 체크 박스 */}
                  <col style={{ width: "55px" }}></col>
                  {/* IP */}
                  <col style={{ minWidth: "100px", width: "30%" }}></col>
                  {/* 등록 일시 */}
                  <col style={{ minWidth: "100px", width: "30%" }}></col>
                  {/* 설명 */}
                  <col style={{ minWidth: "100px", width: "40%" }}></col>
                  {/*  */}
                  <col style={{ width: "50px" }}></col>
                </colgroup>
                <tbody>
                  {list?.map((item) => (
                    <tr
                      className="mindsatTable-item"
                      onClick={() => checkboxGroup.handleClick(item.ipId ?? -1)}
                    >
                      <td>
                        <Checkbox
                          checked={checkboxGroup.isSelected(item.ipId ?? -1)}
                          onChange={() =>
                            checkboxGroup.handleClick(item.ipId ?? -1)
                          }
                        />
                      </td>
                      <td>{item.ipAddress}</td>
                      <td>{utilsCommon.getShortDate(item.createDate)}</td>
                      <td>{item.summary}</td>
                      <td>
                        <SettingButton>
                          <DropdownItem
                            onClick={(e) => {
                              e.stopPropagation();
                              setButtonClick(
                                buttonClick > 99999 ? 1 : buttonClick + 1
                              );
                              changeInsertUpdateIPModalInfo({
                                isOpen: true,
                                ipCode: item.ipId ?? -1,
                                modalType: "UPDATE",
                              });
                            }}
                          >
                            <Icons variant="file" label="rename" />
                            <Typography>{t("common.button.update")}</Typography>
                          </DropdownItem>
                          <DropdownItem
                            isRed
                            onClick={(e) => {
                              e.stopPropagation();
                              setButtonClick(
                                buttonClick > 99999 ? 1 : buttonClick + 1
                              );
                              changeDeleteModalInfo({
                                isOpen: true,
                                targetIpId: item?.ipId ?? -1,
                              });
                            }}
                          >
                            <Icons
                              variant="common"
                              label="trash"
                              stroke={"red"}
                            />
                            <Typography>{t("common.button.delete")}</Typography>
                          </DropdownItem>
                        </SettingButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </Box>
        ) : (
          <NoInfo />
        )}
      </Box>
    );
  };
  const handleDeleteIp = async (ip: number) => {
    let resp: any = await deleteIp(
      ip === -1 ? checkboxGroup.selected.map((item) => item) : [ip]
    );
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("log.msg.logDeleteSuccessMsg"), "success");
      getIpList();
    } else {
      toast.toastMsg(nanoid(), t("log.msg.logDeleteFailMsg"), "error");
    }
    changeDeleteModalInfo({ isOpen: false, targetIpId: -1 });
    checkboxGroup.handleAllClick([]);
    getIpList();
  };

  const [openDateModal, toggleDateModal] = useState(false);
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });

  const dateModal = () => {
    return (
      <Modal
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        isDivider={false}
        width={480}
        footer={
          <Box justifyContent="flex-end">
            <Button
              color={"secondary"}
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
            >
              {t("common.button.cancel")}
            </Button>
            <Button
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }

                toggleDateModal(false);
                donwloadAccessLog({
                  startDate: utilsCommon.getShortDate(tempPeriod.start),
                  endDate: utilsCommon.getShortDate(tempPeriod.end),
                });
              }}
              classes={"mg ml-8"}
            >
              {t("attach.download")}
            </Button>
          </Box>
        }
      >
        <Box alignItems="center" justifyContent="center">
          <Calendar
            onSubmit={(date) =>
              changeTempPeriod({
                ...tempPeriod,
                start: dayjs(date)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0)
                  .valueOf(),
              })
            }
            placeholder={t("common.info.startDate")}
            selected={new Date(tempPeriod.start)}
            dateFormat="yyyy-MM-dd"
            lang={i18n.language}
            maxDate={
              tempPeriod && tempPeriod.end
                ? new Date(tempPeriod.end)
                : new Date()
            }
          />
          <Box
            style={{ color: "#717985" }}
            alignItems="center"
            classes="mg ml-4 mr-4"
          >
            ~
          </Box>
          <Calendar
            onSubmit={(date) =>
              changeTempPeriod({
                ...tempPeriod,
                end: dayjs(date)
                  .set("hour", 23)
                  .set("minute", 59)
                  .set("second", 59)
                  .valueOf(),
              })
            }
            selected={new Date(tempPeriod.end)}
            placeholder={t("common.info.endDate")}
            dateFormat="yyyy-MM-dd"
            lang={i18n.language}
            minDate={
              tempPeriod && tempPeriod.start
                ? new Date(tempPeriod.start)
                : undefined
            }
            maxDate={new Date()}
          />
        </Box>
      </Modal>
    );
  };
  return (
    <Box classes="main manageLog">
      {insertUpdateIPModalInfo.isOpen && (
        <InsertUpdateIPModal
          tab={tab}
          isOpen={insertUpdateIPModalInfo.isOpen}
          onClose={() =>
            changeInsertUpdateIPModalInfo({
              isOpen: false,
              ipCode: -1,
              modalType: "CLOSE",
            })
          }
          targetIPId={insertUpdateIPModalInfo.ipCode}
          modalType={insertUpdateIPModalInfo.modalType}
        />
      )}
      {deleteModalInfo.isOpen && (
        <CommonModal
          open={deleteModalInfo.isOpen}
          onClose={() =>
            changeDeleteModalInfo({ isOpen: false, targetIpId: -1 })
          }
          title={t("log.delete")}
          // body={
          //   <Box width={"100%"} justifyContent="flex-start">
          //     {t("common.msg.deleteConfirmMsg")}
          //   </Box>
          // }
          subMessage={t("common.msg.deleteConfirmMsg")}
          type={"delete"}
          afterSubmitButton={() => handleDeleteIp(deleteModalInfo.targetIpId)}
        />
      )}
      {openDateModal && dateModal()}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.logManagement")]} />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("menu.logManagement")}
          </Typography>
        </Box>
        <Box>
          <Button
            startIcon={<Icons variant="common" label="plus" stroke="white" />}
            onClick={() =>
              changeInsertUpdateIPModalInfo({
                isOpen: true,
                ipCode: -1,
                modalType: "CREATE",
              })
            }
          >
            {t("log.add")}
          </Button>
          <Button
            startIcon={<Icons variant="file" label="file" stroke="white" />}
            classes="mg ml-10"
            onClick={() =>
              // console.log(programState.configListWithCount.list[0].configId)
              downloadFilteredLog({
                // customerName: "test_injubi",
                configId: programState.configListWithCount.list[0].configId,
                type: tab === 0 ? "whitelist" : "blacklist",
              })
            }
          >
            {t("log.downloadFilteredLog")}
          </Button>
          {getUserInfoFromLocalStorage()?.superAdmin && (
            <Button
              startIcon={<Icons variant="file" label="file" stroke="white" />}
              classes="mg ml-10"
              onClick={() => {
                // donwloadAccessLog({
                //   startDate: "2024-06-11",
                //   endDate: dayjs().format("YYYY-MM-DD"),
                // })
                toggleDateModal(true);
              }}
            >
              {t("log.downloadAllLog")}
            </Button>
          )}
        </Box>
      </Box>
      <Box
        classes="mg mt-16"
        width={"100%"}
        style={{ height: "calc(100% - 104px)" }}
      >
        <TabBox style={{ height: "calc(100% - 112px)" }}>
          <Tabs value={tab} onChange={(selected) => setTab(selected)}>
            <Tab
              style={{ display: "flex" }}
              index={0}
              fontSize={16}
              fontWeight={700}
              label={t("log.whiteList")}
            />
            <Tab
              style={{ display: "flex" }}
              label={t("log.blackList")}
              index={1}
              fontSize={16}
              fontWeight={700}
            />
          </Tabs>

          <TabPanel value={tab} index={0}>
            {renderListTable(whiteIPlist)}
          </TabPanel>

          <TabPanel value={tab} index={1}>
            {renderListTable(blackIPlist)}
          </TabPanel>
        </TabBox>
      </Box>
    </Box>
  );
};

export default ManageLog;
