import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { useEffect, useRef, useState } from "react";
import { SiteInfo } from "../../../types/Info";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

interface RegitOrEditSiteModalProps {
  isOpen: boolean;
  onClose: () => void;
  siteId?: number;
}

const RegitOrEditSiteModal: React.FC<RegitOrEditSiteModalProps> = ({
  isOpen,
  onClose,
  siteId,
}) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<any>(null);
  const [siteInfo, setSiteInfo] = useState<SiteInfo>();
  const [content, setContent] = useState<string>();
  const [file, setFile] = useState<File | null>();
  const [siteName, setSiteName] = useState<string | null>();

  const { contentState, getSites, uploadSites, updateSites } =
    useContentStore();
  const toast = useToast();

  const ext = ["html"];

  useEffect(() => {
    setSiteName(
      siteId
        ? contentState.sites.filter(
            (item: SiteInfo) => item.siteId === siteId
          )[0].siteName
        : null
    );
    setFile(null);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
  }, [siteId]);

  const onSubmit = async () => {
    if (siteName) {
      if (/^\s*$/.test(siteName)) {
        toast.toastMsg(nanoid(), t("common.msg.nameEmptyMsg"), "error");
        return;
      }

      if (siteName?.length > 128) {
        toast.toastMsg(nanoid(), t("common.msg.fieldLengthLimitMsg"), "error");
        return;
      }
      if (siteId) {
        const resp: any = await updateSites({
          request: { siteName, siteId: siteId },
          file: file ?? null,
        });
        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("link.msg.linkUpdateSuccessMsg"),
            "success"
          );
          // getSites({});
        } else {
          if (resp.payload.code === "DATA_DUPLICATE") {
            toast.toastMsg(
              nanoid(),
              t("link.msg.linkDuplicatedLinkMsg"),
              "error"
            );
            return;
          }
          toast.toastMsg(nanoid(), t("link.msg.siteUpdateFailMsg"), "error");
        }
      } else {
        if (!file) {
          toast.toastMsg(nanoid(), t("link.msg.nofilePlaceholder"), "error");
          return;
        }
        const resp: any = await uploadSites({
          request: { siteName },
          file,
        });
        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(nanoid(), t("link.msg.linkAddSuccessMsg"), "success");
          getSites({});
        } else {
          if (resp.payload.code === "DATA_DUPLICATE") {
            toast.toastMsg(
              nanoid(),
              t("link.msg.linkDuplicatedLinkMsg"),
              "error"
            );
            return;
          }
          toast.toastMsg(nanoid(), t("link.msg.siteInsertErrorMsg"), "error");
        }
      }

      setFile(null);
      setSiteName(null);
      onClose();
    } else {
      toast.toastMsg(nanoid(), t("common.msg.nameEmptyMsg"), "error");
    }
  };

  const handleFileClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleFileChange = (e: any) => {
    let value = "";
    const name = e.target.name;
    const chgcontent = "";

    if (name === "file") {
      const fileName = e.target.files[0] && e.target.files[0].name;
      const idx = fileName ? fileName.lastIndexOf(".") : -1;
      if (idx > 0) {
        const extension = fileName.substring(idx + 1);
        // 확장자 체크
        if (!ext.includes(extension.toLowerCase())) {
          toast.toastMsg(nanoid(), t("link.msg.fileTypeCheckMsg"), "error");
          e.target.value = "";
          return;
        }
      }

      // 사이즈 체크
      if (e.target.files[0] && e.target.files[0].size > 20971520) {
        toast.toastMsg(
          nanoid(),
          t("attach.msg.attachInvalidFileSizeMsg"),
          "error"
        );
        e.target.value = "";
        return;
      }

      setFile(e.target.files[0]);
    } else {
      value = e.target.value.trim();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      width={480}
      title={siteId ? t("link.linkUpdate") : t("link.linkAdd")}
      isDivider={false}
      footer={
        <Box justifyContent="flex-end" width="100%">
          <Button color="secondary" onClick={onClose}>
            {t("common.button.cancel")}
          </Button>
          <Button classes="mg ml-8" onClick={onSubmit}>
            {siteId ? t("common.button.update") : t("common.button.add")}
          </Button>
        </Box>
      }
    >
      <Box direction="column">
        <Box direction="column">
          <Typography fontWeight={700}>{t("link.linkNameSimple")}</Typography>
          <TextField
            classes="mg mt-12"
            height={40}
            borderRadius={8}
            text={siteName ?? ""}
            width={424}
            onChange={(e: any) => setSiteName(e.target.value)}
            hasText
            placeholder={t("template.msg.linkNamePlaceholder")}
            maxLength={128}
          />
        </Box>
        <Box direction="column" classes="mg mt-32">
          <Typography fontWeight={700}>{t("attach.upload")}</Typography>
          <Box
            alignItems="center"
            // justifyContent="center"
            width={600}
            height={50}
            classes="mg mt-12"
          >
            <input
              style={{ display: "none" }}
              name="file"
              type="file"
              onChange={handleFileChange}
              className="modalField hide"
              ref={hiddenFileInput}
            />

            <TextField
              readOnly
              name="fileName"
              hasText
              text={
                file
                  ? file.name
                  : // : siteId
                    // ? contentState.sites
                    //     .filter((item: SiteInfo) => item.siteId === siteId)[0]
                    //     .siteUrl.split("/")
                    //     .pop()
                    ""
              }
              placeholder={t("link.msg.nofilePlaceholder")}
              height={40}
              width={329}
            />

            <Button
              color="secondary"
              onClick={handleFileClick}
              classes="mg mt-10 ml-8"
              width={87}
              padding={10}
            >
              {t("common.button.selectFile")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default RegitOrEditSiteModal;
