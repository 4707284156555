import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./en/page.json";
import ko from "./ko/page.json";

const resources = {
  en: { translation: en },
  ko: { translation: ko },
};

const fallbackLng = ["ko"];

interface Navigator1 {
  userLanguage?: string;
  browserLanguage?: string;
  languages: readonly string[];
  language: string;
}

const nowNavigator: Navigator1 = window.navigator;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng:
      window.navigator.language === "ko" ||
      window.navigator.language === "ko-KR" ||
      window.navigator.language === "ko-kr"
        ? "ko"
        : window.navigator.language === "en" ||
          window.navigator.language === "en-US" ||
          window.navigator.language === "en-us"
        ? "en"
        : nowNavigator.browserLanguage === "ko-KR" ||
          nowNavigator.browserLanguage === "ko-kr"
        ? "ko"
        : "en",
    fallbackLng,
    // ns: ["page"],
    // interpolation: {
    //   escapeValue: false,
    // },
  });

export default i18n;
