import { useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  trunkAddNewManager,
  trunkDeleteManager,
  trunkGetCommonMangerList,
  trunkGetManagerList,
  trunkUpdateManagerInfo,
} from "../store/manager";

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useManagerStore = () => {
  const managerState = useRootState((state) => state.managerStore);

  const dispatch = useAppDispatch();

  const boundGetManagerList = useMemo(
    () => bindActionCreators(trunkGetManagerList, dispatch),
    [dispatch]
  );
  const boundGetCommonMangerList = useMemo(
    () => bindActionCreators(trunkGetCommonMangerList, dispatch),
    [dispatch]
  );
  const boundAddNewManager = useMemo(
    () => bindActionCreators(trunkAddNewManager, dispatch),
    [dispatch]
  );
  const boundUpdateManagerInfo = useMemo(
    () => bindActionCreators(trunkUpdateManagerInfo, dispatch),
    [dispatch]
  );
  const boundDeleteManager = useMemo(
    () => bindActionCreators(trunkDeleteManager, dispatch),
    [dispatch]
  );

  return {
    managerState,
    getManagerList: boundGetManagerList,
    getCommonMangerList: boundGetCommonMangerList,
    addNewManager: boundAddNewManager,
    updateManagerInfo: boundUpdateManagerInfo,
    deleteManager: boundDeleteManager,
  };
};

export default useManagerStore;
