import { CustomerInfoBean } from "../types/Info";
import { Progress } from "../types/Mail";
import { ConfigListType } from "../types/Send";

export const DUMMY_CUSTOMER_LIST = [
  {
    email: null,
    customerName: null,
    companyName: "컨설팅서비스팀",
    pricingPlan: null,
    supportPlan: null,
    etcInfo: null,
    reportInfo: null,
    regularPayment: false,
    startDate: null,
    endDate: null,
    createDate: null,
    lastAccessDate: null,
    validInUse: false,
    customerCode: "7dc190244ea14ed9b0760c6f452d918f",
    serviceType: 0,
    dbName: "test_consulting",
    activateAutoReply: false,
    categoryId: 0,
    categoryName: null,
  },
  {
    email: null,
    customerName: null,
    companyName: "랩소디개발팀",
    pricingPlan: null,
    supportPlan: null,
    etcInfo: null,
    reportInfo: null,
    regularPayment: false,
    startDate: null,
    endDate: null,
    createDate: null,
    lastAccessDate: null,
    validInUse: false,
    customerCode: "9760866a088249d9ac66a7f22a44fe66",
    serviceType: 0,
    dbName: "wsd-test-hjj",
    activateAutoReply: false,
    categoryId: 0,
    categoryName: null,
  },
  {
    email: null,
    customerName: null,
    companyName: "인주비 테스트",
    pricingPlan: null,
    supportPlan: null,
    etcInfo: null,
    reportInfo: null,
    regularPayment: false,
    startDate: null,
    endDate: null,
    createDate: null,
    lastAccessDate: null,
    validInUse: false,
    customerCode: "d42585b2f4ce466a97b62f28f4236d77",
    serviceType: 0,
    dbName: "test_injubi",
    activateAutoReply: false,
    categoryId: 0,
    categoryName: null,
  },
] as CustomerInfoBean[];

export const DUMMY_DASHBOARD_PROGRESS_LIST = [
  {
    config_id: 46,
    send_start_date: "2024-01-08T06:00:00",
    send_end_date: "2024-01-19T22:59:00",
    total_mail_count: 1595,
    send_mail_count: 1595,
    recentHistory: null,
  },
] as Progress[];

export const DUMMY_REPLY_UPDATE_REPLY = {
  autoYn: false,
  replyBody: "<p>change_testste</p>",
  replyId: 5,
  replyTitle: "change tset",
  updateUser: "admin",
};

export const DUMMY_REPLY_INSERT = {
  autoYn: false,
  replyBody: "<p>insert testdd</p>",
  replyTitle: "insert test",
  updateUser: "admin",
};

export const DUMMY_REPORT_MAIL_LIST = {
  //    "size": 3,
  //    "cursor": 17,
  //    "searchWord": "근태",
  mailType: "",
  forwardedStartDate: 1709251200,
  forwamailReportIdrdedEndDate: 1711929599,
};

export const DUMMY_REPORT_MAIL_CNT = {
  searchTerm: "",
  rsd: "",
  red: "",
  fsd: "",
  fed: "",
  trainingFlag: null,
};

export const DUMMY_REPORT_COMMENT = {
  mailReportId: 50,
  spamComment: "api test",
};

export const DUMMY_SEND_REPLY = {
  bcc_address: "",
  body: "<p>ㅎㅇ</p>",
  cc_address: "",
  ex_subject: "222 test",
  mail_id: "22",
  mail_report_id: 50,
  report_date: "2024-03-19 13:19:15.000",
  send_history_id: 0,
  subject: "222 test",
  training_flag: false,
  user_email: "injubi@fasoo.com",
  user_name: "인주비",
};

export const DUMMY_DOWNLOAD_ATTACHMENT = {
  fileName: "hyundaicard_20240227.html",
  filePath:
    "C:/Exception/mind-SAT-attachments/hyundaicard_20240227_202403191456016.html",
};

export const DUMMY_REPORT_STAT_BY_PERIOD = {
  startDate: "2023-03-25 14:24:47",
  endDate: "2024-03-25 14:24:47",
  realTrainingFlag: null,
};

export const DUMMY_INSERT_USER = {
  userId: null,
  userEmail: "insertTest@fasoo.com",
  userName: "인서트",
  deptCode: "37aeb28411dd4b78bf2f1f304f531aae",
  activateFlag: null,
  jobTitle: null,
  managerFlag: false,
  etc1: null,
  etc2: null,
  etc3: null,
  etc4: null,
  etc5: null,
  etc6: null,
  etc7: null,
  etc8: null,
  etc9: null,
  etc10: null,
};

export const DUMMY_INSERT_DEPT = {
  deptCode: "codetest2222",
  deptName: "insertdepttest",
  parentCode: "COMPANY",
};

export const DUMMY_TRAIN_LIST = [
  {
    realTrainingFlag: true,
    configId: 123,
    configName: "악성 메일 모의 훈련",
    sendStartDate: "2024-04-15",
    sendEndDate: "2024-06-13",
    progress: 50,
    userCount: 10,
    send_mail_count: 10,
    template_count: 3,
    activate_flag: true,
  },
  {
    realTrainingFlag: false,
    configId: 122,
    configName: "악성 메일 모의 훈련 1134112341",
    sendStartDate: "2024-04-15",
    sendEndDate: "2024-06-13",
    // progress: 50,
    userCount: 10,
    sendMailCount: 10,
    templateCount: 3,
    activateFlag: false,
  },
] as ConfigListType[];
