import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoryApi from "../api/categoryApi";
import { Category } from "../../types/Info";

interface CategoryState {
  categoryList: Category[];
}

// # initial state
const initialState: CategoryState = {
  categoryList: [],
};

export const trunkGetCategories = createAsyncThunk(
  "category/list",
  async () => {
    const resp = await categoryApi.getCategories();
    return resp;
  }
);
export const trunkAddCategory = createAsyncThunk(
  "category/add",
  async (categoryName: string) => {
    const resp = await categoryApi.addCategory(categoryName);
    return resp;
  }
);
export const trunkUpdateCategory = createAsyncThunk(
  "category/update",
  async (req: Category) => {
    const resp = await categoryApi.updateCategory(req);
    return resp;
  }
);
export const trunkDeleteCategory = createAsyncThunk(
  "category/delete",
  async (categoryCodes: string) => {
    const resp = await categoryApi.deleteCategory(categoryCodes);
    return resp;
  }
);
const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkGetCategories.fulfilled,
      (state, { payload: data }) => {
        state.categoryList = data.data;
      }
    );
    builder.addCase(trunkAddCategory.fulfilled, (state, { payload: data }) => {
      if (!data.success) return;
      state.categoryList = [...state.categoryList, data.data];
    });
    builder.addCase(
      trunkUpdateCategory.fulfilled,
      (state, { payload: data }) => {
        if (!data.success) return;

        state.categoryList = state.categoryList.map((item) =>
          item.categoryName === data.data.categoryName
            ? { ...item, category_name: data.data.categoryName }
            : item
        );
      }
    );
    builder.addCase(trunkDeleteCategory.fulfilled, (state, resq) => {
      if (!resq.payload.success) return;

      state.categoryList = state.categoryList.filter(
        (item) => item.categoryName !== resq.meta.arg
      );
    });
  },
});
export const categoryActions = categorySlice.actions;

export default categorySlice.reducer;
