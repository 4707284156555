import { useMemo } from "react";
import { useAppDispatch, useRootState } from ".";
import {
  trunkGetRank,
  trunkGetRankByPeriod,
  trunkGetRankDeptDetail,
  trunkGetRankDeptDetailByPeriod,
  trunkGetRankDetail,
  trunkGetRankDetailByPeriod,
  trunkGetUserReportRankDetailByConfig,
  trunkGetProgramConfigInfo,
} from "../store/reportRank";
import { bindActionCreators } from "redux";
const useReportRankStore = () => {
  const reportRankState = useRootState((state) => state.reportRankStore);

  const dispatch = useAppDispatch();

  const boundGetRank = useMemo(
    () => bindActionCreators(trunkGetRank, dispatch),
    [dispatch]
  );

  const boundGetRankByPeriod = useMemo(
    () => bindActionCreators(trunkGetRankByPeriod, dispatch),
    [dispatch]
  );
  const boundGetRankDetail = useMemo(
    () => bindActionCreators(trunkGetRankDetail, dispatch),
    [dispatch]
  );

  const boundGetUserReportRankDetailByConfig = useMemo(
    () => bindActionCreators(trunkGetUserReportRankDetailByConfig, dispatch),
    [dispatch]
  );

  const boundGetRankDetailByPeriod = useMemo(
    () => bindActionCreators(trunkGetRankDetailByPeriod, dispatch),
    [dispatch]
  );

  const boundGetRankDeptDetail = useMemo(
    () => bindActionCreators(trunkGetRankDeptDetail, dispatch),
    [dispatch]
  );
  const boundGetRankDeptDetailByPeriod = useMemo(
    () => bindActionCreators(trunkGetRankDeptDetailByPeriod, dispatch),
    [dispatch]
  );

  const boundGetProgramConfigInfo = useMemo(
    () => bindActionCreators(trunkGetProgramConfigInfo, dispatch),
    [dispatch]
  );

  return {
    reportRankState,
    getRank: boundGetRank,
    getRankByPeriod: boundGetRankByPeriod,
    getRankDeptDetail: boundGetRankDeptDetail,
    getRankDeptDetailByPeriod: boundGetRankDeptDetailByPeriod,
    getRankDetail: boundGetRankDetail,
    getRankDetailByPeriod: boundGetRankDetailByPeriod,
    getUserReportRankDetailByConfig: boundGetUserReportRankDetailByConfig,
    getConfigProgramInfo: boundGetProgramConfigInfo,
  };
};

export default useReportRankStore;
