import { Box, Select, Typography } from "fasoo-ui-component-next";
import { DUMMY_CUSTOMER_LIST } from "../../Pages/DummyData";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUserInfoFromLocalStorage,
  setUserInfoFromLocalStorage,
} from "../../shared/utils/auth";
import { SelectItem } from "fasoo-ui-component-next/src/components/atom/Select";
import useCustomerStore from "../../redux/dispatcher/useCustomerStore";
import { CustomerInfoBean } from "../../types/Info";

const Customer: React.FC = () => {
  const navigate = useNavigate();

  const [customerList, changeCustomerList] = useState<CustomerInfoBean[]>(
    [] as CustomerInfoBean[]
  );
  const [userInfo] = useState(
    getUserInfoFromLocalStorage() ? { ...getUserInfoFromLocalStorage() } : null
  );

  useEffect(() => {
    changeCustomerList(DUMMY_CUSTOMER_LIST);
  }, []);

  // const { selectCustomer } = loginApi;

  const { selectCustomer } = useCustomerStore();
  const onClickCustomer = async (key: any) => {
    setUserInfoFromLocalStorage({
      ...userInfo,
      customerCode: key,
    });

    // const res = await selectCustomer(key);
    const res = selectCustomer(key);

    navigate(`${process.env.PUBLIC_URL}/dashboard`);
  };

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      width={"100%"}
      height={"100%"}
    >
      <Typography size={16}>접속할 고객사를 선택해 주세요.</Typography>
      <Select
        classes="mg ml-40"
        title={"고객사 선택"}
        width={300}
        height={40}
        onClick={(key: any) => onClickCustomer(key)}
      >
        {customerList.map((item, index) => (
          <SelectItem eventKey={item.customerCode} key={"customer" + index}>
            {item.companyName}
          </SelectItem>
        ))}
      </Select>
    </Box>
  );
};
export default Customer;
