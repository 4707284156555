import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import axiosForm from "../../shared/utils/axiosForm";
import { BaseResponseBean, BaseResponseDTO } from "../../types/Common";
import { Category, CategoryType } from "../../types/Info";

const GATEWAY_URL = config.servers.gateway.url;
interface CategoryApi {
  getCategories(): Promise<BaseResponseDTO<Category[]>>;
  addCategory(categoryName: string): Promise<BaseResponseBean<Category>>;
  updateCategory(req: Category): Promise<BaseResponseBean<CategoryType>>;
  deleteCategory(categoryCodes: string): Promise<BaseResponseBean<null>>;
}
class CategoryServerApi implements CategoryApi {
  async getCategories(): Promise<BaseResponseDTO<Category[]>> {
    const res = await axios.post<BaseResponseDTO<Category[]>>(
      `${GATEWAY_URL}/api/v2/scenario/categorys`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async addCategory(categoryName: string): Promise<BaseResponseBean<Category>> {
    let formData = new FormData();
    formData.set("categoryName", categoryName);
    const res = await axiosForm.post<BaseResponseBean<Category>>(
      `/category/add`,
      formData
    );
    return Promise.resolve(res.data);
  }
  async updateCategory(req: Category): Promise<BaseResponseBean<CategoryType>> {
    const res = await axios.post<BaseResponseBean<CategoryType>>(
      `/category/update`,
      req
    );
    return Promise.resolve(res.data);
  }
  async deleteCategory(categoryCode: string): Promise<BaseResponseBean<null>> {
    const res = await axios.post<BaseResponseBean<null>>(
      `/category/delete?categoryCodes=${categoryCode}`
    );
    return Promise.resolve(res.data);
  }
}

const categoryApi: CategoryApi = new CategoryServerApi();

export default categoryApi;
