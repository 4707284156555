import {
  Box,
  Button,
  Checkbox,
  Divider,
  DropdownItem,
  Icons,
  Modal,
  SettingButton,
  Table,
  TextField,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../component/TitlePath";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import "./index.scss";
import TreeSimple from "../../component/TreeSimple";
import { SimpleTreeNode, UserType } from "../../types/User";
import useDeptStore from "../../redux/dispatcher/useDeptStore";
import NoUserImage from "../../shared/image/NoUserImage";
import CreateUpdateUserModal from "./CreateUpdateUserModal";
import { nanoid } from "@reduxjs/toolkit";
import InsertDeptByCSVModal from "./InsertDeptByCSVModal";
import { useTranslation } from "react-i18next";
import useCheckboxGroup from "../../shared/utils/checkbox";
import ManagerIcon from "../../shared/image/ManagerIcon";

const Organ: React.FC = () => {
  const {
    deptState,
    getDeptList,
    getOrganUserList,
    getOrganUserCount,
    exportDeptFile,
    deleteOrganUserList,
  } = useDeptStore();
  const toast = useToast();
  const { t } = useTranslation();
  const checkboxGroup = useCheckboxGroup<string>();
  const [clickedId, changeClickedId] = useState("COMPANY");
  const [organTree, changeOrganTree] = useState<SimpleTreeNode[] | undefined>();
  const [userList, changeUserList] = useState<UserType[]>([]);
  const [targetDept, changeTargetDept] = useState<SimpleTreeNode | undefined>();
  const [regitOrUpdateUserInfo, changeRegitOrUpdateUserInfo] = useState({
    isOpen: false,
    userId: "",
  });
  const [targetDeleteUser, changeTargetDeleteUser] = useState<string[]>([]);
  const [uploadUserDeptModalMode, changeUploadUserDeptModalMode] = useState<
    "DEPT" | "CLOSE" | "USER"
  >("CLOSE");
  const [searchDeptTxt, changeSearchDeptText] = useState("");
  const [buttonClick, setButtonClick] = useState(0);
  useEffect(() => {
    getDeptList();
    getOrganUserCount();
  }, []);
  useEffect(() => {
    if (searchDeptTxt === "") changeOrganTree(deptState.organList);
    else changeSearchDeptText("");
  }, [deptState.organList]);
  useEffect(() => {
    changeClickedId("COMPANY");
    changeOrganTree(filterDeptList(deptState.organList));
  }, [searchDeptTxt]);
  useEffect(() => {
    changeUserList(deptState.userListInDept);
  }, [deptState.userListInDept]);
  useEffect(() => {
    changeTargetDept(
      deptState.organList?.filter(
        (item: SimpleTreeNode) => item.deptCode === clickedId
      )[0]
    );
  }, [clickedId, deptState.organList]);
  useEffect(() => {
    checkboxGroup.handleAllClick([]);
    getOrganUserList(clickedId);
  }, [clickedId]);

  const handleRemoveUser = async (userId: string[] | null) => {
    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
    if (!userId) return;
    let resp: any = await deleteOrganUserList(userId);
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("user.msg.userDeleteSuccessMsg"), "success");
    } else {
      toast.toastMsg(
        nanoid(),
        resp?.payload?.message ?? t("user.msg.userDeleteFailMsg"),
        "error"
      );
    }
    changeTargetDeleteUser([]);
    getOrganUserList(clickedId);
  };
  const renderSelectDeptInfo = () => {
    return (
      <Box
        alignItems="center"
        height={24}
        classes="mg mb-12"
        justifyContent="space-between"
      >
        <Box alignItems="center" width={"90%"}>
          <ToolTip message={targetDept?.deptName} maxWidth={"100%"}>
            <Typography
              size={16}
              fontWeight={700}
              lineHeight="24px"
              classes="mg ml-6"
              ellipsis
            >
              {targetDept?.deptName}
            </Typography>
          </ToolTip>

          <Typography
            size={16}
            fontWeight={700}
            lineHeight="24px"
            classes="mg ml-8"
            color="green"
          >
            {userList?.length ?? 0}
          </Typography>
        </Box>
        {checkboxGroup.selected.length >= 1 && (
          <Button
            color={"text-noline"}
            text={t("common.button.delete")}
            onClick={() => changeTargetDeleteUser(checkboxGroup.selected)}
            startIcon={<Icons variant="common" label="trash" stroke={"red"} />}
            textColor="red"
          />
        )}
      </Box>
    );
  };
  const filterDeptList = (organList: SimpleTreeNode[]) => {
    if (searchDeptTxt === "") return organList;
    if (!organList || organList?.length === 0) return [];
    let remainDeptList = organList?.filter(
      (item) => item?.deptName.includes(searchDeptTxt) && item.type === "dept"
    );
    let returnDeptList = organList?.filter(
      (item) => item?.deptCode === "COMPANY"
    );

    while (remainDeptList?.length > 0 && remainDeptList[0]) {
      let targetDept = remainDeptList[0];
      if (
        targetDept?.parentCode !== "COMPANY" &&
        returnDeptList?.filter(
          (item) => item?.deptCode === targetDept?.parentCode
        ).length === 0 &&
        targetDept
      ) {
        remainDeptList.push(
          organList.filter(
            (item) =>
              item?.deptCode === targetDept?.parentCode && item.type === "dept"
          )[0]
        );
      }
      if (
        returnDeptList.filter((item) => item?.deptCode === targetDept?.deptCode)
          .length === 0
      )
        returnDeptList.push(targetDept);

      remainDeptList.shift();
    }

    return returnDeptList;
  };

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [userList]);

  return (
    <Box classes="main organ">
      {regitOrUpdateUserInfo.isOpen && (
        <CreateUpdateUserModal
          isOpen={regitOrUpdateUserInfo.isOpen}
          targetDept={targetDept}
          updateUserId={regitOrUpdateUserInfo.userId}
          onClose={() =>
            changeRegitOrUpdateUserInfo({
              isOpen: false,
              userId: "",
            })
          }
        />
      )}
      {uploadUserDeptModalMode !== "CLOSE" && (
        <InsertDeptByCSVModal
          isOpen={true}
          type={uploadUserDeptModalMode}
          onClose={() => changeUploadUserDeptModalMode("CLOSE")}
        />
      )}
      {targetDeleteUser.length > 0 && (
        <Modal
          title={t("user.userDelete")}
          onClose={() => changeTargetDeleteUser([])}
          open={targetDeleteUser.length > 0}
          width={480}
          isDivider={false}
          footer={
            <Box justifyContent="flex-end">
              <Button
                text={t("common.button.cancel")}
                color="secondary"
                onClick={() => changeTargetDeleteUser([])}
              />
              <Button
                classes={"mg ml-8"}
                color="warning"
                onClick={() =>
                  handleRemoveUser(
                    targetDeleteUser.filter((item) => item !== null)
                  )
                }
              >
                {t("common.button.delete")}
              </Button>
            </Box>
          }
        >
          <Box width={"100%"}>
            <Typography color="secondary">
              {t("common.msg.deleteConfirmMsg")}
            </Typography>
          </Box>
        </Modal>
      )}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.userManagement")]} />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("menu.userManagement")}
          </Typography>
        </Box>
        <Box>
          <Button
            color={"secondary"}
            startIcon={<Icons variant="common" label="plus" stroke="#252d38" />}
            onClick={() =>
              changeRegitOrUpdateUserInfo({
                isOpen: true,
                userId: "",
              })
            }
          >
            {t("user.commonUserAdd")}
          </Button>
          <Button
            color={"secondary"}
            classes="mg ml-10"
            startIcon={<Icons variant="common" label="plus" stroke="#252d38" />}
            onClick={() => changeUploadUserDeptModalMode("DEPT")}
          >
            {t("dept.deptAddCsvTitle")}
          </Button>
          <Button
            color={"secondary"}
            classes="mg ml-10"
            startIcon={<Icons variant="common" label="plus" stroke="#252d38" />}
            onClick={() => changeUploadUserDeptModalMode("USER")}
          >
            {t("user.userAddCsvTitle")}
          </Button>
          <Button
            color={"secondary"}
            classes="mg ml-10"
            startIcon={<Icons variant="file" label="download" />}
            onClick={exportDeptFile}
          >
            {t("common.button.exportToExcel")}
          </Button>
        </Box>
      </Box>
      <Box classes="mg mt-8" height={"calc(100% - 84px)"}>
        <Box classes="organ-tree" direction="column">
          <Box alignItems="flex-end">
            <Typography isBold size={16} lineHeight="24px" classes="mg mb-12">
              {t("dept.deptChart")}
            </Typography>
            <Typography
              isBold
              size={16}
              lineHeight="24px"
              classes="mg mb-12 ml-12"
              color="green"
            >
              {deptState.userTotal}
            </Typography>
          </Box>
          <TextField
            size={"lg"}
            placeholder={t("dept.msg.undefinedNameMsg")}
            borderRadius={4}
            text={searchDeptTxt}
            hasText
            onChange={(e) => changeSearchDeptText(e.target.value)}
          />
          {organTree && (
            <TreeSimple
              rootCode={"COMPANY"}
              clickedId={clickedId}
              changeClickedId={changeClickedId}
              treeNodes={organTree}
              treeType={"DEPT_ONLY"}
              depth={0}
              classes="mg mt-8"
              defaultExpanded={false}
              isOranPage
            />
          )}
        </Box>
        <Box classes="organ-body">
          {renderSelectDeptInfo()}{" "}
          <Table classes="default-table">
            <colgroup>
              {/*  */}
              <col style={{ width: "16px" }}></col>
              {/* 체크 박스 */}
              <col style={{ width: "36px" }}></col>
              {/* 사용자 이름 */}
              <col style={{ maxWidth: "192px" }}></col>
              {/* 직급 */}
              <col style={{ maxWidth: "96px" }}></col>
              {/* 이메일 */}
              <col style={{ maxWidth: "192px" }}></col>
              {/* 기타 */}
              <col style={{ maxWidth: "192px" }}></col>
              {/* 더보기 */}
              <col style={{ width: `calc(48px + ${scrollbarWidth}px)` }}></col>
            </colgroup>
            <thead style={{ height: "48px" }}>
              <tr>
                <th />
                <th>
                  {userList?.length > 0 && (
                    <Checkbox
                      checked={
                        checkboxGroup.selected.length === userList?.length
                      }
                      onChange={() =>
                        checkboxGroup.handleAllClick(
                          checkboxGroup.selected.length === userList?.length
                            ? []
                            : userList?.map((item) => item.userId ?? "")
                        )
                      }
                    />
                  )}
                </th>
                <th>
                  <Box alignItems="center">
                    <Typography color="secondary">
                      {t("user.userName")}
                    </Typography>
                  </Box>
                </th>
                <th>
                  <Box alignItems="center">
                    <Typography color="secondary">
                      {t("user.userPosition")}
                    </Typography>
                  </Box>
                </th>
                <th>
                  <Box alignItems="center">
                    <Typography color="secondary">
                      {t("user.userEmail")}
                    </Typography>
                  </Box>
                </th>
                <th>
                  <Box alignItems="center">
                    <Typography color="secondary">
                      {t("user.userMemo")}
                    </Typography>
                  </Box>
                </th>
                <th />
              </tr>
            </thead>
          </Table>
          {!userList || userList.length === 0 ? (
            <Box
              width={"100%"}
              height={"100%"}
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <NoUserImage classes={"mg mt-170"} />
              <Typography
                classes="mg mt-24"
                size={16}
                fontWeight={400}
                lineHeight="24px"
                color={"secondary"}
              >
                {t("user.msg.noUserMsg")}
              </Typography>
              <Button
                classes="mg mt-24"
                startIcon={
                  <Icons variant="common" label="plus" stroke="white" />
                }
                onClick={() =>
                  changeRegitOrUpdateUserInfo({
                    isOpen: true,
                    userId: "",
                  })
                }
              >
                {t("user.commonUserAdd")}
              </Button>
            </Box>
          ) : (
            <Box
              classes="table-scroll"
              // style={{ height: "calc(100% - 610px)" }}
            >
              <Table>
                <colgroup>
                  {/*  */}
                  <col style={{ width: "16px" }}></col>
                  {/* 체크 박스 */}
                  <col style={{ width: "36px" }}></col>
                  {/* 사용자 이름 */}
                  <col style={{ maxWidth: "192px" }}></col>
                  {/* 직급 */}
                  <col style={{ maxWidth: "96px" }}></col>
                  {/* 이메일 */}
                  <col style={{ maxWidth: "192px" }}></col>
                  {/* 기타 */}
                  <col style={{ maxWidth: "192px" }}></col>
                  {/* 더보기 */}
                  <col style={{ width: "48px" }}></col>
                </colgroup>
                <tbody className="roundTable">
                  {userList?.map((item) => (
                    <tr>
                      <td></td>
                      <td>
                        <Checkbox
                          checked={
                            !!item.userId &&
                            checkboxGroup.isSelected(item.userId)
                          }
                          onChange={() =>
                            item.userId &&
                            checkboxGroup.handleClick(item.userId)
                          }
                        />
                      </td>
                      <td>{item.userName}</td>
                      <td>
                        <Box alignItems="center">
                          {item.managerFlag && <ManagerIcon />}
                          <Typography classes="mg ml-4">
                            {item.jobTitle}
                          </Typography>
                        </Box>
                      </td>
                      <td>{item.userEmail}</td>
                      <td>{item.etc1}</td>
                      <td>
                        {
                          <SettingButton buttonClick={buttonClick}>
                            <DropdownItem
                              onClick={() => {
                                changeRegitOrUpdateUserInfo({
                                  isOpen: true,
                                  userId: item.userId ?? "",
                                });
                                setButtonClick(
                                  buttonClick > 99999 ? 1 : buttonClick + 1
                                );
                              }}
                            >
                              <Icons variant="file" label="rename" />
                              <Typography>
                                {t("common.button.update")}
                              </Typography>
                            </DropdownItem>
                            <Divider direction="row" classes="mg mt-2 mb-2" />
                            <DropdownItem
                              isRed
                              onClick={() => {
                                item.userId &&
                                  changeTargetDeleteUser([item.userId]);
                                setButtonClick(
                                  buttonClick > 99999 ? 1 : buttonClick + 1
                                );
                              }}
                            >
                              <Icons
                                variant="common"
                                label="trash"
                                stroke={"red"}
                              />
                              <Typography>
                                {t("common.button.delete")}
                              </Typography>
                            </DropdownItem>
                          </SettingButton>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Organ;
