import { useMemo } from "react";
import { useAppDispatch, useRootState } from ".";
import { bindActionCreators } from "redux";
import {
  decryptUser,
  deleteUser,
  insertCsv,
  trunkGetUsers,
  trunkSetUser,
  updateUserActivateFlag,
  updateUserDeactivateFlag,
  uploadCsv,
} from "../store/user";

const useUserStore = () => {
  const userState = useRootState((state) => state.userStore);

  const dispatch = useAppDispatch();

  const boundGetUsers = useMemo(
    () => bindActionCreators(trunkGetUsers, dispatch),
    [dispatch]
  );

  const boundUpdateUserActivateFlag = useMemo(
    () => bindActionCreators(updateUserActivateFlag, dispatch),
    [dispatch]
  );

  const boundUpdateUserDeactivateFlag = useMemo(
    () => bindActionCreators(updateUserDeactivateFlag, dispatch),
    [dispatch]
  );

  const boundDeleteUser = useMemo(
    () => bindActionCreators(deleteUser, dispatch),
    [dispatch]
  );

  const boundUploadCsv = useMemo(
    () => bindActionCreators(uploadCsv, dispatch),
    [dispatch]
  );

  const boundInsertCsv = useMemo(
    () => bindActionCreators(insertCsv, dispatch),
    [dispatch]
  );

  const boundSetUser = useMemo(
    () => bindActionCreators(trunkSetUser, dispatch),
    [dispatch]
  );

  const boundDecryptUser = useMemo(
    () => bindActionCreators(decryptUser, dispatch),
    [dispatch]
  );

  return {
    userState,
    getUsers: boundGetUsers,
    updateUserActivateFlag: boundUpdateUserActivateFlag,
    updateUserDeactivateFlag: boundUpdateUserDeactivateFlag,
    deleteUser: boundDeleteUser,
    uploadUserCsv: boundUploadCsv,
    insertUserCsv: boundInsertCsv,
    setUser: boundSetUser,
    decryptUser: boundDecryptUser,
  };
};

export default useUserStore;
