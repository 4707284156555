import { Box, Typography } from "fasoo-ui-component-next";
import React from "react";
import NoInfoImage from "../../shared/image/NoInfoImage";
import { useTranslation } from "react-i18next";

const NoInfo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      width={"100%"}
      height={"400px"}
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <NoInfoImage width={400} />
      <Typography size={18} isBold classes="mg mt-8">
        {t("common.msg.noHistoryMsg")}
      </Typography>
    </Box>
  );
};

export default NoInfo;
