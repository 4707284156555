import { useCallback, useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  loginSession,
  logoutSession,
  trunkChangePwd,
  sessionCheck,
  trunkInitPwd,
} from "../store/account";

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAccountStore = () => {
  const accountState = useRootState((state) => state.accountStore);

  const dispatch = useAppDispatch();

  const boundLoginSession = useMemo(
    () => bindActionCreators(loginSession, dispatch),
    [dispatch]
  );
  const boundSessionLogOut = useMemo(
    () => bindActionCreators(logoutSession, dispatch),
    [dispatch]
  );
  const boundChangePwd = useMemo(
    () => bindActionCreators(trunkChangePwd, dispatch),
    [dispatch]
  );
  const boundResetPassword = useMemo(
    () => bindActionCreators(trunkInitPwd, dispatch),
    [dispatch]
  );
  const boundChangePassword = useMemo(
    () => bindActionCreators(trunkChangePwd, dispatch),
    [dispatch]
  );
  const boundSessionCheck = useMemo(
    () => bindActionCreators(sessionCheck, dispatch),
    [dispatch]
  );

  return {
    accountState,
    loginSession: boundLoginSession,
    sessionLogOut: boundSessionLogOut,
    changePwd: boundChangePwd,
    resetPassword: boundResetPassword,
    changePassword: boundChangePassword,
    sessionCheck: boundSessionCheck,
  };
};

export default useAccountStore;
