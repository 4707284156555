import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import { BaseResponseBean, BaseResponseDTO } from "../../types/Common";
import { CustomerInfoBean } from "../../types/Info";
import { CustomerCategoryBean } from "../../types/Manage";

const GATEWAY_URL = config.servers.gateway.url;

interface CustomerApi {
  getAccessCompanyList(): Promise<BaseResponseBean<CustomerInfoBean[]>>;
  getCustomerList(): Promise<CustomerInfoBean[]>;
  getCustomerCategory(): Promise<BaseResponseBean<CustomerCategoryBean[]>>;
  getCustomerSetting(): Promise<CustomerInfoBean[]>;
  updateCustomerInfo(req: {
    companyName: string;
    customerCode: string;
  }): Promise<BaseResponseBean<boolean>>;
  checkCustomerTraining(customerCode: string): Promise<BaseResponseBean<null>>;
  getCustomerName(customerCode: string): Promise<BaseResponseBean<string>>;
  selectCustomer(
    customerCode: string | null
  ): Promise<BaseResponseDTO<CustomerInfoBean>>;
  syncMasterToCustomer(): Promise<BaseResponseDTO<string>>;
}

class CustomerServerApi implements CustomerApi {
  async getAccessCompanyList(): Promise<BaseResponseBean<CustomerInfoBean[]>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/manager/customer/list`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async updateCustomerInfo(req: {
    companyName: string;
    customerCode: string;
  }): Promise<BaseResponseBean<boolean>> {
    const res = await axios.post<BaseResponseBean<boolean>>(
      `${GATEWAY_URL}/api/v2/manager/customer/update`,
      req
    );
    return Promise.resolve(res.data);
  }
  async checkCustomerTraining(
    customerCode: string
  ): Promise<BaseResponseBean<null>> {
    const res = await axios.get<BaseResponseBean<null>>(
      `/customer/training?customerCode=${customerCode}`
    );
    return Promise.resolve(res.data);
  }
  async getCustomerList(): Promise<CustomerInfoBean[]> {
    const res = await axios.get<CustomerInfoBean[]>("/customers");
    return Promise.resolve(res.data);
  }
  async getCustomerCategory(): Promise<
    BaseResponseBean<CustomerCategoryBean[]>
  > {
    const res = await axios.get<BaseResponseBean<CustomerCategoryBean[]>>(
      "/customer/categories"
    );
    return Promise.resolve(res.data);
  }
  async getCustomerSetting(): Promise<CustomerInfoBean[]> {
    const res = await axios.get<CustomerInfoBean[]>("/customer/settings");
    return Promise.resolve(res.data);
  }
  async getCustomerName(
    customerCode: string
  ): Promise<BaseResponseBean<string>> {
    const res = await axios.get<BaseResponseBean<string>>(
      `/customer/name?customerCode=${customerCode}`
    );
    return Promise.resolve(res.data);
  }
  // switch access customer
  async selectCustomer(
    customerCode: string | null
  ): Promise<BaseResponseDTO<CustomerInfoBean>> {
    const res = await axios.post<BaseResponseDTO<CustomerInfoBean>>(
      `${GATEWAY_URL}/api/v2/manager/customer/select`,
      { customerCode }
    );
    return Promise.resolve(res.data);
  }

  async syncMasterToCustomer(): Promise<BaseResponseDTO<string>> {
    const res = await axios.post<BaseResponseDTO<string>>(
      `${GATEWAY_URL}/api/v2/scenario/sync`
    );

    return Promise.resolve(res.data);
  }
}

const customerApi: CustomerApi = new CustomerServerApi();

export default customerApi;
