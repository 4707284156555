import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./locales/index";

import { Provider } from "react-redux";
import rootReducer from "./redux/dispatcher/index";
import { configureStore } from "@reduxjs/toolkit";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
export const stores = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  // .concat(middlewares), //미들웨어 적용
  devTools: process.env.NODE_ENV !== "production",
});
root.render(
  // <React.StrictMode>
  <Provider store={stores}>
    <App />
  </Provider>
  // </React.StrictMode>
);
