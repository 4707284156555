import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import {
  BaseResponseArrDTO,
  BaseResponseBean,
  BaseResponseDTO,
} from "../../types/Common";
import { CustomerInfoBean } from "../../types/Info";
import {
  ReplyInsertBean,
  ReplyUpdateConfigParam,
  ReportDeleteReplyBean,
  ReportReply,
  ReportReplyReqDTO,
  ReportReplyUpdateBean,
} from "../../types/Reply";

const GATEWAY_URL = config.servers.gateway.url;

interface ReplyApi {
  getReplys(req: ReportReplyReqDTO): Promise<
    BaseResponseDTO<{
      total: number;
      list: ReportReply[];
      customerInfo: CustomerInfoBean;
    }>
  >;
  deleteReply(
    replyIDs: number[]
  ): Promise<BaseResponseBean<ReportDeleteReplyBean>>;
  updateConfigReplyFlag(
    req: ReplyUpdateConfigParam
  ): Promise<BaseResponseBean<any>>;
  updateReply(
    reportReply: ReportReplyUpdateBean
  ): Promise<BaseResponseBean<any>>;
  insertReply(reportReply: ReplyInsertBean): Promise<BaseResponseBean<any>>;
  getManualReplys(): Promise<ReportReply[]>;
}

class ReplyServerApi implements ReplyApi {
  async getReplys(req: ReportReplyReqDTO): Promise<
    BaseResponseDTO<{
      total: number;
      list: ReportReply[];
      customerInfo: CustomerInfoBean;
    }>
  > {
    const res = await axios.post<
      BaseResponseDTO<{
        total: number;
        list: ReportReply[];
        customerInfo: CustomerInfoBean;
      }>
    >(`${GATEWAY_URL}/api/v2/report/reply/list`, req);

    return Promise.resolve(res.data);
  }

  async deleteReply(
    replyIDs: number[]
  ): Promise<BaseResponseBean<ReportDeleteReplyBean>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/report/reply/delete`, {
      replyIds: replyIDs,
    });

    return Promise.resolve(res.data);
  }

  async updateConfigReplyFlag(
    req: ReplyUpdateConfigParam
  ): Promise<BaseResponseBean<any>> {
    const { configId, flag, sendAll } = req;
    const res = await axios.post("/reply/config/update", null, {
      params: {
        configId,
        flag,
        sendAll,
      },
    });

    return Promise.resolve(res.data);
  }

  async updateReply(
    reportReply: ReportReplyUpdateBean
  ): Promise<BaseResponseBean<any>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/report/reply/update`,
      reportReply
    );

    return Promise.resolve(res.data);
  }

  async insertReply(
    reportReply: ReplyInsertBean
  ): Promise<BaseResponseBean<any>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/report/reply/insert`,
      reportReply
    );

    return Promise.resolve(res.data);
  }

  async getManualReplys(): Promise<ReportReply[]> {
    const res = await axios.get("/replys/manual");

    return Promise.resolve(res.data);
  }
}

const replyApi: ReplyApi = new ReplyServerApi();

export default replyApi;
