import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import configApi from "../api/configApi";
import { ConfigListType } from "../../types/Send";

interface ConfigState {
  configInfo?: ConfigListType;
}

// # initial state
const initialState: ConfigState = { configInfo: undefined };

export const trunkGetConfigInfo = createAsyncThunk(
  "config/getConfigInfo",
  async (configId: number) => {
    const resp = await configApi.getConfigInfo(configId);
    return resp;
  }
);
export const trunkGetTrainConfigInfo = createAsyncThunk(
  "config/getConfigInfo/train",
  async (configId: number) => {
    const resp = await configApi.getTrainConfigInfo(configId);
    return resp;
  }
);
export const trunkGetConfigList = createAsyncThunk(
  "config/getConfigList",
  async () => {
    const resp = await configApi.getConfigList();
    return resp;
  }
);
const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkGetConfigInfo.fulfilled,
      (state, { payload: data }) => {
        console.log(data);
        state.configInfo = data;
      }
    );
  },
});
export const configActions = configSlice.actions;

export default configSlice.reducer;
