import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from "@reduxjs/toolkit";
import dashboardApi from "../api/dashboardApi";
import { mindSatConfig } from "../../types/Common";

interface DashboardState {
  stats: StatTraining[];
  statsTop: StatTopTraining;
}

// # initial state
const initialState: DashboardState = {
  stats: [],
  statsTop: {
    clickedUser: [],
    nothingUser: [],
    scenario: [],
    statDate: [],
  },
};

export const trunkGetStatTraining = createAsyncThunk(
  "dashboard/getStatTraining",
  async (req?: {
    realTrainingFlag: boolean | null;
    configIds?: number[];
    intervalTime?: number;
    startDate?: number;
    endDate?: number;
  }) => {
    const resp = await dashboardApi.getStatTraining(req);
    return resp;
  }
);
export const trunkGetStatTopTraining = createAsyncThunk(
  "dashboard/getStatTopTraining",
  async (req: {
    configId?: number;
    intervalTime?: number;
    startDate?: number;
    endDate?: number;
    realTrainingFlag?: boolean | null;
  }) => {
    const resp = await dashboardApi.getStatTopTraining(req);
    return resp;
  }
);

export const trunkGetRecentClickHistoryList = createAsyncThunk(
  "dashboard/recentClickHistoryList",
  async (req: mindSatConfig) => {
    const resp = await dashboardApi.recentClickHistoryList(req);
    return resp;
  }
);
export const trunketProgressList = createAsyncThunk(
  "dashboard/getProgressList",
  async (configId: number) => {
    const resp = await dashboardApi.getProgressList(configId);
    return resp;
  }
);
export const trunkGetDashboardReport = createAsyncThunk(
  "dashboard/getDashboardReport",
  async (configId: number) => {
    const resp = await dashboardApi.getDashboardReport(configId);
    return resp;
  }
);
export const trunkGetSendHistory = createAsyncThunk(
  "dashboard/getSendHistory",
  async (configId: number) => {
    const resp = await dashboardApi.getSendHistory(configId);
    return resp;
  }
);
export const trunkGetPhishTypes = createAsyncThunk(
  "dashboard/getPhishTypes",
  async (configId: number) => {
    const resp = await dashboardApi.getPhishTypes(configId);
    return resp;
  }
);
export const trunkGetReportHistory = createAsyncThunk(
  "dashboard/getReportHistory",
  async () => {
    const resp = await dashboardApi.getReportHistory();
    return resp;
  }
);
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkGetStatTraining.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.stats = data.data;
        } else {
          state.stats = [];
        }
      }
    );

    builder.addCase(
      trunkGetStatTopTraining.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.statsTop = data.data;
        } else {
          state.statsTop = {
            clickedUser: [],
            nothingUser: [],
            scenario: [],
            statDate: [],
          };
        }
      }
    );

    builder.addCase(
      trunkGetRecentClickHistoryList.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunketProgressList.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
  },
});
export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
