import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import traineStaticsApi from "../api/traineStatics";
import { TimeRequest } from "../../types/Common";
import { DateBean } from "../../types/Report";

interface TraineStaticsState {}

// # initial state
const initialState: TraineStaticsState = {};

export const trunkGetTraineGraphList = createAsyncThunk(
  "traineStatics/getTraineGraphList",
  async (req: TimeRequest) => {
    const resp = await traineStaticsApi.getTraineGraphList(req);
    return resp;
  }
);
export const trunkGetTraineGraphListType = createAsyncThunk(
  "traineStatics/getTraineGraphList/type",
  async (req: DateBean) => {
    const resp = await traineStaticsApi.getTraineGraphListType(req);
    return resp;
  }
);
export const trunkGetTraineGraphListConfig = createAsyncThunk(
  "traineStatics/getTraineGraphList/config",
  async (ids: number[]) => {
    const resp = await traineStaticsApi.getTraineGraphListConfig(ids);
    return resp;
  }
);
export const trunkGetInpercent = createAsyncThunk(
  "traineStatics/getInpercent",
  async (req: TimeRequest) => {
    const resp = await traineStaticsApi.getInpercent(req);
    return resp;
  }
);
export const trunkGetInpercentType = createAsyncThunk(
  "traineStatics/getInpercent/type",
  async (req: DateBean) => {
    const resp = await traineStaticsApi.getInpercentType(req);
    return resp;
  }
);
export const trunkGetInpercentConfig = createAsyncThunk(
  "traineStatics/getInpercent/config",
  async (ids: number[]) => {
    const resp = await traineStaticsApi.getInpercentConfig(ids);
    return resp;
  }
);
const traineStaticsSlice = createSlice({
  name: "trainestatics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkGetTraineGraphList.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
  },
});
export const traineStaticsActions = traineStaticsSlice.actions;

export default traineStaticsSlice.reducer;
