import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from "@reduxjs/toolkit";
import accountApi from "../api/accountApi";
import { LoginRequestDto } from "../../types/Login";
import {
  removeAccessToken,
  removeLocalCustomerCode,
  removeUserInfoFromLocalStorage,
  setAccessToken,
  setLocalCustomerCode,
  setUserInfoFromLocalStorage,
} from "../../shared/utils/auth";

interface AccountState {
  customerCode: string | null;
  accessToken: string | null;
  superAdmin: boolean;
  email: string | null;
  name: string | null;
  // accessMenu: string;
}

// # initial state
const initialState: AccountState = {
  customerCode: "",
  accessToken: "",
  superAdmin: false,
  email: "",
  name: "",
  // accessMenu: "",
};

export const loginSession = createAsyncThunk(
  "account/login",
  async ({ userId, userPwd }: LoginRequestDto) => {
    const resp = await accountApi.login(userId, userPwd);
    return resp;
  }
);

export const logoutSession = createAsyncThunk("account/logout", async () => {
  const resp = await accountApi.logout();
  return resp;
});

export const trunkInitPwd = createAsyncThunk(
  "change/pwd/init",
  async (req: { email: string; name: string }) => {
    const resp = await accountApi.initPwd(req);
    return resp;
  }
);
export const trunkChangePwd = createAsyncThunk(
  "change/pwd",
  async (req: { email: string; pwd: string; newPwd: string }) => {
    const resp = await accountApi.changePwd(req);
    return resp;
  }
);

export const sessionCheck = createAsyncThunk("account/check", async () => {
  const resp = await accountApi.sessionCheck();
  return resp;
});

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginSession.fulfilled, (state, { payload: data }) => {
      if (!data.data) return;
      if (data.data.user.customerCode) {
        setLocalCustomerCode(data.data.user.customerCode);
      }
      setAccessToken(data.data.token);
      setUserInfoFromLocalStorage({
        menuAccess: data.data.user.menuAccess,
        email: data.data.user.managerEmail,
        name: data.data.user.managerName,
        superAdmin: data.data.user.managerType === 1 ? true : false,
      });
      state.accessToken = data.data.token;
      state.email = data.data.user.managerEmail;
      state.superAdmin = data.data.user.managerType === 1 ? true : false;
      state.name = data.data.user.managerName;
    });

    builder.addCase(logoutSession.fulfilled, (state, { payload: data }) => {
      // console.log("-->", data);
      state.accessToken = "";
      state.email = "";
      state.superAdmin = false;
      removeAccessToken();
      removeUserInfoFromLocalStorage();
      removeLocalCustomerCode();
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    });
    builder.addCase(logoutSession.rejected, (state, { payload: data }) => {
      // console.log("-->", data);
      state.accessToken = "";
      state.email = "";
      state.superAdmin = false;
      removeAccessToken();
      removeUserInfoFromLocalStorage();
      removeLocalCustomerCode();
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    });

    builder.addCase(trunkChangePwd.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
  },
});
export const accountActions = accountSlice.actions;

export default accountSlice.reducer;
