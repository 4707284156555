const NoUserImage = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      width={width || "360"}
      height="200"
      className={classes}
      viewBox="0 0 360 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="302.949"
        y="100"
        width="7"
        height="7"
        transform="rotate(45 302.949 100)"
        fill="#FFC34E"
        fill-opacity="0.5"
      />
      <rect
        x="40.4844"
        y="44"
        width="12"
        height="12"
        transform="rotate(45 40.4844 44)"
        fill="#FFC34E"
      />
      <rect x="70" y="33" width="190" height="123" fill="#F5F8FB" />
      <path
        d="M70 29C70 23.4772 74.4772 19 80 19H250C255.523 19 260 23.4772 260 29V33H70V29Z"
        fill="#6B7684"
      />
      <path
        d="M260 160C260 165.523 255.523 170 250 170L80 170C74.4771 170 70 165.523 70 160L70 156L260 156L260 160Z"
        fill="#CCD1D9"
      />
      <rect x="144" y="58" width="96" height="6" fill="#DDE1E7" />
      <rect x="144.334" y="71.917" width="36" height="6" fill="#DDE1E7" />
      <circle cx="108" cy="68" r="20" fill="#FFC34E" />
      <mask
        id="mask0_5156_42223"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="88"
        y="48"
        width="40"
        height="40"
      >
        <circle cx="108" cy="68" r="20" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_5156_42223)">
        <ellipse cx="108" cy="91" rx="16" ry="13" fill="white" />
      </g>
      <circle cx="108.003" cy="66.6663" r="7.33333" fill="white" />
      <circle
        cx="123.409"
        cy="54.5575"
        r="5.93443"
        fill="#67E0C7"
        stroke="#F5F8FB"
        stroke-width="4"
      />
      <rect x="144" y="112" width="96" height="6" fill="#DDE1E7" />
      <rect x="144.334" y="125.917" width="36" height="6" fill="#DDE1E7" />
      <circle cx="108" cy="122" r="20" fill="#FFC34E" />
      <mask
        id="mask1_5156_42223"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="88"
        y="102"
        width="40"
        height="40"
      >
        <circle cx="108" cy="122" r="20" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask1_5156_42223)">
        <ellipse cx="108" cy="145" rx="16" ry="13" fill="white" />
      </g>
      <circle cx="108.003" cy="120.666" r="7.33333" fill="white" />
      <circle
        cx="123.409"
        cy="108.557"
        r="5.93443"
        fill="#67E0C7"
        stroke="#F5F8FB"
        stroke-width="4"
      />
      <circle cx="79" cy="26" r="3" fill="#F6705E" />
      <circle cx="89" cy="26" r="3" fill="#FFC34E" />
      <circle cx="99" cy="26" r="3" fill="#F5F8FB" />
      <circle cx="260" cy="153" r="34" fill="#4593FC" />
      <mask
        id="mask2_5156_42223"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="226"
        y="119"
        width="68"
        height="68"
      >
        <circle cx="260" cy="153" r="34" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask2_5156_42223)">
        <ellipse cx="260.001" cy="192.1" rx="27.2" ry="22.1" fill="#F5F8FB" />
      </g>
      <circle cx="260.228" cy="149.303" r="12.4667" fill="#F5F8FB" />
      <circle
        cx="284.026"
        cy="128.973"
        r="12.4733"
        fill="#67E0C7"
        stroke="white"
        stroke-width="5"
      />
      <path d="M284.027 124.439V133.506" stroke="white" stroke-width="2" />
      <path
        d="M288.561 128.973L279.494 128.973"
        stroke="white"
        stroke-width="2"
      />
      <rect
        x="63.9492"
        y="183"
        width="7"
        height="7"
        transform="rotate(45 63.9492 183)"
        fill="#FFC34E"
        fill-opacity="0.5"
      />
    </svg>
  );
};

export default NoUserImage;
