import { Box, Button, Typography } from "fasoo-ui-component-next";
import useReplyStore from "../../../redux/dispatcher/useReplyStore";
import {
  DUMMY_DOWNLOAD_ATTACHMENT,
  DUMMY_INSERT_DEPT,
  DUMMY_INSERT_USER,
  DUMMY_REPLY_INSERT,
  DUMMY_REPLY_UPDATE_REPLY,
  DUMMY_REPORT_COMMENT,
  DUMMY_REPORT_MAIL_CNT,
  DUMMY_REPORT_MAIL_LIST,
  DUMMY_REPORT_STAT_BY_PERIOD,
  DUMMY_SEND_REPLY,
} from "../../DummyData";
import useReportStore from "../../../redux/dispatcher/useReportStore";
import useReportStatStore from "../../../redux/dispatcher/useReportStatStrore";
import useReportRankStore from "../../../redux/dispatcher/useReportRankStore";
import useUserStore from "../../../redux/dispatcher/useUserStore";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import useIpStore from "../../../redux/dispatcher/useIpStore";
import useSignupStore from "../../../redux/dispatcher/useSignupStore";
import useConfigStore from "../../../redux/dispatcher/useConfigStore";

const DummyAPIPage2: React.FC = () => {
  const {
    getReplys,
    deleteReply,
    updateConfigReplyFlag,
    updateReply,
    insertReply,
    getManualReplys,
  } = useReplyStore();

  const {
    getReportMails,
    getReportMailCnt,
    getReportMailDetail,
    updateSpamFlag,
    updateComment,
    updateComplete,
    updateManualReplyState,
    sendManualReply,
    getReportAttachments,
    downloadAttachment,
  } = useReportStore();

  const {
    getReportStat,
    getReportStatByPeriod,
    getReportStatDetailPaging,
    getReportStatDetailByPeriod,
    getReportStatDetailByPeriodType,
    getConfigsByDate,
  } = useReportStatStore();
  const { getTrainConfigInfo } = useConfigStore();
  const {
    getRank,
    getRankByPeriod,
    getRankDeptDetail,
    getRankDeptDetailByPeriod,
    getRankDetail,
    getRankDetailByPeriod,
    getUserReportRankDetailByConfig,
    getConfigProgramInfo,
  } = useReportRankStore();

  const {
    getUsers,
    updateUserActivateFlag,
    updateUserDeactivateFlag,
    deleteUser,
    uploadUserCsv,
    insertUserCsv,
    setUser,
    decryptUser,
  } = useUserStore();

  const {
    getDeptList,
    getDeptListForUser,
    getDept,
    updateDept,
    uploadDeptCsv,
    insertDeptCsv,
    addDept,
    deleteDept,
  } = useDeptStore();

  const {
    getIpList,
    insertIp,
    deleteIp,
    updateIp,
    downloadFilteredLog,
    donwloadAccessLog,
    isExistsIp,
  } = useIpStore();

  const {
    isExistsEmail,
    sendAuthMail,
    isMatchAuthCode,
    isExistsReportAddress,
    doSignup,
  } = useSignupStore();

  return (
    <Box classes="main dummyAPI">
      {/* /reply start  */}
      <Typography size={20} isBold>
        /reply(s)
      </Typography>
      <Box classes="apis">
        <Button width={100} onClick={() => getReplys({})}>
          /replys
        </Button>
        <Button width={100} onClick={() => deleteReply([2])}>
          /reply/delete
        </Button>
        <Button
          width={200}
          onClick={() =>
            updateConfigReplyFlag({ configId: 23, flag: true, sendAll: true })
          }
        >
          /report/config/update
        </Button>
        <Button
          width={100}
          onClick={() => updateReply(DUMMY_REPLY_UPDATE_REPLY)}
        >
          /reply/update
        </Button>

        <Button width={100} onClick={() => insertReply(DUMMY_REPLY_INSERT)}>
          /reply/insert
        </Button>

        <Button width={150} onClick={() => getManualReplys()}>
          /replys/manual
        </Button>
      </Box>
      {/* reply end  */}
      {/* report start */}
      <Typography size={20} isBold>
        /report
      </Typography>
      <Box classes="apis">
        <Button
          width={100}
          onClick={() => getReportMails(DUMMY_REPORT_MAIL_LIST)}
        >
          /report/list
        </Button>
        <Button
          width={150}
          onClick={() => getReportMailCnt(DUMMY_REPORT_MAIL_CNT)}
        >
          /report/list/cnt
        </Button>
        <Button width={200} onClick={() => getReportMailDetail(50)}>
          {"/report/detail/${id}"}
        </Button>
        <Button
          width={250}
          onClick={() => updateSpamFlag({ flag: true, mailReportId: 50 })}
        >
          /report/update/spamFlag
        </Button>

        <Button width={200} onClick={() => updateComment(DUMMY_REPORT_COMMENT)}>
          /report/update/comment
        </Button>

        <Button
          width={200}
          onClick={() => updateComplete({ flag: true, mailReportId: 50 })}
        >
          /report/update/complete
        </Button>

        <Button
          width={200}
          onClick={() =>
            updateManualReplyState({ nowFlag: false, mailReportId: 50 })
          }
        >
          /report/update/manualReply
        </Button>

        <Button width={100} onClick={() => console.log("API CHANGEAS")}>
          /report/send
        </Button>

        <Box classes="apis">
          <Button width={200} onClick={() => getReportAttachments(50)}>
            {"/report/attachments/${id}"}
          </Button>

          {/* local 경로일 경우 해당되는 파일로 테스트 해야 함 */}
          <Button
            width={150}
            onClick={() => downloadAttachment(DUMMY_DOWNLOAD_ATTACHMENT)}
          >
            /report/download
          </Button>
        </Box>
      </Box>
      {/* report end */}
      {/* report stat start */}
      <Box classes="apis">
        <Button width={150} onClick={() => getReportStat([29])}>
          /report/statistics
        </Button>
        {/* local 경로일 경우 해당되는 파일로 테스트 해야 함 */}
        <Button
          width={200}
          onClick={() => getReportStatByPeriod(DUMMY_REPORT_STAT_BY_PERIOD)}
        >
          /report/statistics/period
        </Button>
        <Button
          width={250}
          onClick={() =>
            getReportStatDetailPaging({ configId: 29, pageNum: 1, pageRow: 10 })
          }
        >
          /report/statistics/detail/paging
        </Button>
        <Button
          width={250}
          onClick={() =>
            getReportStatDetailByPeriod({
              startConfig: 1,
              endConfig: 29,
              month: 6,
              realTrainingFlag: null,
            })
          }
        >
          /report/statistics/detail/period
        </Button>
        <Button
          width={250}
          onClick={() =>
            getReportStatDetailByPeriodType({
              startConfig: 1,
              endConfig: 29,
              month: 6,
              realTrainingFlag: false,
            })
          }
        >
          /report/statistics/detail/period/type
        </Button>
        <Button
          width={150}
          onClick={() => getConfigsByDate(DUMMY_REPORT_STAT_BY_PERIOD)}
        >
          /report/configs
        </Button>
      </Box>
      {/* report stat end  */}

      {/* report rank start */}
      <Box classes="apis">
        <Button width={150} onClick={() => getRank([29])}>
          /report/rank
        </Button>

        {/* local 경로일 경우 해당되는 파일로 테스트 해야 함 */}
        <Button
          width={200}
          onClick={() =>
            getRankByPeriod({
              startDate: 1709251200,
              endDate: 1711929599,
              realTrainingFlag: null,
            })
          }
        >
          /report/rank/period
        </Button>
        <Button
          width={150}
          onClick={() =>
            getRankDetail({
              configIds: [23],
              ids: ["fd76be3e9dbc4a50b7ef76f496790c96"],
            })
          }
        >
          /report/rank/detail
        </Button>
        <Button
          width={250}
          onClick={() =>
            getUserReportRankDetailByConfig({
              type: 1,
              configCodeListType: {
                configIds: [23],
                ids: ["fd76be3e9dbc4a50b7ef76f496790c96"],
              },
            })
          }
        >
          {"/report/rank/detail/config/${type}"}
        </Button>
        <Button
          width={200}
          onClick={() =>
            getRankDetailByPeriod({
              endDate: "2024-03-25 16:24:26",
              ids: ["fd76be3e9dbc4a50b7ef76f496790c96"],
              startDate: "2023-03-25 16:24:26",
            })
          }
        >
          /report/rank/detail/period
        </Button>
        <Button
          width={200}
          onClick={() =>
            getRankDeptDetail({
              configIds: [23],
              ids: ["37aeb28411dd4b78bf2f1f304f531aae"],
            })
          }
        >
          /report/rank/dept/detail
        </Button>

        <Button
          width={250}
          onClick={() =>
            getRankDeptDetailByPeriod({
              endDate: "2024-03-25 16:24:26",
              ids: ["37aeb28411dd4b78bf2f1f304f531aae"],
              startDate: "2023-03-25 16:24:26",
            })
          }
        >
          /report/rank/dept/detail/period
        </Button>
        <Box classes="apis">
          <Button width={250} onClick={() => getTrainConfigInfo(23)}>
            /getTrainConfigInfo
          </Button>
          <Button width={200} onClick={() => getConfigProgramInfo(23)}>
            {"/programs/${configId}"}
          </Button>
        </Box>
      </Box>
      {/* report rank end  */}
      {/* user start */}
      <Typography size={20} isBold>
        /user(s)
      </Typography>
      <Box classes="apis">
        {/* local 경로일 경우 해당되는 파일로 테스트 해야 함 */}
        <Button width={100} onClick={() => getUsers()}>
          /users
        </Button>
        <Button
          width={200}
          onClick={() =>
            updateUserActivateFlag(["58daf00b75cb4456a226391904dbbc4f"])
          }
        >
          /users/update/active
        </Button>
        <Button
          width={200}
          onClick={() =>
            updateUserDeactivateFlag(["58daf00b75cb4456a226391904dbbc4f"])
          }
        >
          /users/update/deactive
        </Button>
        <Button
          width={150}
          onClick={() => deleteUser(["bff93af92e104356b7cc4691458cf6ba"])}
        >
          /users/delete
        </Button>
        <Button
          width={200}
          onClick={() => {
            const formData = new FormData();
            uploadUserCsv(formData);
          }}
        >
          /users/uploadCsv
        </Button>
        <Button width={200} onClick={() => insertUserCsv([DUMMY_INSERT_USER])}>
          /users/insertCsv
        </Button>

        <Button width={150} onClick={() => setUser(DUMMY_INSERT_USER)}>
          /users/setUser
        </Button>

        <Button
          width={150}
          onClick={() =>
            decryptUser({
              userEmail: "injubi@fasoo.com",
              userName: "인주비",
              dbName: "test_injubi",
            })
          }
        >
          /decrypt/user
        </Button>
      </Box>
      {/* user end */}
      {/* dept start  */}
      <Typography size={20} isBold>
        /organ (dept)
      </Typography>
      <Box classes="apis">
        {/* local 경로일 경우 해당되는 파일로 테스트 해야 함 */}
        <Button width={150} onClick={() => getDeptList()}>
          /organ/getDeptList
        </Button>
        <Button width={200} onClick={() => getDeptListForUser()}>
          /organ/getDeptListforUser
        </Button>
        {/* <Button
          width={150}
          onClick={() => getDept("37aeb28411dd4b78bf2f1f304f531aae")}
        >
          /organ/getDept
        </Button> */}
        <Button
          width={180}
          onClick={
            () => console.log("API CHANGED")
            // updateDept({
            //   deptCode: "37aeb28411dd4b78bf2f1f304f531aae",
            //   deptName: "update test",
            // })
          }
        >
          /organ/updateDept
        </Button>
        <Button
          width={200}
          onClick={() => {
            const formData = new FormData();
            uploadDeptCsv(formData);
          }}
        >
          /organ/uploadCsv
        </Button>
        <Button
          width={200}
          onClick={() =>
            insertDeptCsv({
              deptCodes: ["insertcsvtest"].toString(),
              deptNames: ["insertcsvTest"].toString(),
              parentCodes: ["COMPANY"].toString(),
            })
          }
        >
          /organ/insertCsv
        </Button>

        <Button width={150} onClick={() => addDept(DUMMY_INSERT_DEPT)}>
          /organ/addDept
        </Button>

        <Button width={180} onClick={() => deleteDept("codetest2222")}>
          /organ/deleteDept
        </Button>
      </Box>
      {/* dept end */}
      {/* ip start  */}
      <Typography size={20} isBold>
        /ip (log)
      </Typography>
      <Box classes="apis">
        {/* local 경로일 경우 해당되는 파일로 테스트 해야 함 */}
        <Button width={100} onClick={() => getIpList()}>
          /ip/list
        </Button>
        <Button
          width={100}
          onClick={() =>
            insertIp({
              ipType: "W",
              ipAddress: "127.0.0.1",
              createDate: null,
              createUser: "admin",
              summary: "insert ip test",
            })
          }
        >
          /ip/add
        </Button>
        <Button
          width={100}
          onClick={() =>
            updateIp({
              ipId: 1,
              ipType: "W",
              ipAddress: "127.0.0.1",
              createDate: null,
              createUser: "admin",
              summary: "update ip test",
            })
          }
        >
          /ip/update
        </Button>
        <Button width={100} onClick={() => deleteIp([1])}>
          /ip/delete
        </Button>
        <Button
          width={180}
          onClick={() =>
            downloadFilteredLog({
              // customerName: "test_injubi",
              configId: 23,
              type: "W",
            })
          }
        >
          /log/filteredLog
        </Button>

        {/* <Button width={200} onClick={() => donwloadAccessLog(["2024-03-16"])}>
          /log/accessDownload
        </Button> */}

        <Button width={100} onClick={() => isExistsIp("127.0.0.1")}>
          /ip/exist
        </Button>
      </Box>
      {/* ip end */}

      {/* signup start */}
      <Typography size={20} isBold>
        /signup (account)
      </Typography>
      <Box classes="apis">
        <Button width={150} onClick={() => isExistsEmail("injubi@fasoo.com")}>
          /account/exists
        </Button>
        <Button width={100} onClick={() => sendAuthMail("injubi2@fasoo.com")}>
          /signup/send
        </Button>
        <Button
          width={100}
          onClick={() =>
            isMatchAuthCode({
              email: "injubi2@fasoo.com",
              code: "000",
            })
          }
        >
          /signup/auth
        </Button>
        <Button
          width={180}
          onClick={() => isExistsReportAddress("test_injubi@mind-sat.com")}
        >
          /signup/reportAddress
        </Button>

        <Button
          width={100}
          onClick={() =>
            doSignup({
              email: "injubi2@fasoo.com",
              customerName: "인주비",
              companyName: "signupTest",
              reportInfo: "injubi2@mind-sat.com",
              serviceType: 1,
              managerPwd: "dlswnql0",
              accessMenu: [1],
              categoryId: 11,
            })
          }
        >
          /signup
        </Button>
      </Box>
      {/* signup end */}
    </Box>
  );
};

export default DummyAPIPage2;
