import "./index.scss";
import {
  Box,
  Button,
  Checkbox,
  Icons,
  Modal,
  ScrollBox,
  Tag,
  TextField,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import CategoryTag from "../../../component/CategoryTag";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import MailBody from "../../../component/MailBody";
import { useTranslation } from "react-i18next";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import useCategoryStore from "../../../redux/dispatcher/useCategoryStore";
import { Category } from "../../../types/Info";
import { useNavigate } from "react-router-dom";
import utilsCommon from "../../../shared/utils/common";
import { nanoid } from "@reduxjs/toolkit";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import CommonModal from "../../../component/CommonModal";

interface ScenarioDetailModalProps {
  isOpen: boolean;
  onClose?: () => void;
  templateId: number;
  isShowButtons?: boolean;
  modalType: "DETAIL" | "USED" | "NONE";
  setModalType: Dispatch<SetStateAction<"DETAIL" | "USED" | "NONE">>;
}

const ScenarioDetailModal: React.FC<ScenarioDetailModalProps> = ({
  isOpen,
  onClose,
  templateId,
  isShowButtons = true,
  modalType,
  setModalType,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    mailTemplateState,
    getMailTemplateData,
    updateMailTemplate,
    deleteMailTemplate,
  } = useMailTemplateStore();
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState<string[]>(
    []
  );
  const [categoryInput, setCategoryInput] = useState<string>("");
  const [viewedBody, setViewedBody] = useState<string | null>(null);
  const { categoryState, getCategories } = useCategoryStore();
  const [openDeleteModal, toggleDeleteModal] = useState(false);
  const categoryCheckboxGroup = useCheckboxGroup();
  const localCustomerCode = getLocalCustomerCode();
  const toast = useToast();

  const addCategory = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      let newCategoryName = categoryInput.trim();
      if (newCategoryName.length > 30) {
        toast.toastMsg(
          nanoid(),
          t("category.msg.categoryNameLengthLimitMsg"),
          "error"
        );
        return;
      }

      if (!selectedCategoryList.some((item) => item === newCategoryName)) {
        if (newCategoryName === "") {
          return;
        }
        if (/^\s*$/.test(newCategoryName)) {
          return;
        }
        updateScenario([...selectedCategoryList, newCategoryName]);
        setSelectedCategoryList((prev) => [...prev, newCategoryName]);
        if (categoryList.includes(newCategoryName)) {
          categoryCheckboxGroup.handleClick(newCategoryName);
        }
        setCategoryInput("");
        setCategoryList([]);
        // e.target.value("");
      } else {
        setCategoryInput("");
      }
    }
  };
  const onDelete = async () => {
    const resp: any = await deleteMailTemplate({
      request: {
        templateIds: [templateId],
      },
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateDeleteSuccessMsg"),
        "success"
      );
      onClose && onClose();
    } else {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateNoDeletedTemplateMsg"),
        "error"
      );
    }
  };

  const clickCategory = (e: any, cate: string) => {
    if (!selectedCategoryList.some((item) => item === cate)) {
      updateScenario([...selectedCategoryList, cate]);
      setSelectedCategoryList((prev) => [...prev, cate]);
      setCategoryInput("");
      setCategoryList([]);
      categoryCheckboxGroup.handleClick(cate);
    } else {
      setCategoryInput("");
    }
  };

  const deleteCategory = (name: string) => {
    if (name && selectedCategoryList.some((item) => item === name)) {
      updateScenario(selectedCategoryList.filter((p) => p !== name));
      setSelectedCategoryList((prev) => {
        return prev.filter((p) => p !== name);
      });
    }
  };

  // useEffect(() => {
  //   if (mailTemplateState.mailTemplateDetail) {
  //     updateScenario();
  //   }
  // }, [selectedCategoryList]);

  useEffect(() => {
    getMailTemplateData(templateId);
    getCategories();
  }, []);

  const updateScenario = async (cate: string[]) => {
    const resp: any = await updateMailTemplate({
      // file: bodyFile,
      request: {
        ...mailTemplateState.mailTemplateDetail,
        categorys: cate,
      },
    });
    if (resp.payload && resp.payload.code === "SUCCESS") {
      getMailTemplateData(templateId);

      return;
    } else {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateUpdateFailMsg"),
        "error"
      );
    }
  };

  useEffect(() => {
    const newCategoryName = categoryInput.trim();
    // category 검색
    if (newCategoryName.length > 0) {
      setCategoryList(
        categoryState.categoryList
          .filter((item: Category) =>
            item.categoryName.includes(newCategoryName)
          )
          .map((c: Category) => c.categoryName)
      );
      console.log(categoryList);
      console.log(categoryCheckboxGroup.selected);
    } else {
      setCategoryList([]);
    }
  }, [categoryInput]);

  useEffect(() => {
    if (
      mailTemplateState.mailTemplateDetail &&
      mailTemplateState.mailTemplateDetail.categorys
    ) {
      mailTemplateState.mailTemplateDetail.categorys.map((item: string) =>
        categoryCheckboxGroup.handleClick(item)
      );
      setSelectedCategoryList(mailTemplateState.mailTemplateDetail.categorys);

      //template image 테스트
      let orgHtml: string = mailTemplateState.mailTemplateDetail.templateHtml;
      if (mailTemplateState.mailTemplateDetail.imageId !== 0) {
        orgHtml = orgHtml.replaceAll(
          "##IMAGE##",
          mailTemplateState.mailTemplateDetail.imagePath
        );
      }

      if (mailTemplateState.mailTemplateDetail.siteId !== 0) {
        orgHtml = orgHtml.replaceAll(
          "##LINK##",
          mailTemplateState.mailTemplateDetail.siteUrl
        );
      }

      setViewedBody(orgHtml);

      if (
        mailTemplateState.mailTemplateDetail.imageId === 0 &&
        mailTemplateState.mailTemplateDetail.siteId === 0
      ) {
        setViewedBody(mailTemplateState.mailTemplateDetail.templateHtml);
      }
    }
  }, [mailTemplateState.mailTemplateDetail]);

  const [isUsedModal, setIsUsedModal] = useState<number>(0);
  return (
    <>
      <Modal
        open={modalType === "DETAIL"}
        title={t("template.detail")}
        width={800}
        isDivider={false}
        onClose={onClose}
        height={936}
        footer={
          <Box justifyContent="space-between">
            <Box>
              {!mailTemplateState.mailTemplateDetail?.commonId &&
                isShowButtons && (
                  <>
                    <Button
                      classes="mg mr-10"
                      color="lightWarning"
                      onClick={() => toggleDeleteModal(true)}
                    >
                      {t("common.button.delete")}
                    </Button>
                    <Button
                      classes="mg mr-10"
                      color="activeGreen"
                      textColor="green"
                      text={t("common.button.update")}
                      onClick={() => {
                        if (
                          mailTemplateState.mailTemplateDetail.useConfig &&
                          parseInt(
                            mailTemplateState.mailTemplateDetail.useConfig
                          ) > 0
                        ) {
                          setModalType("USED");
                          setIsUsedModal(
                            mailTemplateState.mailTemplateDetail.templateId
                          );
                        } else {
                          navigate(
                            `/manageScenario/update/${mailTemplateState.mailTemplateDetail.templateId}`
                          );
                        }
                      }}
                    />
                  </>
                )}
              {mailTemplateState.mailTemplateDetail?.commonId && (
                <Button
                  classes="mg mr-10"
                  color="activeGreen"
                  textColor="green"
                  text={t("common.button.copy")}
                  onClick={() => {
                    window.sessionStorage.setItem(
                      "newTemplateId",
                      mailTemplateState.mailTemplateDetail?.templateId
                    );
                    navigate("/manageScenario/write?newTemplateId");
                  }}
                />
              )}
            </Box>
            <Button onClick={onClose}>{t("common.button.close")}</Button>
          </Box>
        }
      >
        {openDeleteModal && (
          <CommonModal
            open={openDeleteModal}
            onClose={() => toggleDeleteModal(false)}
            title={t("template.delete")}
            afterSubmitButton={onDelete}
            subMessage={
              mailTemplateState.mailTemplateDetail?.useConfig &&
              parseInt(mailTemplateState.mailTemplateDetail?.useConfig) > 0
                ? t("template.msg.templateDeleteUsedTemplateMsg")
                : t("common.msg.deleteConfirmMsg")
            }
            type="delete"
          />
        )}
        {mailTemplateState.mailTemplateDetail && (
          <ScrollBox height={750} width="100%">
            <Box direction="column">
              <Box direction="column" classes="mg mb-36">
                <Typography fontWeight={700} classes="mg mb-12">
                  {t("template.subject")}
                </Typography>
                <Typography>
                  {mailTemplateState.mailTemplateDetail.subject}
                </Typography>
              </Box>
              <Box>
                <Box direction="column" width={360} classes="mg mb-36">
                  <Typography fontWeight={700} classes="mg mb-12">
                    {t("template.senderName")}
                  </Typography>
                  <Typography>
                    {mailTemplateState.mailTemplateDetail.sendName}
                  </Typography>
                </Box>
                <Box direction="column" width={360}>
                  <Typography fontWeight={700} classes="mg mb-12">
                    {t("template.senderEmail")}
                  </Typography>
                  <Typography>
                    {mailTemplateState.mailTemplateDetail.sendEmail}
                  </Typography>
                </Box>
              </Box>
              <Box direction="column" classes="mg mb-36">
                <Typography fontWeight={700} classes="mg mb-12">
                  {t("template.body")}
                </Typography>
                {/* <Box
              style={{
                border: "1px solid #D1D6DB ",
                borderRadius: "8px",
                padding: "12px",
                maxHeight: "500px",
              }}
            > */}

                {viewedBody ? (
                  <MailBody body={viewedBody} height="fit-content" />
                ) : (
                  "no body"
                )}

                {/* </Box> */}
              </Box>
              <Box classes="mg mb-28">
                <Box width={232} direction="column">
                  <Typography fontWeight={700} classes="mg mb-12">
                    {t("link.link")}
                  </Typography>
                  <Box height={40}>
                    {!mailTemplateState.mailTemplateDetail.siteId ||
                    mailTemplateState.mailTemplateDetail.siteId === 0 ? (
                      <Typography
                        ellipsis={true}
                        maxWidth="232px"
                        classes="detail__text"
                      >
                        {t("common.info.noData")}
                      </Typography>
                    ) : (
                      <ToolTip
                        message={mailTemplateState.mailTemplateDetail.siteUrl}
                      >
                        <Typography
                          ellipsis={true}
                          maxWidth="232px"
                          classes="detail__text"
                        >
                          {mailTemplateState.mailTemplateDetail.siteName}
                        </Typography>
                      </ToolTip>
                    )}
                  </Box>
                </Box>
                <Box width={232} direction="column">
                  <Typography fontWeight={700} classes="mg mb-12">
                    {t("image.image")}
                  </Typography>
                  <Box height={40}>
                    {!mailTemplateState.mailTemplateDetail.imageId ||
                    mailTemplateState.mailTemplateDetail.imageId === 0 ? (
                      <Typography
                        ellipsis={true}
                        maxWidth="232px"
                        classes="detail__text"
                      >
                        {t("common.info.noData")}
                      </Typography>
                    ) : (
                      <ToolTip
                        message={
                          <img
                            src={mailTemplateState.mailTemplateDetail.imagePath}
                            style={{ maxWidth: "200px" }}
                          />
                        }
                      >
                        <Typography
                          ellipsis={true}
                          maxWidth="232px"
                          classes="detail__text"
                        >
                          {mailTemplateState.mailTemplateDetail.imageOrgName}
                        </Typography>
                      </ToolTip>
                    )}
                  </Box>
                </Box>

                <Box width={232} direction="column">
                  <Typography fontWeight={700} classes="mg mb-12">
                    {t("attach.attach")}
                  </Typography>
                  <Box height={40}>
                    {!mailTemplateState.mailTemplateDetail.documentId ||
                    mailTemplateState.mailTemplateDetail.documentId === 0 ? (
                      <Typography
                        ellipsis={true}
                        maxWidth="232px"
                        classes="detail__text"
                      >
                        {t("common.info.noData")}
                      </Typography>
                    ) : (
                      <ToolTip
                        message={
                          mailTemplateState.mailTemplateDetail.documentOrgName
                        }
                      >
                        <Typography
                          ellipsis={true}
                          maxWidth="232px"
                          classes="detail__text"
                        >
                          {mailTemplateState.mailTemplateDetail.documentName}
                        </Typography>
                      </ToolTip>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box classes="mg mb-36" direction="column">
                <Typography fontWeight={700} classes="mg mb-12">
                  {t("template.name")}
                </Typography>
                <Box alignItems="center">
                  {localCustomerCode ? (
                    mailTemplateState.mailTemplateDetail.commonId ? (
                      <Tag
                        name={t("template.public")}
                        size={i18n.language === "ko" ? "ms" : "sm"}
                        color="blue"
                        radius={6}
                      />
                    ) : (
                      <Tag
                        name={t("template.private")}
                        size={i18n.language === "ko" ? "ms" : "sm"}
                        color="pink"
                        radius={6}
                      />
                    )
                  ) : (
                    <Tag
                      name={t("template.public")}
                      size={i18n.language === "ko" ? "ms" : "sm"}
                      color="blue"
                      radius={6}
                    />
                  )}
                  <Typography classes="mg ml-8" lineHeight="1.4">
                    {mailTemplateState.mailTemplateDetail.templateName}
                  </Typography>
                </Box>
              </Box>
              <Box classes="mg mb-36" direction="column">
                <Typography fontWeight={700} classes="mg mb-12">
                  {t("category.category")}
                </Typography>
                <Box classes="category__select" width={338}>
                  <TextField
                    placeholder={t("template.msg.categoryPlaceholder")}
                    width={338}
                    height={40}
                    borderRadius={8}
                    text={categoryInput}
                    hasText={true}
                    onChange={(e) => setCategoryInput(e.target.value)}
                    onKeyPress={(e) => addCategory(e)}
                    maxLength={30}
                  />

                  {categoryList.length > 0 && (
                    <Box classes="mg category__select__list">
                      {categoryList.map((item) => (
                        <Box
                          classes="category__select__item"
                          key={item}
                          style={{
                            backgroundColor: categoryCheckboxGroup.isSelected(
                              item
                            )
                              ? "#EAF4FF"
                              : "#FFFFFF",
                          }}
                        >
                          <Typography>{item}</Typography>
                          <Checkbox
                            radio={true}
                            onChange={(e: any) => clickCategory(e, item)}
                            checked={categoryCheckboxGroup.isSelected(item)}
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
                <ScrollBox classes="mg mt-24 category__container">
                  {selectedCategoryList
                    ? selectedCategoryList.map((item: string) => (
                        <CategoryTag
                          key={item}
                          text={item}
                          endIcon={<Icons variant="common" label="delete" />}
                          onClick={() => deleteCategory(item)}
                        />
                      ))
                    : null}
                </ScrollBox>
              </Box>
            </Box>
          </ScrollBox>
        )}
      </Modal>

      <CommonModal
        open={isUsedModal > 0 && modalType === "USED"}
        onClose={() => (setIsUsedModal(0), setModalType("DETAIL"))}
        title={t("template.updateSimple")}
        afterSubmitButton={() =>
          navigate(`/manageScenario/update/${isUsedModal}`)
        }
        footer={
          <Box justifyContent="flex-end">
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => (setIsUsedModal(0), setModalType("DETAIL"))}
            />

            <Button
              text={t("common.button.update")}
              classes={"mg ml-8"}
              color="primary"
              onClick={() => {
                navigate(`/manageScenario/update/${isUsedModal}`);
              }}
            />
          </Box>
        }
        body={
          <Box direction="column" style={{ lineHeight: "1.5" }}>
            <Typography>
              {t("template.msg.templateUpdateUsedTemplateMsg")}
            </Typography>
          </Box>
        }
      />
    </>
  );
};

export default ScenarioDetailModal;
