import { combineReducers } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import accountStore from "../store/account";
import dashboardStore from "../store/dashboard";
import commonStore from "../store/common";
import replyStore from "../store/reply";
import reportStore from "../store/report";
import configStore from "../store/config";
import programStore from "../store/program";
import staticStore from "../store/statics";
import trainestaticsStore from "../store/traineStatics";
import sendHistoryStore from "../store/sendHistory";
import customerStore from "../store/customer";
import categoryStore from "../store/category";
import mailTemplateStore from "../store/mailTemplate";
import contentStore from "../store/content";
import reportStatStore from "../store/reportStat";
import reportRankStore from "../store/reportRank";
import userStore from "../store/user";
import deptStore from "../store/dept";
import ipStore from "../store/ip";
import managerStore from "../store/manager";
import signupStore from "../store/signup";
import progressStore from "../store/progress";

// 루트 리듀서
const rootReducer = combineReducers({
  accountStore,
  dashboardStore,
  commonStore,
  customerStore,
  replyStore,
  reportStore,
  configStore,
  programStore,
  staticStore,
  trainestaticsStore,
  sendHistoryStore,
  categoryStore,
  mailTemplateStore,
  contentStore,
  reportStatStore,
  reportRankStore,
  userStore,
  deptStore,
  ipStore,
  managerStore,
  signupStore,
  progressStore,
});

// 루트 리듀서의 반환값를 유추
// 추후 이 타입을 컨테이너 컴포넌트에서 불러와서 사용해야 하므로 내보내줌
export type RootState = ReturnType<typeof rootReducer>;
type StateSelector<T> = (state: RootState) => T;
type EqualityFn<T> = (left: T, right: T) => boolean;

export function useRootState<T>(
  selector: StateSelector<T>,
  equalityFn?: EqualityFn<T>
): any {
  return useSelector(selector, equalityFn);
}

//configureStore를 사용해야 extraReducers 사용 가능
export const store = configureStore({
  reducer: rootReducer,
});
//export default store;

export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default rootReducer;
