import { useMemo } from "react";
import { useAppDispatch, useRootState } from ".";
import { bindActionCreators } from "redux";
import {
  trunkAddDept,
  trunkCheckBeforeUpdateDeptCSV,
  trunkCheckBeforeUpdateUserCSV,
  trunkDeleteDept,
  trunkDeleteOrganUserList,
  trunkExportDeptFile,
  trunkGetDept,
  trunkGetDeptList,
  trunkGetDeptListForUser,
  trunkGetOrganUserList,
  trunkGetOrganUserCount,
  trunkInsertCsv,
  trunkInsertOrganUserList,
  trunkUpdateDept,
  trunkUpdateOrganUserList,
  trunkUploadCsv,
  trunkUploadDeptCSV,
  trunkUploadUserCSV,
} from "../store/dept";

const useDeptStore = () => {
  const deptState = useRootState((state) => state.deptStore);

  const dispatch = useAppDispatch();

  const boundGetDeptList = useMemo(
    () => bindActionCreators(trunkGetDeptList, dispatch),
    [dispatch]
  );
  const boundGetOrganUserList = useMemo(
    () => bindActionCreators(trunkGetOrganUserList, dispatch),
    [dispatch]
  );
  const boundGetOrganUserCount = useMemo(
    () => bindActionCreators(trunkGetOrganUserCount, dispatch),
    [dispatch]
  );
  const boundInsertOrganUserList = useMemo(
    () => bindActionCreators(trunkInsertOrganUserList, dispatch),
    [dispatch]
  );
  const boundUpdateOrganUserList = useMemo(
    () => bindActionCreators(trunkUpdateOrganUserList, dispatch),
    [dispatch]
  );
  const boundDeleteOrganUserList = useMemo(
    () => bindActionCreators(trunkDeleteOrganUserList, dispatch),
    [dispatch]
  );
  const boundGetDeptListForUser = useMemo(
    () => bindActionCreators(trunkGetDeptListForUser, dispatch),
    [dispatch]
  );

  const boundGetDept = useMemo(
    () => bindActionCreators(trunkGetDept, dispatch),
    [dispatch]
  );

  const boundUpdateDept = useMemo(
    () => bindActionCreators(trunkUpdateDept, dispatch),
    [dispatch]
  );

  const boundUploadCsv = useMemo(
    () => bindActionCreators(trunkUploadCsv, dispatch),
    [dispatch]
  );
  const boundCheckBeforeUpdateUserCSV = useMemo(
    () => bindActionCreators(trunkCheckBeforeUpdateUserCSV, dispatch),
    [dispatch]
  );
  const boundCheckBeforeUpdateDeptCSV = useMemo(
    () => bindActionCreators(trunkCheckBeforeUpdateDeptCSV, dispatch),
    [dispatch]
  );
  const boundUploadUserCSV = useMemo(
    () => bindActionCreators(trunkUploadUserCSV, dispatch),
    [dispatch]
  );
  const boundUploadDeptCSV = useMemo(
    () => bindActionCreators(trunkUploadDeptCSV, dispatch),
    [dispatch]
  );
  const boundInsertCsv = useMemo(
    () => bindActionCreators(trunkInsertCsv, dispatch),
    [dispatch]
  );
  const boundExportDeptFile = useMemo(
    () => bindActionCreators(trunkExportDeptFile, dispatch),
    [dispatch]
  );

  const boundAddDept = useMemo(
    () => bindActionCreators(trunkAddDept, dispatch),
    [dispatch]
  );

  const boundDeleteDept = useMemo(
    () => bindActionCreators(trunkDeleteDept, dispatch),
    [dispatch]
  );

  return {
    deptState,
    getDeptList: boundGetDeptList,
    getDeptListForUser: boundGetDeptListForUser,
    getDept: boundGetDept,
    updateDept: boundUpdateDept,
    uploadDeptCsv: boundUploadCsv,
    checkBeforeUpdateUserCSV: boundCheckBeforeUpdateUserCSV,
    checkBeforeUpdateDeptCSV: boundCheckBeforeUpdateDeptCSV,
    uploadUserCSV: boundUploadUserCSV,
    uploadDeptCSV: boundUploadDeptCSV,
    insertDeptCsv: boundInsertCsv,
    addDept: boundAddDept,
    deleteDept: boundDeleteDept,
    getOrganUserList: boundGetOrganUserList,
    getOrganUserCount: boundGetOrganUserCount,
    exportDeptFile: boundExportDeptFile,
    insertOrganUserList: boundInsertOrganUserList,
    updateOrganUserList: boundUpdateOrganUserList,
    deleteOrganUserList: boundDeleteOrganUserList,
  };
};

export default useDeptStore;
