import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import staticsApi from "../api/staticsApi";
import { DateBean } from "../../types/Report";
import { TimeRequest } from "../../types/Common";

interface StaticsState {}

// # initial state
const initialState: StaticsState = {};

export const trunkGetStaticsList = createAsyncThunk(
  "statics/getStaticsList",
  async (id?: number) => {
    const resp = await staticsApi.getStaticsList(id);
    return resp;
  }
);
export const trunkGetTraineGraphList = createAsyncThunk(
  "statics/traineGraph",
  async (req: TimeRequest) => {
    const resp = await staticsApi.getTraineGraphList(req);
    return resp;
  }
);
export const trunkGetTraineGraphListConfig = createAsyncThunk(
  "statics/traineGraph/config",
  async (ids: number[]) => {
    const resp = await staticsApi.getTraineGraphListConfig(ids);
    return resp;
  }
);
export const trunkGetTraineGraphListType = createAsyncThunk(
  "statics/traineGraph/type",
  async (req: DateBean) => {
    const resp = await staticsApi.getTraineGraphListType(req);
    return resp;
  }
);
export const trunkGetPhishingGraph = createAsyncThunk(
  "statics/phishingGraph",
  async (req: { configId: number; clickType: number }) => {
    const resp = await staticsApi.getPhishingGraph(req);
    return resp;
  }
);
const staticsSlice = createSlice({
  name: "statics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkGetStaticsList.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkGetTraineGraphList.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkGetTraineGraphListConfig.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkGetTraineGraphListType.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkGetPhishingGraph.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
  },
});
export const staticsActions = staticsSlice.actions;

export default staticsSlice.reducer;
