import {
  Box,
  Button,
  Icons,
  Modal,
  Table,
  TextField,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { useRef, useState } from "react";
import useDeptStore from "../../redux/dispatcher/useDeptStore";
import { nanoid } from "@reduxjs/toolkit";
import { SimpleTreeNode, UserType } from "../../types/User";
import utilsCommon from "../../shared/utils/common";
import { useTranslation } from "react-i18next";

interface InsertDeptByCSVModalProps {
  isOpen: boolean;
  type: "USER" | "DEPT";
  onClose: () => void;
}

const InsertDeptByCSVModal: React.FC<InsertDeptByCSVModalProps> = ({
  isOpen,
  type,
  onClose,
}) => {
  const toast = useToast();
  const {
    deptState,
    checkBeforeUpdateUserCSV,
    uploadUserCSV,
    checkBeforeUpdateDeptCSV,
    uploadDeptCSV,
  } = useDeptStore();
  const { t } = useTranslation();
  const [file, changeFile] = useState<File>();
  const [validateResult, changeValidateResult] = useState<any[] | undefined>();
  const iconInput = useRef<HTMLInputElement | null>(null);

  const loadDeptFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<any> => {
    if (
      !(
        e.target.files &&
        e.target.files.length > 0 &&
        e.target.files[0].name !== undefined
      )
    ) {
      return;
    }
    const file = e.target.files[0];
    if (file.type !== "text/csv") {
      toast.toastMsg(
        nanoid(),
        t("common.msg.invalidFileExtensionMsg"),
        "error"
      );
      return;
    }
    let resp: any =
      type === "USER"
        ? await checkBeforeUpdateUserCSV(e.target.files[0])
        : await checkBeforeUpdateDeptCSV(e.target.files[0]);
    if (resp && resp.payload && resp.payload.data) {
      changeFile(file);
      changeValidateResult(resp.payload.data);
    } else {
      toast.toastMsg(nanoid(), t("common.msg.uploadCsvFailMsg"), "error");
    }
  };
  const checkItemError = (item: any) => {
    let errorItems = [];
    if (type === "USER") {
      if (!item.userName || !item.userEmail || !item.deptCode)
        errorItems.push(t("dept.msg.undefinedInputMsg"));
      if (item.userEmail && !utilsCommon.emailValidation(item.userEmail))
        errorItems.push(t("user.msg.userInvalidEmailMsg"));
      if (
        deptState.organList.filter(
          (item1: SimpleTreeNode) => item1.deptCode === item.deptCode
        ).length === 0
      )
        errorItems.push(t("user.msg.userUndefinedDeptCodeMsg"));
    } else {
      if (item.noParent)
        errorItems.push(t("dept.msg.undefinedHighLevelDeptCodeMsg"));
      if (item.existDept) errorItems.push(t("dept.msg.duplicatedCodeMsg"));
      // if (!utilsCommon.deptCodeValidation(item.deptCode))
      //   errorItems.push("부서 코드는 영어, 한글, 숫자, _만 입력 가능합니다.");
      // if (!utilsCommon.deptCodeValidation(item.deptName))
      //   errorItems.push("부서 이름은 영어, 한글, 숫자, _만 입력 가능합니다.");
      if (item.deptCode && item.deptCode?.length > 64)
        errorItems.push(t("dept.msg.codeLengthLimitMsg"));
      if (item.deptCode && item.deptName?.length > 128)
        errorItems.push(t("dept.msg.namdLengthLimitMsg"));
    }

    return errorItems;
  };
  const handleUploadUserCSV = async (file?: File) => {
    if (!file) return;
    let resp: any = await uploadUserCSV(file);
    if (resp && resp.payload && resp.payload.data) {
      toast.toastMsg(nanoid(), t("user.msg.userAddListSuccessMsg"), "success");
      window.location.reload();
    } else {
      toast.toastMsg(nanoid(), t("user.msg.userAddFailMsg"), "error");
    }
    onClose();
  };
  const handleUploadDeptCSV = async (file?: File) => {
    if (!file) return;
    let resp: any = await uploadDeptCSV(file);
    if (resp && resp.payload && resp.payload.data) {
      toast.toastMsg(nanoid(), t("dept.msg.addListSuccessMsg"), "success");
      window.location.reload();
    } else {
      toast.toastMsg(nanoid(), t("dept.msg.addFailMsg"), "error");
    }
    onClose();
  };
  return (
    <Modal
      title={
        type === "USER" ? t("user.userAddCsvTitle") : t("dept.deptAddCsvTitle")
      }
      onClose={onClose}
      width={736}
      isDivider={false}
      footer={
        <Box justifyContent="flex-end">
          <Button color={"secondary"} onClick={onClose}>
            {t("common.button.cancel")}
          </Button>
          <Button
            classes="mg ml-8"
            disabled={
              !file ||
              (validateResult &&
                validateResult?.filter(
                  (item) => checkItemError(item).length > 0
                )?.length > 0)
            }
            onClick={() =>
              type === "USER"
                ? handleUploadUserCSV(file)
                : handleUploadDeptCSV(file)
            }
          >
            {t("common.button.check")}
          </Button>
        </Box>
      }
      open={isOpen}
    >
      <Box direction="column" height={290} width={"100%"}>
        <Box width={"100%"}>
          <TextField
            size="lg"
            placeholder={t("common.button.selectFile")}
            fullWidth
            text={file?.name}
            hasText
          />
          <Button
            classes="mg ml-10"
            color={"secondary"}
            onClick={() => iconInput.current?.click()}
            width={130}
          >
            {t("common.button.selectFile")}
          </Button>
          <input
            style={{ display: "none" }}
            type="file"
            ref={iconInput}
            accept=".csv"
            onChange={(e) => loadDeptFile(e)}
            id={"fileUpload"}
          />
        </Box>
        {file && type === "USER" && (
          <Typography color="secondary" size={12}>
            {t("user.msg.userWarnDuplicationMsg")}
          </Typography>
        )}
        {!file ? (
          <Box
            direction="column"
            alignItems="center"
            justifyContent="center"
            height={"100%"}
          >
            <Typography
              textAlign={"center"}
              lineHeight="normal"
              color={"secondary"}
            >
              {type === "USER"
                ? t("user.msg.userInfoCsvMsg")
                : t("dept.msg.infoCsvMsg")}
            </Typography>
            <a
              href={
                type === "USER"
                  ? `${process.env.PUBLIC_URL}/storage/user_template.csv`
                  : `${process.env.PUBLIC_URL}/storage/dept_template.csv`
              }
              hrefLang="ko"
              download={
                type === "USER" ? "user_template.csv" : "dept_template.csv"
              }
              className="mg mt-10"
            >
              {t("user.userDownloadCsvTemplate")}
            </a>
          </Box>
        ) : type === "USER" ? (
          <Box
            direction="column"
            alignItems="center"
            justifyContent="center"
            height={"calc(100% - 30px)"}
            classes="mg mt-20"
          >
            <Table>
              <colgroup>
                {/* */}
                <col style={{ width: "55px" }}></col>
                {/*  사용자 이름 */}
                <col style={{ minWidth: "55px", width: "100px" }}></col>
                {/* 이메일 */}
                <col style={{ minWidth: "100px", width: "150px" }}></col>
                {/* 부서 */}
                <col style={{ minWidth: "100px", width: "150px" }}></col>
                {/* 직급 */}
                <col style={{ minWidth: "55px", width: "100px" }}></col>
                {/* 부서장 여부 */}
                <col style={{ minWidth: "55px", width: "150px" }}></col>
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th>{t("user.userName")}</th>
                  <th>{t("user.userEmail")}</th>
                  <th>{t("dept.deptCode")}</th>
                  <th>{t("user.userPosition")}</th>
                  <th>{t("dept.managerFlag")}</th>
                </tr>
              </thead>
            </Table>
            <Box classes="table-scroll" height={"192px"}>
              <Table>
                <colgroup>
                  {/* */}
                  <col style={{ width: "55px" }}></col>
                  {/*  사용자 이름 */}
                  <col style={{ minWidth: "55px", width: "100px" }}></col>
                  {/* 이메일 */}
                  <col style={{ minWidth: "100px", width: "150px" }}></col>
                  {/* 부서 */}
                  <col style={{ minWidth: "100px", width: "150px" }}></col>
                  {/* 직급 */}
                  <col style={{ minWidth: "55px", width: "100px" }}></col>
                  {/* 부서장 여부 */}
                  <col style={{ minWidth: "55px", width: "150px" }}></col>
                </colgroup>
                <tbody>
                  {validateResult &&
                    validateResult?.map((item) => {
                      let errors = checkItemError(item);
                      return (
                        <tr className={item.existUser ? "existUSER" : ""}>
                          <td>
                            {item.existUser ? (
                              <Icons
                                variant="mindsat"
                                label="icon_check_circle"
                                stroke={"#d1d6db"}
                              />
                            ) : errors.length === 0 ? (
                              <Icons
                                variant="mindsat"
                                label="icon_check_circle"
                                stroke={"#3182f6"}
                              />
                            ) : (
                              <ToolTip
                                message={
                                  <Box direction="column">
                                    {errors.map((item) => (
                                      <Typography color="white">
                                        - {item}
                                      </Typography>
                                    ))}
                                  </Box>
                                }
                              >
                                <Icons
                                  variant="mindsat"
                                  label="icon_alert_circle"
                                  stroke={"#f6705e"}
                                />
                              </ToolTip>
                            )}
                          </td>
                          <td>{item.userName}</td>
                          <td>{item.userEmail}</td>
                          <td>{item.deptCode}</td>
                          <td>{item.jobTitle}</td>
                          <td>{item.managerFlag ? "O" : "-"}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Box>
          </Box>
        ) : (
          <Box
            direction="column"
            alignItems="center"
            justifyContent="center"
            height={"calc(100% - 30px)"}
            classes="mg mt-20"
          >
            <Table>
              <colgroup>
                {/* */}
                <col style={{ width: "55px" }}></col>
                {/*  부서 코드 */}
                <col style={{ minWidth: "55px", width: "150px" }}></col>
                {/* 부서 이름 */}
                <col style={{ width: "*" }}></col>
                {/* 상위 부서 코드 */}
                <col style={{ minWidth: "100px", width: "180px" }}></col>
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th>{t("dept.deptCode")}</th>
                  <th>{t("dept.deptName")}</th>
                  <th>{t("dept.deptHighLevelDeptCode")}</th>
                </tr>
              </thead>
            </Table>
            <Box classes="table-scroll">
              <Table>
                <colgroup>
                  {/* */}
                  <col style={{ width: "55px" }}></col>
                  {/*  부서 코드 */}
                  <col style={{ minWidth: "55px", width: "150px" }}></col>
                  {/* 부서 이름 */}
                  <col style={{ width: "*" }}></col>
                  {/* 상위 부서 코드 */}
                  <col style={{ minWidth: "100px", width: "180px" }}></col>{" "}
                </colgroup>
                <tbody>
                  {validateResult &&
                    validateResult?.map((item) => {
                      let errors = checkItemError(item);
                      return (
                        <tr>
                          <td>
                            {item.exist ? (
                              <Icons
                                variant="mindsat"
                                label="icon_check_circle"
                                stroke={"gray"}
                              />
                            ) : errors.length === 0 ? (
                              <Icons
                                variant="mindsat"
                                label="icon_check_circle"
                                stroke={"#3182f6"}
                              />
                            ) : (
                              <ToolTip
                                message={
                                  <Box direction="column">
                                    {errors.map((item) => (
                                      <Typography color="white">
                                        - {item}
                                      </Typography>
                                    ))}
                                  </Box>
                                }
                              >
                                <Icons
                                  variant="mindsat"
                                  label="icon_alert_circle"
                                  stroke={"#f6705e"}
                                />
                              </ToolTip>
                            )}
                          </td>
                          <td>{item.deptCode}</td>
                          <td>{item.deptName}</td>
                          <td>{item.parentCode}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default InsertDeptByCSVModal;
