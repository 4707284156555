import "./index.scss";
import {
  Box,
  Button,
  Checkbox,
  Icons,
  ScrollBox,
  Select,
  SettingButton,
  TextField,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import { SelectItem } from "fasoo-ui-component-next/src/components/atom/Select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import {
  Category,
  ImagesInfo,
  MailTemplateInsertRequest,
  PhishingDocument,
  SiteInfo,
} from "../../../types/Info";
import ImageThumb from "./ImageThumb";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import CategoryTag from "../../../component/CategoryTag";
import useCategoryStore from "../../../redux/dispatcher/useCategoryStore";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import utilsCommon from "../../../shared/utils/common";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { nanoid } from "@reduxjs/toolkit";
import WSEdit from "../../../component/WSEdit";
import RegitOrEditImageModal from "../Image/RegitOrEditImageModal";
import { useTranslation } from "react-i18next";
import RegitOrEditDocumentModal from "../Document/RegitOrEditDocumentModal";
import RegitOrEditSiteModal from "../Site/RegitOrEditSiteModal";
import useReportStore from "../../../redux/dispatcher/useReportStore";
import { toPng } from "html-to-image";
import html2canvas from "html2canvas";

const WriteScenario: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [templateId, setTemplateId] = useState<number | null>();
  const [siteList, setSiteList] = useState<SiteInfo[]>([]);
  const [imageList, setImageList] = useState<ImagesInfo[]>([]);
  const [attachList, setAttachList] = useState<PhishingDocument[]>([]);
  const [isLinkOpen, setIsLinkOpen] = useState<boolean>(false);
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);
  const [isAttachOpen, setIsAttachOpen] = useState<boolean>(false);

  const [selectedSite, setSelectedSite] = useState<number>(0);
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [selectedAttach, setSelectedAttach] = useState<number>(0);

  const [scenarioInfo, setScenarioInfo] = useState<MailTemplateInsertRequest>({
    templateName: "",
    subject: "",
    sendEmail: "",
    sendName: "",
    imageId: 0,
    documentId: 0,
    siteId: 0,
    categorys: null,
  });
  const [body, setBody] = useState<string>("");
  const [initBody, setInitBody] = useState<string>("");
  // const [tempBody, setTempBody] = useState<string>("");
  // 사용자 입력값
  const [categoryInput, setCategoryInput] = useState<string>("");
  //사용자 입력값에 대한 기존 category 검색 리스트
  const [categoryList, setCategoryList] = useState<string[]>([]);

  //사용중인 템플릿
  const [isUsed, setIsUsed] = useState<boolean>(false);

  const { categoryState, getCategories } = useCategoryStore();
  const { contentState, getImages, getSites, getAttaches } = useContentStore();
  const {
    mailTemplateState,
    insertMailTemplate,
    getMailTemplateData,
    updateMailTemplate,
    parseEMLFile,
  } = useMailTemplateStore();

  const { reportState, getReportMailDetail } = useReportStore();

  const [selectedCategoryList, setSelectedCategoryList] = useState<string[]>(
    []
  );
  const [type, changeType] = useState<"CREATE" | "UPDATE">("CREATE");

  const [isOpenImageModal, setIsOpenImageModal] = useState<boolean>(false);
  const [isOpenDocumentModal, setIsOpenDocumentModal] =
    useState<boolean>(false);
  const [isOpenLinkModal, setIsOpenLinkModal] = useState<boolean>(false);
  const categoryCheckboxGroup = useCheckboxGroup();
  const toast = useToast();

  useEffect(() => {
    // setCategoryList(dummyCatgeoryData);'
    if (location.search && location.search.includes("mailId")) {
      const mailId = location.search.split("=")[1];
      getReportMailDetail(parseInt(mailId));
      setBody(reportState.reportDetail.body);
      setInitBody(reportState.reportDetail.body);
      setScenarioInfo((prev) => ({
        ...prev,
        subject: reportState.reportDetail.exSubject,
      }));
    }
    if (params["*"]) {
      setTemplateId(parseInt(params["*"]));
      changeType(Number.isNaN(parseInt(params["*"])) ? "CREATE" : "UPDATE");
    }
    let newId = window.sessionStorage.getItem("newTemplateId");
    if (newId) {
      setTemplateId(parseInt(newId));
      window.sessionStorage.removeItem("newTemplateId");
      changeType("CREATE");
    }
    getCategories();
    getSites({});
    getImages({});
    getAttaches({});
  }, []);

  useEffect(() => {
    setImageList(contentState.images);
    setSearchedImageList(contentState.images);
  }, [contentState.images]);
  useEffect(() => {
    setSiteList(contentState.sites);
    setSearchedSiteList(contentState.sites);
  }, [contentState.sites]);
  useEffect(() => {
    setAttachList(contentState.attaches);
    setSearchedAttachList(contentState.attaches);
  }, [contentState.attaches]);
  useEffect(() => {
    if (templateId) {
      getMailTemplateData(templateId);
    }
  }, [templateId]);
  useEffect(() => {
    if (templateId) {
      setBody(mailTemplateState.mailTemplateDetail.templateHtml);
      setInitBody(mailTemplateState.mailTemplateDetail.templateHtml);
      setSelectedSite(mailTemplateState.mailTemplateDetail.siteId);
      setSelectedImage(mailTemplateState.mailTemplateDetail.imageId);
      setSelectedAttach(mailTemplateState.mailTemplateDetail.documentId);
      setSelectedCategoryList(mailTemplateState.mailTemplateDetail.categorys);

      if (location.search.includes("newTemplateId")) {
        setScenarioInfo({
          ...mailTemplateState.mailTemplateDetail,
          templateName: `[${t("common.button.copy")}] ${
            mailTemplateState.mailTemplateDetail.templateName
          }`,
        });
      } else {
        setScenarioInfo(mailTemplateState.mailTemplateDetail);
      }

      if (
        mailTemplateState.mailTemplateDetail.useConfig &&
        parseInt(mailTemplateState.mailTemplateDetail.useConfig) > 0
      ) {
        setIsUsed(true);
      }
    }
  }, [mailTemplateState.mailTemplateDetail]);

  useEffect(() => {
    const newCatName = categoryInput.trim();
    // category 검색
    if (newCatName.length > 0) {
      setCategoryList(
        categoryState.categoryList
          .filter((item: Category) => item.categoryName.includes(newCatName))
          .map((c: Category) => c.categoryName)
      );
    } else {
      setCategoryList([]);
    }
  }, [categoryInput]);
  useEffect(() => {}, [window.location.href]);
  const addCategory = (e: KeyboardEvent<HTMLInputElement>) => {
    const newCategoryName = categoryInput.trim();
    if (newCategoryName.length > 30) {
      toast.toastMsg(
        nanoid(),
        t("category.msg.categoryNameLengthLimitMsg"),
        "error"
      );
      return;
    }
    if (newCategoryName === "") {
      return;
    }

    if (/^\s*$/.test(newCategoryName)) {
      return;
    }

    if (e.key === "Enter") {
      if (!selectedCategoryList.some((item) => item === newCategoryName)) {
        setSelectedCategoryList((prev) => [...prev, newCategoryName]);
        if (categoryList.includes(newCategoryName)) {
          categoryCheckboxGroup.handleClick(newCategoryName);
        }
        setCategoryInput("");
        setCategoryList([]);
        // e.target.value("");
      } else {
        setCategoryInput("");
      }
    }
  };

  const clickCategory = (e: any, cate: string) => {
    if (!selectedCategoryList.some((item) => item === cate)) {
      setSelectedCategoryList((prev) => [...prev, cate]);
      setCategoryInput("");
      setCategoryList([]);
      categoryCheckboxGroup.handleClick(cate);
    } else {
      setCategoryInput("");
    }
  };

  const deleteCategory = (name: string) => {
    if (name && selectedCategoryList.some((item) => item === name)) {
      setSelectedCategoryList((prev) => {
        return prev.filter((p) => p !== name);
      });
    }
  };

  const validation = () => {
    if (!scenarioInfo.subject) {
      toast.toastMsg(nanoid(), t("template.msg.subjectPlaceholder"), "error");
      return false;
    }

    if (!scenarioInfo.sendName) {
      toast.toastMsg(
        nanoid(),
        t("template.msg.senderNamePlaceholder"),
        "error"
      );
      return false;
    }
    if (!scenarioInfo.sendEmail) {
      toast.toastMsg(
        nanoid(),
        t("template.msg.senderEmailPlaceholder"),
        "error"
      );
      return false;
    }
    const reg =
      /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
    if (!reg.test(scenarioInfo.sendEmail)) {
      toast.toastMsg(nanoid(), t("common.msg.invalidEmailMsg"), "error");
      return false;
    }

    if (!body) {
      toast.toastMsg(nanoid(), t("template.msg.templateBodyInfoMsg"), "error");
      return false;
    }

    if (!selectedSite && !selectedImage && !selectedAttach) {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateNotSelectedPhishingContentMsg"),
        "error"
      );
      return false;
    }

    if (!scenarioInfo.templateName) {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateUndefinedNameMsg"),
        "error"
      );
      return false;
    }

    if (scenarioInfo.templateName.length > 128) {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateNameTooLongMsg"),
        "error"
      );
      return false;
    }

    return true;
  };

  const insertScenario = async () => {
    if (!validation()) return;

    const element = document.getElementsByClassName("note-editable")[0];

    const bodyFile = utilsCommon.generateHtmlFileFromString(
      scenarioInfo.templateName,
      body
    );

    html2canvas(element as HTMLElement, {
      useCORS: true,
      width: 500,
    })
      .then(async (canvas) => {
        const dataUrl = canvas.toDataURL("image/png");

        const byteString = atob(dataUrl.split(",")[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          intArray[i] = byteString.charCodeAt(i);
        }
        const imageBlob = new Blob([intArray], { type: "image/png" });
        const imageFile = new File([imageBlob], "converted-image.png", {
          type: "image/png",
        });

        const resp: any = await insertMailTemplate({
          file: bodyFile,
          thumb: imageFile,
          request: {
            sendEmail: scenarioInfo.sendEmail,
            sendName: scenarioInfo.sendName,
            subject: scenarioInfo.subject,
            templateName: scenarioInfo.templateName,
            siteId: selectedSite ?? 0,
            imageId: selectedImage ?? 0,
            documentId: selectedAttach ?? 0,
            categorys: selectedCategoryList,
          },
        });

        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("template.msg.templateAddSuccessMsg"),
            "success"
          );
          navigate("/manageScenario/list");
        } else {
          toast.toastMsg(
            nanoid(),
            t("template.msg.templateDuplicatedTemplateMsg"),
            "error"
          );
        }
      })
      .catch((error) => {
        console.error("Error generating image:", error);
      });
  };

  const updateScenario = async () => {
    if (!validation()) return;

    if (templateId) {
      const bodyFile = utilsCommon.generateHtmlFileFromString(
        scenarioInfo.templateName,
        body
      );

      const element = document.getElementsByClassName("note-editable")[0];

      html2canvas(element as HTMLElement, { useCORS: true, width: 500 })
        .then(async (canvas) => {
          const dataUrl = canvas.toDataURL("image/png");

          const byteString = atob(dataUrl.split(",")[1]);
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const intArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
          }
          const imageBlob = new Blob([intArray], { type: "image/png" });
          const imageFile = new File([imageBlob], "converted-image.png", {
            type: "image/png",
          });

          const resp: any = await updateMailTemplate({
            file: bodyFile,
            thumb: imageFile,
            request: {
              ...scenarioInfo,
              templateId: templateId,
              siteId: selectedSite,
              imageId: selectedImage,
              documentId: selectedAttach,
              categorys: selectedCategoryList,
            },
          });

          if (resp.payload && resp.payload.code === "SUCCESS") {
            toast.toastMsg(
              nanoid(),
              t("template.msg.templateUpdateSuccessMsg"),
              "success"
            );
          } else {
            toast.toastMsg(
              nanoid(),
              t("template.msg.templateUpdateFailMsg"),
              "error"
            );
          }
          navigate("/manageScenario/list");
        })
        .catch((error) => {
          console.error("Error generating image:", error);
        });
    }
  };

  const [searchImageName, setSearchImageName] = useState<string | null>();
  const [searchSiteName, setSearchSiteName] = useState<string | null>();
  const [searchAttachName, setSearchAttachName] = useState<string | null>();
  const [searchedImageList, setSearchedImageList] = useState<ImagesInfo[]>([]);
  const [searchedSiteList, setSearchedSiteList] = useState<SiteInfo[]>([]);
  const [searchedAttachList, setSearchedAttachList] = useState<
    PhishingDocument[]
  >([]);

  useEffect(() => {
    if (searchImageName && searchImageName.length > 0) {
      setSearchedImageList(
        imageList.filter((item) => item.imageOrgName.includes(searchImageName))
      );
    } else {
      setSearchedImageList(imageList);
    }
  }, [searchImageName]);
  useEffect(() => {
    if (searchSiteName && searchSiteName.length > 0) {
      setSearchedSiteList(
        siteList.filter((item) => item.siteName.includes(searchSiteName))
      );
    } else {
      setSearchedSiteList(siteList);
    }
  }, [searchSiteName]);
  useEffect(() => {
    if (searchAttachName && searchAttachName.length > 0) {
      setSearchedAttachList(
        attachList.filter((item) =>
          item.documentName.includes(searchAttachName)
        )
      );
    } else {
      setSearchedAttachList(attachList);
    }
  }, [searchAttachName]);

  const linkDropdown = (
    <SettingButton
      width={338}
      classes="mg mb-12"
      listPosition="right"
      buttonClick={selectedSite}
      button={
        <Box
          width={338}
          classes={`select select-root 
         ${isLinkOpen && `on`}
        `}
        >
          <button
            className="button-end-icon"
            style={{
              width: "338px",
              height: "40px",
            }}
            onClick={() => setIsLinkOpen((prev) => !prev)}
          >
            <span
              className={`typography-root typography-variant-span ${
                selectedSite
                  ? `typography-color-primary`
                  : `typography-color-secondary`
              }`}
            >
              {selectedSite &&
              siteList.filter((item) => item.siteId === selectedSite).length >
                0 ? (
                <Box alignItems="center">
                  <Typography>
                    {
                      siteList.filter((item) => item.siteId === selectedSite)[0]
                        .siteName
                    }
                  </Typography>
                </Box>
              ) : (
                t("template.msg.linkPlaceholderMsg")
              )}
            </span>
            <Icons classes="image__icon" variant="common" label="smallDown" />
          </button>
        </Box>
      }
    >
      <Box>
        <Box
          alignItems="center"
          classes="setting-button-list-search"
          height={40}
          width={225}
        >
          <Icons variant="mindsat" label="icon_search" />
          <TextField
            borderNone
            placeholder={t("template.msg.linkNamePlaceholder")}
            hasText
            text={searchSiteName ?? ""}
            onChange={(e) => setSearchSiteName(e.target.value)}
            // width={181}
          />
        </Box>
        <Button
          color="secondary"
          startIcon={<Icons variant="common" label="plus" />}
          width={89}
          classes="mg ml-8"
          onClick={() => setIsOpenLinkModal(true)}
        >
          {t("common.button.add")}
        </Button>
      </Box>
      {searchedSiteList.length === 0 ? (
        <Box
          height={240}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Box
            width={150}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              color="secondary"
              whiteSpace="normal"
              textAlign="center"
              lineHeight="1.5"
            >
              {t("template.msg.noLinkMsg")}
            </Typography>
          </Box>
        </Box>
      ) : (
        <ScrollBox classes="contents__contaier" height={240}>
          {searchedSiteList.map((item) => (
            <Box
              key={item.siteId}
              onClick={() => {
                if (selectedSite === item.siteId) {
                  setSelectedSite(0);
                  console.log(selectedSite);
                } else {
                  setSelectedSite(item.siteId);
                }
                setIsLinkOpen(false);
                setInitBody(body);
              }}
              classes={selectedSite === item.siteId ? "selected" : ""}
            >
              {item.siteName}
            </Box>
          ))}
        </ScrollBox>
      )}
    </SettingButton>
  );
  const [buttonClick, setButtonClick] = useState<number>(0);

  const imageDropdown = (
    <SettingButton
      buttonClick={buttonClick}
      width={338}
      classes="mg mb-12"
      listPosition="right"
      button={
        <Box
          width={338}
          classes={`select select-root 
         ${isImageOpen && `on`}
        `}
        >
          <button
            className="button-end-icon"
            style={{
              width: "338px",
              height: "40px",
            }}
            onClick={() => setIsImageOpen((prev) => !prev)}
          >
            <span
              className={`detail__text typography-root typography-variant-span ${
                selectedImage
                  ? `typography-color-primary`
                  : `typography-color-secondary`
              }`}
            >
              {selectedImage ? (
                <>
                  <ToolTip
                    message={
                      <img
                        src={
                          imageList.filter(
                            (item) => item.imageId === selectedImage
                          )[0].imagePath
                        }
                        className="selected__tooltip__image"
                      ></img>
                    }
                  >
                    <Box alignItems="center">
                      <img
                        className="selected__image"
                        src={
                          imageList.filter(
                            (item) => item.imageId === selectedImage
                          )[0].imagePath
                        }
                      />
                      <Typography>
                        {
                          imageList.filter(
                            (item) => item.imageId === selectedImage
                          )[0].imageOrgName
                        }
                      </Typography>
                    </Box>
                  </ToolTip>
                </>
              ) : (
                t("template.msg.imagePlaceholderMsg")
              )}
            </span>
            <Icons classes="image__icon" variant="common" label="smallDown" />
          </button>
        </Box>
      }
    >
      <Box>
        <Box
          alignItems="center"
          classes="setting-button-list-search"
          height={40}
          width={225}
        >
          <Icons variant="mindsat" label="icon_search" />
          <TextField
            borderNone
            placeholder={t("template.msg.imageNamePlaceholder")}
            hasText
            text={searchImageName ?? ""}
            onChange={(e) => setSearchImageName(e.target.value)}
            classes="detail__text"
          />
        </Box>
        <Button
          color="secondary"
          startIcon={<Icons variant="common" label="plus" />}
          width={89}
          classes="mg ml-8"
          onClick={() => setIsOpenImageModal(true)}
        >
          {t("common.button.add")}
        </Button>
      </Box>
      {searchedImageList?.length === 0 ? (
        <Box
          height={240}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Box
            width={160}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              color="secondary"
              whiteSpace="normal"
              textAlign="center"
              lineHeight="1.5"
            >
              {t("template.msg.noImageMsg")}
            </Typography>
          </Box>
        </Box>
      ) : (
        <ScrollBox classes="img__thumb__contaier">
          {searchedImageList.map((item) => (
            <SelectItem
              key={item.imageId}
              eventKey={item.imageId.toString()}
              onClick={(e) => {
                setSelectedImage(parseInt(e.selectedEventKey));
                setInitBody(body);
                setIsImageOpen(false);
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            >
              <ToolTip message={item.imageOrgName}>
                <ImageThumb src={item.imagePath} />
              </ToolTip>
            </SelectItem>
          ))}
        </ScrollBox>
      )}
    </SettingButton>
  );
  const attachDropdown = (
    <SettingButton
      width={338}
      classes="mg mb-12"
      listPosition="right"
      buttonClick={buttonClick}
      button={
        <Box
          width={338}
          classes={`select select-root 
         ${isAttachOpen && `on`}
        `}
        >
          <button
            className="button-end-icon"
            style={{
              width: "338px",
              height: "40px",
            }}
            onClick={() => setIsAttachOpen((prev) => !prev)}
          >
            <span
              className={`typography-root typography-variant-span ${
                selectedAttach
                  ? `typography-color-primary`
                  : `typography-color-secondary`
              }`}
            >
              {selectedAttach ? (
                <Box alignItems="center">
                  <Typography>
                    {
                      attachList.filter(
                        (item) => item.documentId === selectedAttach
                      )[0].documentName
                    }
                  </Typography>
                </Box>
              ) : (
                t("template.msg.attachPlaceholder")
              )}
            </span>
            <Icons classes="image__icon" variant="common" label="smallDown" />
          </button>
        </Box>
      }
    >
      <Box>
        <Box
          alignItems="center"
          classes="setting-button-list-search"
          height={40}
          width={225}
        >
          <Icons variant="mindsat" label="icon_search" />
          <TextField
            borderNone
            placeholder={t("template.msg.attachNamePlaceholder")}
            hasText
            text={searchAttachName ?? ""}
            onChange={(e) => setSearchAttachName(e.target.value)}
            // width={181}
          />
        </Box>
        <Button
          color="secondary"
          startIcon={<Icons variant="common" label="plus" />}
          width={89}
          classes="mg ml-8"
          onClick={() => setIsOpenDocumentModal(true)}
        >
          {t("common.button.add")}
        </Button>
      </Box>
      {searchedAttachList.length === 0 ? (
        <Box
          height={240}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Box
            width={170}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              color="secondary"
              whiteSpace="normal"
              textAlign="center"
              lineHeight="1.5"
            >
              {t("template.msg.noAttachMsg")}
            </Typography>
          </Box>
        </Box>
      ) : (
        <ScrollBox classes="contents__contaier" height={240}>
          {searchedAttachList.map((item) => (
            <Box
              key={item.documentId}
              onClick={() => {
                setIsAttachOpen(false);
                setSelectedAttach(item.documentId);
                setInitBody(body);
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              classes={selectedAttach === item.documentId ? "selected" : ""}
            >
              <Typography width="100%" ellipsis>
                {item.documentName}
              </Typography>
            </Box>
          ))}
        </ScrollBox>
      )}
    </SettingButton>
  );
  // const disabledImageDropDown = (
  //   <Select
  //     width={338}
  //     height={40}
  //     placeholder={
  //       imageList.length === 0
  //         ? "등록된 열람 확인용 이미지가 없습니다."
  //         : t("template.msg.imagePlaceholderMsg")
  //     }
  //     disabled={imageList.length === 0 ? true : false}
  //   >
  //     <SelectItem eventKey="0">hi</SelectItem>
  //   </Select>
  // );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const ext = ["eml"];

  const parseEml = async (req: { file: File }) => {
    const resp: any = await parseEMLFile(req);

    if (resp.payload && resp.payload.code === "SUCCESS") {
      setScenarioInfo({
        ...scenarioInfo,
        subject: resp.payload.data.subject,
        sendName: resp.payload.data.sendName,
        sendEmail: resp.payload.data.sendEmail,
      });
      setBody(resp.payload.data.body);
      setInitBody(resp.payload.data.body);
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileName = file.name;
      const idx = fileName ? fileName.lastIndexOf(".") : -1;
      if (idx > 0) {
        const extension = fileName.substring(idx + 1);
        // 확장자 체크
        if (!ext.includes(extension.toLowerCase())) {
          toast.toastMsg(
            nanoid(),
            t("template.msg.templateInvalidExtensionMsg"),
            "error"
          );
          event.target.value = "";
          return;
        }

        // parseEMLFile({ file });
        parseEml({ file });
      }
    }
  };
  return (
    <ScrollBox classes="main">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath
            path={[
              t("menu.templateManagement"),
              t("menu.template"),
              // "훈련 시나리오 생성",
              type === "UPDATE"
                ? t("template.updateSimple")
                : t("template.add"),
            ]}
          />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {/* 훈련 시나리오 생성 */}
            {type === "UPDATE" ? t("template.updateSimple") : t("template.add")}
          </Typography>
        </Box>
        <Box>
          <Button
            startIcon={
              <Icons
                variant="file"
                label="upload"
                stroke="#252d38"
                width={16}
              />
            }
            color="secondary"
            onClick={handleClick}
            classes="mg mr-10"
          >
            {t("template.upload")}
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </Box>
      </Box>

      <Box classes="mg mt-16" direction="column">
        <Box width="100%" justifyContent="space-between">
          <Box direction="column" width="calc(100%/3)" classes="mg mr-24">
            <Typography fontWeight={700} classes="mg mb-12">
              {t("template.subject")}
            </Typography>
            <TextField
              borderRadius={8}
              // width={338}
              height={40}
              hasText
              placeholder={t("template.msg.subjectPlaceholder")}
              text={scenarioInfo.subject ? scenarioInfo.subject : ""}
              onChange={(e: any) =>
                setScenarioInfo((prev) => {
                  return { ...prev, subject: e.target.value };
                })
              }
              disabled={isUsed && type === "UPDATE"}
              maxLength={255}
            />
          </Box>

          <Box direction="column" width="calc(100%/3)" classes="mg mr-24">
            <Typography fontWeight={700} classes="mg mb-12">
              {t("template.senderName")}
            </Typography>
            <TextField
              borderRadius={8}
              // width={338}
              height={40}
              hasText
              placeholder={t("template.msg.senderNamePlaceholder")}
              text={scenarioInfo.sendName ? scenarioInfo.sendName : ""}
              onChange={(e: any) =>
                setScenarioInfo((prev) => {
                  return { ...prev, sendName: e.target.value };
                })
              }
              disabled={isUsed && type === "UPDATE"}
              maxLength={128}
            />
          </Box>
          <Box direction="column" width="calc(100%/3)">
            <Typography fontWeight={700} classes="mg mb-12">
              {t("template.senderEmail")}
            </Typography>
            <TextField
              borderRadius={8}
              // width={519}
              height={40}
              hasText
              placeholder={t("template.msg.senderEmailPlaceholder")}
              text={scenarioInfo.sendEmail ? scenarioInfo.sendEmail : ""}
              onChange={(e: any) =>
                setScenarioInfo((prev) => {
                  return { ...prev, sendEmail: e.target.value };
                })
              }
              disabled={isUsed && type === "UPDATE"}
              maxLength={128}
            />
          </Box>
        </Box>

        {/* editor start */}
        <Box classes="mg mt-36" direction="column">
          <Typography fontWeight={700} classes="mg mb-12">
            {t("template.body")}
          </Typography>
          <Box height={456}>
            <WSEdit
              onChange={(e) => {
                setBody(e);
                // if (e.includes("##IMAGE##")) {
                //   let imagePath = imageList.find(
                //     (i) => i.imageId === selectedImage
                //   )?.imagePath;
                //   setBody(e.replace("##IMAGE##", imagePath ?? "##IMAGE##"));
                // }
              }}
              initBody={initBody}
              isShowButtons={true}
              isSelectedLink={selectedSite === 0 ? false : true}
              isSelectedImage={selectedImage === 0 ? false : true}
            />
          </Box>
        </Box>
        {/* editor end */}

        {/* content start */}
        <Box classes="mg mb-16">
          {/* link start */}
          <Box direction="column" classes="mg mr-24">
            <Typography fontWeight={700} classes="mg mb-12">
              {t("link.link")}
            </Typography>
            {linkDropdown}
          </Box>
          {/* link end */}
          {/* image start */}
          <Box direction="column" classes="mg mr-24">
            <Typography fontWeight={700} classes="mg mb-12">
              {t("image.image")}
            </Typography>
            {imageDropdown}
          </Box>
          {/* image end */}
          {/* attachment start */}
          <Box direction="column" classes="mg mr-24">
            <Typography fontWeight={700} classes="mg mb-12">
              {t("attach.attach")}
            </Typography>
            {attachDropdown}
          </Box>
          {/* attachment end */}
        </Box>
        {/* content end */}

        <Box direction="column" classes="mg mr-24 mb-36">
          <Typography fontWeight={700} classes="mg mb-12">
            {t("template.name")}
          </Typography>
          <TextField
            width={1062}
            height={40}
            borderRadius={8}
            placeholder={t("template.msg.namePlaceholder")}
            hasText
            text={scenarioInfo.templateName ? scenarioInfo.templateName : ""}
            onChange={(e: any) =>
              setScenarioInfo((prev) => {
                return { ...prev, templateName: e.target.value };
              })
            }
            disabled={isUsed && type === "UPDATE"}
            maxLength={128}
          ></TextField>
        </Box>

        {/* category start */}
        <Box
          direction="column"
          style={{
            marginBottom: "62px",
          }}
          width={338}
        >
          <Typography fontWeight={700} classes="mg mb-12">
            {t("category.category")}
          </Typography>

          <Box classes="category__select" width={338}>
            <TextField
              placeholder={t("template.msg.categoryPlaceholder")}
              width={338}
              height={40}
              borderRadius={8}
              text={categoryInput}
              hasText={true}
              onChange={(e) => setCategoryInput(e.target.value)}
              onKeyPress={(e) => addCategory(e)}
              maxLength={30}
            />

            {categoryList.length > 0 && (
              <Box classes="mg category__select__list">
                {categoryList.map((item) => (
                  <Box
                    classes="category__select__item"
                    key={item}
                    style={{
                      backgroundColor: categoryCheckboxGroup.isSelected(item)
                        ? "#EAF4FF"
                        : "#FFFFFF",
                    }}
                  >
                    <Typography>{item}</Typography>
                    <Checkbox
                      radio={true}
                      onChange={(e: any) => clickCategory(e, item)}
                      checked={categoryCheckboxGroup.isSelected(item)}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          <Box classes="mg mt-24">
            {selectedCategoryList.length > 0 && (
              <Box classes="write__catgeory__list">
                {selectedCategoryList.map((item) => (
                  // <Box key={item}>{item}</Box>
                  <CategoryTag
                    endIcon={<Icons variant="common" label="delete" />}
                    key={item}
                    text={item}
                    onClick={() => deleteCategory(item)}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Box>
        {/* category end */}

        {/* footer start */}

        <Box width={"100%"} justifyContent="flex-end">
          <Box classes="mg mt-14" style={{ marginRight: "40px" }}>
            <Icons
              variant="common"
              label="important"
              width={20}
              stroke="#717985"
            />
            <Typography size={12} color="secondary" classes="mg ml-4">
              {t("template.msg.typeInfoMsg")}
            </Typography>
          </Box>
          <Button
            color="secondary"
            classes="mg mr-8"
            onClick={() =>
              navigate(`${process.env.PUBLIC_URL}/manageScenario/list`)
            }
          >
            {t("common.button.cancel")}
          </Button>
          <Button
            onClick={() => {
              if (type === "UPDATE") updateScenario();
              else insertScenario();
            }}
          >
            {type === "UPDATE"
              ? t("common.button.update")
              : t("common.button.create")}
          </Button>
        </Box>
        {/* footer end */}
      </Box>
      <RegitOrEditImageModal
        isOpen={isOpenImageModal}
        onClose={() => setIsOpenImageModal(false)}
      />
      <RegitOrEditDocumentModal
        isOpen={isOpenDocumentModal}
        onClose={() => setIsOpenDocumentModal(false)}
      />
      <RegitOrEditSiteModal
        isOpen={isOpenLinkModal}
        onClose={() => setIsOpenLinkModal(false)}
      />
      {/* <div
        ref={htmlRef}
        dangerouslySetInnerHTML={{ __html: body }}
        style={{
          // visibility: "hidden", // 화면에 보이지 않게 숨기기
          position: "relative",
          height: "100%", // 여전히 렌더링되도록 보장
        }}
      /> */}
    </ScrollBox>
  );
};

export default WriteScenario;
