import { TrainResultFilterProps } from "../../Pages/ManageTrain/Result";
import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import axiosBlob from "../../shared/utils/axiosBlob";
import {
  BaseResponseArrDTO,
  BaseResponseBean,
  BaseResponseDTO,
} from "../../types/Common";
import {
  ProgramAddBean,
  ProgramUpdateBean,
  ProgramDetail,
} from "../../types/Info";
import { ConfigListType, TrainingResultReqType } from "../../types/Send";
import { TrainingListReqDTO } from "../../types/Training";

const GATEWAY_URL = config.servers.gateway.url;

interface ProgramApi {
  getProgramList(
    filter: TrainingListReqDTO
  ): Promise<BaseResponseArrDTO<ConfigListType[]>>;
  addNewProgram(req: ProgramAddBean): Promise<BaseResponseDTO<ConfigListType>>;
  deleteProgram(configId: number): Promise<BaseResponseDTO<ConfigListType>>;
  getProgramDetail(configId: number): Promise<BaseResponseDTO<ProgramDetail>>;
  updateProgram(
    req: ProgramUpdateBean
  ): Promise<BaseResponseDTO<ConfigListType>>;
  activateProgram(req: {
    configId: number;
    isActivated: boolean;
  }): Promise<BaseResponseBean<null>>;
  downloadResultFile(req?: TrainingResultReqType): Promise<any>;
  exportResultReport(req?: TrainingResultReqType): Promise<any>;
}

class ProgramServerApi implements ProgramApi {
  async getProgramList(
    filter: TrainingListReqDTO
  ): Promise<BaseResponseArrDTO<ConfigListType[]>> {
    const res = await axios.post<BaseResponseArrDTO<ConfigListType[]>>(
      `${GATEWAY_URL}/api/v2/training/list`,
      filter
    );
    return Promise.resolve(res.data);
  }
  async addNewProgram(
    req: ProgramAddBean
  ): Promise<BaseResponseDTO<ConfigListType>> {
    const res = await axios.post<BaseResponseDTO<ConfigListType>>(
      `${GATEWAY_URL}/api/v2/training/insert`,
      req
    );
    return Promise.resolve(res.data);
  }
  async updateProgram(
    req: ProgramUpdateBean
  ): Promise<BaseResponseDTO<ConfigListType>> {
    const res = await axios.post<BaseResponseDTO<ConfigListType>>(
      `${GATEWAY_URL}/api/v2/training/update`,
      req
    );
    return Promise.resolve(res.data);
  }
  async activateProgram(req: {
    configId: number;
    isActivated: boolean;
  }): Promise<BaseResponseBean<null>> {
    const res = await axios.post<BaseResponseBean<null>>(
      `/programs/update/active?configId=${req.configId}&isActivated=${req.isActivated}`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async deleteProgram(
    configId: number
  ): Promise<BaseResponseDTO<ConfigListType>> {
    const res = await axios.post<BaseResponseDTO<ConfigListType>>(
      `${GATEWAY_URL}/api/v2/training/delete`,
      { configId: configId }
    );
    return Promise.resolve(res.data);
  }
  async getProgramDetail(
    configId: number
  ): Promise<BaseResponseDTO<ProgramDetail>> {
    const res = await axios.post<BaseResponseDTO<ProgramDetail>>(
      // `/program/detail?configId=${configId}`
      `${GATEWAY_URL}/api/v2/training/detail`,
      {
        configId,
      }
    );
    return Promise.resolve(res.data);
  }
  async downloadResultFile(req?: TrainingResultReqType): Promise<any> {
    const res = await axiosBlob.post(
      `${GATEWAY_URL}/api/v2/training/result/export`,
      req ?? {}
    );

    return Promise.resolve(res.data);
  }
  async exportResultReport(req?: TrainingResultReqType): Promise<any> {
    const res = await axiosBlob.post(
      `${GATEWAY_URL}/api/v2/training/result/report`,
      req ?? {}
    );

    return Promise.resolve(res.data);
  }
}

const programApi: ProgramApi = new ProgramServerApi();

export default programApi;
