import axios from "../../shared/utils/axios";
import { TimeRequest } from "../../types/Common";
import { DateBean } from "../../types/Report";
import { ClickHistoryType, PhishingGraphBean } from "../../types/Send";

interface StaticsApi {
  getStaticsList(id?: number): Promise<ClickHistoryType[]>;
  getTraineGraphList(req: TimeRequest): Promise<PhishingGraphBean[]>;
  getTraineGraphListConfig(ids: number[]): Promise<PhishingGraphBean[]>;
  getTraineGraphListType(req: DateBean): Promise<PhishingGraphBean[]>;
  getPhishingGraph(req: {
    configId: number;
    clickType: number;
  }): Promise<ClickHistoryType[]>;
}
class StaticsServerApi implements StaticsApi {
  async getStaticsList(id?: number): Promise<ClickHistoryType[]> {
    const res = await axios.post<ClickHistoryType[]>(
      `/statistics/list` + (id ? `/${id}` : "")
    );
    return Promise.resolve(res.data);
  }
  async getTraineGraphList(req: TimeRequest): Promise<PhishingGraphBean[]> {
    const res = await axios.post<PhishingGraphBean[]>(
      `/statistics/getTraineGraphList?startDate=${req.startDate}&endDate=${req.endDate}`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async getTraineGraphListConfig(ids: number[]): Promise<PhishingGraphBean[]> {
    const res = await axios.post<PhishingGraphBean[]>(
      "/statistics/getTraineGraphList/configs",
      ids
    );
    return Promise.resolve(res.data);
  }
  async getTraineGraphListType(req: DateBean): Promise<PhishingGraphBean[]> {
    const res = await axios.post<PhishingGraphBean[]>(
      `/statistics/getTraineGraphList/type?startDate=${req.startDate}&endDate=${req.endDate}&realTrainingFlag=${req.realTrainingFlag}`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async getPhishingGraph(req: {
    configId: number;
    clickType: number;
  }): Promise<ClickHistoryType[]> {
    const res = await axios.post("/statistics/phishing", null, {
      params: req,
    });

    return Promise.resolve(res.data);
  }
}

const staticsApi: StaticsApi = new StaticsServerApi();

export default staticsApi;
