import Axios from "axios";
import {
  getAccessToken,
  removeAccessToken,
  removeUserInfoFromLocalStorage,
} from "./auth";
import { startProgress, stopProgress } from "../../redux/store/progress";
import { stores } from "../..";
import i18n from "../../locales";

const axios = Axios.create({
  headers: {
    "Content-Type": `application/json`,
    Authorization: getAccessToken() !== null && `Bearer ${getAccessToken()}`,
    "Accept-Language": i18n.language,
  },
});

axios.interceptors.request.use(function (config) {
  stores.dispatch(startProgress());
  const accessToken = getAccessToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    delete config.headers.Authorization;
  }
  return config;
});

axios.interceptors.response.use(
  (res) => {
    stores.dispatch(stopProgress());

    if (res.data.code === "REQUEST_UNAUTHORIZED") {
      // logout
      delete axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers["Authorization"];
      removeAccessToken();
      removeUserInfoFromLocalStorage();
      if (window.location.pathname !== `${process.env.PUBLIC_URL}/login`) {
        window.location.href = `${process.env.PUBLIC_URL}/login`;
      }
    }
    if (res.data && res.data?.success === false) alert("Fail");
    return res;
  },
  (err) => {
    stores.dispatch(stopProgress());
    if (
      err.response &&
      (err.response.status === 401 ||
        err.response.status === 400 ||
        err.response.status === 503 ||
        err?.response?.data?.code === "REQUEST_UNAUTHORIZED")
    ) {
      // logout
      delete axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers["Authorization"];
      removeAccessToken();
      removeUserInfoFromLocalStorage();
      if (window.location.pathname !== `${process.env.PUBLIC_URL}/login`) {
        window.location.href = `${process.env.PUBLIC_URL}/login`;
      }
    }
    return Promise.reject(err);
  }
);

export default axios;
