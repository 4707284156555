import {
  Box,
  Button,
  Checkbox,
  DropdownItem,
  Icons,
  Modal,
  Select,
  SettingButton,
  Switch,
  Tab,
  TabBox,
  TabPanel,
  Table,
  Tabs,
  Tag,
  TextField,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { useEffect, useState } from "react";
import TitlePath from "../../../component/TitlePath";
import { CustomerInfoBean } from "../../../types/Info";
import useReplyStore from "../../../redux/dispatcher/useReplyStore";
import { ReportReply } from "../../../types/Reply";
import utilsCommon from "../../../shared/utils/common";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import classNames from "classnames";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import { ConfigListType } from "../../../types/Send";
import "./index.scss";
import RegitOrEditTemplateModal from "./RegitOrEditTemplateModal";
import { SelectItem } from "fasoo-ui-component-next/src/components/atom/Select";
import { nanoid } from "@reduxjs/toolkit";
import CommonModal from "../../../component/CommonModal";
import NoInfo from "../../../component/NoInfo";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const ReportSetting: React.FC = () => {
  const { t } = useTranslation();
  const { replyState, getReplys, deleteReply, updateReply } = useReplyStore();
  const { programState, getProgramList, updateProgram } = useProgramStore();
  const checkboxGroup = useCheckboxGroup<number>();
  const toast = useToast();

  const [tab, setTab] = useState(0);
  const [replys, changeReplys] = useState<ReportReply[]>([]);
  const [replysTotal, changeReplysTotal] = useState(0);
  const [reportCenterInfo, changeReportCenterInfo] = useState<CustomerInfoBean>(
    {} as CustomerInfoBean
  );
  const [configList, changeConfigList] = useState<ConfigListType[]>();
  const [configListTotal, changeConfigListTotal] = useState(0);
  const [sort, changeSort] = useState("");
  const [registerReplyTemplateModalMode, changeRegisterReplyTemplateModalMode] =
    useState<"CLOSE" | "CREATE" | "EDIT">("CLOSE");
  const [targetReply, changeTargetReply] = useState(-1);
  const [openDeleteModal, toggleDeleteModal] = useState<boolean>(false);
  const [openActivateModal, toggleActivatModal] = useState<boolean>(false);
  const [replyTargetType, changeReplyTargetType] = useState("train");
  const [openDefaultReplyModal, toggleDefaultReplyModal] =
    useState<boolean>(false);
  const [filter, changeFilter] = useState({
    training: {
      size: 20,
      orderId: "configId",
      orderType: "desc",
      configId: undefined,
    },
  });
  useEffect(() => {
    changeReplys(replyState?.replyListWithCnt?.list ?? []);
    changeReplysTotal(replyState?.replyListWithCnt?.total ?? 0);
    changeReportCenterInfo(replyState.customerInfo);
  }, [replyState.replyListWithCnt]);
  useEffect(() => {
    changeConfigList(programState?.configListWithCount?.list ?? []);
    changeConfigListTotal(programState?.configListWithCount?.total ?? 0);
  }, [programState.configListWithCount]);
  useEffect(() => {
    changeFilter({
      ...filter,
      training: {
        ...filter.training,
        orderType: sort.includes("Desc") || sort === "" ? "desc" : "asc",
      },
    });
    getProgramList({
      ...filter.training,
      orderId: sort.split("-Desc")[0],
      orderType: sort.includes("Desc") || sort === "" ? "desc" : "asc",
    });
    checkboxGroup.handleAllClick([]);
    getReplys({});
  }, [sort]);
  const renderTableHeaderWithSort = (key: string, name: string) => {
    return (
      <Box
        onClick={() =>
          changeSort(
            !sort.includes(key)
              ? key + "-Desc"
              : sort === key + "-Desc"
              ? key
              : ""
          )
        }
      >
        <Typography classes="cursor-pointer">{name}</Typography>
        {key === "autoYn" && (
          <ToolTip message={t("reply.msg.reportReplyTypeInfoMsg")}>
            <Icons
              variant="header"
              label="questionBlack"
              width={16}
              height={16}
              classes="mg ml-4"
            />
          </ToolTip>
        )}
        {sort.includes(key) && (
          <Icons
            variant="arrow"
            label={sort.includes("-Desc") ? "down" : "up"}
          />
        )}
      </Box>
    );
  };
  /** 훈련별 답변 활성화 탭 UI */
  const renderReplyTemplateActivateList = () => {
    return (
      <Box direction="column" height={"100%"}>
        <Box
          classes="mg mt-20 mb-10"
          alignItems="center"
          style={{ minHeight: 37 }}
        >
          <Typography size={16} fontWeight={700} lineHeight="16px">
            {t("program.programList")}
          </Typography>
          <Typography
            size={16}
            fontWeight={700}
            lineHeight="16px"
            color={"green"}
            classes="mg ml-4"
          >
            {configListTotal ?? 0}
          </Typography>
          <ToolTip message={t("reply.msg.replyActiveConfigInfoMsg")}>
            <Icons
              variant="header"
              label="questionBlack"
              width={16}
              height={16}
              classes="mg ml-4"
            />
          </ToolTip>
        </Box>

        {configList && configList?.length > 0 ? (
          <>
            <Table>
              <colgroup>
                {/* */}
                <col style={{ width: "55px" }}></col>
                {/* 회차 */}
                <col style={{ minWidth: "100px", width: "150px" }}></col>
                {/* 훈련명 */}
                <col style={{ minWidth: "230px", width: "280px" }}></col>
                {/* 기간 */}
                <col style={{ width: "*" }}></col>
                {/* 활성 여부 */}
                <col style={{ minWidth: "70px", width: "200px" }}></col>
                {/* 자동 답변 활성화 */}
                <col style={{ minWidth: "70px", width: "120px" }}></col>
                {/*  */}
                <col style={{ width: "50px" }}></col>
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th>
                    {renderTableHeaderWithSort(
                      "configId",
                      t("program.configRound")
                    )}
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "configName",
                      t("program.programName")
                    )}
                  </th>
                  <th>{t("common.time.period")}</th>
                  <th>
                    <Box alignItems="center">
                      {renderTableHeaderWithSort(
                        "activateFlag",
                        t("program.isActivateFlag")
                      )}
                      <ToolTip
                        message={t("reply.msg.replyActiveConfigInfoMsg")}
                      >
                        <Icons
                          variant="header"
                          label="questionBlack"
                          width={16}
                          height={16}
                          classes="mg ml-4"
                        />
                      </ToolTip>
                    </Box>
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "autoReplyFlag",
                      t("reply.autoReplyActivate")
                    )}
                  </th>
                  <th />
                </tr>
              </thead>
            </Table>
            <Box classes="table-scroll">
              <Table>
                <colgroup>
                  {/* */}
                  <col style={{ width: "55px" }}></col>
                  {/* 회차 */}
                  <col style={{ minWidth: "100px", width: "150px" }}></col>
                  {/* 훈련명 */}
                  <col style={{ minWidth: "230px", width: "280px" }}></col>
                  {/* 기간 */}
                  <col style={{ width: "*" }}></col>
                  {/* 활성 여부 */}
                  <col style={{ minWidth: "70px", width: "200px" }}></col>
                  {/* 자동 답변 활성화 */}
                  <col style={{ minWidth: "70px", width: "120px" }}></col>
                  {/*  */}
                  <col style={{ width: "50px" }}></col>
                </colgroup>
                <tbody>
                  {configList?.map((item) => (
                    <tr>
                      <td></td>
                      <td>{item.configId}</td>
                      <td>{item.configName}</td>
                      <td>
                        {utilsCommon.getFullDateStr(item.sendStartDate) +
                          " ~ " +
                          utilsCommon.getFullDateStr(item.sendEndDate)}
                      </td>
                      <td>
                        {dayjs(item?.sendEndDate).valueOf() * 1000 <
                        dayjs().valueOf() ? (
                          <Tag
                            name={t("program.finished")}
                            isBgFilled
                            size="sm"
                            style={{ fontSize: 12 }}
                            color="gray"
                            radius={6}
                          />
                        ) : item.activateFlag ? (
                          <Tag
                            name={t("program.activated")}
                            isBgFilled
                            size="sm"
                            style={{ fontSize: 12 }}
                            color="blue"
                            radius={6}
                          />
                        ) : (
                          <Tag
                            name={t("program.deactivated")}
                            isBgFilled
                            size="sm"
                            style={{ fontSize: 12 }}
                            color="gray"
                            radius={6}
                          />
                        )}
                      </td>
                      <td>
                        <Switch
                          checked={item.autoReplyFlag}
                          disabled={
                            dayjs(item?.sendEndDate).valueOf() * 1000 <
                              dayjs().valueOf() || !item.activateFlag
                          }
                          onChange={() => {
                            changeTargetReply(item.configId);
                            toggleActivatModal(true);
                          }}
                        />
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </>
        ) : (
          <NoInfo />
        )}
      </Box>
    );
  };
  /** 답변 템플릿 목록 탭 UI */
  const renderReplyTemplateList = () => {
    return (
      <Box direction="column" height={"100%"}>
        <Box
          classes="mg mt-20 mb-10 tempate-header"
          justifyContent="space-between"
        >
          <Box>
            <Typography size={16} fontWeight={700} lineHeight="16px">
              {t("reply.reportReplyList")}
            </Typography>
            <Typography
              size={16}
              fontWeight={700}
              lineHeight="16px"
              color={"green"}
              classes="mg ml-4"
            >
              {replysTotal ?? 0}
            </Typography>
            <ToolTip message={t("reply.msg.reportTemplatInfoMsg")}>
              <Icons
                variant="header"
                label="questionBlack"
                width={16}
                height={16}
                classes="mg ml-4"
              />
            </ToolTip>
          </Box>
          <Box>
            {checkboxGroup.selected.length === 1 &&
              replys?.filter(
                (item) => item.replyId === checkboxGroup.selected[0]
              )[0]?.autoYn && (
                <Button
                  color={"text-noline"}
                  height={37}
                  startIcon={
                    <Icons
                      variant="mindsat"
                      label="icon_check_circle"
                      classes="mg mr-4"
                    />
                  }
                  onClick={() => toggleDefaultReplyModal(true)}
                >
                  {t("reply.reportReplySetDefault")}
                </Button>
              )}
            {checkboxGroup.selected.length === 1 && (
              <Button
                color={"text-noline"}
                height={37}
                startIcon={
                  <Icons
                    variant="mindsat"
                    label="icon_edit3"
                    classes="mg mr-4"
                  />
                }
                onClick={() => {
                  changeTargetReply(checkboxGroup.selected[0]);
                  changeRegisterReplyTemplateModalMode("EDIT");
                }}
              >
                {t("common.button.update")}
              </Button>
            )}
            {checkboxGroup.selected.length > 0 && (
              <Button
                color={"text-noline"}
                textColor="red"
                height={37}
                startIcon={
                  <Icons
                    variant="mindsat"
                    label="icon_trash"
                    classes="mg mr-4"
                  />
                }
                onClick={() => toggleDeleteModal(true)}
              >
                {t("common.button.delete")}
              </Button>
            )}
          </Box>
        </Box>
        {replys && replys.length > 0 ? (
          <>
            <Table>
              <colgroup>
                {/* 체크 박스 */}
                <col style={{ width: "55px" }}></col>
                {/* 템플릿 이름 */}
                <col style={{ width: "250px" }}></col>
                {/* 답변 종류 */}
                <col style={{ minWidth: "150px" }}></col>
                {/* 기본 답변 */}
                <col style={{ minWidth: "180px" }}></col>
                {/* 답변 대상*/}
                <col style={{ minWidth: "180px" }}></col>
                {/* 등록 일자 */}
                <col style={{ minWidth: "180px" }}></col>
                {/* 수정 일자 */}
                <col style={{ width: "*" }}></col>
                {/* 더보기 */}
                <col style={{ width: "50px" }}></col>
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      wrapsodyCheckbox
                      checked={checkboxGroup.selected.length === replys.length}
                      onChange={() =>
                        checkboxGroup.handleAllClick(
                          checkboxGroup.selected.length === replys.length
                            ? []
                            : replys.map((item) => item.replyId)
                        )
                      }
                    />
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "replyTitle",
                      t("reply.reportReplyName")
                    )}
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "autoYn",
                      t("reply.reportReplyType")
                    )}
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "defaultYn",
                      t("reply.reportReplyDefault")
                    )}
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "replyTargetType",
                      t("reply.reportReplyTarget")
                    )}
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "createDate",
                      t("common.time.addDate")
                    )}
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "updateDate",
                      t("common.time.updateDate")
                    )}
                  </th>
                  <th />
                </tr>
              </thead>
            </Table>
            <Box classes="table-scroll">
              <Table>
                <colgroup>
                  {/* 체크 박스 */}
                  <col style={{ width: "55px" }}></col>
                  {/* 템플릿 이름 */}
                  <col style={{ width: "250px" }}></col>
                  {/* 답변 종류 */}
                  <col style={{ minWidth: "150px" }}></col>
                  {/* 기본 답변 */}
                  <col style={{ minWidth: "180px" }}></col>
                  {/* 답변 대상*/}
                  <col style={{ minWidth: "180px" }}></col>
                  {/* 등록 일자 */}
                  <col style={{ minWidth: "180px" }}></col>
                  {/* 수정 일자 */}
                  <col style={{ width: "*" }}></col>
                  {/* 더보기 */}
                  <col style={{ width: "50px" }}></col>
                </colgroup>
                <tbody>
                  {replys?.map((item) => (
                    <tr
                      className={classNames(
                        "cursor-pointer mindsatTable-item",
                        {
                          "table-selected": checkboxGroup.isSelected(
                            item.replyId
                          ),
                        }
                      )}
                      onClick={() => checkboxGroup.handleClick(item.replyId)}
                    >
                      <td>
                        <Checkbox
                          wrapsodyCheckbox
                          checked={checkboxGroup.isSelected(item.replyId)}
                          onChange={() =>
                            checkboxGroup.handleClick(item.replyId)
                          }
                        />
                      </td>
                      <td>{item.replyTitle}</td>
                      <td>
                        {item.autoYn
                          ? t("reply.reportReplyAuto")
                          : t("reply.reportReplyManual")}
                      </td>
                      <td>
                        {item.defaultYn ? (
                          <Icons
                            variant="common"
                            label="check"
                            stroke="#05c072"
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {item.replyTargetType === 0
                          ? "-"
                          : item.replyTargetType === 1
                          ? t("report.reportTrainingMail")
                          : t("report.reportNotTrainingMail")}
                      </td>
                      <td>
                        {utilsCommon.getFullDate(item.createDate + "+00:00")}
                      </td>
                      <td>
                        {utilsCommon.getFullDate(
                          item.updateDate
                            ? item.updateDate + "+00:00"
                            : item.createDate + "+00:00"
                        )}
                      </td>
                      <td>
                        <SettingButton>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              changeTargetReply(item.replyId);
                              changeRegisterReplyTemplateModalMode("EDIT");
                            }}
                          >
                            <Icons
                              variant="mindsat"
                              label="icon_edit3"
                              classes="mg mr-4"
                            />
                            <Typography>{t("common.button.update")}</Typography>
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              changeTargetReply(item.replyId);
                              toggleDeleteModal(true);
                            }}
                          >
                            <Icons
                              variant="mindsat"
                              label="icon_trash"
                              classes="mg mr-4"
                              stroke={"red"}
                            />
                            <Typography color={"red"}>
                              {t("common.button.delete")}
                            </Typography>
                          </DropdownItem>
                        </SettingButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </>
        ) : (
          <NoInfo />
        )}
      </Box>
    );
  };
  const handleUpdateDefaultReport = async () => {
    let resp: any = await updateReply({
      updateUser: "admin",
      replyId: checkboxGroup.selected[0],
      replyTargetType: replyTargetType === "train" ? 1 : 2,
    });
    if (resp.payload) {
      toast.toastMsg(
        nanoid(),
        t("reply.msg.reportSetDefaultReplySuccessMsg"),
        "success"
      );
      getReplys({});
    }
  };
  /** 기본 답변 지정 모달  */
  const renderDefaultReplyModal = () => {
    const targetReply = replys.filter(
      (item) => item.replyId === checkboxGroup.selected[0]
    )[0];
    return (
      <Modal
        open={openDefaultReplyModal}
        onClose={() => toggleDefaultReplyModal(false)}
        title={t("reply.reportReplySetDefault")}
        isDivider={false}
        width={531}
        footer={
          <Box justifyContent="flex-end">
            <Button
              color={"secondary"}
              onClick={() => toggleDefaultReplyModal(false)}
            >
              {t("common.button.cancel")}
            </Button>
            <Button
              classes="mg ml-12"
              onClick={() => {
                handleUpdateDefaultReport();
                toggleDefaultReplyModal(false);
              }}
            >
              {t("common.button.check")}
            </Button>
          </Box>
        }
      >
        <Box direction="column">
          <Typography size={16} isBold lineHeight="normal">
            {t("reply.reportGetDefaultReply")}
          </Typography>
          <Table classes="mg mt-24">
            <colgroup>
              {/* 답변 템플릿 이름 */}
              <col style={{ width: "55px" }}></col>
              {/* 답변 템플릿 이름 */}
              <col style={{ width: "50%" }}></col>
              {/* 답변 대상 메일 */}
              <col style={{ width: "50%" }}></col>
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>{t("reply.reportTemplateName")}</th>
                <th>{t("reply.reportReplyMailType")}</th>
              </tr>
            </thead>
            <tbody>
              {replys
                .filter((item) => item.defaultYn)
                .map((item1) => (
                  <tr>
                    <td></td>
                    <td>{item1.replyTitle}</td>
                    <td>
                      {item1.replyTargetType === 0
                        ? ""
                        : item1.replyTargetType === 1
                        ? t("report.reportTrainingMail")
                        : t("report.reportNotTrainingMail")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Typography
            size={16}
            isBold
            lineHeight="normal"
            classes="mg mt-40 mb-10"
          >
            {t("reply.reportSelectedTemplate")}
          </Typography>
          <Box classes="mg mt-20">
            <Box direction="column" width={"48%"}>
              <Typography isBold>{t("reply.reportTemplateName")}</Typography>
              <TextField
                size={"lg"}
                classes="mg mt-12"
                text={targetReply.replyTitle}
                disabled
                hasText
              />
            </Box>
            <Box direction="column" width={"48%"} classes="mg ml-14">
              <Typography>{t("reply.reportReplyMailType")}</Typography>
              <Select
                classes="mg mt-12"
                size={"md"}
                height={40}
                isWidthFull
                onClick={(e) => changeReplyTargetType(e)}
              >
                <SelectItem eventKey="train">
                  {t("report.reportTrainingMail")}
                </SelectItem>
                <SelectItem eventKey="real">
                  {t("report.reportNotTrainingMail")}
                </SelectItem>
              </Select>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  const handleDeleteReply = async () => {
    let resp: any = await deleteReply(
      targetReply !== -1 ? [targetReply] : checkboxGroup.selected
    );
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("reply.msg.reportReplyDeleteSuccessMsg"),
        "success"
      );
      checkboxGroup.handleAllClick([]);
      getReplys({});
    } else {
      toast.toastMsg(nanoid(), t("reply.msg.answerTemplateDeleteMsg"), "error");
    }
    if (!targetReply) {
      checkboxGroup.handleAllClick([]);
    }
    changeTargetReply(-1);
    toggleDeleteModal(false);
  };
  /** 삭제 확인 모달 */
  const renderDeleteReplyModal = () => {
    return (
      <CommonModal
        title={t("common.button.delete")}
        open={openDeleteModal}
        onClose={() => {
          changeTargetReply(-1);
          toggleDeleteModal(false);
        }}
        type={"delete"}
        body={
          <Box width={"100%"}>
            <Typography>
              {t("template.msg.templateDeleteTemplateMsg")}
            </Typography>
          </Box>
        }
        afterSubmitButton={handleDeleteReply}
      />
    );
  };
  const updateDectivate = async (replyId: number, nextStatus: boolean) => {
    const resp: any = await updateProgram({
      configId: replyId,
      autoReplyFlag: nextStatus,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        nextStatus
          ? t("reply.msg.reportActivateAutoFlagSuccessMsg")
          : t("reply.msg.reportDeactivateAutoFlagSuccessMsg"),
        "success"
      );
      getReplys({});
    } else {
      toast.toastMsg(
        nanoid(),
        nextStatus
          ? t("reply.msg.reportActivateAutoFlagFailMsg")
          : t("reply.msg.reportDeactivateAutoFlagFailMsg"),
        "error"
      );
    }

    changeTargetReply(-1);
    toggleActivatModal(false);
  };
  const renderActivateModal = () => {
    const activateTarget = configList?.filter(
      (item) => item.configId === targetReply
    )[0];
    return (
      <Modal
        open={openActivateModal}
        title={
          activateTarget?.autoReplyFlag
            ? t("reply.autoReplyDeactivate")
            : t("reply.autoReplyActivate")
        }
        width={388}
        onClose={() => {
          changeTargetReply(-1);
          toggleActivatModal(false);
        }}
        isDivider={false}
        footer={
          <Box justifyContent="flex-end">
            <Button
              color={"secondary"}
              onClick={() => {
                changeTargetReply(-1);
                toggleActivatModal(false);
              }}
            >
              {t("common.button.cancel")}
            </Button>
            <Button
              classes="mg ml-10"
              onClick={() =>
                updateDectivate(targetReply, !activateTarget?.autoReplyFlag)
              }
            >
              {activateTarget?.autoReplyFlag
                ? t("program.programDeactive")
                : t("program.programActivate")}
            </Button>
          </Box>
        }
      >
        <Box>
          <Typography>
            {activateTarget?.autoReplyFlag
              ? t("reply.msg.autoReplyDeactivateConfirmMsg")
              : t("reply.msg.autoReplyActivateConfirmMsg")}
          </Typography>
        </Box>
      </Modal>
    );
  };
  return (
    <Box classes="main reportSetting">
      {registerReplyTemplateModalMode !== "CLOSE" && (
        <RegitOrEditTemplateModal
          type={registerReplyTemplateModalMode}
          isOpen={true}
          targetId={targetReply}
          onClose={() => {
            changeTargetReply(-1);
            changeRegisterReplyTemplateModalMode("CLOSE");
          }}
        />
      )}
      {openDeleteModal && renderDeleteReplyModal()}
      {openActivateModal && renderActivateModal()}
      {openDefaultReplyModal && renderDefaultReplyModal()}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath
            path={[t("menu.reportManagement"), t("menu.reportSetting")]}
          />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("menu.reportSetting")}
          </Typography>
        </Box>
        <Button
          startIcon={<Icons variant="common" label="plus" stroke="white" />}
          onClick={() => changeRegisterReplyTemplateModalMode("CREATE")}
        >
          {t("reply.reportReplyAdd")}
        </Button>
      </Box>
      <Box height={40} style={{ minHeight: 40 }}>
        <Typography isBold width="146px">
          {t("menu.reportCenter")}
        </Typography>
        <Typography>
          {reportCenterInfo?.reportInfo ?? t("common.info.noData")}
        </Typography>
      </Box>
      <TabBox classes="mg mt-20" style={{ height: "calc(100% - 112px)" }}>
        <Tabs value={tab} onChange={(selected) => setTab(selected)}>
          <Tab
            label={t("reply.reportReplyList")}
            index={0}
            fontSize={16}
            fontWeight={700}
          />
          <Tab
            label={t("reply.activateReplyByConfig")}
            index={1}
            fontSize={16}
            fontWeight={700}
          />
        </Tabs>
        <TabPanel value={tab} index={0}>
          {renderReplyTemplateList()}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {renderReplyTemplateActivateList()}
        </TabPanel>
      </TabBox>
    </Box>
  );
};

export default ReportSetting;
