import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Icons,
  Modal,
  ScrollBox,
  Table,
  Tag,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { SiteResourceBean } from "../../../types/Info";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import { nanoid } from "@reduxjs/toolkit";
import RegitOrEditResourceModal from "./RegitOrEditResourceModal";
import CommonModal from "../../../component/CommonModal";
import { useTranslation } from "react-i18next";
import NoInfo from "../../../component/NoInfo";
import { getLocalCustomerCode } from "../../../shared/utils/auth";

interface ResourceListModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalType: "LIST" | "DELETE" | "INSERT" | "NONE";
  setModalType: Dispatch<SetStateAction<"LIST" | "DELETE" | "INSERT" | "NONE">>;
}

const ResourceListModal: React.FC<ResourceListModalProps> = ({
  isOpen,
  onClose,
  modalType,
  setModalType,
}) => {
  const { t, i18n } = useTranslation();
  const { contentState, getSiteResource, deleteSiteResource } =
    useContentStore();
  const [resources, setResources] = useState<SiteResourceBean[]>([]);
  const checkboxGroup = useCheckboxGroup<number>();
  const localCustomerCode = getLocalCustomerCode();

  const toast = useToast();

  const [selectedResourceId, setSelectedResourceId] = useState<number>(0);
  useEffect(() => {
    getSiteResource();
    if (modalType !== "DELETE") {
      checkboxGroup.handleAllClick([]);
    }
  }, [isOpen]);
  // useEffect(() => {
  //   checkboxGroup.handleAllClick([]);
  // }, [modalType]);

  useEffect(() => {
    setResources(contentState.siteResources);
  }, [contentState.siteResources]);

  const onCopy = (url: string) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          toast.toastMsg(nanoid(), t("resource.msg.copy"), "success");
        })
        .catch((err) => {
          toast.toastMsg(nanoid(), t("resource.msg.copyFail"), "error");
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      toast.toastMsg(nanoid(), t("resource.msg.copy"), "success");
    }
  };

  const onDelete = async () => {
    console.log(checkboxGroup.selected);
    const resp: any = await deleteSiteResource({
      request: {
        resourceIds: checkboxGroup.selected as number[],
      },
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("resource.msg.deleteSuccessMsg"), "success");
    } else {
      toast.toastMsg(nanoid(), t("resource.msg.deleteFailMsg"), "error");
    }

    checkboxGroup.handleAllClick([]);
    setSelectedResourceId(0);
    setModalType("LIST");
  };

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [resources]);

  return (
    <>
      <Modal
        open={isOpen}
        subtitle={t("resource.msg.phishingInfoImageInfoMsg")}
        onClose={onClose}
        title={t("resource.phishingInfoImageManage")}
        width={1000}
        isDivider={false}
        footer={
          <Box width="100%" justifyContent="flex-end">
            <Button color="secondary" onClick={onClose}>
              {t("common.button.cancel")}
            </Button>
          </Box>
        }
      >
        <Box
          width="100%"
          direction="column"
          style={{ maxHeight: "60vh", width: "100%" }}
        >
          <Box justifyContent="flex-end" width="100%">
            {checkboxGroup.selected.length > 0 && (
              <Box>
                {checkboxGroup.selected.length === 1 && (
                  <Button
                    color={"text-noline"}
                    height={37}
                    startIcon={
                      <Icons
                        variant="mindsat"
                        label="icon_edit3"
                        classes="mg mr-4"
                      />
                    }
                    onClick={() => {
                      const item: SiteResourceBean = resources.filter(
                        (t) => t.resourceId === checkboxGroup.selected[0]
                      )[0];

                      if (item.commonId) {
                        toast.toastMsg(
                          nanoid(),
                          t("common.msg.cannotDeletePublicContentsMsg"),
                          "error"
                        );
                        return;
                      }
                      setSelectedResourceId(checkboxGroup.selected[0]);
                      setModalType("INSERT");
                    }}
                  >
                    {t("common.button.update")}
                  </Button>
                )}
                <Button
                  color={"text-noline"}
                  textColor="red"
                  height={37}
                  startIcon={
                    <Icons
                      variant="mindsat"
                      label="icon_trash"
                      classes="mg mr-4"
                    />
                  }
                  onClick={() => {
                    let commonFlag = false;
                    checkboxGroup.selected.map((c) => {
                      const item: SiteResourceBean[] = resources.filter(
                        (t) => t.resourceId === c
                      );

                      item.map((i) => {
                        if (i.commonId) {
                          commonFlag = true;
                        }
                      });
                    });

                    if (commonFlag) {
                      toast.toastMsg(
                        nanoid(),
                        t("common.msg.cannotDeletePublicContentsMsg"),
                        "error"
                      );
                      return;
                    }
                    setModalType("DELETE");
                  }}
                >
                  {t("common.button.delete")}
                </Button>
              </Box>
            )}
            <Button
              onClick={() => {
                setModalType("INSERT");
              }}
            >
              {t("common.button.add")}
            </Button>
          </Box>
          {/* <ScrollBox> */}
          {resources?.length > 0 ? (
            <Box height="calc(100% - 40px)" direction="column">
              <Table classes="default-table mg mt-16">
                <colgroup>
                  <col style={{ width: "52px" }}></col>
                  <col style={{ width: "72px" }}></col>
                  <col style={{ width: "*" }}></col>
                  <col style={{ width: "52px" }}></col>
                  <col
                    style={{ width: `calc(550px + ${scrollbarWidth}px)` }}
                  ></col>
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <Checkbox
                        checked={
                          checkboxGroup.selected.length === resources.length
                        }
                        onChange={() =>
                          checkboxGroup.handleAllClick(
                            checkboxGroup.selected.length === resources.length
                              ? []
                              : resources.map((item) => item.resourceId)
                          )
                        }
                      />
                    </th>
                    <th>{t("template.type")}</th>
                    <th>{t("resource.phishingInfoImageName")}</th>
                    <th></th>
                    <th>{t("link.linkUrl")}</th>
                  </tr>
                </thead>
              </Table>
              <Box classes="table-scroll">
                <Table>
                  <colgroup>
                    <col style={{ width: "52px" }}></col>
                    <col style={{ width: "72px" }}></col>
                    <col style={{ width: "*" }}></col>
                    <col style={{ width: "52px" }}></col>
                    <col style={{ width: "550px" }}></col>
                  </colgroup>

                  <tbody>
                    {resources.map((item) => (
                      <tr key={item.resourceId}>
                        <td>
                          <Checkbox
                            checked={checkboxGroup.isSelected(item.resourceId)}
                            onChange={() =>
                              checkboxGroup.handleClick(item.resourceId)
                            }
                          />
                        </td>
                        <td>
                          {localCustomerCode ? (
                            item.commonId ? (
                              <Tag
                                name={t("template.public")}
                                size={i18n.language === "ko" ? "ms" : "sm"}
                                color="blue"
                                radius={6}
                              />
                            ) : (
                              <Tag
                                name={t("template.private")}
                                size={i18n.language === "ko" ? "ms" : "sm"}
                                color="pink"
                                radius={6}
                              />
                            )
                          ) : (
                            <Tag
                              name={t("template.public")}
                              size={i18n.language === "ko" ? "ms" : "sm"}
                              color="blue"
                              radius={6}
                            />
                          )}
                        </td>
                        <td>{item.resourceName}</td>
                        <td>
                          <IconButton
                            size="xsm"
                            transparent
                            onClick={() => onCopy(item.resourceUrl)}
                          >
                            <Icons variant="file" label="copy" width={14} />
                          </IconButton>
                        </td>
                        <td>
                          <Box>
                            <Typography ellipsis={true}>
                              {item.resourceUrl}
                            </Typography>
                          </Box>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Box>
            </Box>
          ) : (
            <Box width="100%">
              <NoInfo />
            </Box>
          )}
          {/* </ScrollBox> */}
        </Box>
      </Modal>
      <CommonModal
        open={modalType === "DELETE"}
        onClose={() => setModalType("LIST")}
        title={t("resource.phishingInfoImageDelete")}
        subMessage={t("common.msg.deleteConfirmMsg")}
        type="delete"
        afterSubmitButton={onDelete}
      />
      <RegitOrEditResourceModal
        isOpen={modalType == "INSERT"}
        onClose={() => (
          setModalType("LIST"),
          setSelectedResourceId(0),
          checkboxGroup.handleAllClick([])
        )}
        resourceId={selectedResourceId > 0 ? selectedResourceId : undefined}
      />
    </>
  );
};

export default ResourceListModal;
