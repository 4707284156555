import { useMemo } from "react";
import { useAppDispatch, useRootState } from ".";
import { bindActionCreators } from "redux";
import { startProgress, stopProgress } from "../store/progress";

const useProgressStore = () => {
  const progressState = useRootState((state) => state.progressStore);

  const dispatch = useAppDispatch();

  const boundStartProgress = useMemo(
    () => bindActionCreators(startProgress, dispatch),
    [dispatch]
  );
  const boundStopProgress = useMemo(
    () => bindActionCreators(stopProgress, dispatch),
    [dispatch]
  );
  return {
    progressState,
    boundStartProgress,
    boundStopProgress,
  };
};

export default useProgressStore;
