import "./index.scss";
import {
  Avatar,
  Box,
  Button,
  Calendar,
  Divider,
  Icons,
  Modal,
  Tab,
  TabBox,
  TabPanel,
  Tabs,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import useDashboardStore from "../../../redux/dispatcher/useDashboardStore";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { TotalType } from "../../Dashboard";
import utilsCommon from "../../../shared/utils/common";
import { HighchartsReact } from "highcharts-react-official";
import Highcharts, { TooltipFormatterContextObject } from "highcharts";
import NoInfo from "../../../component/NoInfo";
import Select, {
  SelectItem,
} from "fasoo-ui-component-next/src/components/atom/Select";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import { ConfigListType } from "../../../types/Send";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { nanoid } from "@reduxjs/toolkit";
import ScenarioDetailModal from "../../ManageScenario/List/ScenarioDetailModal";
import NoInfoImage from "../../../shared/image/NoInfoImage";

const ManageTrainStat: React.FC = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const { dashboardState, getStatTraining, getStatTopTraining } =
    useDashboardStore();
  const { programState, getProgramList } = useProgramStore();
  const [stats, setStats] = useState<StatTraining[]>([]);
  const [statsTop, setStatsTop] = useState<StatTopTraining>();
  const [totalData, setTotalData] = useState<TotalType | null>();
  const [targetConfigId, changeTargetConfigId] = useState(0);
  const [targetOpenSenarioId, changeOpenSenarioId] = useState(-1);
  const [scenarioModalType, setScenarioModalType] = useState<
    "DETAIL" | "USED" | "NONE"
  >("NONE");
  const [selectedConfigId, setSelectedConfigId] = useState(0);
  const [configList, setConfigList] = useState<number[]>([]);
  const [realTrainingFlag, setRealTrainingFlag] = useState<boolean | null>(
    null
  );

  const [topWidth, setTopWidth] = useState<number>(window.innerWidth - 310);
  useEffect(() => {
    getProgramList({});
    const handleResize = () => {
      setTopWidth(window.innerWidth - 310);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setConfigList(
      programState.configListWithCount?.list?.map(
        (i: ConfigListType) => i.configId
      ) ?? []
    );
  }, [programState.configListWithCount]);

  // useEffect(() => {
  //   getStatTopTraining(selectedConfigId);
  //   if (selectedConfigId && selectedConfigId > 0) {
  //     changeTargetConfigId(selectedConfigId);
  //   }

  //   if (targetConfigId && targetConfigId > 0) {
  //     getStatTopTraining(targetConfigId);
  //   }
  //   if (selectedConfigId === 0) {
  //     getStatTraining({
  //       realTrainingFlag: null,
  //     });
  //   } else {
  //     getStatTraining({
  //       realTrainingFlag: null,
  //       configIds: [selectedConfigId],
  //     });
  //   }
  // }, [selectedConfigId]);

  // useEffect(() => {
  //   if (selectedConfigId === 0) {
  //     getStatTraining({
  //       realTrainingFlag: null,
  //     });
  //     getStatTopTraining(0);
  //   } else {
  //     getStatTraining({
  //       realTrainingFlag: null,
  //       configIds: [selectedConfigId],
  //     });
  //   }
  //   changeTargetConfigId(selectedConfigId);
  //   setChartKey((prevKey) => prevKey + 1);
  //   setChartKey2((prevKey) => prevKey + 1);
  // }, [selectedConfigId]);

  useEffect(() => {
    if (targetConfigId && targetConfigId > 0) {
      getStatTopTraining({
        configId: targetConfigId,
        intervalTime: utilsCommon.getIntervalTime(),
        realTrainingFlag,
      });
    } else if (targetConfigId === 0) {
      getStatTopTraining({
        configId: 0,
        intervalTime: utilsCommon.getIntervalTime(),
        startDate: Math.trunc(selectedPeriod.start / 1000),
        endDate: Math.trunc(selectedPeriod.end / 1000),
        realTrainingFlag,
      });
    }
  }, [targetConfigId]);

  useEffect(() => {
    setStats(dashboardState.stats);

    if (dashboardState.stats.length > 0) {
      // changeTargetConfigId(dashboardState.stats[0].configId);

      let totalType: TotalType = {
        callAverage: 0,
        callTotal: 0,
        applyAverage: 0,
        applyTotal: 0,
        linkAverage: 0,
        linkTotal: 0,
        infoAverage: 0,
        infoTotal: 0,
        fileAverage: 0,
        fileTotal: 0,
        allFile: 0,
        allLink: 0,
        allInfo: 0,
      };
      let allUser = 0;
      let allSendSuccess = 0;

      dashboardState.stats.forEach((item: StatTraining) => {
        totalType.callTotal += item.callResult;
        totalType.applyTotal += item.applyUser;
        totalType.linkTotal += item.clickLink;
        totalType.infoTotal += item.clickInfo;
        totalType.fileTotal += item.clickFile;
        allUser += item.sendUserCount;
        allSendSuccess += item.sendSuccess;
        totalType.allLink += item.totalClickLink;
        totalType.allFile += item.totalClickFile;
      });

      totalType.callAverage =
        allSendSuccess === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.callTotal / allSendSuccess) * 100
            );
      totalType.applyAverage =
        allUser === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.applyTotal / allUser) * 100
            );
      totalType.linkAverage =
        totalType.allLink === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.linkTotal / totalType.allLink) * 100
            );

      totalType.infoAverage =
        allSendSuccess === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.infoTotal / allSendSuccess) * 100
            );
      totalType.fileAverage =
        totalType.allFile === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.fileTotal / totalType.allFile) * 100
            );

      setTotalData(totalType);
    } else {
      setTotalData(null);
    }
  }, [dashboardState.stats]);

  useEffect(() => {
    setStatsTop(dashboardState.statsTop);
  }, [dashboardState.statsTop]);

  // useEffect(() => {
  //   setselectedLineOptions({
  //     chart: {
  //       type: "spline",
  //       width: 744,
  //       height: 384,
  //       // style: { fontFamily: "Noto Sans KR" },
  //     },
  //     title: {
  //       text: "",
  //     },
  //     legend: {
  //       align: "left",
  //       verticalAlign: "top",
  //       margin: 30,
  //       itemStyle: {
  //         fontSize: "12px",
  //       },
  //     },
  //     xAxis: {
  //       categories: statsTop?.statDate
  //         .filter((item) => item.applyUser !== 0)
  //         .map((item) => utilsCommon.getShortDate(item?.sendStartDate)),
  //       title: {
  //         text: t("common.time.date"),
  //         align: "high",
  //         style: {
  //           fontSize: "12px",
  //         },
  //       },
  //       labels: {
  //         style: {
  //           fontSize: "12px",
  //         },
  //       },
  //     },
  //     yAxis: {
  //       title: {
  //         text: t("program.programDataUnit"),
  //         align: "high",
  //         rotation: 0,
  //         style: {
  //           fontSize: "12px",
  //         },
  //       },
  //       style: {
  //         fontSize: "12px",
  //       },
  //       labels: {
  //         style: {
  //           fontSize: "12px",
  //         },
  //       },
  //       dataMin: 0,
  //       dataMax: 0,
  //       // tickPositioner: function () {
  //       //   let tick = Math.floor(this.dataMin);
  //       //   const positions = [],
  //       //     increment = Math.ceil((this.dataMax - this.dataMin) / 6);

  //       //   if (this.dataMax !== null && this.dataMin !== null) {
  //       //     for (tick; tick - increment <= this.dataMax; tick += increment) {
  //       //       positions.push(tick);
  //       //     }
  //       //   }
  //       //   return positions;
  //       // },
  //     },

  //     plotOptions: {
  //       series: {
  //         allowPointSelect: true,
  //         events: {},
  //       },
  //     },
  //     tooltip: {
  //       shared: true,
  //       style: {
  //         fontSize: "14px",
  //       },
  //       pointFormatter: function (this: TooltipFormatterContextObject): string {
  //         return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${this.y} %</b><br/>`;
  //       },
  //     },
  //     credits: { enabled: false },
  //     series: [
  //       {
  //         name: t("dashboard.clickUser"),
  //         data: statsTop?.statDate
  //           .filter((item) => item.applyUser !== 0)
  //           .map((item) => item.applyUser),
  //         color: "#1bbb97",
  //       },
  //       {
  //         name: t("template.linkOpen"),
  //         data: statsTop?.statDate
  //           .filter((item) => item.applyUser !== 0)
  //           .map((item) => item.clickLink),
  //         color: "#ff9700",
  //       },
  //       {
  //         name: t("template.personalInfo"),
  //         data: statsTop?.statDate
  //           .filter((item) => item.applyUser !== 0)
  //           .map((item) => item.clickInfo),
  //         color: "#fa504b",
  //       },
  //       {
  //         name: t("template.attachOpen"),
  //         data: statsTop?.statDate
  //           .filter((item) => item.applyUser !== 0)
  //           .map((item) => item.clickFile),
  //         color: "#9b77fc",
  //       },
  //     ],
  //   });
  // }, [statsTop, i18n.language]);

  const [lineOptions, setLineOptions] = useState<any>([]);
  // const [selectedLineOptions, setselectedLineOptions] = useState<any>([]);
  const [chartKey, setChartKey] = useState(0);
  // const [chartKey2, setChartKey2] = useState(0);

  // useEffect(() => {
  //   setChartKey((prevKey) => prevKey + 1);
  // }, [lineOptions]);totalType.allFile

  // useEffect(() => {
  //   setChartKey2((prevKey) => prevKey + 1);
  // }, [selectedLineOptions]);

  // console.log(totalData);
  useEffect(() => {
    var nowConfig = 0;
    setLineOptions({
      chart: {
        type: "spline",
        width: topWidth,
        height: 280,
        // style: { fontFamily: "Noto Sans KR" },
      },
      title: {
        text: "",
      },
      legend: {
        align: "left",
        verticalAlign: "top",
        margin: 30,
        itemStyle: {
          fontSize: "12px",
        },
      },
      xAxis: {
        categories: stats
          .map((item) => t("report.reportConfigId", { round: item.configId }))
          .reverse(),
        title: {
          text: t("program.configRound"),
          align: "high",
          style: {
            fontSize: "12px",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      yAxis: {
        max: 100,
        title: {
          text: "%",
          align: "high",
          rotation: 0,
          style: {
            fontSize: "12px",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      plotOptions: {
        series: {
          allowPointSelect: false,
          point: {
            events: {
              click: function (this: Highcharts.Point) {
                const category: string = this.category as string; // 클릭된 포인트의 카테고리 값 가져오기
                if (
                  nowConfig ===
                  parseInt(category.replace(t("program.configRound"), ""))
                ) {
                  nowConfig = 0;
                  changeTargetConfigId(0);
                  const chart = this.series.chart;
                  chart.series.forEach((series) => {
                    series.points.forEach((point) => {
                      if (point.category === category) {
                        point.update({
                          selected: false,
                        });
                      }
                    });
                  });
                } else {
                  nowConfig = parseInt(
                    category.replace(t("program.configRound"), "")
                  );
                  changeTargetConfigId(
                    parseInt(category.replace(t("program.configRound"), ""))
                  );
                  const chart = this.series.chart;
                  chart.series.forEach((series) => {
                    series.points.forEach((point) => {
                      if (point.category === category) {
                        point.update({
                          selected: true,
                        });
                      } else if (point.selected) {
                        point.update({
                          selected: false,
                        });
                      }
                    });
                  });
                }
              },
            },
          },
        },
      },
      tooltip: {
        shared: true,
        style: {
          fontSize: "12px",
        },
        pointFormatter: function (this: TooltipFormatterContextObject): string {
          return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${this.y} %</b><br/>`;
        },
      },
      credits: { enabled: false },
      series: [
        {
          name: t("dashboard.report"),
          data: stats
            .map((item) =>
              item.callResult === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.callResult * 100) / item.sendSuccess
                  )
            )
            .reverse(),

          color: "#55a3ee",
        },
        {
          name: t("dashboard.clickUser"),
          data: stats
            .map((item) =>
              item.applyUser === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.applyUser * 100) / item.sendUserCount
                  )
            )
            .reverse(),

          color: "#1bbb97",
        },
        {
          name: t("template.linkOpen"),
          data: stats
            .map((item) =>
              item.totalClickLink === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.clickLink * 100) / item.totalClickLink
                  )
            )

            .reverse(),

          color: "#ff9700",
        },
        // {
        //   name: t("template.personalInfo"),
        //   data: stats
        //     .map((item) =>
        //       item.clickInfo === 0
        //         ? 0
        //         : utilsCommon.truncateToTwoDecimals(
        //             (item.clickInfo * 100) / item.sendSuccess
        //           )
        //     )
        //     .reverse(),
        //   color: "#fa504b",
        // },
        {
          name: t("template.attachOpen"),
          data: stats
            .map((item) =>
              item.totalClickFile === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.clickFile * 100) / item.totalClickFile
                  )
            )
            .reverse(),
          color: "#9b77fc",
        },
      ],
    });
  }, [stats, i18n.language, topWidth]);

  const [tab] = useState(0);
  const [selectedPeriod, changeSelectedPeriod] = useState({
    value: "PERIOD_6MONTH",
    start: dayjs()
      .subtract(6, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });

  const changeDateFilter = (type: string) => {
    if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(
            type === "PERIOD_3MONTH" ? 3 : type === "PERIOD_6MONTH" ? 6 : 12,
            "month"
          )
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };

  const changeTrainingFlag = (type: string) => {
    if (type === "mock") {
      setRealTrainingFlag(false);
    } else if (type === "real") {
      setRealTrainingFlag(true);
    } else {
      setRealTrainingFlag(null);
    }
  };

  const [openDateModal, toggleDateModal] = useState(false);

  useEffect(() => {
    getStatTraining({
      startDate: Math.trunc(selectedPeriod.start / 1000),
      endDate: Math.trunc(selectedPeriod.end / 1000),
      intervalTime: utilsCommon.getIntervalTime(),
      realTrainingFlag,
    });
    getStatTopTraining({
      configId: 0,
      intervalTime: utilsCommon.getIntervalTime(),
      startDate: Math.trunc(selectedPeriod.start / 1000),
      endDate: Math.trunc(selectedPeriod.end / 1000),
      realTrainingFlag,
    });
  }, [selectedPeriod, realTrainingFlag]);

  const dateModal = () => {
    return (
      <Modal
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        isDivider={false}
        width={480}
        footer={
          <Box justifyContent="flex-end">
            <Button
              color={"secondary"}
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
            >
              {t("common.button.cancel")}
            </Button>
            <Button
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              classes={"mg ml-8"}
            >
              {t("common.button.apply")}
            </Button>
          </Box>
        }
      >
        <Box alignItems="center" justifyContent="center">
          <Calendar
            onSubmit={(date) =>
              changeTempPeriod({
                ...tempPeriod,
                start: dayjs(date)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0)
                  .valueOf(),
              })
            }
            placeholder={t("common.info.startDate")}
            selected={new Date(tempPeriod.start)}
            dateFormat="yyyy-MM-dd"
            lang={i18n.language}
            maxDate={
              tempPeriod && tempPeriod.end
                ? new Date(tempPeriod.end)
                : undefined
            }
          />
          <Box
            style={{ color: "#717985" }}
            alignItems="center"
            classes="mg ml-4 mr-4"
          >
            ~
          </Box>
          <Calendar
            onSubmit={(date) =>
              changeTempPeriod({
                ...tempPeriod,
                end: dayjs(date)
                  .set("hour", 23)
                  .set("minute", 59)
                  .set("second", 59)
                  .valueOf(),
              })
            }
            selected={new Date(tempPeriod.end)}
            placeholder={t("common.info.endDate")}
            dateFormat="yyyy-MM-dd"
            lang={i18n.language}
            minDate={
              tempPeriod && tempPeriod.start
                ? new Date(tempPeriod.start)
                : undefined
            }
          />
        </Box>
      </Modal>
    );
  };
  return (
    <Box classes="main manageTrainStat">
      {targetOpenSenarioId !== -1 && (
        <ScenarioDetailModal
          isOpen={targetOpenSenarioId !== -1}
          onClose={() => (
            changeOpenSenarioId(-1), setScenarioModalType("NONE")
          )}
          templateId={targetOpenSenarioId}
          isShowButtons={false}
          modalType={scenarioModalType}
          setModalType={setScenarioModalType}
        />
      )}
      {openDateModal && dateModal()}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
        id={"manageTrainStat"}
      >
        <Box direction="column">
          <TitlePath
            path={[t("menu.trainingManagement"), t("menu.trainingGraph")]}
          />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("menu.trainingGraph")}
          </Typography>
        </Box>
      </Box>
      {configList.length === 0 ? (
        <Box direction="column" alignItems="center">
          <img
            src={require("../../../shared/image/logo/NoMain.png")}
            alt={"noTrain"}
            width={360}
            height={200}
            className="mg mt-48"
            style={{ marginTop: "160px" }}
          />
          <Typography classes="mg mt-24" size={16} color={"secondary"}>
            {t("program.msg.noStatsMsg")}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box width={"100%"}>
            <TabBox style={{ height: "calc(100% - 112px)" }}>
              <Tabs value={0}>
                <Tab
                  style={{ display: "flex" }}
                  labelComp={
                    <Box alignItems="center">
                      <Select
                        transparent
                        autoWidth
                        align="left"
                        height={20}
                        title={
                          realTrainingFlag === null
                            ? t("common.auth.all")
                            : realTrainingFlag
                            ? t("program.realTrainingLabel")
                            : t("program.mockTrainingLabel")
                        }
                        onClick={changeTrainingFlag}
                        stroke={tab === 0 ? "#252d38" : "#717985"}
                        selectedEventKey={
                          realTrainingFlag === null
                            ? "all"
                            : realTrainingFlag
                            ? "real"
                            : "mock"
                        }
                        classes="tab__select"
                      >
                        <SelectItem eventKey="all">
                          {t("common.auth.all")}
                        </SelectItem>
                        <SelectItem eventKey="mock">
                          {t("program.mockTrainingLabel")}
                        </SelectItem>
                        <SelectItem eventKey="real">
                          {t("program.realTrainingLabel")}
                        </SelectItem>
                      </Select>
                    </Box>
                  }
                  index={0}
                  fontSize={16}
                  fontWeight={700}
                  // classes="mg mr-8"
                />

                <Tab
                  style={{ display: "flex" }}
                  // label="hi"
                  labelComp={
                    <Box alignItems="center">
                      {/* <Typography color={tab === 0 ? "primary" : "secondary"}>
                        {selectedPeriod.value === "PERIOD_6MONTH"
                          ? t("report.stats.reportRecent6Month")
                          : selectedPeriod.value === "PERIOD_1YEAR"
                          ? t("report.stats.reportRecent1Year")
                          : selectedPeriod.value === "PERIOD_3MONTH"
                          ? t("report.stats.reportRecent3Month")
                          : t("graph.phishingCustomScope")}
                      </Typography> */}
                      <Select
                        transparent
                        // isWidthFull
                        autoWidth
                        align="left"
                        height={20}
                        title={
                          selectedPeriod.value === "PERIOD_6MONTH"
                            ? t("report.stats.reportRecent6Month")
                            : selectedPeriod.value === "PERIOD_1YEAR"
                            ? t("report.stats.reportRecent1Year")
                            : selectedPeriod.value === "PERIOD_3MONTH"
                            ? t("report.stats.reportRecent3Month")
                            : t("graph.phishingCustomScope")
                          // ""
                        }
                        onClick={changeDateFilter}
                        stroke={tab === 0 ? "#252d38" : "#717985"}
                        selectedEventKey={selectedPeriod.value}
                        classes="tab__select"
                      >
                        <SelectItem eventKey="PERIOD_3MONTH">
                          {t("report.stats.reportRecent3Month")}
                        </SelectItem>
                        <SelectItem eventKey="PERIOD_6MONTH">
                          {t("report.stats.reportRecent6Month")}
                        </SelectItem>
                        <SelectItem eventKey="PERIOD_1YEAR">
                          {t("report.stats.reportRecent1Year")}
                        </SelectItem>
                        <Divider direction="row" classes="mg mt-2 mb-2" />
                        <SelectItem eventKey="PERIOD_CUSTOM">
                          {t("graph.phishingCustomScope")}
                        </SelectItem>
                      </Select>
                    </Box>
                  }
                  index={0}
                  fontSize={16}
                  fontWeight={700}
                />
              </Tabs>
              <TabPanel value={tab} index={0}>
                {stats.length === 0 ? (
                  <Box
                    width={"100%"}
                    height={"100%"}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    classes="mg mt-24"
                  >
                    <NoInfoImage />
                    <Typography
                      size={16}
                      classes="mg mt-16 mb-16"
                      lineHeight="24px"
                      color={"secondary"}
                    >
                      {t("program.msg.noFilterMsg")}
                    </Typography>
                    <Button
                      startIcon={
                        <Icons
                          variant="common"
                          label="refresh"
                          stroke="white"
                        />
                      }
                      onClick={() => changeDateFilter("PERIOD_6MONTH")}
                    >
                      {t("common.button.resetFilter")}
                    </Button>
                  </Box>
                ) : (
                  <Box direction="column">
                    {/* <Box width="100%" justifyContent="flex-end">
              <Select
                selectedEventKey={selectedConfigId.toString()}
                height={40}
                onClick={(e) => setSelectedConfigId(parseInt(e))}
              >
                <SelectItem eventKey="0">{t("common.auth.all")}</SelectItem>
                {configList.map((item) => (
                  <SelectItem key={item} eventKey={item.toString()}>
                    {t("common.list.numOrder", { round: item })}
                  </SelectItem>
                ))}
              </Select>
            </Box> */}
                    {/* 최근 10회 훈련 평균 */}
                    <Box classes="mg mt-16" direction="column" width={"100%"}>
                      {totalData && (
                        <Box
                          justifyContent="space-between"
                          width={"100%"}
                          classes="dashboard__boxes"
                        >
                          <Box classes="dashboard__box blue__box">
                            <Box direction="column">
                              <Typography
                                size={12}
                                classes="mg mb-4"
                                color="white"
                              >
                                {t("dashboard.report")}
                              </Typography>
                              <Typography
                                size={32}
                                fontWeight={900}
                                color="white"
                              >
                                {totalData.callAverage} %
                              </Typography>
                            </Box>
                            <Icons
                              variant="mindsat"
                              label={"dashboardReport"}
                            />
                          </Box>
                          <Box classes="dashboard__box mint__box">
                            <Box
                              direction="column"
                              justifyContent="space-between"
                            >
                              <Typography
                                size={12}
                                classes="mg mb-4"
                                color="white"
                              >
                                {t("dashboard.clickUser")}
                              </Typography>
                              <Typography
                                size={32}
                                fontWeight={900}
                                color="white"
                              >
                                {totalData.applyAverage} %
                              </Typography>
                            </Box>
                            <Icons variant="mindsat" label={"dashboardWarn"} />
                          </Box>
                          <Box classes="dashboard__box yellow__box">
                            <Box direction="column">
                              <Box direction="column">
                                <Typography
                                  size={12}
                                  classes="mg mb-4"
                                  color="white"
                                >
                                  {`${t("dashboard.linkOpen")} / ${t(
                                    "template.personalInfo"
                                  )}`}
                                </Typography>
                                <Typography
                                  size={32}
                                  fontWeight={900}
                                  color="white"
                                >
                                  {`${totalData.linkAverage} % / ${
                                    totalData.infoTotal
                                  } ${t("program.programDataUnit")}`}
                                </Typography>
                              </Box>
                            </Box>
                            <Icons variant="mindsat" label={"dashboardLink"} />
                          </Box>
                          {/* <Box classes="dashboard__box red__box">
                          <Box direction="column">
                            <Typography
                              size={12}
                              classes="mg mb-4"
                              color="white"
                            >
                              {t("template.personalInfo")}
                            </Typography>
                            <Typography
                              size={32}
                              fontWeight={900}
                              color="white"
                            >
                              {totalData.infoAverage} %
                            </Typography>
                          </Box>
                          <Icons variant="mindsat" label={"dashboardProvide"} />
                        </Box> */}
                          <Box classes="dashboard__box purple__box">
                            <Box direction="column">
                              <Typography
                                size={12}
                                classes="mg mb-4"
                                color="white"
                              >
                                {t("dashboard.attachOpen")}
                              </Typography>
                              <Typography
                                size={32}
                                fontWeight={900}
                                color="white"
                              >
                                {totalData.fileAverage} %
                              </Typography>
                            </Box>
                            <Icons
                              variant="mindsat"
                              label={"dashboardAttach"}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>

                    {/* 훈련 추이 */}
                    <Box direction="column" width={"100%"} classes="mg mt-40">
                      <Box alignItems="flex-end" justifyContent="space-between">
                        <Typography size={16} fontWeight={700}>
                          {t("graph.result")}
                        </Typography>
                        {/* <Box>
                        <IconButton
                          transparent
                          noHover
                          onClick={() =>
                            setLineOptions({
                              ...lineOptions,
                              chart: { ...lineOptions.chart, type: "spline" },
                            })
                          }
                        >
                          <Icons
                            variant="mindsat"
                            label="lineChart"
                            stroke={
                              lineOptions?.chart?.type === "column"
                                ? "#717985"
                                : "#3182F6"
                            }
                          />
                        </IconButton>
                        <IconButton
                          transparent
                          noHover
                          onClick={() =>
                            setLineOptions({
                              ...lineOptions,
                              chart: { ...lineOptions.chart, type: "column" },
                            })
                          }
                        >
                          <Icons
                            variant="mindsat"
                            label="barChart"
                            stroke={
                              lineOptions?.chart?.type === "spline"
                                ? "#717985"
                                : "#3182F6"
                            }
                          />
                        </IconButton>
                      </Box> */}
                      </Box>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={lineOptions}
                        key={chartKey}
                      />
                    </Box>

                    {/* 최근 훈련 목록 */}
                    {/* <Box width={"100%"} direction="column">
                    <Box alignItems="flex-end">
                      <Typography size={16} fontWeight={700}>
                        {t("program.programList")}
                      </Typography>
                    </Box>
                    <Box width={"100%"} classes="mg mt-12">
                      <Box width={"50%"}>
                        <Table classes="default-table">
                          <colgroup>
                            <col style={{ width: "76px" }}></col>
                            <col style={{ width: "56px" }}></col>
                            <col style={{ width: "*" }}></col>
                            <col style={{ width: "72px" }}></col>
                            <col style={{ width: "72px" }}></col>
                          </colgroup>
                          <thead>
                            <tr>
                              <th>{t("program.configRound")}</th>
                              <th>{t("template.type")}</th>
                              <th>{t("program.programName")}</th>
                              <th>{t("dashboard.user")}</th>
                              <th>{t("dashboard.clickCnt")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {stats.map((item) => (
                              <tr
                                key={item.configId}
                                className={
                                  targetConfigId === item.configId
                                    ? "selected-table"
                                    : ""
                                }
                                onClick={() =>
                                  changeTargetConfigId(item.configId)
                                }
                              >
                                <td>{item.configId}</td>
                                <td>
                                  <Tag
                                    name={
                                      item.realTrainingFlag
                                        ? t("program.realTrainingLabel")
                                        : t("program.mockTrainingLabel")
                                    }
                                    isBgFilled={false}
                                    size={i18n.language === "ko" ? "ms" : "sm"}
                                    color={
                                      item.realTrainingFlag ? "green" : "gray"
                                    }
                                    radius={6}
                                  />
                                </td>
                                <td>{item.configName}</td>
                                <td>
                                  {item.userCount}{" "}
                                  {t("program.programPeopleUnit")}
                                </td>
                                <td>
                                  {
                                    item.clickFile +
                                      item.clickInfo +
                                      item.clickLink
                                    // + item.clickMail
                                  }{" "}
                                  {t("program.programDataUnit")}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Box>
                      {targetConfigId === 0 ? (
                        <Box classes="configChart">
                          <Box
                            classes="configChart-body"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <StatInfoImage />
                            <Typography size={14} classes="mg mt-8">
                              {t("graph.msg.phsihngNotSelectedConfigMsg")}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Box classes="configChart">
                          <Typography size={14} isBold>
                            {t("dashboard.trainingTrend", {
                              configId: targetConfigId,
                            })}
                          </Typography>
                          <Box classes="mg mt-12 configChart-body">
                            <Box
                              justifyContent="space-between"
                              height={32}
                              width={"100%"}
                            >
                              <Typography
                                size={14}
                                lineHeight="32px"
                                color={"secondary"}
                              >
                                {t("program.trainingDuration")}:{" "}
                                {utilsCommon.getShortDate(
                                  dashboardState.stats.filter(
                                    (item: StatTraining) =>
                                      item.configId === targetConfigId
                                  )[0]?.sendStartDate + "+00:00"
                                )}
                                ~{" "}
                                {utilsCommon.getShortDate(
                                  dashboardState.stats.filter(
                                    (item: StatTraining) =>
                                      item.configId === targetConfigId
                                  )[0]?.sendEndDate + "+00:00"
                                )}
                              </Typography>
                              <Box>
                                <IconButton
                                  transparent
                                  noHover
                                  onClick={() =>
                                    setselectedLineOptions({
                                      ...selectedLineOptions,
                                      chart: {
                                        ...selectedLineOptions.chart,
                                        type: "spline",
                                      },
                                    })
                                  }
                                >
                                  <Icons
                                    variant="mindsat"
                                    label="lineChart"
                                    stroke={
                                      selectedLineOptions?.chart?.type ===
                                      "column"
                                        ? "#717985"
                                        : "#3182F6"
                                    }
                                  />
                                </IconButton>
                                <IconButton
                                  transparent
                                  noHover
                                  onClick={() =>
                                    setselectedLineOptions({
                                      ...selectedLineOptions,
                                      chart: {
                                        ...selectedLineOptions.chart,
                                        type: "column",
                                      },
                                    })
                                  }
                                >
                                  <Icons
                                    variant="mindsat"
                                    label="barChart"
                                    stroke={
                                      selectedLineOptions?.chart?.type ===
                                      "spline"
                                        ? "#717985"
                                        : "#3182F6"
                                    }
                                  />
                                </IconButton>
                              </Box>
                            </Box>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={selectedLineOptions}
                              key={chartKey2}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box> */}

                    {/* TopN */}
                    <Box classes="mg mt-40 mb-40" width={"100%"}>
                      <Box direction="column" width={"33%"}>
                        <Box alignItems="flex-end">
                          <Typography size={16} fontWeight={700}>
                            {targetConfigId
                              ? t("report.reportConfigId", {
                                  round: targetConfigId,
                                })
                              : t("common.auth.all")}{" "}
                            {t("dashboard.clickUserTopTen")}
                          </Typography>
                        </Box>
                        <Box classes="mg mt-16" direction="column">
                          {statsTop?.clickedUser &&
                          statsTop?.clickedUser.length > 0 ? (
                            statsTop?.clickedUser?.map((item, index) => (
                              <Box
                                justifyContent="space-between"
                                alignItems="center"
                                height={52}
                                classes="dashboard_toplist"
                                onClick={() => {
                                  navigate(`/manageTrain/result`);
                                  window.sessionStorage.setItem(
                                    "resultFilter",
                                    `configId=${targetConfigId}&userName=${item.name}`
                                  );
                                }}
                              >
                                <Box alignItems="center">
                                  <Typography width="28px">
                                    {(index < 9 ? "0" : "") + (index + 1)}
                                  </Typography>
                                  <Avatar
                                    name={item.name}
                                    userId={item.email}
                                  />
                                  <Typography
                                    classes="mg ml-8"
                                    maxWidth="150px"
                                    ellipsis
                                    lineHeight="1.4"
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    classes="mg ml-8"
                                    maxWidth="200px"
                                    ellipsis
                                    lineHeight="1.4"
                                  >
                                    ({item.email})
                                  </Typography>
                                </Box>
                                <Typography>
                                  {item.count.toLocaleString()}{" "}
                                  {t("program.programDataUnit")}
                                </Typography>
                              </Box>
                            ))
                          ) : (
                            <NoInfo />
                          )}
                        </Box>
                      </Box>
                      <Divider direction="col" classes="mg ml-32 mr-32" />
                      <Box direction="column" width={"33%"}>
                        <Box alignItems="flex-end">
                          <Typography size={16} fontWeight={700}>
                            {targetConfigId
                              ? t("report.reportConfigId", {
                                  round: targetConfigId,
                                })
                              : t("common.auth.all")}{" "}
                            {t("dashboard.noReportUserTopTen")}
                          </Typography>
                        </Box>
                        <Box classes="mg mt-16" direction="column">
                          {statsTop?.nothingUser &&
                          statsTop?.nothingUser.length > 0 ? (
                            statsTop?.nothingUser?.map((item, index) => (
                              <Box
                                justifyContent="space-between"
                                height={52}
                                alignItems="center"
                                classes="dashboard_toplist"
                                onClick={() => {
                                  navigate(`/manageTrain/result`);
                                  window.sessionStorage.setItem(
                                    "resultFilter",
                                    `configId=${targetConfigId}&userName=${item.name}&callResult`
                                  );
                                }}
                              >
                                <Box alignItems="center">
                                  <Typography width="28px">
                                    {(index < 9 ? "0" : "") + (index + 1)}
                                  </Typography>
                                  <Avatar
                                    name={item.name}
                                    userId={item.email}
                                  />

                                  <Typography
                                    classes="mg ml-8"
                                    maxWidth="150px"
                                    ellipsis
                                    lineHeight="1.4"
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    classes="mg ml-8"
                                    maxWidth="200px"
                                    lineHeight="1.4"
                                    ellipsis
                                  >
                                    ({item.email})
                                  </Typography>
                                </Box>
                                <Typography>
                                  {item.count.toLocaleString()}{" "}
                                  {t("program.programDataUnit")}
                                </Typography>
                              </Box>
                            ))
                          ) : (
                            <NoInfo />
                          )}
                        </Box>
                      </Box>
                      <Divider direction="col" classes="mg ml-32 mr-32" />
                      <Box direction="column" width={"33%"}>
                        <Box alignItems="flex-end">
                          <Typography size={16} fontWeight={700}>
                            {targetConfigId
                              ? t("report.reportConfigId", {
                                  round: targetConfigId,
                                })
                              : t("common.auth.all")}{" "}
                            {t("dashboard.clickScenarioTopTen")}
                          </Typography>
                        </Box>
                        <Box classes="mg mt-16" direction="column">
                          {statsTop?.scenario &&
                          statsTop?.scenario.length > 0 ? (
                            statsTop?.scenario.map((item, index) => (
                              <Box
                                justifyContent="space-between"
                                alignItems="center"
                                height={52}
                                classes="dashboard_toplist"
                                onClick={() => (
                                  changeOpenSenarioId(parseInt(item.id)),
                                  setScenarioModalType("DETAIL")
                                )}
                              >
                                <Box alignItems="center" height="100%">
                                  <Typography
                                    width="28px"
                                    size={14}
                                    fontWeight={500}
                                  >
                                    {(index < 9 ? "0" : "") + (index + 1)}
                                  </Typography>
                                  <Typography
                                    classes="mg ml-8"
                                    maxWidth="350px"
                                    lineHeight="1.4"
                                    ellipsis
                                  >
                                    {item.name}
                                  </Typography>
                                </Box>
                                <Typography>
                                  {item.count.toLocaleString()}{" "}
                                  {t("program.programDataUnit")}
                                </Typography>
                              </Box>
                            ))
                          ) : (
                            <NoInfo />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </TabPanel>
            </TabBox>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ManageTrainStat;
