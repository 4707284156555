import React from "react";

const NoInfoImage = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      height="110"
      width={width || "300"}
      className={classes}
      viewBox="0 0 141 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M131.364 32.3539H9.24365C4.32148 32.3539 0.333252 28.3657 0.333252 23.4435V8.91045C0.333252 3.98829 4.32148 0 9.24365 0H131.364C136.286 0 140.275 3.98829 140.275 8.91045V23.4435C140.275 28.3657 136.286 32.3539 131.364 32.3539Z"
        fill="#3182F6"
      />
      <path
        d="M127.335 11.05H40.5708C40.0185 11.05 39.5708 11.4978 39.5708 12.05V12.7932C39.5708 13.3455 40.0185 13.7932 40.5708 13.7932H127.335C127.887 13.7932 128.335 13.3455 128.335 12.7932V12.05C128.335 11.4978 127.887 11.05 127.335 11.05Z"
        fill="white"
      />
      <path
        d="M75.6718 18.5605H40.5708C40.0185 18.5605 39.5708 19.0083 39.5708 19.5605V20.3037C39.5708 20.856 40.0185 21.3037 40.5708 21.3037H75.6718C76.224 21.3037 76.6718 20.856 76.6718 20.3037V19.5605C76.6718 19.0083 76.224 18.5605 75.6718 18.5605Z"
        fill="white"
      />
      <path
        d="M23.402 25.6322H20.046C15.7464 25.6322 12.2639 22.1497 12.2639 17.8501V14.4941C12.2639 10.1945 15.7464 6.71204 20.046 6.71204H23.402C27.7016 6.71204 31.184 10.1945 31.184 14.4941V17.8501C31.184 22.1497 27.7016 25.6322 23.402 25.6322Z"
        fill="#E8F3FF"
      />
      <path
        d="M131.364 70.3907H9.24365C4.32148 70.3907 0.333252 66.4024 0.333252 61.4802V46.9472C0.333252 42.025 4.32148 38.0367 9.24365 38.0367H131.364C136.286 38.0367 140.275 42.025 140.275 46.9472V61.4802C140.275 66.4024 136.286 70.3907 131.364 70.3907Z"
        fill="#EBF0F2"
      />
      <path
        d="M127.34 49.0872H40.5757C40.0234 49.0872 39.5757 49.5349 39.5757 50.0872V50.8303C39.5757 51.3826 40.0234 51.8303 40.5757 51.8303H127.34C127.892 51.8303 128.34 51.3826 128.34 50.8303V50.0872C128.34 49.5349 127.892 49.0872 127.34 49.0872Z"
        fill="#D0D5D8"
      />
      <path
        d="M75.6766 56.5978H40.5757C40.0234 56.5978 39.5757 57.0455 39.5757 57.5978V58.341C39.5757 58.8933 40.0234 59.341 40.5757 59.341H75.6766C76.2289 59.341 76.6766 58.8933 76.6766 58.341V57.5978C76.6766 57.0455 76.2289 56.5978 75.6766 56.5978Z"
        fill="#D0D5D8"
      />
      <path
        d="M23.4059 63.6685H20.0499C15.7503 63.6685 12.2678 60.1861 12.2678 55.8865V52.5305C12.2678 48.2309 15.7503 44.7484 20.0499 44.7484H23.4059C27.7055 44.7484 31.188 48.2309 31.188 52.5305V55.8865C31.188 60.1861 27.7055 63.6685 23.4059 63.6685Z"
        fill="#D0D5D8"
      />
      <path
        d="M131.364 109.999H9.24365C4.32148 109.999 0.333252 106.011 0.333252 101.089V86.5557C0.333252 81.6335 4.32148 77.6451 9.24365 77.6451H131.364C136.286 77.6451 140.275 81.6335 140.275 86.5557V101.089C140.275 106.011 136.286 109.999 131.364 109.999Z"
        fill="#EBF0F2"
      />
      <path
        d="M127.34 88.6975H40.5757C40.0234 88.6975 39.5757 89.1452 39.5757 89.6975V90.4407C39.5757 90.993 40.0234 91.4407 40.5757 91.4407H127.34C127.892 91.4407 128.34 90.993 128.34 90.4407V89.6975C128.34 89.1452 127.892 88.6975 127.34 88.6975Z"
        fill="#D0D5D8"
      />
      <path
        d="M75.6766 96.2062H40.5757C40.0234 96.2062 39.5757 96.6539 39.5757 97.2062V97.9494C39.5757 98.5017 40.0234 98.9494 40.5757 98.9494H75.6766C76.2289 98.9494 76.6766 98.5017 76.6766 97.9494V97.2062C76.6766 96.6539 76.2289 96.2062 75.6766 96.2062Z"
        fill="#D0D5D8"
      />
      <path
        d="M23.4059 103.28H20.0499C15.7503 103.28 12.2678 99.7975 12.2678 95.4979V92.1418C12.2678 87.8422 15.7503 84.3597 20.0499 84.3597H23.4059C27.7055 84.3597 31.188 87.8422 31.188 92.1418V95.4979C31.188 99.7975 27.7055 103.28 23.4059 103.28Z"
        fill="#D0D5D8"
      />
    </svg>
  );
};

export default NoInfoImage;
