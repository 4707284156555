import React from "react";

const MindsatIcon = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      width={width || "254"}
      height="48"
      viewBox="0 0 254 48"
      fill="none"
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_205)">
        <path
          d="M84.104 46.4H79.1652V32.1984C79.1652 30.4363 78.8297 29.1243 78.1588 28.2624C77.4879 27.4005 76.4418 26.9632 75.0206 26.9504C73.1236 26.9504 71.7302 27.568 70.8407 28.8032C69.9511 30.0384 69.5116 32.08 69.5224 34.928V46.4H64.6029V23.36C64.6029 23.36 66.7282 23.4304 67.8182 24.4576C68.3788 25.0069 68.8276 25.6589 69.1397 26.3776H69.3938C70.0431 25.2717 71.0124 24.3862 72.175 23.8368C73.4348 23.2319 74.8181 22.9252 76.2167 22.9408C79.7772 22.9408 82.1351 24.1493 83.2905 26.5664H83.6281C84.3189 25.4217 85.3225 24.4961 86.5219 23.8976C87.8468 23.2399 89.3117 22.9106 90.7919 22.9376C93.5571 22.9376 95.5667 23.632 96.8207 25.0208C98.0747 26.4096 98.7028 28.5323 98.7049 31.3888V46.4H93.7854V32.1984C93.7854 30.4363 93.4435 29.1243 92.7597 28.2624C92.0759 27.4005 91.0288 26.9632 89.6183 26.9504C87.7084 26.9504 86.3065 27.5477 85.4126 28.7424C84.5188 29.9371 84.0783 31.7504 84.0911 34.1824L84.104 46.4Z"
          fill="#4D4C4D"
        />
        <path
          d="M109.637 46.4H104.718V23.36C104.718 23.36 106.811 23.1712 108.232 24.4576C109.444 25.5616 109.637 27.3376 109.637 27.3376V46.4ZM104.425 17.2512C104.401 16.8841 104.452 16.516 104.575 16.1691C104.697 15.8221 104.889 15.5033 105.139 15.232C105.42 14.9836 105.747 14.7932 106.103 14.6718C106.458 14.5504 106.835 14.5004 107.21 14.5248C107.582 14.4993 107.957 14.5503 108.309 14.6748C108.661 14.7993 108.984 14.9945 109.257 15.2484C109.531 15.5023 109.748 15.8094 109.897 16.1506C110.046 16.4919 110.123 16.8599 110.123 17.232C110.123 17.604 110.046 17.9721 109.897 18.3133C109.748 18.6546 109.531 18.9617 109.257 19.2156C108.984 19.4695 108.661 19.6647 108.309 19.7892C107.957 19.9137 107.582 19.9647 107.21 19.9392C106.835 19.9636 106.458 19.9136 106.103 19.7922C105.747 19.6708 105.42 19.4804 105.139 19.232C104.896 18.9663 104.708 18.6553 104.587 18.3169C104.465 17.9786 104.413 17.6196 104.431 17.2608L104.425 17.2512Z"
          fill="#4D4C4D"
        />
        <path
          d="M136.209 46.4H131.27V32.24C131.27 30.4651 130.907 29.1392 130.18 28.2624C129.453 27.3856 128.316 26.9483 126.768 26.9504C124.687 26.9504 123.166 27.5616 122.206 28.784C121.246 30.0064 120.764 32.0544 120.762 34.928V46.4H115.843V23.36C115.843 23.36 117.814 23.36 118.955 24.32C119.587 24.8908 120.076 25.601 120.383 26.3936H120.637C121.357 25.2762 122.39 24.393 123.608 23.8528C124.947 23.2431 126.405 22.9371 127.878 22.9568C133.434 22.9568 136.211 25.7696 136.209 31.3952V46.4Z"
          fill="#4D4C4D"
        />
        <path
          d="M150.006 46.8256C147.119 46.8256 144.868 45.7835 143.254 43.6992C141.64 41.6149 140.83 38.6912 140.826 34.928C140.826 31.152 141.643 28.2144 143.276 26.1152C144.91 24.016 147.184 22.9611 150.099 22.9504C153.141 22.9504 155.458 24.0683 157.051 26.304H157.301C157.092 25.0123 156.969 23.708 156.935 22.4V13.9904H161.874V46.4C161.874 46.4 159.433 46.6272 158.305 45.6352C157.382 44.8256 157.166 43.3952 157.166 43.3952H156.935C155.357 45.6821 153.048 46.8256 150.006 46.8256ZM151.324 42.8672C153.346 42.8672 154.818 42.304 155.742 41.1776C156.666 40.0512 157.141 38.2112 157.166 35.6576V34.9696C157.166 32.0683 156.692 30.0064 155.742 28.784C154.792 27.5616 153.306 26.9504 151.282 26.9504C150.489 26.9189 149.702 27.0969 149 27.4661C148.298 27.8353 147.706 28.3827 147.286 29.0528C146.349 30.4416 145.881 32.4277 145.881 35.0112C145.881 37.5947 146.335 39.5467 147.244 40.8672C148.151 42.2091 149.511 42.88 151.324 42.88V42.8672Z"
          fill="#4D4C4D"
        />
        <path
          d="M166.684 35.7408V31.9328H177.442V35.7408H166.684Z"
          fill="#4D4C4D"
        />
        <path
          d="M224.313 46.4L222.094 39.1488H210.937L208.718 46.4H201.728L212.528 15.824H220.464L231.306 46.4H224.313ZM220.547 33.7312C218.492 27.1605 217.333 23.4453 217.072 22.5856C216.821 21.7248 216.638 21.0432 216.528 20.544C216.07 22.3232 214.75 26.7189 212.57 33.7312H220.547Z"
          fill="#4D4C4D"
        />
        <path
          d="M245.688 46.4H239.216V21.3248H230.888V15.9488H254.006V21.3248H245.695L245.688 46.4Z"
          fill="#4D4C4D"
        />
        <path
          d="M199.644 37.9008C199.644 40.6869 198.634 42.8821 196.612 44.4864C194.591 46.0907 191.792 46.8928 188.214 46.8928C184.904 46.8928 181.977 46.2741 179.433 45.0368V38.9568C181.146 39.7469 182.919 40.4028 184.735 40.9184C186.034 41.2766 187.374 41.468 188.722 41.488C190.167 41.488 191.27 41.2139 192.031 40.6656C192.417 40.3892 192.727 40.0196 192.931 39.5915C193.136 39.1633 193.227 38.6906 193.198 38.2176C193.206 37.6437 193.028 37.0825 192.69 36.6176C192.277 36.0707 191.766 35.6052 191.182 35.2448C189.89 34.4679 188.552 33.77 187.175 33.1552C185.278 32.2699 183.857 31.4165 182.912 30.5952C181.981 29.7976 181.21 28.8312 180.642 27.7472C180.054 26.567 179.763 25.2625 179.793 23.9456C179.793 21.2149 180.72 19.0699 182.574 17.5104C184.428 15.9509 187.001 15.1701 190.291 15.168C191.842 15.16 193.387 15.3515 194.889 15.7376C196.463 16.1512 198.003 16.6861 199.493 17.3376L197.371 22.4256C196.036 21.856 194.662 21.3833 193.259 21.0112C192.218 20.7466 191.149 20.6112 190.076 20.608C188.834 20.608 187.881 20.8971 187.214 21.4752C186.701 21.9452 186.363 22.5742 186.255 23.2599C186.147 23.9456 186.275 24.6475 186.619 25.2512C186.964 25.7453 187.4 26.169 187.905 26.4992C189.259 27.2901 190.654 28.0079 192.085 28.6496C194.985 30.0299 196.973 31.4165 198.046 32.8096C199.12 34.2027 199.653 35.8997 199.644 37.9008Z"
          fill="#4D4C4D"
        />
        <path
          d="M52.825 22.8576H46.7158C46.1214 22.8355 45.5587 22.5849 45.1461 22.1585C44.7334 21.7322 44.5028 21.1632 44.5028 20.5712C44.5028 19.9792 44.7334 19.4102 45.1461 18.9839C45.5587 18.5575 46.1214 18.3069 46.7158 18.2848H52.825C53.4194 18.3069 53.9821 18.5575 54.3948 18.9839C54.8075 19.4102 55.038 19.9792 55.038 20.5712C55.038 21.1632 54.8075 21.7322 54.3948 22.1585C53.9821 22.5849 53.4194 22.8355 52.825 22.8576V22.8576Z"
          fill="#FF6A4A"
        />
        <path
          d="M43.2401 14.8736C42.7873 14.8697 42.3457 14.7329 41.9708 14.4802C41.5959 14.2276 41.3043 13.8703 41.1325 13.4534C40.9608 13.0364 40.9167 12.5782 41.0056 12.1364C41.0945 11.6945 41.3126 11.2886 41.6324 10.9696L45.9635 6.6592C46.1767 6.44699 46.4299 6.27865 46.7085 6.1638C46.9871 6.04895 47.2857 5.98984 47.5872 5.98984C47.8888 5.98984 48.1874 6.04895 48.466 6.1638C48.7446 6.27865 48.9977 6.44699 49.211 6.6592C49.4242 6.87142 49.5934 7.12336 49.7088 7.40063C49.8242 7.6779 49.8836 7.97508 49.8836 8.2752C49.8836 8.57532 49.8242 8.87251 49.7088 9.14978C49.5934 9.42705 49.4242 9.67899 49.211 9.89121L44.8799 14.2016C44.6651 14.4164 44.4095 14.5864 44.1279 14.7018C43.8464 14.8172 43.5445 14.8756 43.2401 14.8736V14.8736Z"
          fill="#FF6A4A"
        />
        <path
          d="M35.2145 10.6656C34.6057 10.6656 34.0217 10.4249 33.5912 9.99639C33.1607 9.56791 32.9188 8.98677 32.9188 8.3808V2.2848C32.941 1.69321 33.1928 1.13323 33.6212 0.722518C34.0496 0.311809 34.6213 0.0823517 35.2161 0.0823517C35.811 0.0823517 36.3827 0.311809 36.8111 0.722518C37.2395 1.13323 37.4913 1.69321 37.5135 2.2848V8.3808C37.5135 8.68111 37.454 8.97848 37.3385 9.2559C37.2229 9.53331 37.0535 9.78532 36.84 9.99753C36.6265 10.2097 36.373 10.378 36.0941 10.4926C35.8152 10.6072 35.5163 10.666 35.2145 10.6656Z"
          fill="#FF6A4A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.12524 14.4768C4.50351 14.4768 2.94795 15.1169 1.79971 16.2566C0.651461 17.3964 0.00425652 18.9428 0 20.5568V41.92C0.00425652 43.534 0.651461 45.0804 1.79971 46.2202C2.94795 47.3599 4.50351 48 6.12524 48H33.684C35.3058 48 36.8613 47.3599 38.0096 46.2202C39.1578 45.0804 39.805 43.534 39.8093 41.92V20.5728C39.805 18.9588 39.1578 17.4124 38.0096 16.2726C36.8613 15.1328 35.3058 14.4928 33.684 14.4928L6.12524 14.4768ZM9.01906 23.3152C8.77775 23.1351 8.50315 23.0041 8.21096 22.9297C7.91878 22.8552 7.61474 22.8388 7.31616 22.8812C7.01759 22.9237 6.73034 23.0243 6.47084 23.1773C6.21133 23.3303 5.98464 23.5326 5.80371 23.7728C5.62277 24.013 5.49114 24.2862 5.41633 24.577C5.34152 24.8678 5.32498 25.1704 5.36768 25.4676C5.41038 25.7647 5.51147 26.0506 5.66517 26.3088C5.81888 26.5671 6.02219 26.7927 6.2635 26.9728L18.5108 36.1152C18.9086 36.411 19.3919 36.5708 19.8886 36.5708C20.3852 36.5708 20.8685 36.411 21.2663 36.1152L33.5168 26.9728C33.7581 26.7927 33.9614 26.5671 34.1152 26.3088C34.2689 26.0506 34.37 25.7647 34.4126 25.4676C34.4553 25.1704 34.4388 24.8678 34.364 24.577C34.2892 24.2862 34.1576 24.013 33.9766 23.7728C33.7957 23.5326 33.569 23.3303 33.3095 23.1773C33.05 23.0243 32.7627 22.9237 32.4642 22.8812C32.1656 22.8388 31.8615 22.8552 31.5693 22.9297C31.2772 23.0041 31.0026 23.1351 30.7613 23.3152L19.8902 31.4272L9.01906 23.3152Z"
          fill="#FF6A4A"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_205">
          <rect width="254" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MindsatIcon;
