import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commonApi from "../api/commonApi";
import { MenuBean } from "../../types/Login";
import { setMenuList } from "../../shared/utils/auth";

interface CommonState {
  accessMenu: MenuBean[];
  configIds: number[];
}

// # initial state
const initialState: CommonState = {
  accessMenu: [],
  configIds: [],
};

export const trunkGetConfigIds = createAsyncThunk(
  "common/getConfigIds",
  async () => {
    const resp = await commonApi.getConfigIds();
    return resp;
  }
);
export const trunkGetMenu = createAsyncThunk(
  "common/getMenu",
  async (req: { accessType: number; managerCode: string }) => {
    const resp = await commonApi.getMenu(req);
    return resp;
  }
);
export const trunkGetManager = createAsyncThunk(
  "common/getManager",
  async (email: string) => {
    const resp = await commonApi.getManager(email);
    return resp;
  }
);
const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(trunkGetConfigIds.fulfilled, (state, { payload: data }) => {
      state.configIds = data;
    });
    builder.addCase(trunkGetMenu.fulfilled, (state, { payload: data }) => {
      setMenuList(JSON.stringify(data));
      state.accessMenu = data;
    });
  },
});
export const commonActions = commonSlice.actions;

export default commonSlice.reducer;
