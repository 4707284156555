import { useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  trunkGetInpercent,
  trunkGetInpercentConfig,
  trunkGetInpercentType,
  trunkGetTraineGraphList,
  trunkGetTraineGraphListConfig,
  trunkGetTraineGraphListType,
} from "../store/traineStatics";

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useProgramStore = () => {
  const trainestaticsState = useRootState((state) => state.trainestaticsStore);

  const dispatch = useAppDispatch();

  const boundGetTraineGraphList = useMemo(
    () => bindActionCreators(trunkGetTraineGraphList, dispatch),
    [dispatch]
  );
  const boundGetTraineGraphListType = useMemo(
    () => bindActionCreators(trunkGetTraineGraphListType, dispatch),
    [dispatch]
  );
  const boundGetTraineGraphListConfig = useMemo(
    () => bindActionCreators(trunkGetTraineGraphListConfig, dispatch),
    [dispatch]
  );
  const boundGetInpercent = useMemo(
    () => bindActionCreators(trunkGetInpercent, dispatch),
    [dispatch]
  );
  const boundGetInpercentType = useMemo(
    () => bindActionCreators(trunkGetInpercentType, dispatch),
    [dispatch]
  );
  const boundGetInpercentConfig = useMemo(
    () => bindActionCreators(trunkGetInpercentConfig, dispatch),
    [dispatch]
  );
  return {
    trainestaticsState,
    getTraineGraphList: boundGetTraineGraphList,
    getTraineGraphListType: boundGetTraineGraphListType,
    getTraineGraphListConfig: boundGetTraineGraphListConfig,
    getInpercent: boundGetInpercent,
    getInpercentType: boundGetInpercentType,
    getInpercentConfig: boundGetInpercentConfig,
  };
};

export default useProgramStore;
