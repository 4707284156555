const ManagerIcon = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      width={width || "21"}
      height={width || "21"}
      viewBox="0 0 21 20"
      fill="none"
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333496 10C0.333496 4.47715 4.81065 0 10.3335 0V0C15.8563 0 20.3335 4.47715 20.3335 10V10C20.3335 15.5228 15.8563 20 10.3335 20V20C4.81065 20 0.333496 15.5228 0.333496 10V10Z"
        fill="#F77E6E"
      />
      <path
        d="M10.3335 19.375C5.15583 19.375 0.958496 15.1777 0.958496 10C0.958496 4.82233 5.15583 0.625 10.3335 0.625C15.5112 0.625 19.7085 4.82233 19.7085 10C19.7085 15.1777 15.5112 19.375 10.3335 19.375Z"
        stroke="black"
        stroke-opacity="0.1"
        stroke-width="1.25"
      />
      <path
        d="M8.22134 9.14071L9.69444 6.19452C9.95758 5.66823 10.7086 5.66823 10.9718 6.19452L12.4449 9.14071C12.6457 9.54235 13.163 9.66114 13.5189 9.38735L15.3747 7.9598C15.9528 7.51511 16.7471 8.12109 16.471 8.79613L14.6234 13.3124C14.4038 13.8491 13.8815 14.1998 13.3016 14.1998H7.36459C6.7847 14.1998 6.26239 13.8491 6.04282 13.3124L4.19525 8.79613C3.91909 8.12109 4.7134 7.51511 5.29149 7.9598L7.14731 9.38735C7.50323 9.66114 8.02052 9.54235 8.22134 9.14071Z"
        fill="white"
      />
    </svg>
  );
};

export default ManagerIcon;
