import { useMemo } from "react";
import { useAppDispatch, useRootState } from ".";
import { bindActionCreators } from "redux";
import {
  trunkDeleteIp,
  trunkDownloadAccessLog,
  trunkDownloadFilteredLog,
  trunkGetIpList,
  trunkInsertIp,
  trunkIsExistsIp,
  trunkUpdateIp,
} from "../store/ip";

const useIpStore = () => {
  const ipState = useRootState((state) => state.ipStore);

  const dispatch = useAppDispatch();

  const boundGetIpList = useMemo(
    () => bindActionCreators(trunkGetIpList, dispatch),
    [dispatch]
  );

  const boundInsertIp = useMemo(
    () => bindActionCreators(trunkInsertIp, dispatch),
    [dispatch]
  );

  const boundDeleteIp = useMemo(
    () => bindActionCreators(trunkDeleteIp, dispatch),
    [dispatch]
  );
  const boundUpdateIp = useMemo(
    () => bindActionCreators(trunkUpdateIp, dispatch),
    [dispatch]
  );
  const boundDownloadFilteredLog = useMemo(
    () => bindActionCreators(trunkDownloadFilteredLog, dispatch),
    [dispatch]
  );
  const boundDownloadAccessLog = useMemo(
    () => bindActionCreators(trunkDownloadAccessLog, dispatch),
    [dispatch]
  );
  const boundIsExistsIp = useMemo(
    () => bindActionCreators(trunkIsExistsIp, dispatch),
    [dispatch]
  );

  return {
    ipState,
    getIpList: boundGetIpList,
    insertIp: boundInsertIp,
    deleteIp: boundDeleteIp,
    updateIp: boundUpdateIp,
    downloadFilteredLog: boundDownloadFilteredLog,
    donwloadAccessLog: boundDownloadAccessLog,
    isExistsIp: boundIsExistsIp,
  };
};

export default useIpStore;
