import { useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  downloadAttachment,
  getReportAttachments,
  sendManualReply,
  trunkGetReportMailCnt,
  trunkGetReportMailDetail,
  trunkGetReportMails,
  trunkUpdateReportMail,
  updateComment,
  updateComplete,
  updateManualReplyState,
  updateSpamFlag,
} from "../store/report";

const useReportStore = () => {
  const reportState = useRootState((state) => state.reportStore);

  const dispatch = useAppDispatch();

  const boundGetReportMails = useMemo(
    () => bindActionCreators(trunkGetReportMails, dispatch),
    [dispatch]
  );

  const boundGetReportMailCnt = useMemo(
    () => bindActionCreators(trunkGetReportMailCnt, dispatch),
    [dispatch]
  );

  const boundGetReportMailDetail = useMemo(
    () => bindActionCreators(trunkGetReportMailDetail, dispatch),
    [dispatch]
  );

  const boundUpdateSpmaFlag = useMemo(
    () => bindActionCreators(updateSpamFlag, dispatch),
    [dispatch]
  );

  const boundUpdateComment = useMemo(
    () => bindActionCreators(updateComment, dispatch),
    [dispatch]
  );

  const boundUpdateComplete = useMemo(
    () => bindActionCreators(updateComplete, dispatch),
    [dispatch]
  );

  const boundUpdateManualReplyState = useMemo(
    () => bindActionCreators(updateManualReplyState, dispatch),
    [dispatch]
  );

  const boundSendManualReply = useMemo(
    () => bindActionCreators(sendManualReply, dispatch),
    [dispatch]
  );

  const boundGetReportAttachments = useMemo(
    () => bindActionCreators(getReportAttachments, dispatch),
    [dispatch]
  );

  const boundDownloadAtachment = useMemo(
    () => bindActionCreators(downloadAttachment, dispatch),
    [dispatch]
  );
  const boundUpdateReportMail = useMemo(
    () => bindActionCreators(trunkUpdateReportMail, dispatch),
    [dispatch]
  );

  //   const boudGetReportMailCnt(trunk)

  return {
    reportState,
    getReportMails: boundGetReportMails,
    getReportMailCnt: boundGetReportMailCnt,
    getReportMailDetail: boundGetReportMailDetail,
    updateSpamFlag: boundUpdateSpmaFlag,
    updateComment: boundUpdateComment,
    updateComplete: boundUpdateComplete,
    updateManualReplyState: boundUpdateManualReplyState,
    sendManualReply: boundSendManualReply,
    getReportAttachments: boundGetReportAttachments,
    downloadAttachment: boundDownloadAtachment,
    updateReportMail: boundUpdateReportMail,
  };
};

export default useReportStore;
