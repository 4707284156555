import "./index.scss";
import { FilterTag } from "fasoo-ui-component-next";
import { ReactNode } from "react";

interface CategoryTagProps {
  endIcon?: ReactNode;
  onClick?: () => void;
  key: string | number;
  text?: string;
}

const CategoryTag: React.FC<CategoryTagProps> = ({
  endIcon,
  key,
  onClick,
  text,
}) => {
  return (
    <FilterTag
      endIcon={endIcon}
      key={key}
      text={text}
      classes="category__tag"
      onClick={onClick}
    />
  );
};

export default CategoryTag;
