import { useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  trunkGetDashboardReport,
  trunkGetPhishTypes,
  trunkGetRecentClickHistoryList,
  trunkGetReportHistory,
  trunkGetSendHistory,
  trunkGetStatTopTraining,
  trunkGetStatTraining,
  trunketProgressList,
} from "../store/dashboard";

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useDashboardStore = () => {
  const dashboardState = useRootState((state) => state.dashboardStore);

  const dispatch = useAppDispatch();

  const boundGetStatTraining = useMemo(
    () => bindActionCreators(trunkGetStatTraining, dispatch),
    [dispatch]
  );

  const boundGetStatTopTraining = useMemo(
    () => bindActionCreators(trunkGetStatTopTraining, dispatch),
    [dispatch]
  );

  const boundRecentClickHistoryList = useMemo(
    () => bindActionCreators(trunkGetRecentClickHistoryList, dispatch),
    [dispatch]
  );
  const boundGetProgressList = useMemo(
    () => bindActionCreators(trunketProgressList, dispatch),
    [dispatch]
  );
  const boundGetDashboardReport = useMemo(
    () => bindActionCreators(trunkGetDashboardReport, dispatch),
    [dispatch]
  );
  const boundGetSendHistory = useMemo(
    () => bindActionCreators(trunkGetSendHistory, dispatch),
    [dispatch]
  );
  const boundGetPhishTypes = useMemo(
    () => bindActionCreators(trunkGetPhishTypes, dispatch),
    [dispatch]
  );
  const boundGetReportHistory = useMemo(
    () => bindActionCreators(trunkGetReportHistory, dispatch),
    [dispatch]
  );
  return {
    dashboardState,
    getStatTopTraining: boundGetStatTopTraining,
    getStatTraining: boundGetStatTraining,
    getRecentClickHistoryList: boundRecentClickHistoryList,
    getProgressList: boundGetProgressList,
    getDashboardReport: boundGetDashboardReport,
    getSendHistoryList: boundGetSendHistory,
    getPhishTypes: boundGetPhishTypes,
    getReportHistory: boundGetReportHistory,
  };
};

export default useDashboardStore;
