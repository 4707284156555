import { useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  trunkGetConfigInfo,
  trunkGetConfigList,
  trunkGetTrainConfigInfo,
} from "../store/config";

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useConfigStore = () => {
  const configState = useRootState((state) => state.configStore);

  const dispatch = useAppDispatch();

  const boundGetConfigInfo = useMemo(
    () => bindActionCreators(trunkGetConfigInfo, dispatch),
    [dispatch]
  );
  const boundGetTrainConfigInfo = useMemo(
    () => bindActionCreators(trunkGetTrainConfigInfo, dispatch),
    [dispatch]
  );
  const boundGetConfigList = useMemo(
    () => bindActionCreators(trunkGetConfigList, dispatch),
    [dispatch]
  );
  return {
    configState,
    getConfigInfo: boundGetConfigInfo,
    getTrainConfigInfo: boundGetTrainConfigInfo,
    getConfigList: boundGetConfigList,
  };
};

export default useConfigStore;
