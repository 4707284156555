import { useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  trunkGetConfigIds,
  trunkGetManager,
  trunkGetMenu,
} from "../store/common";

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useCommonStore = () => {
  const commonState = useRootState((state) => state.commonStore);

  const dispatch = useAppDispatch();

  const boundGetConfigIds = useMemo(
    () => bindActionCreators(trunkGetConfigIds, dispatch),
    [dispatch]
  );

  const boundGetMenu = useMemo(
    () => bindActionCreators(trunkGetMenu, dispatch),
    [dispatch]
  );
  const boundGetManager = useMemo(
    () => bindActionCreators(trunkGetManager, dispatch),
    [dispatch]
  );
  return {
    commonState,
    getConfigIds: boundGetConfigIds,
    getMenu: boundGetMenu,
    getManager: boundGetManager,
  };
};

export default useCommonStore;
