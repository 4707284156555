import config from "../../shared/config/config";
import {
  removeAccessToken,
  removeLocalCustomerCode,
  removeUserInfoFromLocalStorage,
} from "../../shared/utils/auth";
import axios from "../../shared/utils/axios";
import { BaseResponseBean, BaseResponseDTO } from "../../types/Common";
import { AccountResDTO } from "../../types/Login";

const GATEWAY_URL = config.servers.gateway.url;
interface AccountApi {
  login(email: string, pwd: string): Promise<BaseResponseDTO<AccountResDTO>>;
  logout(): Promise<any>;
  initPwd(req: { email: string; name: string }): Promise<BaseResponseBean<any>>;
  changePwd(req: {
    email: string;
    pwd: string;
    newPwd: string;
  }): Promise<BaseResponseBean<any>>;
  sessionCheck(): Promise<void>;
}

class AccountServerApi implements AccountApi {
  async login(
    email: string,
    pwd: string
  ): Promise<BaseResponseDTO<AccountResDTO>> {
    removeAccessToken();
    const res = await axios.post<BaseResponseDTO<AccountResDTO>>(
      `${GATEWAY_URL}/api/v2/account/login`,
      {
        email,
        pwd,
      }
    );
    return Promise.resolve(res.data);
  }
  async logout(): Promise<any> {
    const res = await axios.post<any>(`${GATEWAY_URL}/api/v2/account/logout`);
    return Promise.resolve(res.data);
  }

  async isMatchAccount(req: {
    email: string;
    pwd: string;
  }): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/account/match", null, { params: req });

    return Promise.resolve(res.data);
  }
  async initPwd(req: {
    email: string;
    name: string;
  }): Promise<BaseResponseBean<any>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/account/reset`, req);
    return Promise.resolve(res.data);
  }
  async changePwd(req: {
    email: string;
    pwd: string;
    newPwd: string;
  }): Promise<BaseResponseBean<any>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/account/change`, req);
    return Promise.resolve(res.data);
  }

  async sessionCheck(): Promise<any> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/account/check`,
      null,
      {}
    );

    if (res.data && res.data.code !== "SUCCESS") {
      removeAccessToken();
      removeUserInfoFromLocalStorage();
      removeLocalCustomerCode();
    }

    return res;
  }
}

const accountApi: AccountApi = new AccountServerApi();

export default accountApi;
