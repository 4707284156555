import {
  Box,
  Button,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { ReportReply, ReportReplyUpdateBean } from "../../../types/Reply";
import { useEffect, useState } from "react";
import useReplyStore from "../../../redux/dispatcher/useReplyStore";
import { nanoid } from "@reduxjs/toolkit";
import WSEdit from "../../../component/WSEdit";
import { useTranslation } from "react-i18next";
interface RegitOrEditTemplateModalProps {
  isOpen: boolean;
  targetId?: number;
  type: "CLOSE" | "CREATE" | "EDIT";
  onClose: () => void;
}
const RegitOrEditTemplateModal: React.FC<RegitOrEditTemplateModalProps> = ({
  isOpen,
  type,
  targetId,
  onClose,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const { replyState, getReplys, insertReply, updateReply } = useReplyStore();
  const [body, changeBody] = useState("");
  const [targetReply, changeReply] = useState<ReportReplyUpdateBean>(
    type === "EDIT"
      ? replyState.replyListWithCnt?.list?.filter(
          (item: ReportReply) => item.replyId + "" === type
        )[0]
      : {
          autoYn: false,
          replyBody: "<p></p>",
          replyTitle: "",
          updateUser: "admin",
        }
  );
  useEffect(() => {
    changeReply(
      type === "EDIT"
        ? replyState?.replyListWithCnt?.list?.filter(
            (item: ReportReply) => item.replyId === targetId
          )[0]
        : {
            autoYn: false,
            replyBody: "",
            replyTitle: "",
            updateUser: "admin",
          }
    );
    changeBody(
      type === "EDIT"
        ? replyState?.replyListWithCnt?.list?.filter(
            (item: ReportReply) => item.replyId === targetId
          )[0].replyBody
        : ""
    );
  }, [type, targetId]);
  const handleRegitOrEditTemplate = async () => {
    let resp: any =
      type === "CREATE"
        ? await insertReply({ ...targetReply, replyBody: body })
        : await updateReply({ ...targetReply, replyBody: body });
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        type === "CREATE"
          ? t("reply.msg.reportReplyAddSuccessMsg")
          : t("reply.msg.reportReplyUpdateSuccessMsg"),
        "success"
      );
      getReplys({});
    } else {
      toast.toastMsg(
        nanoid(),
        type === "CREATE" ? "추가하지 못했습니다." : "수정하지 못했습니다.",
        "error"
      );
    }
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      isDivider={false}
      onClose={onClose}
      title={
        type === "CREATE"
          ? t("reply.reportReplyAdd")
          : t("reply.reportReplyUpdate")
      }
      footer={
        <Box justifyContent="flex-end">
          <Button color={"secondary"} onClick={onClose}>
            {t("common.button.cancel")}
          </Button>
          <Button
            onClick={() => handleRegitOrEditTemplate()}
            classes="mg ml-10"
            disabled={!targetReply?.replyTitle}
          >
            {type === "CREATE"
              ? t("common.button.add")
              : t("common.button.update")}
          </Button>
        </Box>
      }
    >
      <Box direction="column" width={"100%"}>
        <Box justifyContent="space-between">
          <Box direction="column" width={"48%"}>
            <Typography isBold>{t("reply.reportTemplateName")}</Typography>
            <TextField
              classes="mg mt-12"
              size={"lg"}
              text={targetReply?.replyTitle}
              onChange={(e) =>
                changeReply({ ...targetReply, replyTitle: e.target.value })
              }
              hasText
            />
          </Box>
          <Box direction="column" width={"48%"}>
            <Typography isBold>{t("reply.reportReplyType")}</Typography>
            <Box classes="mg mt-12" height={"40px"} alignItems="center">
              <RadioGroup
                onChange={() =>
                  changeReply({ ...targetReply, autoYn: !targetReply?.autoYn })
                }
              >
                <Radio
                  label={t("reply.reportReplyAuto")}
                  checked={targetReply?.autoYn}
                  disabled={
                    type == "EDIT" &&
                    targetReply &&
                    targetReply.replyTargetType !== 0
                      ? true
                      : false
                  }
                />
                <Radio
                  label={t("reply.reportReplyManual")}
                  checked={!targetReply?.autoYn}
                  disabled={
                    type == "EDIT" &&
                    targetReply &&
                    targetReply.replyTargetType !== 0
                      ? true
                      : false
                  }
                />
              </RadioGroup>
            </Box>
          </Box>
        </Box>
        <Box classes="mg mt-20">
          <WSEdit
            initBody={targetReply?.replyBody}
            isShowButtons={false}
            onChange={changeBody}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default RegitOrEditTemplateModal;
