import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import managerApi from "../api/managerApi";
import { ManagerBean, ManagerRequestBean } from "../../types/Manage";

interface ManagerState {
  managerList: ManagerBean[];
}

const initialState: ManagerState = { managerList: [] };

export const trunkGetManagerList = createAsyncThunk(
  "manager/list",
  async (managerName?: string) => {
    const resp = await managerApi.getManagerList(managerName);
    return resp;
  }
);
export const trunkGetCommonMangerList = createAsyncThunk(
  "manager/list/common",
  async (customerCode: string) => {
    const resp = await managerApi.getCommonMangerList(customerCode);
    return resp;
  }
);
export const trunkAddNewManager = createAsyncThunk(
  "manager/add",
  async (req: ManagerRequestBean) => {
    const resp = await managerApi.addNewManager(req);
    return resp;
  }
);
export const trunkUpdateManagerInfo = createAsyncThunk(
  "manager/update",
  async (req: ManagerRequestBean) => {
    const resp = await managerApi.updateManagerInfo(req);
    return resp;
  }
);
export const trunkDeleteManager = createAsyncThunk(
  "manager/delete",
  async (ids: string[]) => {
    const resp = await managerApi.deleteManager(ids);
    return resp;
  }
);

const managerSlice = createSlice({
  name: "manager",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkGetManagerList.fulfilled,
      (state, { payload: data }) => {
        state.managerList = data.data;
      }
    );
  },
});

export const managerActions = managerSlice.actions;

export default managerSlice.reducer;
