import { useCallback, useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  trunkAddCategory,
  trunkDeleteCategory,
  trunkGetCategories,
  trunkUpdateCategory,
} from "../store/category";

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useCategoryStore = () => {
  const categoryState = useRootState((state) => state.categoryStore);

  const dispatch = useAppDispatch();
  const boundGetCategories = useMemo(
    () => bindActionCreators(trunkGetCategories, dispatch),
    [dispatch]
  );
  const boundAddCategory = useMemo(
    () => bindActionCreators(trunkAddCategory, dispatch),
    [dispatch]
  );
  const boundUpdateCategory = useMemo(
    () => bindActionCreators(trunkUpdateCategory, dispatch),
    [dispatch]
  );
  const boundDeleteCategory = useMemo(
    () => bindActionCreators(trunkDeleteCategory, dispatch),
    [dispatch]
  );
  return {
    categoryState,
    getCategories: boundGetCategories,
    addCategory: boundAddCategory,
    updateCategory: boundUpdateCategory,
    deleteCategory: boundDeleteCategory,
  };
};

export default useCategoryStore;
