import axios from "../../shared/utils/axios";
import { MailTemplate } from "../../types/Info";
import { MenuBean } from "../../types/Login";
import { UserBean } from "../../types/Info";

interface CommonApi {
  getConfigIds(): Promise<number[]>;
  getMailTemplateList(): Promise<MailTemplate>;
  getMenu(req: {
    accessType: number;
    managerCode: string;
  }): Promise<MenuBean[]>;
  getManager(email: string): Promise<UserBean>;
}
class CommonServerApi implements CommonApi {
  async getConfigIds(): Promise<number[]> {
    const res = await axios.post<number[]>(`/getConfigIds`, null);
    return Promise.resolve(res.data);
  }
  async getMailTemplateList(): Promise<MailTemplate> {
    const res = await axios.post<MailTemplate>(`/getMailTemplateList`, {});
    return Promise.resolve(res.data);
  }
  async getMenu(req: {
    accessType: number;
    managerCode: string;
  }): Promise<MenuBean[]> {
    const res = await axios.get<MenuBean[]>("/menu", {
      params: { accessType: req.accessType, managerCode: req.managerCode },
    });
    return Promise.resolve(res.data);
  }
  async getManager(email: string): Promise<UserBean> {
    const res = await axios.get<UserBean>(`/manager?managerEmail=${email}`);
    return Promise.resolve(res.data);
  }
}

const commonApi: CommonApi = new CommonServerApi();

export default commonApi;
