import axios from "../../shared/utils/axios";
import { TimeRequest } from "../../types/Common";
import { DateBean } from "../../types/Report";
import { TraineGraph } from "../../types/Send";

interface TraineStaticsApi {
  getTraineGraphList(req: TimeRequest): Promise<TraineGraph[]>;
  getTraineGraphListType(req: DateBean): Promise<TraineGraph[]>;
  getTraineGraphListConfig(ids: number[]): Promise<TraineGraph[]>;
  getInpercent(req: TimeRequest): Promise<number>;
  getInpercentType(req: DateBean): Promise<number>;
  getInpercentConfig(ids: number[]): Promise<number>;
}
class TraineStaticsServerApi implements TraineStaticsApi {
  async getTraineGraphList(req: TimeRequest): Promise<TraineGraph[]> {
    const res = await axios.post<TraineGraph[]>(
      `/traineStatistics/getTraineGraphList?startDate=${req.startDate}&endDate=${req.endDate}`
    );
    return Promise.resolve(res.data);
  }
  async getTraineGraphListType(req: DateBean): Promise<TraineGraph[]> {
    const res = await axios.post<TraineGraph[]>(
      `/traineStatistics/getTraineGraphList/type?startDate=${req.startDate}&endDate=${req.endDate}&realTrainingFlag=${req.realTrainingFlag}`
    );
    return Promise.resolve(res.data);
  }
  async getTraineGraphListConfig(ids: number[]): Promise<TraineGraph[]> {
    const res = await axios.post<TraineGraph[]>(
      `/traineStatistics/getTraineGraphList/configs`,
      ids
    );
    return Promise.resolve(res.data);
  }
  async getInpercent(req: TimeRequest): Promise<number> {
    const res = await axios.post<number>(
      `/traineStatistics/inpercent?startDate=${req.startDate}&endDate=${req.endDate}`
    );
    return Promise.resolve(res.data);
  }
  async getInpercentType(req: DateBean): Promise<number> {
    const res = await axios.post<number>(
      `/traineStatistics/inpercent/type?startDate=${req.startDate}&endDate=${req.endDate}&realTrainingFlag=${req.realTrainingFlag}`
    );
    return Promise.resolve(res.data);
  }
  async getInpercentConfig(ids: number[]): Promise<number> {
    const res = await axios.post<number>(
      `/traineStatistics/inpercent/configs`,
      ids
    );
    return Promise.resolve(res.data);
  }
}

const trainestaticsApi: TraineStaticsApi = new TraineStaticsServerApi();

export default trainestaticsApi;
