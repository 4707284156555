import { Box, Icons, Typography } from "fasoo-ui-component-next";
interface TitlePathProps {
  path: string[];
}
const TitlePath: React.FC<TitlePathProps> = ({ path }) => {
  return (
    <Box alignItems="center">
      {path.map((item, index) =>
        index < path.length - 1 ? (
          <>
            <Typography
              size={14}
              fontWeight={700}
              exactColor="#b5bbc2"
              lineHeight="20px"
            >
              {item}
            </Typography>
            <Icons
              variant="arrow"
              label="rightBigger"
              width={20}
              height={20}
              stroke="#b5bbc2"
            />
          </>
        ) : (
          <Typography
            size={14}
            fontWeight={700}
            exactColor="#b5bbc2"
            lineHeight="20px"
          >
            {item}
          </Typography>
        )
      )}
    </Box>
  );
};

export default TitlePath;
