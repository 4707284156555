import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import reportStatApi from "../api/reportStatApi";
import {
  DateBean,
  ReportStatisticsBean,
  StatisticsDetailBean,
} from "../../types/ReportStat";

interface ReportState {
  reportStats?: ReportStatisticsBean;
  reportStatDetail?: StatisticsDetailBean[];
}

const initialState: ReportState = {
  reportStats: undefined,
  reportStatDetail: undefined,
};

export const trunkGetReportStatistics = createAsyncThunk(
  "reportStat/getStatistics",
  async (config_ids: number[]) => {
    const resp = await reportStatApi.getReportStat(config_ids);

    return resp;
  }
);

export const trunkGetReportStatByPeriod = createAsyncThunk(
  "reportStat/getReportStatByPeriod",
  async (dateBean: DateBean) => {
    const resp = await reportStatApi.getReportStatByPeriod(dateBean);

    return resp;
  }
);

export const trunkGetReportStatDetailPaging = createAsyncThunk(
  "reportStat/getReportStatDetailPaging",
  async (req: { configId: number; pageNum: number; pageRow: number }) => {
    const resp = await reportStatApi.getReportStatDetailPaging(req);

    return resp;
  }
);

export const trunkGetReportStatDetailByPeriod = createAsyncThunk(
  "reportStat/getReportStatDetailByPeriod",
  async (req: {
    month: number;
    startConfig: number;
    endConfig: number;
    realTrainingFlag: boolean | null;
  }) => {
    const resp = await reportStatApi.getReportStatDetailByPeriod(req);

    return resp;
  }
);

export const trunkGetReportStatDetailByPeriodType = createAsyncThunk(
  "reportStat/getReportStatDetailByPeriodType",
  async (req: {
    month: number;
    startConfig: number;
    endConfig: number;
    realTrainingFlag: boolean | null;
  }) => {
    const resp = await reportStatApi.getReportStatDetailByPeriodType(req);

    return resp;
  }
);

export const trunkGetConfigsByDate = createAsyncThunk(
  "reportStat/getConfigsByDate",
  async (dateBean: DateBean) => {
    const resp = await reportStatApi.getConfigsByDate(dateBean);

    return resp;
  }
);

const reportStatSlice = createSlice({
  name: "reportStat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkGetReportStatistics.fulfilled,
      (state, { payload: data }) => {
        state.reportStats = data;
      }
    );
    builder.addCase(
      trunkGetReportStatByPeriod.fulfilled,
      (state, { payload: data }) => {
        state.reportStats = data;
      }
    );
    builder.addCase(
      trunkGetReportStatDetailPaging.fulfilled,
      (state, { payload: data }) => {
        state.reportStatDetail = data;
      }
    );
    builder.addCase(
      trunkGetReportStatDetailByPeriod.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkGetReportStatDetailByPeriodType.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkGetConfigsByDate.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
  },
});

export const reportStatActions = reportStatSlice.actions;

export default reportStatSlice.reducer;
