import React, { useEffect } from "react";
import classNames from "classnames";

import "./index.scss";

const Progress: React.FC = () => {
  return (
    <div className={classNames("progress-root")}>
      <div className="loadingio-spinner-spin-3p9ce1185f9">
        <div className="ldio-j3o061p6lyr">
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
