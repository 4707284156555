import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userApi from "../api/userApi";
import { UserEncType, UserType } from "../../types/User";

interface UserState {}

const initialState: UserState = {};

export const trunkGetUsers = createAsyncThunk("user/getUser", async () => {
  const resp = await userApi.getUsers();

  return resp;
});

export const updateUserActivateFlag = createAsyncThunk(
  "user/updateUserActivateFlag",
  async (userIds: string[]) => {
    const resp = await userApi.updateUserActivateFlag(userIds);

    return resp;
  }
);

export const updateUserDeactivateFlag = createAsyncThunk(
  "user/updateUserDeactivateFlag",
  async (userIds: string[]) => {
    const resp = await userApi.updateUserDeactivateFlag(userIds);

    return resp;
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (userIds: string[]) => {
    const resp = await userApi.deleteUser(userIds);

    return resp;
  }
);

export const uploadCsv = createAsyncThunk(
  "user/uploadCsv",
  async (formData: FormData) => {
    const resp = await userApi.uploadCsv(formData);

    return resp;
  }
);

export const insertCsv = createAsyncThunk(
  "user/insertCsv",
  async (users: UserType[]) => {
    const resp = await userApi.insertCsv(users);

    return resp;
  }
);

export const trunkSetUser = createAsyncThunk(
  "user/setUser",
  async (user: UserType) => {
    const resp = await userApi.setUser(user);

    return resp;
  }
);

export const decryptUser = createAsyncThunk(
  "user/decryptUser",
  async (user: UserEncType) => {
    const resp = await userApi.decryptUser(user);

    return resp;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(trunkGetUsers.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });

    builder.addCase(
      updateUserActivateFlag.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );

    builder.addCase(
      updateUserDeactivateFlag.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );

    builder.addCase(deleteUser.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });

    builder.addCase(uploadCsv.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });

    builder.addCase(insertCsv.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });

    builder.addCase(trunkSetUser.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });

    builder.addCase(decryptUser.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
