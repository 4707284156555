import { Box, Typography } from "fasoo-ui-component-next";
import TitlePath from "../../component/TitlePath";

const System: React.FC = () => {
  return (
    <Box classes="main system">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={["홈", "시스템 관리"]} />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            시스템 관리
          </Typography>
        </Box>
      </Box>
      <Box classes="mg mt-24">본문</Box>
    </Box>
  );
};

export default System;
