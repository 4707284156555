import axios from "../../shared/utils/axios";
import axiosForm from "../../shared/utils/axiosForm";
import { BaseResponseBean } from "../../types/Common";
import { CsvUserType, UserEncType, UserType } from "../../types/User";

interface UserApi {
  getUsers(): Promise<UserType[]>;
  updateUserActivateFlag(userIds: string[]): Promise<BaseResponseBean<any>>;
  updateUserDeactivateFlag(userIds: string[]): Promise<BaseResponseBean<any>>;
  deleteUser(userIds: string[]): Promise<BaseResponseBean<any>>;
  uploadCsv(formData: FormData): Promise<CsvUserType[]>;
  insertCsv(users: UserType[]): Promise<BaseResponseBean<any>>;
  setUser(user: UserType): Promise<BaseResponseBean<any>>;
  decryptUser(user: UserEncType): Promise<BaseResponseBean<any>>;
}

class UserServerApi implements UserApi {
  async getUsers(): Promise<UserType[]> {
    const res = await axios.post("/users");

    return Promise.resolve(res.data);
  }

  async updateUserActivateFlag(
    userIds: string[]
  ): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/users/update/active", userIds);

    return Promise.resolve(res.data);
  }

  async updateUserDeactivateFlag(
    userIds: string[]
  ): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/users/update/deactive", userIds);

    return Promise.resolve(res.data);
  }

  async deleteUser(userIds: string[]): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/users/delete", userIds);

    return Promise.resolve(res.data);
  }

  async uploadCsv(formData: FormData): Promise<CsvUserType[]> {
    const res = await axiosForm.post("/users/uploadCsv", formData);

    return Promise.resolve(res.data);
  }

  async insertCsv(users: UserType[]): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/users/insertCsv", users);
    return Promise.resolve(res.data);
  }

  async setUser(user: UserType): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/users/setUser", user);

    return Promise.resolve(res.data);
  }

  async decryptUser(user: UserEncType): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/decrypt/user", user);

    return Promise.resolve(res.data);
  }
}

const userApi: UserApi = new UserServerApi();

export default userApi;
