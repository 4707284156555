const ScenarioInfoImage = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      width={width || "288"}
      height="160"
      className={classes}
      viewBox="0 0 288 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="136" y="32" width="67.2" height="86.4" fill="#DDE1E7" />
      <circle cx="149.2" cy="43.5999" r="5.2" fill="#64D2D0" />
      <rect
        width="67.2"
        height="9.6"
        transform="matrix(1 0 1.04361e-06 -1 136 118.4)"
        fill="#DDE1E7"
      />
      <rect x="144" y="64.8" width="51.2" height="2.4" fill="#DDE1E7" />
      <rect x="144" y="57.6001" width="51.2" height="2.4" fill="#DDE1E7" />
      <rect x="144" y="88.8" width="51.2" height="2.4" fill="#DDE1E7" />
      <rect x="144" y="81.6001" width="51.2" height="2.4" fill="#DDE1E7" />
      <rect
        x="80.3883"
        y="19.2"
        width="8.8"
        height="8.8"
        transform="rotate(45 80.3883 19.2)"
        fill="#FFE0A3"
      />
      <rect x="126.4" y="22.3999" width="67.2" height="86.4" fill="#EBF0F2" />
      <path
        d="M152 39.1999C152 34.3398 148.06 30.3999 143.2 30.3999C138.34 30.3999 134.4 34.3398 134.4 39.1999C134.4 44.06 138.34 47.9999 143.2 47.9999C148.06 47.9999 152 44.06 152 39.1999Z"
        fill="#64D2D0"
      />
      <path
        d="M140.156 38.8021C141.018 38.8021 141.717 38.1033 141.717 37.2412C141.717 36.379 141.018 35.6802 140.156 35.6802C139.294 35.6802 138.595 36.379 138.595 37.2412C138.595 38.1033 139.294 38.8021 140.156 38.8021Z"
        fill="white"
      />
      <path
        d="M142.072 42.6265H138.258C138.088 42.6265 137.919 42.5928 137.761 42.5274C137.604 42.462 137.461 42.3661 137.34 42.2452C137.219 42.1244 137.124 41.9809 137.059 41.823C136.993 41.665 136.96 41.4958 136.96 41.3249C136.96 40.8628 137.143 40.4196 137.469 40.0929C137.795 39.7661 138.237 39.5826 138.698 39.5826H141.622C142.083 39.5826 142.525 39.7661 142.851 40.0929C143.177 40.4196 143.36 40.8628 143.36 41.3249C143.36 41.4958 143.326 41.665 143.261 41.823C143.196 41.9809 143.1 42.1244 142.979 42.2452C142.859 42.3661 142.716 42.462 142.558 42.5274C142.401 42.5928 142.232 42.6265 142.061 42.6265H142.072Z"
        fill="white"
      />
      <path
        d="M144.64 38.7238L146.136 40.4L149.045 37.2"
        stroke="white"
        stroke-width="1.44"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect x="134.4" y="75.2" width="51.2" height="3.2" fill="#D0D5D8" />
      <rect x="134.4" y="84.8" width="51.2" height="3.2" fill="#D0D5D8" />
      <rect x="134.4" y="94.3999" width="51.2" height="3.2" fill="#D0D5D8" />
      <rect x="156.8" y="42.3999" width="17.6" height="3.2" fill="#D0D5D8" />
      <rect x="156.8" y="32.8" width="28.8" height="3.2" fill="#D0D5D8" />
      <rect
        x="223.159"
        y="110.4"
        width="5.6"
        height="5.6"
        transform="rotate(45 223.159 110.4)"
        fill="#FFE0A3"
      />
      <rect
        x="217.56"
        y="52"
        width="6.4"
        height="6.4"
        transform="rotate(45 217.56 52)"
        fill="#FFC34E"
      />
      <circle cx="230.4" cy="96" r="8" fill="#A6C7FF" />
      <path
        d="M229.587 97.3144C229.587 96.8044 229.649 96.3982 229.773 96.0959C229.897 95.7935 230.123 95.4967 230.451 95.2053C230.782 94.9102 231.002 94.6716 231.112 94.4895C231.221 94.3037 231.276 94.1088 231.276 93.9048C231.276 93.2892 230.992 92.9814 230.423 92.9814C230.154 92.9814 229.937 93.0652 229.773 93.2327C229.613 93.3967 229.529 93.6243 229.522 93.9157H227.937C227.944 93.22 228.168 92.6754 228.609 92.282C229.054 91.8886 229.658 91.6919 230.423 91.6919C231.196 91.6919 231.795 91.8795 232.221 92.2547C232.647 92.6262 232.86 93.1526 232.86 93.8338C232.86 94.1434 232.791 94.4367 232.653 94.7135C232.514 94.9867 232.272 95.2909 231.926 95.626L231.483 96.0467C231.206 96.3126 231.048 96.6241 231.008 96.9811L230.986 97.3144H229.587ZM229.429 98.9918C229.429 98.7478 229.511 98.5474 229.675 98.3908C229.842 98.2305 230.055 98.1504 230.314 98.1504C230.573 98.1504 230.784 98.2305 230.948 98.3908C231.115 98.5474 231.199 98.7478 231.199 98.9918C231.199 99.2322 231.117 99.4308 230.953 99.5874C230.793 99.744 230.58 99.8223 230.314 99.8223C230.048 99.8223 229.833 99.744 229.669 99.5874C229.509 99.4308 229.429 99.2322 229.429 98.9918Z"
        fill="white"
      />
      <circle cx="126.2" cy="133.4" r="7" fill="#FFE0A3" />
      <path
        d="M125.49 134.551C125.49 134.104 125.544 133.749 125.652 133.484C125.761 133.22 125.958 132.96 126.245 132.705C126.535 132.447 126.728 132.238 126.824 132.079C126.919 131.916 126.967 131.746 126.967 131.567C126.967 131.029 126.718 130.759 126.221 130.759C125.985 130.759 125.796 130.832 125.652 130.979C125.512 131.123 125.439 131.322 125.432 131.577H124.046C124.052 130.968 124.248 130.491 124.634 130.147C125.023 129.803 125.552 129.631 126.221 129.631C126.897 129.631 127.421 129.795 127.794 130.123C128.167 130.448 128.354 130.909 128.354 131.505C128.354 131.776 128.293 132.033 128.172 132.275C128.051 132.514 127.839 132.78 127.536 133.073L127.149 133.441C126.907 133.674 126.768 133.947 126.733 134.259L126.714 134.551H125.49ZM125.351 136.018C125.351 135.805 125.423 135.629 125.566 135.492C125.713 135.352 125.899 135.282 126.126 135.282C126.352 135.282 126.537 135.352 126.68 135.492C126.827 135.629 126.9 135.805 126.9 136.018C126.9 136.229 126.828 136.402 126.685 136.539C126.545 136.676 126.358 136.745 126.126 136.745C125.893 136.745 125.705 136.676 125.561 136.539C125.421 136.402 125.351 136.229 125.351 136.018Z"
        fill="white"
      />
      <circle cx="65" cy="45" r="5" fill="#C5DAFF" />
      <path
        d="M64.4943 45.8219C64.4943 45.5032 64.533 45.2493 64.6105 45.0604C64.6879 44.8714 64.829 44.6858 65.0339 44.5037C65.2411 44.3193 65.3788 44.1702 65.4471 44.0563C65.5154 43.9402 65.5496 43.8184 65.5496 43.6909C65.5496 43.3062 65.372 43.1138 65.0168 43.1138C64.8484 43.1138 64.7129 43.1662 64.6105 43.2709C64.5103 43.3733 64.4579 43.5156 64.4534 43.6978H63.463C63.4676 43.2629 63.6076 42.9226 63.8831 42.6767C64.1608 42.4308 64.5387 42.3079 65.0168 42.3079C65.4995 42.3079 65.874 42.4251 66.1404 42.6596C66.4068 42.8918 66.5399 43.2208 66.5399 43.6465C66.5399 43.8401 66.4967 44.0233 66.4102 44.1964C66.3237 44.3671 66.1723 44.5572 65.956 44.7667L65.6794 45.0296C65.5063 45.1958 65.4073 45.3905 65.3822 45.6136L65.3686 45.8219H64.4943ZM64.3953 46.8703C64.3953 46.7178 64.4465 46.5926 64.549 46.4947C64.6537 46.3945 64.7869 46.3444 64.9485 46.3444C65.1102 46.3444 65.2422 46.3945 65.3447 46.4947C65.4494 46.5926 65.5018 46.7178 65.5018 46.8703C65.5018 47.0206 65.4505 47.1447 65.3481 47.2425C65.2479 47.3404 65.1147 47.3894 64.9485 47.3894C64.7823 47.3894 64.648 47.3404 64.5456 47.2425C64.4454 47.1447 64.3953 47.0206 64.3953 46.8703Z"
        fill="white"
      />
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M136 115.2V56H149.6C154.902 56 159.2 60.2981 159.2 65.6V105.6C159.2 110.902 154.902 115.2 149.6 115.2H136Z"
        fill="#656A71"
      />
      <rect
        x="72.8"
        y="52.8"
        width="83.2"
        height="59.2"
        rx="8"
        fill="#4892FB"
      />
      <path
        d="M149.6 100.726V82.9102C149.6 80.4192 147.637 78.3999 145.215 78.3999H125.185C122.763 78.3999 120.8 80.4192 120.8 82.9102V100.726C120.8 103.217 122.763 105.236 125.185 105.236H145.215C147.637 105.236 149.6 103.217 149.6 100.726Z"
        fill="#64D2D0"
      />
      <path
        d="M137.064 94.3886C136.731 94.3886 136.412 94.2571 136.177 94.0228C135.941 93.7886 135.807 93.4706 135.805 93.1383V92.8715C135.805 92.4913 135.956 92.1268 136.225 91.858C136.494 91.5892 136.859 91.4382 137.239 91.4382H139.932C140.263 91.4402 140.58 91.5726 140.814 91.8066C141.048 92.0407 141.18 92.3575 141.182 92.6885V92.9553C141.182 93.3354 141.031 93.7 140.762 93.9688C140.493 94.2375 140.129 94.3886 139.749 94.3886H137.064Z"
        fill="white"
      />
      <path
        d="M139.947 91.8346C140.177 91.8346 140.399 91.9262 140.562 92.0892C140.725 92.2521 140.816 92.4732 140.816 92.7037V92.9705C140.816 93.2496 140.705 93.5171 140.508 93.7144C140.311 93.9117 140.043 94.0226 139.764 94.0226H137.064C136.833 94.0226 136.611 93.9313 136.446 93.7685C136.282 93.6057 136.189 93.3847 136.187 93.1535V92.8867C136.187 92.6077 136.298 92.3401 136.495 92.1428C136.692 91.9455 136.96 91.8346 137.239 91.8346H139.932H139.947ZM139.932 91.0723H137.239C136.758 91.0743 136.298 91.2661 135.958 91.6059C135.618 91.9457 135.426 92.4061 135.424 92.8867V93.1535C135.426 93.5869 135.6 94.0018 135.907 94.3076C136.214 94.6133 136.63 94.7849 137.064 94.7849H139.748C140.229 94.7829 140.69 94.5911 141.03 94.2513C141.37 93.9115 141.562 93.4511 141.564 92.9705V92.7037C141.564 92.271 141.392 91.8561 141.086 91.5501C140.78 91.2441 140.364 91.0723 139.932 91.0723Z"
        fill="#64D2D0"
      />
      <path
        d="M130.878 94.5106C130.547 94.5106 130.229 94.3789 129.994 94.1444C129.759 93.9099 129.628 93.5919 129.628 93.2604V92.9935C129.628 92.6134 129.779 92.2489 130.048 91.9801C130.317 91.7113 130.681 91.5603 131.062 91.5603H133.746C134.08 91.5603 134.4 91.6928 134.636 91.9287C134.872 92.1646 135.005 92.4846 135.005 92.8182V93.0774C135.005 93.4575 134.854 93.8221 134.585 94.0908C134.316 94.3596 133.951 94.5106 133.571 94.5106H130.878Z"
        fill="white"
      />
      <path
        d="M133.746 91.9413C133.978 91.9433 134.2 92.0363 134.364 92.2003C134.528 92.3643 134.621 92.5861 134.623 92.818V93.0772C134.623 93.3562 134.512 93.6238 134.315 93.8211C134.118 94.0184 133.85 94.1293 133.571 94.1293H130.879C130.648 94.1293 130.427 94.0377 130.264 93.8747C130.101 93.7117 130.009 93.4907 130.009 93.2602V92.9934C130.009 92.7143 130.12 92.4467 130.317 92.2494C130.515 92.0521 130.783 91.9413 131.062 91.9413H133.746V91.9413ZM133.746 91.179H131.062C130.581 91.181 130.12 91.3728 129.78 91.7126C129.44 92.0524 129.248 92.5128 129.246 92.9934V93.2602C129.246 93.6929 129.418 94.1078 129.724 94.4138C130.031 94.7197 130.446 94.8916 130.879 94.8916H133.571C134.052 94.8916 134.514 94.7005 134.854 94.3602C135.195 94.0199 135.386 93.5584 135.386 93.0772V92.818C135.386 92.3833 135.213 91.9664 134.906 91.659C134.598 91.3516 134.181 91.179 133.746 91.179V91.179Z"
        fill="#64D2D0"
      />
      <path
        d="M138.489 91.5397C138.202 91.5413 137.922 91.4576 137.682 91.2993C137.443 91.1411 137.256 90.9154 137.145 90.6509C137.034 90.3863 137.004 90.0949 137.06 89.8134C137.115 89.5319 137.252 89.2731 137.454 89.0698C137.657 88.8664 137.915 88.7277 138.196 88.6712C138.477 88.6147 138.769 88.6429 139.034 88.7523C139.3 88.8617 139.526 89.0474 139.686 89.2858C139.846 89.5241 139.931 89.8045 139.931 90.0913C139.931 90.4741 139.779 90.8414 139.509 91.1128C139.239 91.3842 138.872 91.5377 138.489 91.5397V91.5397Z"
        fill="white"
      />
      <path
        d="M138.513 88.9049C138.724 88.9049 138.931 88.9675 139.106 89.0848C139.282 89.2021 139.419 89.3688 139.5 89.5638C139.58 89.7588 139.602 89.9734 139.56 90.1805C139.519 90.3875 139.417 90.5777 139.268 90.7269C139.119 90.8762 138.929 90.9779 138.721 91.019C138.514 91.0602 138.3 91.0391 138.105 90.9583C137.909 90.8775 137.743 90.7407 137.625 90.5652C137.508 90.3897 137.445 90.1833 137.445 89.9722C137.445 89.8321 137.473 89.6933 137.527 89.5638C137.58 89.4343 137.659 89.3167 137.758 89.2176C137.857 89.1184 137.975 89.0398 138.105 88.9862C138.234 88.9326 138.373 88.9049 138.513 88.9049ZM138.513 88.1426C138.151 88.1411 137.796 88.2471 137.494 88.4472C137.192 88.6474 136.956 88.9326 136.817 89.2668C136.677 89.601 136.64 89.9691 136.709 90.3245C136.779 90.6799 136.953 91.0066 137.208 91.2633C137.464 91.5199 137.79 91.695 138.146 91.7662C138.501 91.8374 138.869 91.8016 139.204 91.6633C139.539 91.5251 139.825 91.2906 140.027 90.9895C140.228 90.6885 140.336 90.3344 140.336 89.9722C140.336 89.4883 140.144 89.0241 139.802 88.6812C139.461 88.3383 138.997 88.1446 138.513 88.1426Z"
        fill="#64D2D0"
      />
      <path
        d="M132.312 91.5427C132.025 91.5427 131.745 91.4576 131.506 91.2981C131.268 91.1386 131.082 90.9119 130.973 90.6468C130.863 90.3816 130.835 90.09 130.892 89.8088C130.948 89.5276 131.087 89.2696 131.29 89.0673C131.494 88.865 131.753 88.7277 132.034 88.6727C132.316 88.6176 132.608 88.6474 132.872 88.7582C133.137 88.869 133.363 89.0559 133.521 89.2951C133.679 89.5343 133.763 89.815 133.761 90.1018C133.761 90.2917 133.724 90.4797 133.651 90.655C133.578 90.8303 133.471 90.9895 133.337 91.1234C133.202 91.2573 133.042 91.3633 132.866 91.4352C132.691 91.5072 132.502 91.5437 132.312 91.5427V91.5427Z"
        fill="white"
      />
      <path
        d="M132.312 89.0343C132.524 89.0328 132.731 89.0941 132.907 89.2105C133.084 89.3269 133.222 89.493 133.304 89.6879C133.386 89.8828 133.408 90.0976 133.368 90.3051C133.328 90.5127 133.226 90.7036 133.077 90.8536C132.928 91.0036 132.738 91.106 132.531 91.1478C132.324 91.1896 132.108 91.169 131.913 91.0884C131.717 91.0079 131.55 90.8711 131.432 90.6954C131.315 90.5197 131.252 90.3131 131.252 90.1016C131.251 89.9618 131.278 89.8232 131.33 89.6938C131.383 89.5643 131.461 89.4466 131.56 89.3474C131.658 89.2482 131.776 89.1695 131.905 89.1157C132.034 89.062 132.172 89.0343 132.312 89.0343ZM132.312 88.272C131.95 88.272 131.596 88.3793 131.295 88.5803C130.994 88.7814 130.76 89.0671 130.621 89.4015C130.482 89.7358 130.446 90.1037 130.517 90.4586C130.587 90.8135 130.762 91.1395 131.018 91.3954C131.274 91.6513 131.6 91.8255 131.955 91.8961C132.31 91.9667 132.678 91.9305 133.013 91.792C133.347 91.6535 133.633 91.419 133.834 91.1181C134.035 90.8173 134.143 90.4635 134.143 90.1016C134.144 89.8611 134.097 89.6227 134.005 89.4003C133.914 89.1778 133.779 88.9757 133.609 88.8056C133.439 88.6355 133.236 88.5008 133.014 88.4092C132.791 88.3176 132.553 88.271 132.312 88.272V88.272Z"
        fill="#64D2D0"
      />
      <path
        d="M135.363 91.4435C135.045 91.4435 134.734 91.3491 134.469 91.1724C134.205 90.9956 133.998 90.7444 133.877 90.4505C133.755 90.1565 133.723 89.8331 133.785 89.5211C133.847 89.209 134 88.9224 134.225 88.6975C134.451 88.4725 134.737 88.3193 135.049 88.2572C135.362 88.1952 135.685 88.227 135.979 88.3488C136.273 88.4705 136.525 88.6767 136.701 88.9412C136.878 89.2057 136.973 89.5167 136.973 89.8349C136.974 90.0464 136.933 90.256 136.852 90.4517C136.772 90.6473 136.653 90.825 136.504 90.9746C136.354 91.1241 136.176 91.2426 135.98 91.3231C135.785 91.4036 135.575 91.4445 135.363 91.4435Z"
        fill="white"
      />
      <path
        d="M135.363 88.6532C135.607 88.6517 135.845 88.7226 136.048 88.8569C136.251 88.9912 136.41 89.1828 136.504 89.4074C136.598 89.632 136.623 89.8794 136.575 90.1182C136.528 90.357 136.411 90.5764 136.239 90.7485C136.067 90.9206 135.847 91.0377 135.608 91.0848C135.37 91.1319 135.122 91.107 134.897 91.0131C134.673 90.9193 134.481 90.7608 134.347 90.5578C134.212 90.3548 134.141 90.1164 134.143 89.873C134.145 89.5501 134.274 89.241 134.503 89.0127C134.731 88.7844 135.04 88.6552 135.363 88.6532V88.6532ZM135.363 87.8909C134.969 87.8909 134.584 88.0078 134.256 88.2268C133.929 88.4457 133.674 88.7569 133.523 89.121C133.373 89.485 133.334 89.8854 133.411 90.2716C133.489 90.6578 133.679 91.0123 133.958 91.2903C134.237 91.5683 134.593 91.7572 134.98 91.8331C135.366 91.909 135.767 91.8685 136.13 91.7167C136.494 91.565 136.804 91.3087 137.022 90.9805C137.24 90.6523 137.355 90.2669 137.354 89.873C137.354 89.6121 137.302 89.3537 137.202 89.1127C137.102 88.8717 136.955 88.6529 136.77 88.4687C136.585 88.2846 136.366 88.1387 136.124 88.0396C135.883 87.9404 135.624 87.8899 135.363 87.8909V87.8909Z"
        fill="#64D2D0"
      />
      <path
        d="M133.716 94.8384C133.349 94.8364 132.998 94.6893 132.739 94.4294C132.481 94.1694 132.335 93.8176 132.335 93.4509V93.146C132.335 92.7254 132.503 92.3221 132.8 92.0247C133.098 91.7274 133.501 91.5603 133.922 91.5603H136.973C137.339 91.5603 137.691 91.7054 137.951 91.964C138.211 92.2225 138.359 92.5735 138.361 92.9402V93.2451C138.359 93.6671 138.19 94.0712 137.892 94.3695C137.593 94.6679 137.189 94.8364 136.767 94.8384H133.716Z"
        fill="white"
      />
      <path
        d="M137.011 91.9413C137.276 91.9413 137.531 92.0463 137.72 92.2333C137.908 92.4204 138.015 92.6744 138.017 92.94V93.2449C138.017 93.4041 137.986 93.5617 137.925 93.7088C137.864 93.8559 137.775 93.9895 137.662 94.1021C137.55 94.2146 137.416 94.3039 137.269 94.3648C137.122 94.4257 136.964 94.4571 136.805 94.4571H133.754C133.622 94.4571 133.492 94.431 133.37 94.3803C133.248 94.3297 133.138 94.2554 133.045 94.1619C132.952 94.0683 132.879 93.9573 132.829 93.8353C132.779 93.7133 132.754 93.5826 132.755 93.4508V93.1458C132.755 92.9877 132.786 92.831 132.847 92.6849C132.907 92.5387 132.996 92.406 133.108 92.2941C133.22 92.1823 133.353 92.0935 133.499 92.033C133.645 91.9725 133.802 91.9413 133.96 91.9413H137.011V91.9413ZM137.011 91.179H133.96C133.439 91.181 132.939 91.3888 132.571 91.7573C132.202 92.1257 131.994 92.6248 131.992 93.1458V93.4508C131.991 93.6827 132.036 93.9125 132.124 94.127C132.212 94.3416 132.342 94.5366 132.506 94.701C132.669 94.8653 132.864 94.9957 133.078 95.0847C133.292 95.1736 133.522 95.2194 133.754 95.2194H136.805C137.064 95.2194 137.321 95.1684 137.561 95.0691C137.8 94.9699 138.018 94.8245 138.201 94.6411C138.385 94.4578 138.53 94.2401 138.63 94.0006C138.729 93.761 138.78 93.5042 138.78 93.2449V92.94C138.778 92.4722 138.591 92.0243 138.259 91.6943C137.928 91.3642 137.479 91.179 137.011 91.179V91.179Z"
        fill="#64D2D0"
      />
      <path
        opacity="0.4"
        d="M135.44 100.304C140.136 100.304 143.944 96.4988 143.944 91.8042C143.944 87.1097 140.136 83.304 135.44 83.304C130.743 83.304 126.936 87.1097 126.936 91.8042C126.936 96.4988 130.743 100.304 135.44 100.304Z"
        stroke="white"
        stroke-width="1.2"
        stroke-miterlimit="10"
      />
      <path
        d="M135.44 99.7405C133.379 99.6766 131.425 98.8137 129.99 97.3345C128.556 95.8553 127.754 93.876 127.754 91.8158C127.754 89.7556 128.556 87.7763 129.99 86.2971C131.425 84.8179 133.379 83.955 135.44 83.8911V82.7476C133.645 82.7476 131.891 83.2792 130.399 84.2754C128.907 85.2716 127.743 86.6876 127.056 88.3444C126.369 90.0012 126.188 91.8245 126.537 93.5839C126.886 95.3432 127.749 96.9597 129.017 98.2291C130.284 99.4984 131.9 100.364 133.66 100.715C135.419 101.067 137.244 100.89 138.902 100.205C140.561 99.5211 141.979 98.3607 142.978 96.8708C143.978 95.3809 144.512 93.6285 144.515 91.8348H143.371C143.365 93.9336 142.527 95.9444 141.04 97.4263C139.553 98.9083 137.539 99.7405 135.44 99.7405Z"
        fill="white"
      />
      <rect x="83.2" y="60.8" width="34.4" height="4" fill="#1B64DA" />
      <rect x="121.6" y="60.8" width="16" height="4" fill="#1B64DA" />
      <rect x="141.6" y="60.8" width="4" height="4" fill="#1B64DA" />
      <path d="M70 114L53.6 130.4" stroke="#64D2D0" stroke-width="3.2" />
      <circle
        cx="88.4"
        cy="96.4"
        r="24.8"
        fill="white"
        fill-opacity="0.2"
        stroke="#D1D6DB"
        stroke-width="3.2"
      />
      <path
        d="M88.2837 82L104.284 98"
        stroke="white"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M80.8 88.6001L88.8 96.6001"
        stroke="white"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ScenarioInfoImage;
