import axios from "../../shared/utils/axios";
import {
  DateBean,
  ReportStatisticsBean,
  StatisticsDetailBean,
} from "../../types/ReportStat";

interface reportStatApi {
  getReportStat(config_ids: number[]): Promise<ReportStatisticsBean>;
  getReportStatByPeriod(dateBean: DateBean): Promise<ReportStatisticsBean>;
  getReportStatDetailPaging(req: {
    configId: number;
    pageNum: number;
    pageRow: number;
  }): Promise<StatisticsDetailBean[]>;
  getReportStatDetailByPeriod(req: {
    month: number;
    startConfig: number;
    endConfig: number;
    realTrainingFlag: boolean | null;
  }): Promise<StatisticsDetailBean[]>;

  getReportStatDetailByPeriodType(req: {
    month: number;
    startConfig: number;
    endConfig: number;
    realTrainingFlag: boolean | null;
  }): Promise<StatisticsDetailBean[]>;

  getConfigsByDate(dateBean: DateBean): Promise<number[]>;
}

class reportStatServerApi implements reportStatApi {
  async getReportStat(config_ids: number[]): Promise<ReportStatisticsBean> {
    const res = await axios.post("/report/statistics", config_ids);

    return Promise.resolve(res.data);
  }

  async getReportStatByPeriod(
    dateBean: DateBean
  ): Promise<ReportStatisticsBean> {
    const res = await axios.post("/report/statistics/period", dateBean);

    return Promise.resolve(res.data);
  }

  async getReportStatDetailPaging(req: {
    configId: number;
    pageNum: number;
    pageRow: number;
  }): Promise<StatisticsDetailBean[]> {
    const res = await axios.get("/report/statistics/detail/paging", {
      params: req,
    });

    return Promise.resolve(res.data);
  }

  async getReportStatDetailByPeriod(req: {
    month: number;
    startConfig: number;
    endConfig: number;
    realTrainingFlag: boolean | null;
  }): Promise<StatisticsDetailBean[]> {
    const res = await axios.get("/report/statistics/detail/period", {
      params: req,
    });

    return Promise.resolve(res.data);
  }

  async getReportStatDetailByPeriodType(req: {
    month: number;
    startConfig: number;
    endConfig: number;
    realTrainingFlag: boolean | null;
  }): Promise<StatisticsDetailBean[]> {
    const res = await axios.get("/report/statistics/detail/period/type", {
      params: req,
    });

    return Promise.resolve(res.data);
  }

  async getConfigsByDate(dateBean: DateBean): Promise<number[]> {
    const res = await axios.post("/report/configs", dateBean);

    return Promise.resolve(res.data);
  }
}

const reportStatApi: reportStatApi = new reportStatServerApi();

export default reportStatApi;
