import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customerApi from "../api/customerApi";
import { setSessionCustomerCode } from "../../shared/utils/auth";
import { CustomerInfoBean } from "../../types/Info";

interface CustomerState {
  customerCode: string | null;
  companyList?: CustomerInfoBean[];
}

// # initial state
const initialState: CustomerState = {
  customerCode: "",
  companyList: [],
};

export const trunkSyncMasterToCustomer = createAsyncThunk(
  "customer/sync",
  async () => {
    const resp = await customerApi.syncMasterToCustomer();
    return resp;
  }
);
export const trunkSelectCustomer = createAsyncThunk(
  "customer/sessionCustomer",
  async (customerCode: string | null) => {
    const resp = await customerApi.selectCustomer(customerCode);
    return resp;
  }
);
export const trunkGetCustomerName = createAsyncThunk(
  "customer/name",
  async (customerCode: string) => {
    const resp = await customerApi.getCustomerName(customerCode);
    return resp;
  }
);
export const trunkGetAccessCompanyList = createAsyncThunk(
  "customer/getAccessCompanyList",
  async () => {
    const resp = await customerApi.getAccessCompanyList();
    return resp;
  }
);
export const trunkGetCustomersList = createAsyncThunk(
  "customer/list",
  async () => {
    const resp = await customerApi.getCustomerList();
    return resp;
  }
);
export const trunkUpdateCustomerInfo = createAsyncThunk(
  "customer/update",
  async (req: { companyName: string; customerCode: string }) => {
    const resp = await customerApi.updateCustomerInfo(req);
    return resp;
  }
);
export const trunkCheckCustomerTraining = createAsyncThunk(
  "customer/training",
  async (customerCode: string) => {
    const resp = await customerApi.checkCustomerTraining(customerCode);
    return resp;
  }
);
export const trunkGetCustomerCategory = createAsyncThunk(
  "customer/category",
  async () => {
    const resp = await customerApi.getCustomerCategory();
    return resp;
  }
);
export const trunkGetCustomerSetting = createAsyncThunk(
  "customer/setting",
  async () => {
    const resp = await customerApi.getCustomerSetting();
    return resp;
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkSelectCustomer.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          setSessionCustomerCode(data.data.customerCode);
          state.customerCode = data.data.customerCode;
        }
      }
    );
    builder.addCase(
      trunkGetAccessCompanyList.fulfilled,
      (state, { payload: data }) => {
        state.companyList = data.data;
      }
    );
  },
});
export const customerActions = customerSlice.actions;

export default customerSlice.reducer;
