import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentApi from "../api/contentApi";
import {
  ImagesInfo,
  PhishingDocument,
  SiteInfo,
  SiteResourceBean,
} from "../../types/Info";
import FileSaver from "file-saver";
import { ScenarioCommonReqDTO } from "../../types/Send";

interface ContentState {
  sites: SiteInfo[];
  sitesTotal: number;
  siteResources: SiteResourceBean[];
  siteResourcesTotal: number;
  images: ImagesInfo[];
  imagesTotal: number;
  attaches: PhishingDocument[];
  attachesTotal: number;
}

// # initial state
const initialState: ContentState = {
  sites: [],
  sitesTotal: 0,
  siteResources: [],
  siteResourcesTotal: 0,
  images: [],
  imagesTotal: 0,
  attaches: [],
  attachesTotal: 0,
};
export const trunkGetAttaches = createAsyncThunk(
  "content/attach",
  async (req: ScenarioCommonReqDTO) => {
    const resp = await contentApi.getAttaches(req);
    return resp;
  }
);
export const trunkGetSites = createAsyncThunk(
  "content/sites",
  async (req: ScenarioCommonReqDTO) => {
    const resp = await contentApi.getSites(req);
    return resp;
  }
);
export const trunkUploadSites = createAsyncThunk(
  "content/sites/upload",
  async (req: {
    file: any;
    request: {
      siteName: string;
    };
  }) => {
    const resp = await contentApi.uploadSites(req);
    return resp;
  }
);
export const trunkUpdateSites = createAsyncThunk(
  "content/sites/update",
  async (req: {
    file: File | null;
    request: {
      siteId: number;
      siteName: string;
    };
  }) => {
    const resp = await contentApi.updateSites(req);
    return resp;
  }
);
export const trunkGetSitesFile = createAsyncThunk(
  "content/sites/file",
  async (req: { site_id: number; fileName: string }) => {
    const resp = await contentApi.getSitesFile(req);
    return resp;
  }
);
export const trunkDeleteSite = createAsyncThunk(
  "content/sites/delete",
  async (req: {
    request: {
      siteIds: number[];
    };
  }) => {
    const resp = await contentApi.deleteSites(req);
    return resp;
  }
);
export const trunkGetSitesResource = createAsyncThunk(
  "content/sites/resources",
  async () => {
    const resp = await contentApi.getSitesResource();
    return resp;
  }
);
export const trunkInsertSiteResource = createAsyncThunk(
  "content/sites/resources/insert",
  async (req: {
    request: {
      resourceName: string;
    };
    file: any;
  }) => {
    const resp = await contentApi.insertSiteResource(req);
    return resp;
  }
);
export const trunkUpdateSiteResource = createAsyncThunk(
  "content/sites/resources/update",
  async (req: {
    file: File | null;
    request: {
      resourceId: number;
      resourceName: string;
    };
  }) => {
    const resp = await contentApi.updateSiteResource(req);
    return resp;
  }
);
export const trunkUpdateFileSiteResource = createAsyncThunk(
  "content/sites/resources/updateFile",
  async (req: { id: number; resourceName: string; file: any }) => {
    const resp = await contentApi.updateFileSiteResource(req);
    return resp;
  }
);
export const trunkDeleteSiteResource = createAsyncThunk(
  "content/sites/resources/delete",
  async (req: {
    request: {
      resourceIds: number[];
    };
  }) => {
    const resp = await contentApi.deleteSiteResource(req);
    return resp;
  }
);
export const trunkGetImages = createAsyncThunk(
  "content/images",
  async (req: ScenarioCommonReqDTO) => {
    const resp = await contentApi.getImages(req);
    return resp;
  }
);
export const trunkUploadImage = createAsyncThunk(
  "content/images/upload",
  async (req: {
    file: any;
    request: {
      imageName: string;
    };
  }) => {
    const resp = await contentApi.uploadImages(req);
    return resp;
  }
);
export const trunkUpdateImage = createAsyncThunk(
  "content/images/update",
  async (req: {
    file: File | null;
    request: {
      imageName: string;
      imageId: number;
    };
  }) => {
    const resp = await contentApi.updateImages(req);
    return resp;
  }
);
export const trunkUpdateFileImage = createAsyncThunk(
  "content/images/updateFile",
  async (req: { id: number; fileName: string; file: any }) => {
    const resp = await contentApi.updateFileImages(req);
    return resp;
  }
);
export const trunkDeleteImage = createAsyncThunk(
  "content/images/delete",
  async (req: {
    request: {
      imageIds: number[];
    };
  }) => {
    const resp = await contentApi.deleteImages(req);
    return resp;
  }
);
export const trunkUploadAttach = createAsyncThunk(
  "content/attach/insert",
  async (req: {
    file: any;
    request: {
      documentName: string;
    };
  }) => {
    const resp = await contentApi.uploadAttach(req);
    return resp;
  }
);
export const trunkDeleteAttach = createAsyncThunk(
  "content/attach/delete",
  async (req: {
    request: {
      documentIds: number[];
    };
  }) => {
    const resp = await contentApi.deleteAttach(req);
    return resp;
  }
);
export const trunkUpdateAttach = createAsyncThunk(
  "content/attach/update",
  async (req: {
    file: File | null;
    request: {
      documentName: string;
      documentId: number;
    };
  }) => {
    const resp = await contentApi.updateAttach(req);
    return resp;
  }
);
export const trunkUpdateAttachFile = createAsyncThunk(
  "content/attach/updateFile",
  async (req: {
    fileName: string;
    file: any;
    filePath: string;
    id: number;
  }) => {
    const resp = await contentApi.updateFileAttach(req);
    return resp;
  }
);
export const trunkDownloadAttachFile = createAsyncThunk(
  "content/attach/download",
  async (id: number) => {
    const resp = await contentApi.downloadFileAttach(id);
    return resp;
  }
);
const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(trunkGetSites.fulfilled, (state, { payload: data }) => {
      state.sites = data.data?.list;
      state.sitesTotal = data.data?.total;
    });

    builder.addCase(trunkUploadSites.fulfilled, (state, { payload: data }) => {
      if (data.code === "SUCCESS") {
        // state.sites = [...state.sites, data.data];
        // state.sitesTotal = state.sitesTotal + 1;
      }
    });

    builder.addCase(trunkDeleteSite.fulfilled, (state, req) => {
      if (req.payload.code === "SUCCESS") {
        state.sites = state.sites.filter(
          (item) =>
            !req.meta.arg.request.siteIds.some((sid) => item.siteId === sid)
        );
        state.sitesTotal =
          state.sitesTotal - req.meta.arg.request.siteIds.length;
      }
    });

    builder.addCase(trunkUpdateSites.fulfilled, (state, req) => {
      if (req.payload.code === "SUCCESS") {
        state.sites = state.sites.map((item) =>
          item.siteId === req.meta.arg.request.siteId
            ? {
                ...item,
                siteName: req.meta.arg.request.siteName,
                // siteUrl: req.payload.data.siteUrl,
              }
            : item
        );
      }
    });

    builder.addCase(
      trunkGetSitesResource.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.siteResources = data.data.list;
          state.siteResourcesTotal = data.data.total;
        }
      }
    );
    builder.addCase(
      trunkInsertSiteResource.fulfilled,
      (state, { payload: data }) => {
        // if (data.code === "SUCCESS")
        // state.siteResources = [...state.siteResources, data.data];
      }
    );
    builder.addCase(trunkUpdateSiteResource.fulfilled, (state, req) => {
      if (req.payload.code === "SUCCESS") {
        console.log(req.meta.arg.request.resourceName);
        state.siteResources = state.siteResources.map((item) =>
          item.resourceId === req.meta.arg.request.resourceId
            ? { ...item, resourceName: req.meta.arg.request.resourceName }
            : item
        );
      }
    });
    builder.addCase(trunkDeleteSiteResource.fulfilled, (state, req) => {
      if (req.payload.code === "SUCCESS") {
        state.siteResources = state.siteResources.filter(
          (item) =>
            !req.meta.arg.request.resourceIds.some(
              (sid) => item.resourceId === sid
            )
        );
        state.siteResourcesTotal =
          state.siteResourcesTotal - req.meta.arg.request.resourceIds.length;
      }
    });
    builder.addCase(trunkGetImages.fulfilled, (state, { payload: data }) => {
      state.images = data.data?.list;
      state.imagesTotal = data.data?.total;
    });
    builder.addCase(trunkUploadImage.fulfilled, (state, { payload: data }) => {
      if (data.code === "SUCCESS") {
        // state.images = [...state.images, data.data];
        // state.imagesTotal = state.imagesTotal + 1;
      }
    });
    builder.addCase(trunkDeleteImage.fulfilled, (state, req) => {
      if (req.payload.code === "SUCCESS") {
        state.images = state.images.filter(
          (item) =>
            !req.meta.arg.request.imageIds.some((sid) => item.imageId === sid)
        );
        state.imagesTotal =
          state.imagesTotal - req.meta.arg.request.imageIds.length;
      }
    });
    builder.addCase(trunkUpdateImage.fulfilled, (state, req) => {
      if (req.payload.code === "SUCCESS") {
        state.images = state.images.map((item) =>
          item.imageId === req.meta.arg.request.imageId
            ? {
                ...item,
                imageName: req.meta.arg.request.imageName,
                // siteUrl: req.payload.data.siteUrl,
              }
            : item
        );
      }
    });
    builder.addCase(trunkGetAttaches.fulfilled, (state, { payload: data }) => {
      state.attaches = data.data?.list;
      state.attachesTotal = data.data?.total;
    });
    builder.addCase(trunkUploadAttach.fulfilled, (state, { payload: data }) => {
      if (data.code === "SUCCESS") {
        // state.attaches = [...state.attaches, data.data];
        // state.attachesTotal = state.attachesTotal + 1;
      }
    });
    builder.addCase(trunkDeleteAttach.fulfilled, (state, req) => {
      if (req.payload.code === "SUCCESS") {
        state.attaches = state.attaches.filter(
          (item) =>
            !req.meta.arg.request.documentIds.some(
              (sid) => item.documentId === sid
            )
        );
        state.attachesTotal =
          state.attachesTotal - req.meta.arg.request.documentIds.length;
      }
    });
    builder.addCase(trunkUpdateAttach.fulfilled, (state, req) => {
      if (req.payload.code === "SUCCESS") {
        state.attaches = state.attaches.map((item) =>
          item.documentId === req.meta.arg.request.documentId
            ? {
                ...item,
                documentName: req.meta.arg.request.documentName,
                // siteUrl: req.payload.data.siteUrl,
              }
            : item
        );
      }
    });
    builder.addCase(
      trunkDownloadAttachFile.fulfilled,
      (state, { payload: res }) => {
        const file = res.data;
        const header = res.headers;
        const regex =
          /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i;
        const contentDisposition =
          header["content-disposition"].match(regex)[0];
        let fileName = contentDisposition.split("filename=");
        fileName = decodeURIComponent(fileName[1]);
        const blob = new Blob([file], { type: "application/download" });
        FileSaver.saveAs(blob, fileName.substring(1, fileName.length - 1));
      }
    );
  },
});
export const contentAction = contentSlice.actions;

export default contentSlice.reducer;
