import { bindActionCreators } from "redux";
import { useAppDispatch, useRootState } from "./index";
import {
  deleteReply,
  insertReply,
  trunkGetManualReplys,
  trunkGetReplys,
  updateConfigReplyFlag,
  updateReply,
} from "../store/reply";
import { useMemo } from "react";

const useReplyStore = () => {
  const replyState = useRootState((state) => state.replyStore);

  const dispatch = useAppDispatch();

  const boundGetReplys = useMemo(
    () => bindActionCreators(trunkGetReplys, dispatch),
    [dispatch]
  );

  const boundDeleteReply = useMemo(
    () => bindActionCreators(deleteReply, dispatch),
    [dispatch]
  );

  const boundUpdateConfigReplyFlag = useMemo(
    () => bindActionCreators(updateConfigReplyFlag, dispatch),
    [dispatch]
  );

  const boundUpdateReply = useMemo(
    () => bindActionCreators(updateReply, dispatch),
    [dispatch]
  );

  const boundInsertReply = useMemo(
    () => bindActionCreators(insertReply, dispatch),
    [dispatch]
  );

  const boundGetManualReplys = useMemo(
    () => bindActionCreators(trunkGetManualReplys, dispatch),
    [dispatch]
  );

  return {
    replyState,
    getReplys: boundGetReplys,
    deleteReply: boundDeleteReply,
    updateConfigReplyFlag: boundUpdateConfigReplyFlag,
    updateReply: boundUpdateReply,
    insertReply: boundInsertReply,
    getManualReplys: boundGetManualReplys,
  };
};

export default useReplyStore;
