import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Icons,
  SettingButton,
  TextField,
  TreeSimple,
  Typography,
} from "fasoo-ui-component-next";
import "./index.scss";
import { useState } from "react";
import CommonModal from "../../../component/CommonModal";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import WSEdit from "../../../component/WSEdit";
import NoInfo from "../../../component/NoInfo";

const SampleUI: React.FC = () => {
  // 모달
  const [defaultModal, setDefaultModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // 드랍다운 - 체크박스
  const checkGroup = useCheckboxGroup<number>();

  const checkboxDropdown = (
    <>
      <Box alignItems="center" classes="setting-button-list-search" height={40}>
        <Icons variant="mindsat" label="   icon_search" />
        <TextField borderNone placeholder="직접 검색" />
      </Box>
      <Box classes="setting-button-list-body">
        <Box
          alignItems="center"
          classes="setting-button-list-body-item"
          height={40}
        >
          <Checkbox />
          <Typography classes="mg ml-8">김파수</Typography>
          <Typography classes="mg ml-8 " color="secondary">
            (fasoo)
          </Typography>
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-body-item"
          height={40}
        >
          <Checkbox />
          <Typography classes="mg ml-8">김파수</Typography>
          <Typography classes="mg ml-8 " color="secondary">
            (fasoo)
          </Typography>
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-body-item"
          height={40}
        >
          <Checkbox />
          <Typography classes="mg ml-8">김파수</Typography>
          <Typography classes="mg ml-8 " color="secondary">
            (fasoo)
          </Typography>
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-body-item"
          height={40}
        >
          <Checkbox />
          <Typography classes="mg ml-8">김파수</Typography>
          <Typography classes="mg ml-8 " color="secondary">
            (fasoo)
          </Typography>
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-body-item"
          height={40}
        >
          <Checkbox />
          <Typography classes="mg ml-8">김파수</Typography>
          <Typography classes="mg ml-8 " color="secondary">
            (fasoo)
          </Typography>
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-body-item"
          height={40}
        >
          <Checkbox />
          <Typography classes="mg ml-8">김파수</Typography>
          <Typography classes="mg ml-8 " color="secondary">
            (fasoo)
          </Typography>
        </Box>
      </Box>
      <Box
        alignItems="center"
        classes="setting-button-list-footer"
        justifyContent="space-between"
      >
        <Box width={40} justifyContent="center">
          <IconButton unfilled>
            <Icons variant="common" label="refresh" stroke="#B5BBC2" />
          </IconButton>
        </Box>
        <Box>
          <Button text={"취소"} color="secondary" onClick={() => alert("")} />
          <Button
            text={"확인"}
            classes={"mg ml-8"}
            color="primary"
            onClick={() => alert("")}
          />
        </Box>
      </Box>
    </>
  );

  const nomalDropdown = (
    <>
      <Box alignItems="center" classes="setting-button-list-search" height={40}>
        <TextField borderNone placeholder="훈련명을 입력하세요" />
      </Box>
      <Box
        alignItems="center"
        classes="setting-button-list-footer"
        justifyContent="flex-end"
      >
        <Box>
          <Button text={"취소"} color="secondary" onClick={() => alert("")} />
          <Button
            text={"확인"}
            classes={"mg ml-8"}
            color="primary"
            onClick={() => alert("")}
          />
        </Box>
      </Box>
    </>
  );

  const tree_data = [
    {
      id: "COMPANY",
      text: "01",
      type: "dept",
      parent: "",
      email: "",
      checked: false,
      hasDept: true,
      hasUser: false,
    },
    {
      id: "TEST",
      text: "dsf",
      type: "dept",
      parent: "test",
      email: "",
      checked: false,
      hasDept: false,
      hasUser: false,
    },
    {
      id: "1002",
      text: "dd",
      type: "dept",
      parent: "COMPANY",
      email: "",
      checked: false,
      hasDept: false,
      hasUser: false,
    },
    {
      id: "1001",
      text: "ddd",
      type: "dept",
      parent: "COMPANY",
      email: "",
      checked: false,
      hasDept: false,
      hasUser: false,
    },
    {
      id: "test",
      text: "eee",
      type: "dept",
      parent: "COMPANY",
      email: "",
      checked: false,
      hasDept: true,
      hasUser: false,
    },
    {
      id: "insertcsvtest",
      text: "insertcsvTest",
      type: "dept",
      parent: "COMPANY",
      email: "",
      checked: false,
      hasDept: false,
      hasUser: true,
    },
    {
      id: "f7f5b6b7e3bf4bdabb9fe9b9751a0457",
      text: "test",
      type: "dept",
      parent: "COMPANY",
      email: "",
      checked: false,
      hasDept: false,
      hasUser: true,
    },
    {
      id: "37aeb28411dd4b78bf2f1f304f531aae",
      text: "update test",
      type: "dept",
      parent: "COMPANY",
      email: "",
      checked: false,
      hasDept: false,
      hasUser: true,
    },
    {
      id: "test1",
      text: "테테1",
      type: "dept",
      parent: "COMPANY",
      email: "",
      checked: false,
      hasDept: true,
      hasUser: false,
    },
    {
      id: "test2",
      text: "테테2",
      type: "dept",
      parent: "COMPANY",
      email: "",
      checked: false,
      hasDept: false,
      hasUser: false,
    },
    {
      id: "test3",
      text: "테테3",
      type: "dept",
      parent: "COMPANY",
      email: "",
      checked: false,
      hasDept: false,
      hasUser: false,
    },
    {
      id: "123",
      text: "123",
      type: "dept",
      parent: "COMPANY",
      email: "",
      checked: false,
      hasDept: false,
      hasUser: false,
    },
    {
      id: "test1_c",
      text: "테테1_child",
      type: "dept",
      parent: "test1",
      email: "",
      checked: false,
      hasDept: false,
      hasUser: false,
    },
    {
      id: "user1",
      text: "user1",
      type: "user",
      parent: "test1",
      email: "",
      checked: false,
      hasDept: false,
      hasUser: false,
    },
  ];
  const [text, changeText] = useState("초기에 뭐라 써있을말");
  return (
    <Box classes="main sampleUI">
      <Box alignItems="center">
        <Box classes="sampleUI-left">에디터</Box>
        <Box classes="sampleUI-right" direction="column">
          <WSEdit initBody={text} onChange={(e) => changeText(e)} />
          <Box direction="column">
            <Typography size={16} isBold>
              결과값
            </Typography>
            <Typography>{text}</Typography>
          </Box>
        </Box>
      </Box>
      <Box alignItems="center">
        <Box classes="sampleUI-left">Modal</Box>

        <Box classes="sampleUI-right">
          <Button onClick={() => setDefaultModal(true)}>기본 모달</Button>
          <Button onClick={() => setDeleteModal(true)}>삭제 모달</Button>
        </Box>
      </Box>
      {defaultModal && (
        <CommonModal
          open={defaultModal}
          onClose={() => setDefaultModal(false)}
          title="기본 모달"
          body="String or HTML"
          afterSubmitButton={() => ""}
        />
      )}
      {deleteModal && (
        <CommonModal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          title="훈련 메일 삭제"
          subMessage="훈련을 삭제하시면 발송 대기중인 메일이 모두 삭제됩니다. 해당 훈련을 삭제하시겠습니까?"
          type="delete"
          afterSubmitButton={() => ""}
        />
      )}
      <hr />
      <Box alignItems="center">
        <Box classes="sampleUI-left">드롭다운 checkbox</Box>
        <Box classes="sampleUI-right">
          <SettingButton
            width={280}
            classes={"mg ml-10"}
            listPosition={"right"}
            button={
              <Button
                classes={"mg mb-20"}
                color={
                  checkGroup.selected.length === 0 ||
                  checkGroup.selected.includes(1)
                    ? "tertiary"
                    : "tertiary-click"
                }
                text={"훈련대상자"}
              />
            }
          >
            {checkboxDropdown}
          </SettingButton>
          <SettingButton
            width={280}
            classes={"mg ml-10"}
            listPosition={"right"}
            button={
              <Button
                classes={"mg mb-20"}
                color={
                  checkGroup.selected.length === 0 ||
                  checkGroup.selected.includes(1)
                    ? "tertiary"
                    : "tertiary-click"
                }
                text={"훈련선택"}
              />
            }
          >
            {nomalDropdown}
          </SettingButton>
        </Box>
      </Box>
      <Box alignItems="center">
        <Box classes="sampleUI-left">no info page</Box>

        <Box classes="sampleUI-right">
          <NoInfo />
        </Box>
      </Box>
      <Box alignItems="center">
        <Box classes="sampleUI-left">조직도</Box>

        <Box classes="sampleUI-right">
          <TreeSimple rootCode={"COMPANY"} treeNodes={tree_data} depth={0} />
        </Box>
      </Box>
    </Box>
  );
};

export default SampleUI;
