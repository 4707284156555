import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import axiosBlob from "../../shared/utils/axiosBlob";
import { BaseResponseBean } from "../../types/Common";
import { IpFilterType } from "../../types/Ip";

const GATEWAY_URL = config.servers.gateway.url;

interface IpApi {
  getIpList(): Promise<BaseResponseBean<IpFilterType[]>>;
  insertIp(ipFilterType: IpFilterType): Promise<BaseResponseBean<any>>;
  deleteIp(ipIds: number[]): Promise<BaseResponseBean<any>>;
  updateIp(ipFilterType: IpFilterType): Promise<BaseResponseBean<any>>;
  downloadFilteredLog(req: { configId: number; type: string }): Promise<any>;
  downloadAccessLog(req: { startDate: string; endDate: string }): Promise<any>;
  isExistsIp(ip: string): Promise<boolean>;
}

class IpServerApi implements IpApi {
  async getIpList(): Promise<BaseResponseBean<IpFilterType[]>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/manager/logFilter/list`,
      {}
    );

    return Promise.resolve(res.data);
  }
  async insertIp(ipFilterType: IpFilterType): Promise<BaseResponseBean<any>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/manager/logFilter/insert`,
      ipFilterType
    );

    return Promise.resolve(res.data);
  }
  async deleteIp(ipIds: number[]): Promise<BaseResponseBean<any>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/manager/logFilter/deletes`,
      {
        ipIds: ipIds,
      }
    );

    return Promise.resolve(res.data);
  }
  async updateIp(ipFilterType: IpFilterType): Promise<BaseResponseBean<any>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/manager/logFilter/update`,
      ipFilterType
    );

    return Promise.resolve(res.data);
  }
  async downloadFilteredLog(req: {
    configId: number;
    type: string;
  }): Promise<any> {
    const res = await axiosBlob.post(
      `${GATEWAY_URL}/api/v2/manager/logFilter/download`,
      req
    );

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    let filename = res.headers["content-disposition"].substring(
      res.headers["content-disposition"].indexOf("filename=") + 9
    );

    filename = filename.replace(/"/g, "");

    link.href = url;
    link.setAttribute("download", decodeURIComponent(filename));

    document.body.appendChild(link);
    link.click();

    return Promise.resolve(res.data);
  }

  async downloadAccessLog(req: {
    startDate: string;
    endDate: string;
  }): Promise<any> {
    const res = await axiosBlob.post(
      `${GATEWAY_URL}/api/v2/manager/logFilter/download/all`,
      req
    );

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    let filename = res.headers["content-disposition"].substring(
      res.headers["content-disposition"].indexOf("filename=") + 9
    );

    filename = filename.replace(/"/g, "");

    link.href = url;
    link.setAttribute("download", decodeURIComponent(filename));

    document.body.appendChild(link);
    link.click();

    return Promise.resolve(res.data);
  }

  async isExistsIp(ip: string): Promise<boolean> {
    const res = await axios.get("/ip/exists", { params: { ip } });

    return Promise.resolve(res.data);
  }
}

const ipApi: IpApi = new IpServerApi();

export default ipApi;
