import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import replyApi from "../api/replyApi";
import {
  ReplyInsertBean,
  ReplyUpdateConfigParam,
  ReportReply,
  ReportReplyReqDTO,
  ReportReplyUpdateBean,
} from "../../types/Reply";
import { CustomerInfoBean } from "../../types/Info";
import dayjs from "dayjs";

interface ReplyState {
  replyListWithCnt: { list: ReportReply[]; total: number };
  customerInfo?: CustomerInfoBean;
}

const initialState: ReplyState = {
  replyListWithCnt: { list: [], total: 0 },
  customerInfo: undefined,
};

export const trunkGetReplys = createAsyncThunk(
  "reply/getReplys",
  async (req: ReportReplyReqDTO) => {
    const resp = await replyApi.getReplys(req);
    return resp;
  }
);

export const deleteReply = createAsyncThunk(
  "reply/delete",
  async (ids: number[]) => {
    const resp = await replyApi.deleteReply(ids);

    return resp;
  }
);

export const updateConfigReplyFlag = createAsyncThunk(
  "reply/updateConfig",
  async (req: ReplyUpdateConfigParam) => {
    const resp = await replyApi.updateConfigReplyFlag(req);

    return resp;
  }
);

export const updateReply = createAsyncThunk(
  "reply/update",
  async (reportReply: ReportReplyUpdateBean) => {
    const resp = await replyApi.updateReply(reportReply);

    return resp;
  }
);

export const insertReply = createAsyncThunk(
  "reply/insert",
  async (reportReply: ReplyInsertBean) => {
    const resp = await replyApi.insertReply(reportReply);

    return resp;
  }
);

export const trunkGetManualReplys = createAsyncThunk(
  "reply/getManualReply",
  async () => {
    const resp = await replyApi.getManualReplys();

    return resp;
  }
);

const replySlice = createSlice({
  name: "reply",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(trunkGetReplys.fulfilled, (state, { payload: data }) => {
      state.replyListWithCnt = data.data;
      state.customerInfo = data.data.customerInfo;
    });

    builder.addCase(
      updateConfigReplyFlag.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(updateReply.fulfilled, (state, { payload: data }) => {
      state.replyListWithCnt = {
        ...state.replyListWithCnt,
        list: state.replyListWithCnt.list.map((item) =>
          item.replyId === data.data.replyId ? { ...item, ...data.data } : item
        ),
      };
    });
    builder.addCase(insertReply.fulfilled, (state, { payload: data }) => {
      state.replyListWithCnt = {
        list: [
          {
            ...data.data,
            defaultYn: false,
            replyTargetType: 0,
            createDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
          },
          ...state.replyListWithCnt.list,
        ],
        total: state.replyListWithCnt.total + 1,
      };
    });
    builder.addCase(
      trunkGetManualReplys.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
  },
});

export const replyActions = replySlice.actions;

export default replySlice.reducer;
