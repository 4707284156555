import {
  Box,
  Button,
  Checkbox,
  DropdownItem,
  Icons,
  Modal,
  SearchBar,
  SettingButton,
  Table,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import useCustomerStore from "../../../redux/dispatcher/useCustomerStore";
import { useEffect, useState } from "react";
import { CustomerInfoBean } from "../../../types/Info";
import utilsCommon from "../../../shared/utils/common";
import NoInfoImage from "../../../shared/image/NoInfoImage";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

const ManageCustomer: React.FC = () => {
  const { t } = useTranslation();
  const checkboxGroup = useCheckboxGroup<string>([]);
  const toast = useToast();
  const { customerState, getAccessCompanyList, updateCustomerInfo } =
    useCustomerStore();
  const [companyList, chageCompanyList] = useState<CustomerInfoBean[]>([]);
  const [companyName, changeCompanyName] = useState("");
  const [buttonClick, setButtonClick] = useState(0);
  const [updateCustomerModalInfo, changeUpdateCustomerModalInfo] = useState({
    isOpen: false,
    customerCode: "",
  });
  const [updateCompanyName, changeUpdateCompanyName] = useState("");
  const [targetCompanyId, changeTargetCompanyId] = useState("");
  useEffect(() => {
    getAccessCompanyList();
  }, []);
  useEffect(() => {
    chageCompanyList(
      customerState.companyList?.filter((item: CustomerInfoBean) =>
        item.companyName.includes(companyName)
      )
    );
  }, [companyName]);
  useEffect(() => {
    chageCompanyList(customerState.companyList);
  }, [customerState.companyList]);
  useEffect(() => {
    if (updateCustomerModalInfo.customerCode) {
      changeUpdateCompanyName(
        companyList.filter(
          (item) => item.customerCode === updateCustomerModalInfo.customerCode
        )[0].companyName
      );
    } else {
      changeUpdateCompanyName("");
    }
  }, [updateCustomerModalInfo.isOpen]);
  const handleUpdateCustomerInfo = async () => {
    let resp: any = await updateCustomerInfo({
      customerCode: updateCustomerModalInfo.customerCode,
      companyName: updateCompanyName,
    });
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("customer.msg.customerUpdateSuccessMsg"),
        "success"
      );
      getAccessCompanyList();
    } else {
      toast.toastMsg(
        nanoid(),
        t("customer.msg.customerUpdateFailMsg"),
        "error"
      );
    }
    changeUpdateCustomerModalInfo({
      isOpen: false,
      customerCode: "",
    });
  };
  const renderUpdateCustomerModal = () => {
    return (
      <CommonModal
        title={t("customer.updateTitle")}
        open={updateCustomerModalInfo.isOpen}
        onClose={() =>
          changeUpdateCustomerModalInfo({
            isOpen: false,
            customerCode: "",
          })
        }
        afterSubmitButton={() => handleUpdateCustomerInfo()}
        width={536}
        disabledConfirm={updateCompanyName.trim() === ""}
        body={
          <Box direction="column" width={"100%"}>
            <Typography size={14} isBold>
              {t("customer.customerName")}
            </Typography>
            <TextField
              size={"lg"}
              fullWidth
              classes="mg mt-12"
              text={updateCompanyName}
              hasText
              onChange={(e) => changeUpdateCompanyName(e.target.value)}
            />
            <Typography size={14} isBold classes="mg mt-20">
              {t("customer.customerCode")}
            </Typography>
            <TextField
              size={"lg"}
              fullWidth
              classes="mg mt-12"
              disabled
              text={updateCustomerModalInfo.customerCode}
              hasText
            />
          </Box>
        }
      ></CommonModal>
    );
  };
  const renderCustomerDetailModal = () => {
    let targetCompany = companyList.filter(
      (item) => item.customerCode === targetCompanyId
    )[0];
    return (
      <Modal
        title={t("customer.detailTitle")}
        onClose={() => changeTargetCompanyId("")}
        open={targetCompanyId !== ""}
        footer={
          <Box justifyContent="flex-end">
            <Button
              color={"secondary"}
              onClick={() => changeTargetCompanyId("")}
            >
              {t("common.button.cancel")}
            </Button>
          </Box>
        }
        isDivider={false}
      >
        <Box direction="column" width={"100%"}>
          <Box width={"100%"}>
            <Box width={"50%"} direction="column">
              <Typography size={14} isBold>
                {t("customer.customerName")}
              </Typography>
              <Typography classes="mg mt-12">
                {targetCompany.companyName ?? ""}
              </Typography>
            </Box>
            <Box width={"50%"} direction="column">
              <Typography size={14} isBold>
                {t("customer.reportAddress")}
              </Typography>
              <Typography classes="mg mt-12">
                {targetCompany.reportInfo ?? ""}
              </Typography>
            </Box>
          </Box>
          <Box width={"100%"} classes="mg mt-20" direction="column">
            <Typography size={14} isBold>
              {t("customer.customerCode")}
            </Typography>
            <Typography classes="mg mt-12">
              {targetCompany.customerCode ?? ""}
            </Typography>
          </Box>
          <Box width={"100%"} classes="mg mt-20">
            <Box width={"50%"} direction="column">
              <Typography size={14} isBold>
                {t("customer.contractPeriod")}
              </Typography>
              <Typography classes="mg mt-12">
                {utilsCommon.getShortDate(targetCompany.startDate) +
                  "~" +
                  utilsCommon.getShortDate(targetCompany.endDate)}
              </Typography>
            </Box>
            <Box width={"50%"} direction="column">
              <Typography size={14} isBold>
                DB
              </Typography>
              <Typography classes="mg mt-12">
                {targetCompany.dbName ? (
                  <Box alignItems="center">
                    <Icons
                      variant="mindsat"
                      label="icon_check"
                      stroke="#25c16f"
                    />
                    <Typography>{t("common.button.yes")}</Typography>
                  </Box>
                ) : (
                  <Box alignItems="center">
                    <Box classes="dot red__dot">⦁</Box>
                    <Typography>{t("common.button.no")}</Typography>
                  </Box>
                )}
              </Typography>
            </Box>
          </Box>
          <Box width={"100%"} classes="mg mt-20" direction="column">
            <Typography size={14} isBold>
              {t("customer.category")}
            </Typography>
            <Typography classes="mg mt-12">
              {targetCompany.categoryName ?? t("customer.noCategory")}
            </Typography>
          </Box>
        </Box>
      </Modal>
    );
  };
  return (
    <Box classes="main manageCustomer">
      {updateCustomerModalInfo.isOpen && renderUpdateCustomerModal()}
      {targetCompanyId !== "" && renderCustomerDetailModal()}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.customerManagement")]} />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("menu.customerManagement")}
          </Typography>
        </Box>
      </Box>
      <Box classes="mg mt-24" direction="column" width={"100%"}>
        <Box justifyContent="space-between">
          <Box alignItems="center">
            <Typography size={16} isBold>
              {t("customer.customerSimple")}
            </Typography>
            <Typography size={16} isBold color="green" classes="mg ml-4">
              {companyList?.length ?? 0}
            </Typography>
          </Box>
          <Box>
            {checkboxGroup.selected.length === 1 && (
              <Button
                color={"text-noline"}
                startIcon={<Icons variant="mindsat" label="icon_edit" />}
                text={t("common.button.update")}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  changeUpdateCustomerModalInfo({
                    isOpen: true,
                    customerCode: checkboxGroup.selected[0],
                  });
                }}
              />
            )}
            <Box width={200} classes="mindsatSearch mg ml-12">
              <SearchBar
                fullWidth={false}
                placeholder={t("customer.searchName")}
                type="root"
                onChange={(e) => changeCompanyName(e.target.value)}
              />
            </Box>
          </Box>
        </Box>

        {companyList.length > 0 ? (
          <>
            <Table classes="mg mt-10">
              <colgroup>
                {/* 체크박스 */}
                <col style={{ width: "55px" }}></col>
                {/* 고객사 이름 */}
                <col style={{ minWidth: "200px", width: "30%" }}></col>
                {/* 계약기간 */}
                <col style={{ minWidth: "200px", width: "30%" }}></col>
                {/* 신고센터 사서함 */}
                <col style={{ minWidth: "50px", width: "20%" }}></col>
                {/* DB */}
                <col style={{ minWidth: "50px", width: "20%" }}></col>
                {/* 더보기 */}
                <col style={{ width: "80px" }}></col>
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      checked={
                        checkboxGroup.selected.length === companyList.length
                      }
                      onChange={() =>
                        checkboxGroup.handleAllClick(
                          checkboxGroup.selected.length === companyList.length
                            ? []
                            : companyList.map((item) => item.customerCode)
                        )
                      }
                    />
                  </th>
                  <th>{t("customer.customerName")}</th>
                  <th>{t("customer.contractPeriod")}</th>
                  <th>{t("customer.reportAddress")}</th>
                  <th>{"DB"}</th>
                  <th />
                </tr>
              </thead>
            </Table>
            <Box classes="table-scroll">
              <Table>
                <colgroup>
                  {/* 체크박스 */}
                  <col style={{ width: "55px" }}></col>
                  {/* 고객사 이름 */}
                  <col style={{ minWidth: "200px", width: "30%" }}></col>
                  {/* 계약기간 */}
                  <col style={{ minWidth: "200px", width: "30%" }}></col>
                  {/* 신고센터 사서함 */}
                  <col style={{ minWidth: "50px", width: "20%" }}></col>
                  {/* DB */}
                  <col style={{ minWidth: "50px", width: "20%" }}></col>
                  {/* 더보기 */}
                  <col style={{ width: "80px" }}></col>
                </colgroup>
                <tbody>
                  {companyList?.map((item) => (
                    <tr
                      onClick={() => changeTargetCompanyId(item.customerCode)}
                      className="cursor-pointer mindsatTable-item"
                    >
                      <td>
                        <Checkbox
                          checked={checkboxGroup.isSelected(item.customerCode)}
                          onChange={() =>
                            checkboxGroup.handleClick(item.customerCode)
                          }
                        />
                      </td>
                      <td>{item.companyName}</td>
                      <td>
                        {utilsCommon.getShortDate(item.startDate) +
                          "~" +
                          utilsCommon.getShortDate(item.endDate)}
                      </td>
                      <td>
                        {item.reportInfo ? (
                          <Icons
                            variant="mindsat"
                            label="icon_check"
                            stroke="#25c16f"
                          />
                        ) : (
                          <Box classes="dot red__dot">⦁</Box>
                        )}
                      </td>
                      <td>
                        {item.dbName ? (
                          <Icons
                            variant="mindsat"
                            label="icon_check"
                            stroke="#25c16f"
                          />
                        ) : (
                          <Box classes="dot red__dot">⦁</Box>
                        )}
                      </td>
                      <td>
                        <SettingButton>
                          <DropdownItem
                            onClick={(e) => {
                              e.stopPropagation();
                              setButtonClick(
                                buttonClick > 99999 ? 1 : buttonClick + 1
                              );
                              changeUpdateCustomerModalInfo({
                                isOpen: true,
                                customerCode: item.customerCode,
                              });
                            }}
                          >
                            <Icons variant="file" label="rename" />
                            <Typography>{t("common.button.update")}</Typography>
                          </DropdownItem>
                        </SettingButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </>
        ) : (
          <Box
            width={"100%"}
            height={"400px"}
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <NoInfoImage width={400} />
            <Typography size={18} isBold>
              {t("common.msg.noHistoryMsg")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ManageCustomer;
