import axios from "../../shared/utils/axios";
import { BaseResponseBean } from "../../types/Common";
import { SignupType } from "../../types/Signup";

interface SignupApi {
  isExistsEmail(email: string): Promise<boolean>;
  sendAuthMail(email: string): Promise<BaseResponseBean<any>>;
  isMatchAuthCode(req: {
    email: string;
    code: string;
  }): Promise<BaseResponseBean<any>>;
  isExistsReportAddress(address: string): Promise<boolean>;
  doSignup(signupType: SignupType): Promise<BaseResponseBean<any>>;
}

class SignupServerApi implements SignupApi {
  async isExistsEmail(email: string): Promise<boolean> {
    const res = await axios.get("/account/exists", { params: { email } });

    return Promise.resolve(res.data);
  }

  async sendAuthMail(email: string): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/signup/send", null, {
      params: { email },
    });
    return Promise.resolve(res.data);
  }

  async isMatchAuthCode(req: {
    email: string;
    code: string;
  }): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/signup/auth", null, { params: req });

    return Promise.resolve(res.data);
  }

  async isExistsReportAddress(address: string): Promise<boolean> {
    const res = await axios.post("/signup/reportAddress", null, {
      params: { address },
    });
    return Promise.resolve(res.data);
  }

  async doSignup(signupType: SignupType): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/signup", signupType);

    return Promise.resolve(res.data);
  }
}

const signupApi: SignupApi = new SignupServerApi();

export default signupApi;
