/* eslint-disable no-loop-func */
import React, { useState, useEffect, useCallback, useRef } from "react";
import classNames from "classnames";

import "./index.scss";
import {
  Icons,
  Avatar,
  Checkbox,
  Accordion,
  DropdownItem,
  SettingButton,
  Typography,
  Divider,
  useToast,
  ToolTip,
  Modal,
  Box,
  Button,
  IconButton,
} from "fasoo-ui-component-next";
import { CommonProps } from "fasoo-ui-component-next/src/components/helpers/props";
import utilsCommon from "../../shared/utils/common";
import { SimpleTreeNode } from "../../types/User";
import useDeptStore from "../../redux/dispatcher/useDeptStore";
import CreateUpdateDeptModal from "./CreateUpdateDeptModal";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import CommonModal from "../CommonModal";

export interface TreeItemProps extends CommonProps {
  rootCode: string;
  treeNode: SimpleTreeNode;
  treeNodes: SimpleTreeNode[];
  defaultExpanded?: boolean;
  changeClickedId?: (id: string) => void;
  clickedId?: string;
  classes?: string;
  treeType?: "DEPT_ONLY" | "ALL";
  depth: number;
  isShowUserCnt?: boolean;
  isDeptCheck?: boolean;
  isUserCheck?: boolean;
  checkDeptIds?: string[];
  checkUserIds?: string[];
  checkboxDeptGroup?: any;
  checkboxUserGroup?: any;
  onChecked?: (node: SimpleTreeNode) => void;
  hoveredId?: string;
  disableCheck?: boolean;
  isOranPage?: boolean;
  changeHoveredId?: (id: string) => void;
}

export interface TreeItemsProps extends CommonProps {
  rootCode: string;
  treeNodes: SimpleTreeNode[];
  treeType?: "DEPT_ONLY" | "ALL";
  defaultExpanded?: boolean;
  clickedId?: string;
  changeClickedId?: (id: string) => void;
  classes?: string;
  depth: number;
  isShowUserCnt?: boolean;
  isDeptCheck?: boolean;
  isUserCheck?: boolean;
  checkDeptIds?: string[];
  checkUserIds?: string[];
  checkboxDeptGroup?: any;
  checkboxUserGroup?: any;
  disableCheck?: boolean;
  onChecked?: (node: SimpleTreeNode) => void;
  isOranPage?: boolean;
}

export const TreeSimpleItem: React.FC<TreeItemProps> = ({
  rootCode,
  treeNodes,
  treeType = "ALL",
  treeNode,
  defaultExpanded = true,
  changeClickedId,
  clickedId,
  classes,
  depth,
  isShowUserCnt,
  isDeptCheck,
  isUserCheck,
  checkDeptIds,
  checkUserIds,
  checkboxDeptGroup,
  checkboxUserGroup,
  onChecked,
  hoveredId,
  disableCheck,
  changeHoveredId,
  isOranPage = false,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const fileMemuRef = useRef<HTMLInputElement>(null);

  const { getDeptList, getOrganUserList, deleteDept } = useDeptStore();
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [buttonClick, setButtonClick] = useState(0);
  const [regitOrUpdateDeptInfo, changeRegitOrUpdateDeptInfo] = useState({
    isOpen: false,
    parentCode: "",
    deptCode: "",
    deptName: "",
    parentName: "",
    type: "CLOSE",
  });
  const [isDeleteDeptModal, toggleDeletDeptModal] = useState(false);
  const [targetUser, chageTargetUser] = useState({
    nodes: [],
    targetValue: false,
  });
  useEffect(() => {
    targetUser.nodes.map(
      async (user) => await handleOnCheck(user, targetUser.targetValue)
    );
  }, [targetUser]);
  useEffect(() => {
    changeHoveredId && changeHoveredId("");
  }, [buttonClick]);
  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  });
  const clickOutside = useCallback((e: MouseEvent) => {
    if (
      fileMemuRef.current &&
      changeHoveredId &&
      !(fileMemuRef.current! as any).contains(e.target)
    ) {
      changeHoveredId("");
    }
  }, []);

  const handleOnCheck = async (
    treeNode: SimpleTreeNode,
    targetValue: boolean
  ) => {
    let returnDeptNodes = checkboxDeptGroup.selected;
    let returnUserNodes = checkboxUserGroup.selected;
    let returnAllNodes = [
      ...checkboxDeptGroup.selected,
      ...checkboxUserGroup.selected,
    ];
    if (treeNode.type === "user") {
      checkboxUserGroup.handleClick(treeNode.deptCode);
    } else {
      let queue = treeNodes.filter(
        (item) => item.parentCode === treeNode.deptCode
      );
      let newDepts = treeNodes
        .filter((item) => item.deptCode === treeNode.deptCode)
        .map((item) => item.deptCode);

      while (queue.length > 0) {
        if (
          queue[queue.length - 1].type === "dept" &&
          treeNodes.filter(
            (item) => item.parentCode === queue[queue.length - 1].deptCode
          )
        ) {
          queue = [
            ...treeNodes.filter(
              (item) => item.parentCode === queue[queue.length - 1].deptCode
            ),
            ...queue,
          ];
        }
        newDepts.push(queue[queue.length - 1].deptCode);
        queue.pop();
      }
      if (targetValue) {
        returnDeptNodes = newDepts.concat(
          checkboxDeptGroup.selected.filter(
            (item: string) => newDepts.indexOf(item) < 0
          )
        );
      } else {
        returnDeptNodes = checkboxDeptGroup.selected.filter(
          (item: string) => !newDepts.includes(item)
        );
      }
      let resp: any = await getDeptList(returnDeptNodes);
      if (resp.payload) {
        let newUsers =
          resp.payload?.data?.list?.map(
            (item: SimpleTreeNode) => item.deptCode
          ) ?? [];
        if (targetValue) {
          returnUserNodes = newUsers.concat(
            checkboxUserGroup.selected.filter(
              (item: string) => newUsers.indexOf(item) < 0
            )
          );
        } else {
          returnUserNodes = checkboxUserGroup.selected.filter((item: string) =>
            newUsers.includes(item)
          );
        }
        checkboxUserGroup.handleAllClick(returnUserNodes);
      }
    }
    if (targetValue) {
      returnAllNodes = [...returnAllNodes, treeNode.deptCode];
    } else {
      returnAllNodes = returnAllNodes.filter(
        (item) => item !== treeNode.deptCode
      );
    }
    let nextNode: SimpleTreeNode | null = treeNode;
    while (nextNode) {
      const negibors = treeNodes.filter(
        (item) => item.parentCode === nextNode?.parentCode
      );
      if (
        negibors.filter((item) => !returnAllNodes.includes(item.deptCode))
          .length > 0 &&
        returnDeptNodes.includes(nextNode.parentCode)
      ) {
        returnDeptNodes = returnDeptNodes.filter(
          (item1: string) => item1 !== nextNode?.parentCode
        );
        returnAllNodes = returnAllNodes.filter(
          (item1: string) => item1 !== nextNode?.parentCode
        );
        nextNode = treeNodes.filter(
          (item2) => item2.deptCode === nextNode?.parentCode
        )[0];
      } else if (
        negibors.filter((item) => !returnAllNodes.includes(item.deptCode))
          .length === 0 &&
        !returnDeptNodes.includes(nextNode.parentCode)
      ) {
        returnDeptNodes = [...returnDeptNodes, nextNode.parentCode];
        returnAllNodes = [...returnAllNodes, nextNode.parentCode];
        nextNode = treeNodes.filter(
          (item2) => item2.deptCode === nextNode?.parentCode
        )[0];
      } else {
        nextNode = null;
      }
      if (nextNode?.deptCode === "COMPANY") {
        nextNode = null;
      }
    }
    checkboxDeptGroup.handleAllClick(returnDeptNodes);
  };
  const handleDeleteDept = async (deptCode: string) => {
    let resp: any = await deleteDept(deptCode);
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("dept.msg.deleteSuccessMsg"), "success");
      changeClickedId && deptCode === clickedId && changeClickedId("COMPANY");
    } else if (resp.payload && resp.payload.code === "DATA_USED") {
      toast.toastMsg(nanoid(), t("dept.msg.deleteDeptInfoMsg"), "error");
      toggleDeletDeptModal(false);
      return;
    } else {
      toast.toastMsg(
        nanoid(),
        resp?.payload?.message ?? t("dept.msg.deleteFailMsg"),
        "error"
      );
    }

    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
    toggleDeletDeptModal(false);
  };
  return (
    <li
      className={classNames(
        "accordion__item-root",
        {
          "accordion__item__tree--on": expanded,
        },
        classes
      )}
    >
      <div
        className={
          treeNode.deptCode === clickedId ? "accordion__item--clicked" : ""
        }
      >
        {regitOrUpdateDeptInfo.isOpen && (
          <CreateUpdateDeptModal
            isOpen={regitOrUpdateDeptInfo.isOpen}
            parentCode={regitOrUpdateDeptInfo.parentCode}
            type={regitOrUpdateDeptInfo.type}
            deptName={regitOrUpdateDeptInfo.deptName}
            deptCode={regitOrUpdateDeptInfo.deptCode}
            parentName={regitOrUpdateDeptInfo.parentName}
            onClose={() =>
              changeRegitOrUpdateDeptInfo({
                isOpen: false,
                parentCode: "",
                deptCode: "",
                deptName: "",
                parentName: "",
                type: "CLOSE",
              })
            }
          />
        )}
        {isDeleteDeptModal && (
          <Modal
            title={t("dept.deptDeleteDept")}
            onClose={() => toggleDeletDeptModal(false)}
            open={isDeleteDeptModal}
            width={480}
            isDivider={false}
            footer={
              <Box justifyContent="flex-end">
                <Button
                  text={t("common.button.cancel")}
                  color="secondary"
                  onClick={() => toggleDeletDeptModal(false)}
                />
                <Button
                  classes={"mg ml-8"}
                  color="warning"
                  onClick={() => handleDeleteDept(treeNode.deptCode)}
                >
                  {t("common.button.delete")}
                </Button>
              </Box>
            }
          >
            <Box width={"100%"}>
              <Typography color="secondary">
                {t("common.msg.deleteConfirmMsg")}
              </Typography>
            </Box>
          </Modal>
        )}
        <span
          className="accordion__item__tree"
          style={{
            display:
              treeType === "ALL" || treeNode.type === "dept" ? "" : "none",
          }}
        >
          <button
            onClick={() => {
              if (
                treeType === "ALL" &&
                treeNode.userCount > 0 &&
                treeNode.type === "dept" &&
                !treeNodes.findLast(
                  (item) =>
                    item.parentCode === treeNode.deptCode &&
                    item.type === "user"
                )
              ) {
                getDeptList([treeNode.deptCode]);
              }
              changeClickedId && changeClickedId(treeNode.deptCode);
            }}
          >
            <div
              className={`accordion__item__tree__depth accordion__item__tree__depth_${depth}`}
            >
              {(treeNode.deptCount > 0 ||
                (treeType === "ALL" && treeNode.userCount > 0)) && (
                // <IconButton >
                <span
                  onClick={(e) => {
                    isOranPage && e.stopPropagation();
                    setExpanded(!expanded);
                  }}
                >
                  <Icons
                    variant="arrow"
                    label="singleDownArrow"
                    classes="accordion_arrowDown"
                    stroke="#717985"
                  />
                </span>

                // </IconButton>
              )}
              {treeNode.type === "dept" ? (
                <Avatar
                  variant="team"
                  color={utilsCommon.pickColor(treeNode.deptCode)}
                  classes={`mg mr-8 ${
                    treeNode.deptCount > 0 ||
                    (treeType === "ALL" && treeNode.userCount > 0)
                      ? "ml-14"
                      : "ml-34"
                  }`}
                />
              ) : (
                <Avatar
                  color={utilsCommon.pickColor(treeNode.deptCode)}
                  classes={`mg mr-8 ${
                    treeNode.deptCount > 0 ||
                    (treeType === "ALL" && treeNode.userCount > 0)
                      ? "ml-14"
                      : "ml-34"
                  }`}
                  name={treeNode.deptName}
                />
              )}
              <ToolTip
                multiline
                message={treeNode.deptName}
                maxWidth={"calc(100% - 75px)"}
              >
                <span
                  className={
                    treeNode.deptCode === clickedId
                      ? "accordion__item--clicked-span overflow-hidden"
                      : "overflow-hidden"
                  }
                  style={{ lineHeight: "normal" }}
                >
                  {treeNode.deptName}
                </span>
              </ToolTip>
            </div>
            {((treeNode.type === "dept" && isDeptCheck) ||
              (treeNode.type === "user" && isUserCheck)) && (
              <Checkbox
                radio
                checked={
                  treeNode.type === "dept" && isDeptCheck
                    ? checkboxDeptGroup?.isSelected(treeNode.deptCode)
                    : checkboxUserGroup?.isSelected(treeNode.deptCode)
                }
                disabled={disableCheck}
                onChange={() =>
                  handleOnCheck &&
                  handleOnCheck(
                    treeNode,
                    treeNode.type === "dept" && isDeptCheck
                      ? !checkboxDeptGroup?.isSelected(treeNode.deptCode)
                      : !checkboxUserGroup?.isSelected(treeNode.deptCode)
                  )
                }
                classes={"mg mr-32"}
              />
            )}
            {isOranPage && (
              <Box inputRef={fileMemuRef}>
                <SettingButton
                  classes={classNames("moreItem", {
                    hovered: hoveredId === treeNode.deptCode,
                  })}
                  id={"moreItems-" + treeNode.deptCode}
                  clickEvent={() =>
                    changeHoveredId && changeHoveredId(treeNode.deptCode)
                  }
                >
                  <DropdownItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                      changeRegitOrUpdateDeptInfo({
                        isOpen: true,
                        deptCode: "",
                        parentCode: treeNode.deptCode,
                        deptName: "",
                        parentName: treeNode.deptName,
                        type: "CREATE",
                      });
                    }}
                  >
                    <Icons variant="common" label="plus" />
                    <Typography>{t("dept.deptAddSimple")}</Typography>
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                      changeRegitOrUpdateDeptInfo({
                        isOpen: true,
                        deptCode: treeNode.deptCode,
                        parentCode: treeNode.parentCode,
                        type: "UPDATE",
                        deptName: treeNode.deptName,
                        parentName:
                          treeNodes.filter(
                            (item) => item.deptCode === treeNode.parentCode
                          )[0]?.deptName ?? "COMPANY",
                      });
                    }}
                  >
                    <Icons variant="file" label="rename" />
                    <Typography> {t("common.button.update")}</Typography>
                  </DropdownItem>
                  {treeNode.deptCode !== "COMPANY" && (
                    <>
                      <Divider direction="row" />
                      <DropdownItem
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDeletDeptModal(true);
                        }}
                        isRed
                        classes="mg mt-2 mb-2"
                      >
                        <Icons variant="common" label="trash" stroke={"red"} />
                        <Typography> {t("common.button.delete")}</Typography>
                      </DropdownItem>
                    </>
                  )}
                </SettingButton>
              </Box>
            )}
          </button>
        </span>
      </div>
      <div className="accordion__item__tree__body">
        <Accordion>
          {treeNodes
            .filter((item) => item.parentCode === rootCode)
            ?.map((treeNode1) => (
              <TreeSimpleItem
                rootCode={treeNode1.deptCode}
                treeNodes={treeNodes}
                treeNode={treeNode1}
                treeType={treeType}
                depth={depth + 1}
                isDeptCheck={isDeptCheck}
                isUserCheck={isUserCheck}
                checkDeptIds={checkDeptIds}
                checkUserIds={checkUserIds}
                checkboxDeptGroup={checkboxDeptGroup}
                checkboxUserGroup={checkboxUserGroup}
                onChecked={onChecked}
                isShowUserCnt={isShowUserCnt}
                hoveredId={hoveredId}
                disableCheck={disableCheck}
                clickedId={clickedId}
                changeClickedId={changeClickedId}
                changeHoveredId={changeHoveredId}
                isOranPage={isOranPage}
              />
            ))}
        </Accordion>
      </div>
    </li>
  );
};

const TreeSimple: React.FC<TreeItemsProps> = ({
  rootCode,
  treeNodes,
  defaultExpanded = true,
  clickedId,
  changeClickedId,
  classes,
  depth = 0,
  isShowUserCnt = false,
  isDeptCheck = false,
  isUserCheck = false,
  checkDeptIds = [],
  checkUserIds = [],
  onChecked,
  treeType,
  isOranPage,
  disableCheck,
  checkboxDeptGroup,
  checkboxUserGroup,
}) => {
  const [hoveredId, changeHoveredId] = useState("");

  return (
    <div className={classNames("tree")}>
      <Accordion>
        {treeNodes &&
        treeNodes?.filter((item) => item.deptCode === rootCode).length > 0
          ? treeNodes?.filter((item) => item.deptCode === rootCode) && (
              <TreeSimpleItem
                rootCode={rootCode}
                treeNodes={treeNodes}
                treeNode={
                  treeNodes.filter((item) => item.deptCode === rootCode)[0]
                }
                depth={depth}
                defaultExpanded={defaultExpanded}
                clickedId={clickedId}
                treeType={treeType}
                changeClickedId={changeClickedId}
                classes={classes}
                isShowUserCnt={isShowUserCnt}
                isDeptCheck={isDeptCheck}
                isUserCheck={isUserCheck}
                checkDeptIds={checkDeptIds}
                checkUserIds={checkUserIds}
                onChecked={onChecked}
                disableCheck={disableCheck}
                checkboxDeptGroup={checkboxDeptGroup}
                checkboxUserGroup={checkboxUserGroup}
                isOranPage={isOranPage}
                hoveredId={hoveredId}
                changeHoveredId={changeHoveredId}
              />
            )
          : ""}
      </Accordion>
    </div>
  );
};

export default TreeSimple;
