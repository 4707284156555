import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import reportRankApi from "../api/reportRankApi";
import { DateBean } from "../../types/Report";
import {
  ConfigCodeListType,
  DateCodeListType,
  ReportRankType,
  ReportStateType,
} from "../../types/ReportRank";
import { ConfigListType } from "../../types/Send";

interface ReportRankState {
  programConfigInfo?: ConfigListType;
  rankInfo?: ReportRankType;
  reportPeriod?: ReportStateType;
}

const initialState: ReportRankState = {
  programConfigInfo: undefined,
  rankInfo: undefined,
  reportPeriod: undefined,
};

export const trunkGetRank = createAsyncThunk(
  "reportRank/getRank",
  async (config_ids: number[]) => {
    const resp = await reportRankApi.getRank(config_ids);

    return resp;
  }
);
export const trunkGetRankByPeriod = createAsyncThunk(
  "reportRank/getRankByPeriod",
  async (dateBean: DateBean) => {
    const resp = await reportRankApi.getRankByPeriod(dateBean);

    return resp;
  }
);

export const trunkGetRankDetail = createAsyncThunk(
  "reportRank/getRankDetail",
  async (configCodeListType: ConfigCodeListType) => {
    const resp = await reportRankApi.getRankDetail(configCodeListType);

    return resp;
  }
);

export const trunkGetUserReportRankDetailByConfig = createAsyncThunk(
  "reportRank/getUserReportRankDetailByConfig",
  async (req: { type: number; configCodeListType: ConfigCodeListType }) => {
    const resp = await reportRankApi.getUserReportRankDetailByConfig(req);

    return resp;
  }
);

export const trunkGetRankDetailByPeriod = createAsyncThunk(
  "reportRank/getRankDetailByPeriod",
  async (dateCodeListType: DateCodeListType) => {
    const resp = await reportRankApi.getRankDetailByPeriod(dateCodeListType);

    return resp;
  }
);

export const trunkGetRankDeptDetail = createAsyncThunk(
  "reportRank/getRankDeptDetail",
  async (configCodeListType: ConfigCodeListType) => {
    const resp = await reportRankApi.getRankDeptDetail(configCodeListType);

    return resp;
  }
);

export const trunkGetRankDeptDetailByPeriod = createAsyncThunk(
  "reportRank/getRankDeptDetailByPeriod",
  async (dateCodeListType: DateCodeListType) => {
    const resp = await reportRankApi.getRankDeptDetailByPeriod(
      dateCodeListType
    );

    return resp;
  }
);

export const trunkGetProgramConfigInfo = createAsyncThunk(
  "reportRank/getConfigInfo",
  async (configId: number) => {
    const resp = await reportRankApi.getConfigInfo(configId);

    return resp;
  }
);

const reportRankSlice = createSlice({
  name: "reportRank",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(trunkGetRank.fulfilled, (state, { payload: data }) => {
      state.rankInfo = data;
    });
    builder.addCase(
      trunkGetRankByPeriod.fulfilled,
      (state, { payload: data }) => {
        state.reportPeriod = data.data;
      }
    );
    builder.addCase(
      trunkGetRankDetail.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkGetUserReportRankDetailByConfig.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkGetRankDetailByPeriod.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkGetRankDeptDetail.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkGetRankDeptDetailByPeriod.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkGetProgramConfigInfo.fulfilled,
      (state, { payload: data }) => {
        state.programConfigInfo = data;
      }
    );
  },
});

export const reportRankActions = reportRankSlice.actions;

export default reportRankSlice.reducer;
