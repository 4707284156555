const NoTemplateImage = ({
  classes,
  width,
  height,
}: {
  classes?: string;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width || "360"}
      height={height || "200"}
      className={classes}
      viewBox="0 0 360 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="128.656"
        y="10"
        width="7"
        height="7"
        transform="rotate(45 128.656 10)"
        fill="#FFC34E"
        fill-opacity="0.5"
      />
      <rect
        x="308.656"
        y="182"
        width="7"
        height="7"
        transform="rotate(45 308.656 182)"
        fill="#FFC34E"
        fill-opacity="0.5"
      />
      <rect
        x="98.8984"
        y="16"
        width="12"
        height="12"
        transform="rotate(45 98.8984 16)"
        fill="#FFC34E"
      />
      <rect
        x="46.2344"
        y="56.6375"
        width="103"
        height="133"
        transform="rotate(-10 46.2344 56.6375)"
        fill="#F5F8FB"
      />
      <circle
        cx="69.1159"
        cy="68.3416"
        r="6.5"
        transform="rotate(-10 69.1159 68.3416)"
        fill="#67E0C7"
      />
      <rect
        x="117.076"
        y="56.5535"
        width="26.6319"
        height="6.65796"
        transform="rotate(-10 117.076 56.5535)"
        fill="#DDE1E7"
      />
      <rect
        width="103"
        height="16"
        transform="matrix(0.984808 -0.173648 -0.173647 -0.984808 69.3301 187.617)"
        fill="#CCD1D9"
      />
      <rect
        x="67.1406"
        y="94.5837"
        width="66"
        height="3"
        transform="rotate(-10 67.1406 94.5837)"
        fill="#DDE1E7"
      />
      <rect
        x="65.5781"
        y="85.7205"
        width="66"
        height="3"
        transform="rotate(-10 65.5781 85.7205)"
        fill="#DDE1E7"
      />
      <rect
        x="72.3516"
        y="124.128"
        width="66"
        height="3"
        transform="rotate(-10 72.3516 124.128)"
        fill="#DDE1E7"
      />
      <rect
        x="70.7891"
        y="115.264"
        width="66"
        height="3"
        transform="rotate(-10 70.7891 115.264)"
        fill="#DDE1E7"
      />
      <rect
        x="77.5605"
        y="153.672"
        width="66"
        height="3"
        transform="rotate(-10 77.5605 153.672)"
        fill="#DDE1E7"
      />
      <rect
        x="75.998"
        y="144.808"
        width="66"
        height="3"
        transform="rotate(-10 75.998 144.808)"
        fill="#DDE1E7"
      />
      <rect
        x="212.33"
        y="38.752"
        width="103"
        height="133"
        transform="rotate(10 212.33 38.752)"
        fill="#F5F8FB"
      />
      <circle
        cx="229.827"
        cy="57.5759"
        r="6.5"
        transform="rotate(10 229.827 57.5759)"
        fill="#67E0C7"
      />
      <rect
        x="278.928"
        y="62.9023"
        width="26.6319"
        height="6.65796"
        transform="rotate(10 278.928 62.9023)"
        fill="#DDE1E7"
      />
      <rect
        width="103"
        height="16"
        transform="matrix(0.984808 0.173648 0.173649 -0.984808 189.234 169.731)"
        fill="#CCD1D9"
      />
      <rect
        x="218.998"
        y="81.5605"
        width="66"
        height="3"
        transform="rotate(10 218.998 81.5605)"
        fill="#DDE1E7"
      />
      <rect
        x="220.561"
        y="72.6973"
        width="66"
        height="3"
        transform="rotate(10 220.561 72.6973)"
        fill="#DDE1E7"
      />
      <rect
        x="213.789"
        y="111.104"
        width="66"
        height="3"
        transform="rotate(10 213.789 111.104)"
        fill="#DDE1E7"
      />
      <rect
        x="215.352"
        y="102.241"
        width="66"
        height="3"
        transform="rotate(10 215.352 102.241)"
        fill="#DDE1E7"
      />
      <rect
        x="208.58"
        y="140.648"
        width="66"
        height="3"
        transform="rotate(10 208.58 140.648)"
        fill="#DDE1E7"
      />
      <rect
        x="210.143"
        y="131.785"
        width="66"
        height="3"
        transform="rotate(10 210.143 131.785)"
        fill="#DDE1E7"
      />
      <rect x="120" y="30.6836" width="120" height="154" fill="#F5F8FB" />
      <rect x="177" y="97.6836" width="32" height="4" fill="#DDE1E7" />
      <rect x="177" y="109.684" width="18" height="4" fill="#DDE1E7" />
      <rect x="177" y="121.684" width="32" height="4" fill="#DDE1E7" />
      <rect x="129" y="139.684" width="102" height="36" fill="#4593FC" />
      <rect x="218" y="149.684" width="5" height="5" fill="#1659B3" />
      <rect x="137" y="149.684" width="75" height="5" fill="white" />
      <rect x="137" y="160.684" width="42" height="5" fill="#1659B3" />
      <rect
        x="199"
        y="51.6836"
        width="56"
        height="5"
        transform="rotate(-180 199 51.6836)"
        fill="#DDE1E7"
      />
      <rect
        x="139"
        y="51.6836"
        width="5"
        height="5"
        transform="rotate(-180 139 51.6836)"
        fill="#DDE1E7"
      />
      <rect
        x="226"
        y="81.6836"
        width="83"
        height="4.99999"
        transform="rotate(-180 226 81.6836)"
        fill="#DDE1E7"
      />
      <rect
        x="139"
        y="81.6836"
        width="5"
        height="5"
        transform="rotate(-180 139 81.6836)"
        fill="#DDE1E7"
      />
      <rect
        x="186"
        y="66.6836"
        width="43"
        height="5"
        transform="rotate(-180 186 66.6836)"
        fill="#DDE1E7"
      />
      <rect
        x="139"
        y="66.6836"
        width="5"
        height="5"
        transform="rotate(-180 139 66.6836)"
        fill="#DDE1E7"
      />
      <rect x="133.641" y="92.6836" width="35" height="38" fill="#DDE1E7" />
      <circle cx="239.355" cy="31" r="15" fill="#67E0C7" />
      <path
        d="M231.855 31L236.855 36L246.855 26"
        stroke="white"
        stroke-width="4"
      />
    </svg>
  );
};

export default NoTemplateImage;
