import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import axiosForm from "../../shared/utils/axiosForm";
import {
  BaseResponseArrDTO,
  BaseResponseBean,
  BaseResponseDTO,
} from "../../types/Common";
import {
  DeleteSiteResourceBean,
  DocumentResDTO,
  ImageResDTO,
  ImagesInfo,
  MailTemplate,
  PhishingDocument,
  SiteInfo,
  SiteResDTO,
  SiteResourceBean,
} from "../../types/Info";
import { ScenarioCommonReqDTO } from "../../types/Send";

const GATEWAY_URL = config.servers.gateway.url;

interface ContentApi {
  getAttaches(
    req: ScenarioCommonReqDTO
  ): Promise<BaseResponseArrDTO<PhishingDocument[]>>;
  getSites(req: ScenarioCommonReqDTO): Promise<BaseResponseArrDTO<SiteInfo[]>>;
  uploadSites(req: {
    file: any;
    request: {
      siteName: string;
    };
  }): Promise<BaseResponseDTO<SiteInfo>>;
  getSitesFile(req: {
    site_id: number;
    fileName: string;
  }): Promise<BaseResponseBean<MailTemplate>>;
  updateSites(req: {
    file: File | null;
    request: {
      siteId: number;
      siteName: string;
    };
  }): Promise<BaseResponseDTO<SiteInfo>>;
  deleteSites(req: {
    request: {
      siteIds: number[];
    };
  }): Promise<BaseResponseDTO<SiteResDTO>>;
  getSitesResource(): Promise<BaseResponseArrDTO<SiteResourceBean[]>>;
  updateSiteResource(req: {
    file: File | null;
    request: {
      resourceId: number;
      resourceName: string;
    };
  }): Promise<BaseResponseDTO<SiteResourceBean>>;
  updateFileSiteResource(req: {
    id: number;
    resourceName: string;
    file: any;
  }): Promise<any>;
  deleteSiteResource(req: {
    request: {
      resourceIds: number[];
    };
  }): Promise<BaseResponseDTO<DeleteSiteResourceBean>>;
  insertSiteResource(req: {
    request: {
      resourceName: string;
    };
    file: any;
  }): Promise<BaseResponseDTO<SiteResourceBean>>;
  getImages(
    req: ScenarioCommonReqDTO
  ): Promise<BaseResponseArrDTO<ImagesInfo[]>>;
  uploadImages(req: {
    file: any;
    request: {
      imageName: string;
    };
  }): Promise<BaseResponseDTO<ImagesInfo>>;
  updateImages(req: {
    file: File | null;
    request: {
      imageName: string;
      imageId: number;
    };
  }): Promise<BaseResponseDTO<SiteInfo>>;
  updateFileImages(req: {
    id: number;
    fileName: string;
    file: any;
  }): Promise<BaseResponseBean<ImagesInfo>>;
  deleteImages(req: {
    request: {
      imageIds: number[];
    };
  }): Promise<BaseResponseDTO<ImageResDTO>>;
  uploadAttach(req: {
    file: any;
    request: {
      documentName: string;
    };
  }): Promise<BaseResponseDTO<PhishingDocument>>;
  deleteAttach(req: {
    request: {
      documentIds: number[];
    };
  }): Promise<BaseResponseDTO<DocumentResDTO>>;
  updateAttach(req: {
    file: File | null;
    request: {
      documentName: string;
      documentId: number;
    };
  }): Promise<BaseResponseDTO<DocumentResDTO>>;
  updateFileAttach(req: {
    fileName: string;
    file: any;
    filePath: string;
    id: number;
  }): Promise<BaseResponseBean<PhishingDocument>>;
  downloadFileAttach(id: number): Promise<any>;
}
class ContentServerApi implements ContentApi {
  async getAttaches(
    req: ScenarioCommonReqDTO
  ): Promise<BaseResponseArrDTO<PhishingDocument[]>> {
    const res = await axios.post<BaseResponseArrDTO<PhishingDocument[]>>(
      `${GATEWAY_URL}/api/v2/scenario/document/list`,
      req
    );
    return Promise.resolve(res.data);
  }
  async getSites(
    req: ScenarioCommonReqDTO
  ): Promise<BaseResponseArrDTO<SiteInfo[]>> {
    const res = await axios.post<BaseResponseArrDTO<SiteInfo[]>>(
      `${GATEWAY_URL}/api/v2/scenario/site/list`,
      req
    );
    return Promise.resolve(res.data);
  }
  async uploadSites(req: {
    file: File;
    request: {
      siteName: string;
    };
  }): Promise<BaseResponseDTO<SiteInfo>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);
    formData.append("file", req.file);

    const res = await axios.post<BaseResponseDTO<SiteInfo>>(
      `${GATEWAY_URL}/api/v2/scenario/site/insert`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return Promise.resolve(res.data);
  }
  async getSitesFile(req: {
    site_id: number;
    fileName: string;
  }): Promise<BaseResponseBean<MailTemplate>> {
    const res = await axiosForm.post<BaseResponseBean<MailTemplate>>(
      `/sites/file`,
      req
    );
    return Promise.resolve(res.data);
  }
  async updateSites(req: {
    file: File | null;
    request: {
      siteId: number;
      siteName: string;
    };
  }): Promise<BaseResponseDTO<SiteInfo>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);
    formData.append("file", req.file as File);

    const res = await axiosForm.post<BaseResponseDTO<SiteInfo>>(
      `${GATEWAY_URL}/api/v2/scenario/site/update`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }
  async deleteSites(req: {
    request: {
      siteIds: number[];
    };
  }): Promise<BaseResponseDTO<SiteResDTO>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);

    const res = await axios.post<BaseResponseDTO<SiteResDTO>>(
      `${GATEWAY_URL}/api/v2/scenario/site/deletes`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }
  async getSitesResource(): Promise<BaseResponseArrDTO<SiteResourceBean[]>> {
    const res = await axios.post<BaseResponseArrDTO<SiteResourceBean[]>>(
      `${GATEWAY_URL}/api/v2/scenario/siteResource/list`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async insertSiteResource(req: {
    request: {
      resourceName: string;
    };
    file: any;
  }): Promise<BaseResponseDTO<SiteResourceBean>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });

    formData.append("request", requestBlob);
    formData.append("file", req.file);

    const res = await axiosForm.post<BaseResponseDTO<SiteResourceBean>>(
      `${GATEWAY_URL}/api/v2/scenario/siteResource/insert`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }
  async updateSiteResource(req: {
    file: File | null;
    request: {
      resourceId: number;
      resourceName: string;
    };
  }): Promise<BaseResponseDTO<SiteResourceBean>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);
    formData.append("file", req.file as File);

    const res = await axiosForm.post<BaseResponseDTO<SiteResourceBean>>(
      `${GATEWAY_URL}/api/v2/scenario/siteResource/update`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }
  async updateFileSiteResource(req: {
    id: number;
    resourceName: string;
    file: any;
  }): Promise<any> {
    let formData = new FormData();
    for (const [key, value] of Object.entries(req)) {
      formData.append(key, value);
    }
    const res = await axiosForm.post<any>(
      `/site/resource/update/file`,
      formData
    );
    return Promise.resolve(res.data);
  }
  async deleteSiteResource(req: {
    request: {
      resourceIds: number[];
    };
  }): Promise<BaseResponseDTO<DeleteSiteResourceBean>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);

    const res = await axios.post<BaseResponseDTO<DeleteSiteResourceBean>>(
      `${GATEWAY_URL}/api/v2/scenario/siteResource/deletes`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }
  async getImages(
    req: ScenarioCommonReqDTO
  ): Promise<BaseResponseArrDTO<ImagesInfo[]>> {
    const res = await axios.post<BaseResponseArrDTO<ImagesInfo[]>>(
      `${GATEWAY_URL}/api/v2/scenario/image/list`,
      req
    );
    return Promise.resolve(res.data);
  }
  async uploadImages(req: {
    file: File;
    request: {
      imageName: string;
    };
  }): Promise<BaseResponseDTO<ImagesInfo>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);
    formData.append("file", req.file);

    const res = await axios.post<BaseResponseDTO<ImagesInfo>>(
      `${GATEWAY_URL}/api/v2/scenario/image/insert`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }
  async updateImages(req: {
    file: File | null;
    request: {
      imageName: string;
      imageId: number;
    };
  }): Promise<BaseResponseDTO<SiteInfo>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);
    formData.append("file", req.file as File);

    const res = await axiosForm.post<BaseResponseDTO<SiteInfo>>(
      `${GATEWAY_URL}/api/v2/scenario/image/update`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }
  async updateFileImages(req: {
    id: number;
    fileName: string;
    file: any;
  }): Promise<BaseResponseBean<ImagesInfo>> {
    let formData = new FormData();
    for (const [key, value] of Object.entries(req)) {
      formData.append(key, value);
    }
    const res = await axiosForm.post<BaseResponseBean<ImagesInfo>>(
      `/images/file/update`,
      formData
    );
    return Promise.resolve(res.data);
  }
  async deleteImages(req: {
    request: {
      imageIds: number[];
    };
  }): Promise<BaseResponseDTO<ImageResDTO>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);

    const res = await axios.post<BaseResponseDTO<ImageResDTO>>(
      `${GATEWAY_URL}/api/v2/scenario/image/deletes`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }
  async uploadAttach(req: {
    file: any;
    request: {
      documentName: string;
    };
  }): Promise<BaseResponseDTO<PhishingDocument>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);
    formData.append("file", req.file);

    const res = await axiosForm.post<BaseResponseDTO<PhishingDocument>>(
      `${GATEWAY_URL}/api/v2/scenario/document/insert`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }

  async deleteAttach(req: {
    request: {
      documentIds: number[];
    };
  }): Promise<BaseResponseDTO<DocumentResDTO>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);

    const res = await axios.post<BaseResponseDTO<DocumentResDTO>>(
      `${GATEWAY_URL}/api/v2/scenario/document/deletes`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }
  async updateAttach(req: {
    file: File | null;
    request: {
      documentName: string;
      documentId: number;
    };
  }): Promise<BaseResponseDTO<DocumentResDTO>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);
    formData.append("file", req.file as File);

    const res = await axiosForm.post<BaseResponseDTO<DocumentResDTO>>(
      `${GATEWAY_URL}/api/v2/scenario/document/update`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }
  async updateFileAttach(req: {
    fileName: string;
    file: any;
    filePath: string;
    id: number;
  }): Promise<BaseResponseBean<PhishingDocument>> {
    let formData = new FormData();
    for (const [key, value] of Object.entries(req)) {
      formData.append(key, value);
    }
    formData.delete("id");
    const res = await axiosForm.post<BaseResponseBean<PhishingDocument>>(
      `/attach/file/update/${req.id}`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async downloadFileAttach(id: number): Promise<any> {
    let res = axios.post<any>(
      `${GATEWAY_URL}/api/v2/scenario/document/download`,
      {
        documentId: id,
      }
    );
    return Promise.resolve(res);
  }
}

const contentApi: ContentApi = new ContentServerApi();

export default contentApi;
