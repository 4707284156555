import { useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  trunkGetPhishingGraph,
  trunkGetStaticsList,
  trunkGetTraineGraphList,
  trunkGetTraineGraphListConfig,
  trunkGetTraineGraphListType,
} from "../store/statics";

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useStatisticsStore = () => {
  const staticsState = useRootState((state) => state.staticStore);

  const dispatch = useAppDispatch();

  const boundGetStaticsList = useMemo(
    () => bindActionCreators(trunkGetStaticsList, dispatch),
    [dispatch]
  );
  const boundGetTraineGraphListConfig = useMemo(
    () => bindActionCreators(trunkGetTraineGraphListConfig, dispatch),
    [dispatch]
  );
  const boundGetTraineGraphListType = useMemo(
    () => bindActionCreators(trunkGetTraineGraphListType, dispatch),
    [dispatch]
  );
  const boundGetTraineGraphList = useMemo(
    () => bindActionCreators(trunkGetTraineGraphList, dispatch),
    [dispatch]
  );
  const boundGetPhshingGraph = useMemo(
    () => bindActionCreators(trunkGetPhishingGraph, dispatch),
    [dispatch]
  );
  return {
    staticsState,
    getStaticsList: boundGetStaticsList,
    getStaticsTraineGraphListConfig: boundGetTraineGraphListConfig,
    getStaticsTraineGraphListType: boundGetTraineGraphListType,
    getStaticsTraineGraphList: boundGetTraineGraphList,
    getPhishingGraph: boundGetPhshingGraph,
  };
};

export default useStatisticsStore;
