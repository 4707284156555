const StatInfoImage = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      width={width || "288"}
      height="160"
      className={classes}
      viewBox="0 0 288 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="136.759"
        y="36"
        width="5.6"
        height="5.6"
        transform="rotate(45 136.759 36)"
        fill="#FFE0A3"
      />
      <rect
        x="77.5593"
        y="117.6"
        width="5.6"
        height="5.6"
        transform="rotate(45 77.5593 117.6)"
        fill="#FFE0A3"
      />
      <path
        d="M222.4 35.2H155.6C150.086 35.2 144.8 39.6678 144.8 45.1792V83.9034C144.8 89.4148 150.086 93.8827 155.6 93.8827H222.4C227.914 93.8827 232.899 89.4148 232.899 83.9034V45.1792C232.899 39.6678 227.914 35.2 222.4 35.2Z"
        fill="#EBF0F2"
      />
      <path
        d="M208.783 45.1971V44.4052C208.783 43.8906 208.365 43.4734 207.851 43.4734H191.333C190.818 43.4734 190.401 43.8906 190.401 44.4052V45.1971C190.401 45.7117 190.818 46.1289 191.333 46.1289H207.851C208.365 46.1289 208.783 45.7117 208.783 45.1971Z"
        fill="#CCD1D9"
      />
      <path
        d="M184.062 45.1886V44.3966C184.062 43.882 183.645 43.4648 183.13 43.4648H155.865C155.35 43.4648 154.932 43.882 154.932 44.3966V45.1886C154.932 45.7032 155.35 46.1204 155.865 46.1204H183.13C183.645 46.1204 184.062 45.7032 184.062 45.1886Z"
        fill="#CCD1D9"
      />
      <path
        d="M184.062 52.9237V52.1317C184.062 51.6171 183.645 51.2 183.13 51.2H155.865C155.35 51.2 154.932 51.6171 154.932 52.1317V52.9237C154.932 53.4383 155.35 53.8555 155.865 53.8555H183.13C183.645 53.8555 184.062 53.4383 184.062 52.9237Z"
        fill="#CCD1D9"
      />
      <path
        d="M214.916 45.1971V44.4052C214.916 43.8906 214.498 43.4734 213.983 43.4734H211.774C211.259 43.4734 210.842 43.8906 210.842 44.4052V45.1971C210.842 45.7117 211.259 46.1289 211.774 46.1289H213.983C214.498 46.1289 214.916 45.7117 214.916 45.1971Z"
        fill="#CCD1D9"
      />
      <path
        d="M225.6 80.8875V59.1127C225.6 56.0682 223.201 53.6001 220.241 53.6001H195.759C192.799 53.6001 190.4 56.0682 190.4 59.1127V80.8875C190.4 83.932 192.799 86.4001 195.759 86.4001H220.241C223.201 86.4001 225.6 83.932 225.6 80.8875Z"
        fill="#64D2D0"
      />
      <path
        d="M211.581 69.675C211.23 69.6768 210.887 69.5745 210.594 69.3811C210.302 69.1877 210.073 68.9119 209.938 68.5886C209.802 68.2652 209.766 67.909 209.833 67.565C209.9 67.2209 210.068 66.9046 210.316 66.6561C210.563 66.4076 210.878 66.2381 211.222 66.169C211.566 66.0999 211.923 66.1344 212.247 66.2681C212.571 66.4019 212.848 66.6288 213.043 66.9201C213.238 67.2114 213.342 67.5541 213.342 67.9046C213.342 68.3725 213.157 68.8214 212.827 69.1532C212.497 69.4849 212.049 69.6725 211.581 69.675V69.675Z"
        fill="white"
      />
      <path
        d="M212.05 66.6005C212.308 66.6005 212.56 66.677 212.775 66.8203C212.989 66.9637 213.157 67.1674 213.255 67.4058C213.354 67.6441 213.38 67.9064 213.33 68.1595C213.279 68.4125 213.155 68.6449 212.972 68.8274C212.79 69.0098 212.557 69.134 212.304 69.1844C212.051 69.2347 211.789 69.2089 211.55 69.1101C211.312 69.0114 211.108 68.8442 210.965 68.6297C210.821 68.4152 210.745 68.163 210.745 67.905C210.745 67.7337 210.778 67.564 210.844 67.4058C210.909 67.2475 211.006 67.1037 211.127 66.9826C211.248 66.8614 211.392 66.7653 211.55 66.6998C211.709 66.6342 211.878 66.6005 212.05 66.6005V66.6005ZM212.05 65.6687C211.607 65.6669 211.173 65.7965 210.804 66.0411C210.435 66.2857 210.147 66.6343 209.976 67.0427C209.805 67.4512 209.76 67.9011 209.845 68.3355C209.93 68.7699 210.143 69.1692 210.455 69.4829C210.768 69.7966 211.166 70.0105 211.6 70.0975C212.035 70.1846 212.485 70.1408 212.894 69.9719C213.304 69.8029 213.654 69.5163 213.9 69.1483C214.146 68.7804 214.277 68.3476 214.277 67.905C214.277 67.3135 214.043 66.7461 213.626 66.327C213.208 65.9079 212.641 65.6712 212.05 65.6687V65.6687Z"
        fill="#64D2D0"
      />
      <path
        d="M210.279 73.1418C209.872 73.1418 209.483 72.9811 209.194 72.6948C208.906 72.4085 208.743 72.0199 208.741 71.6137V71.2876C208.741 70.823 208.925 70.3775 209.254 70.049C209.583 69.7204 210.028 69.5359 210.493 69.5359H213.784C214.188 69.5383 214.576 69.7001 214.862 69.9862C215.148 70.2722 215.31 70.6595 215.312 71.064V71.3901C215.312 71.8547 215.128 72.3003 214.799 72.6288C214.47 72.9573 214.025 73.1418 213.56 73.1418H210.279Z"
        fill="white"
      />
      <path
        d="M213.801 70.0201C214.083 70.0201 214.353 70.1321 214.553 70.3313C214.752 70.5305 214.864 70.8006 214.864 71.0824V71.4085C214.864 71.7495 214.728 72.0766 214.487 72.3177C214.246 72.5588 213.919 72.6943 213.577 72.6943H210.278C209.995 72.6943 209.724 72.5827 209.523 72.3838C209.322 72.1848 209.208 71.9147 209.206 71.6321V71.306C209.206 70.965 209.341 70.6379 209.582 70.3968C209.824 70.1556 210.151 70.0201 210.492 70.0201H213.783H213.801ZM213.783 69.0884H210.492C209.904 69.0908 209.341 69.3253 208.926 69.7406C208.51 70.156 208.276 70.7186 208.273 71.306V71.6321C208.276 72.1618 208.488 72.669 208.864 73.0426C209.239 73.4163 209.748 73.6261 210.278 73.6261H213.559C214.146 73.6236 214.709 73.3892 215.125 72.9739C215.54 72.5585 215.775 71.9959 215.777 71.4085V71.0824C215.777 70.5535 215.567 70.0463 215.193 69.6724C214.819 69.2985 214.312 69.0884 213.783 69.0884Z"
        fill="#64D2D0"
      />
      <path
        d="M204.471 69.8244C204.12 69.8244 203.778 69.7203 203.486 69.5254C203.195 69.3304 202.968 69.0534 202.834 68.7294C202.7 68.4053 202.666 68.0489 202.735 67.7052C202.804 67.3616 202.973 67.0461 203.222 66.7989C203.471 66.5517 203.787 66.3838 204.131 66.3166C204.475 66.2493 204.832 66.2857 205.155 66.4211C205.479 66.5566 205.755 66.7849 205.948 67.0773C206.142 67.3696 206.244 67.7128 206.242 68.0634C206.242 68.2954 206.196 68.5252 206.107 68.7395C206.018 68.9537 205.887 69.1483 205.723 69.3119C205.558 69.4756 205.363 69.6051 205.148 69.693C204.933 69.781 204.703 69.8256 204.471 69.8244V69.8244Z"
        fill="white"
      />
      <path
        d="M204.47 66.7584C204.728 66.7566 204.982 66.8315 205.198 66.9737C205.413 67.116 205.582 67.319 205.682 67.5572C205.783 67.7954 205.81 68.058 205.76 68.3117C205.711 68.5653 205.588 68.7986 205.405 68.982C205.223 69.1653 204.991 69.2905 204.737 69.3416C204.484 69.3927 204.221 69.3674 203.982 69.269C203.743 69.1705 203.539 69.0033 203.395 68.7886C203.251 68.5739 203.174 68.3213 203.174 68.0629C203.173 67.8921 203.206 67.7226 203.27 67.5644C203.335 67.4062 203.43 67.2623 203.55 67.1411C203.671 67.0198 203.814 66.9236 203.972 66.8579C204.13 66.7922 204.299 66.7584 204.47 66.7584ZM204.47 65.8267C204.028 65.8267 203.595 65.9578 203.227 66.2036C202.859 66.4493 202.572 66.7985 202.403 67.2072C202.234 67.6158 202.189 68.0654 202.276 68.4992C202.362 68.933 202.575 69.3314 202.888 69.6442C203.201 69.9569 203.6 70.1699 204.033 70.2562C204.467 70.3425 204.917 70.2982 205.326 70.1289C205.735 69.9597 206.084 69.6731 206.33 69.3053C206.576 68.9376 206.707 68.5052 206.707 68.0629C206.708 67.7689 206.651 67.4776 206.539 67.2057C206.427 66.9338 206.263 66.6868 206.055 66.4789C205.847 66.271 205.6 66.1063 205.328 65.9944C205.056 65.8824 204.764 65.8254 204.47 65.8267V65.8267Z"
        fill="#64D2D0"
      />
      <path
        d="M202.718 73.2903C202.312 73.2903 201.924 73.1293 201.637 72.8427C201.35 72.5561 201.189 72.1674 201.189 71.7622V71.4361C201.189 70.9715 201.374 70.5259 201.702 70.1974C202.031 69.8689 202.477 69.6843 202.942 69.6843H206.223C206.631 69.6843 207.022 69.8463 207.31 70.1346C207.599 70.4229 207.761 70.814 207.761 71.2217V71.5385C207.761 72.0031 207.576 72.4487 207.248 72.7772C206.919 73.1057 206.473 73.2903 206.008 73.2903H202.718Z"
        fill="white"
      />
      <path
        d="M206.223 70.1505C206.507 70.153 206.778 70.2666 206.978 70.467C207.179 70.6675 207.293 70.9386 207.295 71.222V71.5389C207.295 71.8799 207.16 72.2069 206.918 72.4481C206.677 72.6892 206.35 72.8247 206.009 72.8247H202.718C202.436 72.8247 202.166 72.7128 201.967 72.5136C201.768 72.3144 201.656 72.0442 201.656 71.7625V71.4364C201.656 71.0953 201.791 70.7683 202.032 70.5271C202.274 70.286 202.601 70.1505 202.942 70.1505H206.223V70.1505ZM206.223 69.2188H202.942C202.354 69.2212 201.791 69.4556 201.376 69.871C200.96 70.2863 200.726 70.849 200.723 71.4364V71.7625C200.723 72.2913 200.934 72.7985 201.308 73.1724C201.682 73.5464 202.189 73.7565 202.718 73.7565H206.009C206.597 73.7565 207.161 73.5228 207.577 73.1069C207.994 72.6911 208.227 72.127 208.227 71.5389V71.222C208.227 70.6907 208.016 70.1812 207.64 69.8055C207.264 69.4298 206.755 69.2187 206.223 69.2188V69.2188Z"
        fill="#64D2D0"
      />
      <path
        d="M208.2 69.7031C207.811 69.7031 207.43 69.5877 207.107 69.3717C206.783 69.1557 206.531 68.8486 206.382 68.4894C206.234 68.1302 206.195 67.7349 206.271 67.3535C206.346 66.9721 206.534 66.6218 206.809 66.3468C207.084 66.0719 207.434 65.8846 207.816 65.8088C208.197 65.7329 208.593 65.7718 208.952 65.9207C209.312 66.0695 209.619 66.3214 209.835 66.6448C210.051 66.9681 210.167 67.3482 210.167 67.737C210.168 67.9956 210.118 68.2518 210.019 68.4908C209.921 68.7299 209.776 68.9472 209.593 69.13C209.41 69.3128 209.193 69.4576 208.954 69.5559C208.715 69.6543 208.458 69.7043 208.2 69.7031Z"
        fill="white"
      />
      <path
        d="M208.2 66.2926C208.498 66.2908 208.789 66.3774 209.037 66.5416C209.286 66.7057 209.479 66.9399 209.594 67.2144C209.709 67.4889 209.739 67.7913 209.682 68.0831C209.624 68.375 209.481 68.6432 209.271 68.8535C209.06 69.0639 208.792 69.2069 208.5 69.2645C208.208 69.3221 207.905 69.2916 207.631 69.1769C207.356 69.0622 207.122 68.8685 206.958 68.6204C206.794 68.3723 206.707 68.0809 206.709 67.7835C206.711 67.3888 206.869 67.011 207.148 66.732C207.427 66.4529 207.805 66.2951 208.2 66.2926V66.2926ZM208.2 65.3609C207.719 65.3609 207.248 65.5037 206.848 65.7714C206.447 66.039 206.135 66.4194 205.952 66.8643C205.768 67.3092 205.72 67.7987 205.815 68.2707C205.909 68.7427 206.142 69.176 206.483 69.5157C206.824 69.8554 207.259 70.0863 207.731 70.1791C208.204 70.2719 208.693 70.2224 209.138 70.0369C209.582 69.8514 209.961 69.5382 210.228 69.1371C210.494 68.7359 210.635 68.2648 210.633 67.7835C210.633 67.4645 210.57 67.1487 210.448 66.8542C210.325 66.5597 210.146 66.2922 209.92 66.0671C209.694 65.842 209.426 65.6638 209.131 65.5426C208.835 65.4214 208.519 65.3596 208.2 65.3609V65.3609Z"
        fill="#64D2D0"
      />
      <path
        d="M206.186 73.6909C205.737 73.6885 205.308 73.5087 204.992 73.191C204.676 72.8732 204.498 72.4433 204.498 71.9951V71.6224C204.498 71.1084 204.703 70.6154 205.066 70.252C205.43 69.8885 205.923 69.6843 206.437 69.6843H210.166C210.614 69.6843 211.044 69.8617 211.362 70.1777C211.68 70.4937 211.86 70.9227 211.863 71.3708V71.7435C211.86 72.2593 211.654 72.7532 211.289 73.1178C210.924 73.4825 210.43 73.6885 209.914 73.6909H206.186Z"
        fill="white"
      />
      <path
        d="M210.213 70.1505C210.538 70.1505 210.85 70.2788 211.08 70.5075C211.311 70.7361 211.441 71.0465 211.444 71.3711V71.7438C211.444 71.9384 211.406 72.131 211.331 72.3108C211.257 72.4905 211.147 72.6539 211.01 72.7914C210.872 72.929 210.709 73.0381 210.529 73.1126C210.349 73.187 210.156 73.2254 209.962 73.2254H206.233C206.072 73.2254 205.912 73.1935 205.764 73.1316C205.615 73.0696 205.48 72.9789 205.366 72.8646C205.253 72.7502 205.163 72.6146 205.102 72.4654C205.041 72.3162 205.011 72.1565 205.012 71.9954V71.6227C205.012 71.4294 205.05 71.2379 205.124 71.0593C205.198 70.8807 205.307 70.7184 205.443 70.5817C205.58 70.445 205.742 70.3366 205.921 70.2626C206.1 70.1886 206.291 70.1505 206.485 70.1505H210.213V70.1505ZM210.213 69.2188H206.485C205.848 69.2212 205.237 69.4753 204.787 69.9256C204.336 70.3759 204.082 70.9859 204.08 71.6227V71.9954C204.079 72.2789 204.133 72.5597 204.241 72.822C204.349 73.0842 204.507 73.3226 204.707 73.5234C204.907 73.7243 205.145 73.8836 205.407 73.9924C205.669 74.1011 205.949 74.1571 206.233 74.1571H209.962C210.279 74.1571 210.593 74.0947 210.886 73.9734C211.179 73.8521 211.445 73.6744 211.669 73.4503C211.893 73.2262 212.071 72.9602 212.192 72.6674C212.314 72.3746 212.376 72.0608 212.376 71.7438V71.3711C212.374 70.7994 212.145 70.252 211.739 69.8486C211.334 69.4452 210.785 69.2187 210.213 69.2188V69.2188Z"
        fill="#64D2D0"
      />
      <path
        opacity="0.4"
        d="M208.292 80.3722C214.032 80.3722 218.686 75.7208 218.686 69.983C218.686 64.2452 214.032 59.5938 208.292 59.5938C202.552 59.5938 197.898 64.2452 197.898 69.983C197.898 75.7208 202.552 80.3722 208.292 80.3722Z"
        stroke="white"
        stroke-width="1.2"
        stroke-miterlimit="10"
      />
      <path
        d="M208.293 79.6824C205.775 79.6044 203.386 78.5497 201.633 76.7418C199.879 74.9339 198.899 72.5147 198.899 69.9967C198.899 67.4787 199.879 65.0595 201.633 63.2516C203.386 61.4437 205.775 60.3891 208.293 60.311V58.9133C206.1 58.9133 203.956 59.5632 202.132 60.7807C200.308 61.9983 198.886 63.7289 198.046 65.7539C197.206 67.7789 196.985 70.0073 197.412 72.1577C197.838 74.308 198.893 76.2838 200.442 77.8352C201.992 79.3866 203.967 80.4441 206.117 80.8741C208.268 81.304 210.498 81.0871 212.525 80.2507C214.552 79.4143 216.286 77.9961 217.507 76.1751C218.728 74.3541 219.382 72.2122 219.385 70.02H217.987C217.98 72.5852 216.955 75.0428 215.138 76.8541C213.321 78.6653 210.859 79.6824 208.293 79.6824Z"
        fill="white"
      />
      <path
        d="M173.6 66.3999L102.88 66.4C97.7548 66.4 93.6 70.8748 93.6 76.3946V114.805C93.6 120.325 97.7548 124.8 102.88 124.8L173.6 124.8C178.725 124.8 182.88 120.325 182.88 114.805V76.3945C182.88 70.8747 178.725 66.3999 173.6 66.3999Z"
        fill="#FFAA19"
      />
      <path
        opacity="0.4"
        d="M119.769 97.417H133.845C133.846 100.029 133.121 102.591 131.752 104.816C130.383 107.041 128.424 108.843 126.091 110.022C123.758 111.2 121.144 111.708 118.54 111.489C115.936 111.27 113.443 110.333 111.34 108.783C109.237 107.232 107.605 105.129 106.627 102.706C105.648 100.284 105.362 97.6377 105.799 95.0621C106.236 92.4865 107.379 90.0826 109.102 88.118C110.824 86.1534 113.058 84.7051 115.556 83.9343L119.769 97.417Z"
        fill="white"
      />
      <path
        opacity="0.8"
        d="M121.419 95.0967L117.634 81.5023C118.866 81.1569 120.14 80.9813 121.419 80.9805C125.158 80.9976 128.738 82.4891 131.383 85.1307C134.027 87.7723 135.522 91.3505 135.541 95.0874L121.419 95.0967Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M119.769 97.4172H133.844C133.846 100.289 132.972 103.092 131.339 105.454C129.705 107.816 127.39 109.624 124.701 110.637C122.013 111.649 119.08 111.818 116.293 111.121C113.507 110.424 110.999 108.894 109.105 106.735L119.769 97.4172Z"
        fill="white"
      />
      <path
        d="M150 104.778C150.87 104.778 151.575 104.073 151.575 103.204C151.575 102.334 150.87 101.629 150 101.629H143.975C143.105 101.629 142.4 102.334 142.4 103.204C142.4 104.073 143.105 104.778 143.975 104.778L150 104.778Z"
        fill="white"
      />
      <path
        d="M153.6 96.7463C154.47 96.7463 155.175 96.0413 155.175 95.1716C155.175 94.3019 154.47 93.5969 153.6 93.5969H143.975C143.105 93.5969 142.4 94.3019 142.4 95.1716C142.4 96.0413 143.105 96.7463 143.975 96.7463L153.6 96.7463Z"
        fill="white"
      />
      <path
        d="M162.4 88.7048C163.27 88.7048 163.975 87.9998 163.975 87.1301C163.975 86.2604 163.27 85.5554 162.4 85.5554H143.975C143.105 85.5554 142.4 86.2604 142.4 87.1301C142.4 87.9998 143.105 88.7048 143.975 88.7048H162.4Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M103.593 66.3999H118.768V85.203C118.768 88.4155 117.492 91.4965 115.219 93.7681C112.946 96.0398 109.864 97.316 106.65 97.316H93.6V76.3791C93.6 75.0679 93.8585 73.7694 94.3608 72.5581C94.8631 71.3468 95.5993 70.2462 96.5274 69.3195C97.4554 68.3927 98.5571 67.6578 99.7694 67.1569C100.982 66.6559 102.281 66.3987 103.593 66.3999V66.3999Z"
        fill="#B96700"
      />
      <path
        d="M105.164 43.2H65.1834C59.6697 43.2 55.2 47.6678 55.2 53.1792V83.7971C55.2 89.3084 59.6697 93.7763 65.1834 93.7763H105.164C110.678 93.7763 115.147 89.3084 115.147 83.7971V53.1792C115.147 47.6678 110.678 43.2 105.164 43.2Z"
        fill="#EBF0F2"
      />
      <path
        d="M65.1834 43.2H105.164C106.476 43.2 107.775 43.4584 108.987 43.9605C110.198 44.4626 111.299 45.1984 112.227 46.1261C113.154 47.0537 113.889 48.1549 114.39 49.3668C114.891 50.5786 115.149 51.8772 115.147 53.1885V75.4205H55.2V53.1885C55.1987 51.8772 55.4561 50.5786 55.9572 49.3668C56.4584 48.1549 57.1936 47.0537 58.1208 46.1261C59.0479 45.1984 60.1489 44.4626 61.3608 43.9605C62.5726 43.4584 63.8716 43.2 65.1834 43.2Z"
        fill="#4892FB"
      />
      <path
        d="M64.6516 67.4164H74.3461L80.9458 60.5027H89.4565L96.4198 53.2163H105.797"
        stroke="white"
        stroke-width="1.6"
        stroke-miterlimit="10"
      />
      <path
        d="M73.2646 84.2634C73.2646 81.1243 70.7188 78.5796 67.5784 78.5796C64.438 78.5796 61.8922 81.1243 61.8922 84.2634C61.8922 87.4024 64.438 89.9472 67.5784 89.9472C70.7188 89.9472 73.2646 87.4024 73.2646 84.2634Z"
        fill="#858F99"
      />
      <path
        d="M67.5791 84.0493C68.3874 84.0493 69.0426 83.3943 69.0426 82.5864C69.0426 81.7785 68.3874 81.1235 67.5791 81.1235C66.7708 81.1235 66.1156 81.7785 66.1156 82.5864C66.1156 83.3943 66.7708 84.0493 67.5791 84.0493Z"
        fill="white"
      />
      <path
        d="M69.3223 87.3843H65.8453C65.6898 87.3843 65.5359 87.3537 65.3923 87.2942C65.2486 87.2347 65.1181 87.1476 65.0082 87.0377C64.8983 86.9278 64.8111 86.7973 64.7516 86.6538C64.6921 86.5102 64.6615 86.3563 64.6615 86.2009C64.6615 85.7808 64.8284 85.3779 65.1256 85.0809C65.4228 84.7838 65.8259 84.6169 66.2462 84.6169H68.9121C69.3324 84.6169 69.7355 84.7838 70.0327 85.0809C70.3299 85.3779 70.4968 85.7808 70.4968 86.2009C70.4968 86.3563 70.4662 86.5102 70.4067 86.6538C70.3472 86.7973 70.26 86.9278 70.1501 87.0377C70.0402 87.1476 69.9096 87.2347 69.766 87.2942C69.6224 87.3537 69.4684 87.3843 69.313 87.3843H69.3223Z"
        fill="white"
      />
      <path
        d="M90.0062 85.9678V85.9585C90.0062 85.4593 89.6014 85.0547 89.102 85.0547H78.8762C78.3768 85.0547 77.972 85.4593 77.972 85.9585V85.9678C77.972 86.467 78.3768 86.8716 78.8762 86.8716H89.102C89.6014 86.8716 90.0062 86.467 90.0062 85.9678Z"
        fill="#858F99"
      />
      <path
        d="M99.8422 82.139V82.1296C99.8422 81.6305 99.4373 81.2258 98.938 81.2258H78.8871C78.3877 81.2258 77.9829 81.6305 77.9829 82.1296V82.139C77.9829 82.6381 78.3877 83.0428 78.8871 83.0428H98.938C99.4373 83.0428 99.8422 82.6381 99.8422 82.139Z"
        fill="#858F99"
      />
      <path d="M192.8 126.4L209.2 142.8" stroke="#64D2D0" stroke-width="3.2" />
      <circle
        cx="26.4"
        cy="26.4"
        r="24.8"
        transform="matrix(-1 0 0 1 200.8 82.3999)"
        fill="white"
        fill-opacity="0.2"
        stroke="#D1D6DB"
        stroke-width="3.2"
      />
      <path
        d="M172.8 96L156.8 112"
        stroke="white"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M177.6 103.2L169.6 111.2"
        stroke="white"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="107.2" cy="18.4" r="9.6" fill="#FFC34E" />
      <path
        d="M106.226 19.9774C106.226 19.3655 106.3 18.8781 106.448 18.5153C106.597 18.1525 106.868 17.7962 107.261 17.4465C107.659 17.0924 107.924 16.8061 108.055 16.5876C108.186 16.3646 108.252 16.1308 108.252 15.886C108.252 15.1472 107.911 14.7779 107.229 14.7779C106.905 14.7779 106.645 14.8784 106.448 15.0795C106.256 15.2762 106.156 15.5494 106.147 15.8991H104.245C104.254 15.0642 104.523 14.4107 105.052 13.9386C105.585 13.4665 106.311 13.2305 107.229 13.2305C108.155 13.2305 108.874 13.4556 109.386 13.9058C109.897 14.3517 110.153 14.9833 110.153 15.8007C110.153 16.1723 110.07 16.5242 109.904 16.8564C109.738 17.1842 109.447 17.5492 109.032 17.9514L108.501 18.4563C108.169 18.7754 107.978 19.1491 107.93 19.5775L107.904 19.9774H106.226ZM106.035 21.9904C106.035 21.6975 106.134 21.4571 106.33 21.2691C106.532 21.0768 106.787 20.9806 107.098 20.9806C107.408 20.9806 107.661 21.0768 107.858 21.2691C108.059 21.4571 108.16 21.6975 108.16 21.9904C108.16 22.2789 108.061 22.5171 107.865 22.7051C107.672 22.893 107.417 22.987 107.098 22.987C106.778 22.987 106.521 22.893 106.324 22.7051C106.132 22.5171 106.035 22.2789 106.035 21.9904Z"
        fill="white"
      />
      <circle cx="122.565" cy="142.566" r="4.96552" fill="#C5DAFF" />
      <path
        d="M122.063 143.382C122.063 143.065 122.102 142.813 122.179 142.626C122.255 142.438 122.396 142.254 122.599 142.073C122.805 141.89 122.942 141.742 123.009 141.629C123.077 141.513 123.111 141.392 123.111 141.266C123.111 140.884 122.935 140.692 122.582 140.692C122.415 140.692 122.28 140.744 122.179 140.848C122.079 140.95 122.027 141.092 122.023 141.272H121.039C121.044 140.841 121.183 140.503 121.456 140.258C121.732 140.014 122.107 139.892 122.582 139.892C123.062 139.892 123.433 140.009 123.698 140.241C123.962 140.472 124.095 140.799 124.095 141.222C124.095 141.414 124.052 141.596 123.966 141.768C123.88 141.937 123.73 142.126 123.515 142.334L123.24 142.595C123.068 142.76 122.97 142.953 122.945 143.175L122.931 143.382H122.063ZM121.965 144.423C121.965 144.272 122.016 144.147 122.118 144.05C122.222 143.951 122.354 143.901 122.514 143.901C122.675 143.901 122.806 143.951 122.908 144.05C123.012 144.147 123.064 144.272 123.064 144.423C123.064 144.572 123.013 144.696 122.911 144.793C122.812 144.89 122.679 144.939 122.514 144.939C122.349 144.939 122.216 144.89 122.114 144.793C122.015 144.696 121.965 144.572 121.965 144.423Z"
        fill="white"
      />
      <circle cx="220.248" cy="109.848" r="7.44828" fill="#4593FC" />
      <path
        d="M219.493 111.072C219.493 110.598 219.55 110.219 219.666 109.938C219.781 109.656 219.991 109.38 220.296 109.109C220.605 108.834 220.81 108.612 220.912 108.442C221.014 108.269 221.065 108.088 221.065 107.898C221.065 107.325 220.8 107.038 220.271 107.038C220.02 107.038 219.818 107.116 219.666 107.272C219.516 107.425 219.438 107.637 219.432 107.908H217.956C217.963 107.26 218.172 106.753 218.582 106.387C218.996 106.021 219.559 105.838 220.271 105.838C220.99 105.838 221.548 106.012 221.945 106.362C222.341 106.708 222.54 107.198 222.54 107.832C222.54 108.12 222.475 108.393 222.347 108.651C222.218 108.905 221.992 109.188 221.67 109.5L221.258 109.892C221 110.14 220.853 110.43 220.815 110.762L220.795 111.072H219.493ZM219.345 112.634C219.345 112.407 219.421 112.22 219.574 112.075C219.73 111.925 219.928 111.851 220.169 111.851C220.41 111.851 220.607 111.925 220.759 112.075C220.915 112.22 220.993 112.407 220.993 112.634C220.993 112.858 220.917 113.043 220.764 113.189C220.615 113.334 220.417 113.407 220.169 113.407C219.922 113.407 219.722 113.334 219.569 113.189C219.42 113.043 219.345 112.858 219.345 112.634Z"
        fill="white"
      />
    </svg>
  );
};

export default StatInfoImage;
