import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { useEffect, useRef, useState } from "react";
import { SiteInfo, SiteResourceBean } from "../../../types/Info";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

interface RegitOrEditResourceModalProps {
  isOpen: boolean;
  onClose: () => void;
  resourceId?: number;
}

const RegitOrEditResourceModal: React.FC<RegitOrEditResourceModalProps> = ({
  isOpen,
  onClose,
  resourceId,
}) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<any>(null);
  const [content, setContent] = useState<string>();
  const [file, setFile] = useState<File | null>();
  const [resourceName, setResourceName] = useState<string | null>();

  const {
    contentState,
    getSiteResource,
    insertSiteResource,
    updateSiteResource,
  } = useContentStore();
  const toast = useToast();

  useEffect(() => {
    setResourceName(
      resourceId
        ? contentState.siteResources.filter(
            (item: SiteResourceBean) => item.resourceId === resourceId
          )[0].resourceName
        : null
    );
    setFile(null);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
  }, [isOpen, resourceId]);

  // useEffect(() => {
  //   setResourceName(null);
  //   setFile(null);
  // }, [isOpen]);

  const onSubmit = async () => {
    if (resourceName) {
      if (/^\s*$/.test(resourceName)) {
        toast.toastMsg(nanoid(), t("common.msg.nameEmptyMsg"), "error");
        return;
      }

      if (resourceName?.length > 128) {
        toast.toastMsg(nanoid(), t("common.msg.fieldLengthLimitMsg"), "error");
        return;
      }
      const ext = ["gif", "svg", "png", "jpg"];

      if (resourceId) {
        const resp: any = await updateSiteResource({
          request: { resourceName, resourceId: resourceId },
          file: file ?? null,
        });
        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("resource.msg.updateSuccessMsg"),
            "success"
          );
        } else if (resp.payload && resp.payload.code === "DATA_DUPLICATE") {
          toast.toastMsg(
            nanoid(),
            t("image.msg.imageDuplicatedFileNameMsg"),
            "error"
          );
          return;
        } else {
          toast.toastMsg(nanoid(), t("resource.msg.updateFailMsg"), "error");
        }
      } else {
        if (!file) {
          toast.toastMsg(nanoid(), t("image.msg.nofilePlaceholder"), "error");

          return;
        }
        const resp: any = await insertSiteResource({
          request: { resourceName },
          file,
        });
        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(nanoid(), t("resource.msg.addSuccessMsg"), "success");
          getSiteResource();
        } else if (resp.payload && resp.payload.code === "DATA_DUPLICATE") {
          toast.toastMsg(
            nanoid(),
            t("image.msg.imageDuplicatedFileNameMsg"),
            "error"
          );
          return;
        } else {
          toast.toastMsg(nanoid(), t("resource.msg.addFailMsg"), "error");
        }
      }

      setFile(null);
      setResourceName(null);
      onClose();
    } else {
      toast.toastMsg(nanoid(), t("common.msg.nameEmptyMsg"), "error");
    }
  };

  const handleFileClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleFileChange = (e: any) => {
    let value = "";
    const name = e.target.name;
    console.log(e);

    if (name === "file") {
      const ext = ["gif", "svg", "png", "jpg"];

      const fileName = e.target.files[0] && e.target.files[0].name;

      const idx = fileName ? fileName.lastIndexOf(".") : -1;
      if (idx > 0) {
        const extension = fileName.substring(idx + 1);
        // 확장자 체크
        if (!ext.includes(extension.toLowerCase())) {
          toast.toastMsg(
            nanoid(),
            t("image.msg.imageInvalidExtenstionMsg"),
            "error"
          );
          e.target.value = "";
          return;
        }
      }

      // 사이즈 체크
      if (e.target.files[0] && e.target.files[0].size > 20971520) {
        e.target.value = "";
        return;
      }

      setFile(e.target.files[0]);
    } else {
      value = e.target.value.trim();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      width={480}
      title={
        resourceId
          ? t("resource.phishingInfoImageEdit")
          : t("resource.phishingInfoImageAdd")
      }
      isDivider={false}
      footer={
        <Box justifyContent="flex-end" width="100%">
          <Button color="secondary" onClick={onClose}>
            {t("common.button.cancel")}
          </Button>
          <Button classes="mg ml-8" onClick={onSubmit}>
            {resourceId ? t("common.button.update") : t("common.button.add")}
          </Button>
        </Box>
      }
    >
      <Box direction="column">
        <Box direction="column">
          <Typography fontWeight={700}>
            {t("resource.phishingInfoImageName")}
          </Typography>
          <TextField
            classes="mg mt-12"
            height={40}
            borderRadius={8}
            text={resourceName ?? ""}
            width={424}
            onChange={(e: any) => setResourceName(e.target.value)}
            hasText
            maxLength={128}
            placeholder={t("resource.msg.imageNamePlaceholder")}
          />
        </Box>
        <Box direction="column" classes="mg mt-32">
          <Typography fontWeight={700}>
            {t("resource.phishingInfoImageFileUpload")}
          </Typography>
          <Box
            alignItems="center"
            // justifyContent="center"
            width={600}
            height={50}
            classes="mg mt-12"
          >
            <input
              style={{ display: "none" }}
              name="file"
              type="file"
              onChange={handleFileChange}
              className="modalField hide"
              ref={hiddenFileInput}
              accept="image/*"
            />

            <TextField
              readOnly
              name="fileName"
              hasText
              text={file ? file.name : ""}
              placeholder={t("resource.msg.nofilePlaceholder")}
              height={40}
              width={329}
            />

            <Button
              color="secondary"
              onClick={handleFileClick}
              classes="mg mt-10 ml-8"
              width={87}
              padding={10}
            >
              {t("common.button.selectFile")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default RegitOrEditResourceModal;
