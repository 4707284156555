import "./index.scss";
import { Box } from "fasoo-ui-component-next";
import { ImagesInfo } from "../../types/Info";
import sanitizeHtml from "sanitize-html";
import { useEffect } from "react";
import { Scenario } from "../../types/Scenario";

interface HTMLThumbnailProps {
  mail: Scenario;
  image?: ImagesInfo;
  width?: number | string;
  height?: number | string;
}
const HTMLThumbnail: React.FC<HTMLThumbnailProps> = ({
  mail,
  image,
  width = 200,
  height = 300,
}) => {
  console.log(mail.templateHtml);
  useEffect(() => {
    const template = document.getElementById(
      "innerThumbnail-" + mail.templateName
    );
    if (!template) return;
    let width = template.offsetWidth;
    template.style.transform =
      width && width > 200 ? `scale(${200 / width})` : "";
  }, [mail]);
  return (
    <Box width={width} height={height} classes="htmlThumbnail">
      <span
        id={"innerThumbnail-" + mail.templateName}
        className="innerThumbnail"
        dangerouslySetInnerHTML={
          mail.templateHtml
            ? {
                __html: sanitizeHtml(mail.templateHtml, {
                  allowedTags: sanitizeHtml.defaults.allowedTags.filter(
                    (tag) => tag !== "script"
                  ),
                  allowedAttributes: sanitizeHtml.defaults.allowedAttributes,
                  parseStyleAttributes: false,
                }),
              }
            : undefined
        }
      />
    </Box>
  );
};
export default HTMLThumbnail;
