import { useState } from "react";

export default function useCheckboxGroup<T>(
  initialSelected: T[] = [],
  equals: (o1: T, o2: T) => boolean = (o1: T, o2: T) => o1 === o2
): {
  selected: T[];
  handleClick: (id: T) => T[];
  handleAllClick: (allIds: T[]) => void;
  isSelected: (id: T) => boolean;
  initSelected: (initIds: T[]) => void;
} {
  const [selected, setSelected] = useState(initialSelected);

  const handleAllClick = (allIds: T[]): void => {
    setSelected(allIds);
  };
  const handleClick = (id: T): T[] => {
    const selectedIndex = selected.findIndex((o) => equals(o, id));
    let newSelected: T[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);

    return newSelected;
  };
  const isSelected = (id: T): boolean => {
    let selectedIndex = -1;
    if (typeof id === "string")
      selectedIndex = selected.findIndex((o) => equals(o, id));
    else {
      selected.map((item, index) => {
        return JSON.stringify(id) === JSON.stringify(item)
          ? (selectedIndex = index)
          : "";
      });
    }
    return selectedIndex >= 0;
  };
  const initSelected = (initIds: T[]): void => {
    setSelected([]);
  };

  return { selected, handleClick, handleAllClick, isSelected, initSelected };
}
