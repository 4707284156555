import { Box } from "fasoo-ui-component-next";
import CategoryTag from ".";
import { useEffect, useRef, useState } from "react";

interface CategoryTagListProps {
  categorys: string[];
  //   boxWidth: number;
}

const CategoryTagList: React.FC<CategoryTagListProps> = ({
  categorys,
  //   boxWidth,
}) => {
  const [visibleCount, setVisibleCount] = useState(categorys.length);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateVisibleCount = () => {
      if (boxRef.current) {
        const boxWidth = boxRef.current.offsetWidth;
        let totalWidth = 0;
        let count = 0;

        for (let i = 0; i < categorys.length; i++) {
          const tagWidth = measureTextWidth(categorys[i]);
          if (totalWidth + tagWidth + 4 < boxWidth - 50) {
            totalWidth += tagWidth + 4;
            count++;
          } else {
            break;
          }
        }
        setVisibleCount(count);
      }
    };

    updateVisibleCount();
    window.addEventListener("resize", updateVisibleCount);

    return () => {
      window.removeEventListener("resize", updateVisibleCount);
    };
  }, [categorys]);

  const measureTextWidth = (text: string): number => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = "12px NotoSansKR";
      const metrics = context.measureText(text);
      return metrics.width + 20;
    }
    return 0;
  };

  return (
    <Box
      //   classes=""
      divRef={boxRef}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      {categorys.slice(0, visibleCount).map((c) => (
        <CategoryTag key={c} text={c} />
      ))}
      {visibleCount < categorys.length && (
        <CategoryTag key={"..."} text={"..."} />
      )}
    </Box>
  );
};

export default CategoryTagList;
